import React, { Component } from "react";
import DisplayData from "./displaydata";
import YourApps from "./apps";
import Footer from "./footer";
import { Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import "./style.css";
import { connect } from "react-redux";
import { devAdmindetails } from "../../common/devapicalls";
import {
  sessionCheck,
  closeWarningFunc,
  priceModalOpen,
  toastify,
  totalStorageUsed,
} from "../../action";
import CreateApp from "./createapp";
import {
  storageUsed,
  getPostCallCount,
  devStorageUsed,
  fetchbandwidth,
} from "../../common/apicalls";
import { getPriceDetails } from "../../common/paymentapi";
import {
  getParticularNetworkDetails,
  getPartOfNetwork,
} from "../../common/networkapicalls";

import NetworkTabs from "./networktabs";

class Home extends Component {
  state = {
    networkRedirect: false,
    redirectCreateApp: false,
    storageUsedVal: 0,
    networkList: [],
    appCreateSuccess: false,
    networkListErr: "",
    createAppErr: false,
    createAppErrVal: "developer validation failed",
    priceDetails: [],
    postCount: 0,
    bandwidthVal: 0,
  };
  componentDidMount() {
    if (this.props.plan == -1) {
      this.props.priceModalOpen();
      return;
    }
    this.getNetworks();
    this.getStorage();
    this.setPricePlan();
    // this.getPostCount();
    this.getBandwidth();
  }

  checkUpgrade = () => {
    Swal.fire({
      html: "<p style='font-weight: 600;font-size: 20px'>Want to get more premium access?</p><p>Please upgrade your plan</p>",
      icon: "info",
      confirmButtonColor: "#122c50",
      confirmButtonText: "Upgrade",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ networkRedirect: true });
      }
    });
  };

  checkWalletStatus = async () => {
    if (this.state.networkListErr == "error") {
      this.setState({ createAppErr: true });
      setTimeout(() => {
        this.setState({ createAppErr: false });
      }, 3000);
      return;
    }

    let _val = await devAdmindetails();
    let _appsVal = _val.data.appList;
    let list = Object.values(_appsVal);
    // if (this.props.plan == 0) {
    //   console.log("blocking here");
    //   this.props.priceModalOpen();
    //   return;
    // }

    let _getPriceDetail = this.state.priceDetails.filter(
      (data) => data.planName == this.props.plan
    );

    let appCountCheck = 0;
    if (_getPriceDetail) {
      appCountCheck = _getPriceDetail[0].maxNoApps;
    }

    if (list.length >= appCountCheck) {
      this.props.priceModalOpen();
      return;
    }

    if (this.props.appCount >= appCountCheck) {
      this.props.priceModalOpen();
      return;
    }

    let _res = await window.moi_id.isWalletUnlocked();
    if (!_res) {
      this.props.setSession();
    } else {
      this.setState({ redirectCreateApp: true });
      this.getNetworks();
    }
  };
  handleCloseCreateApp = () => {
    this.setState({ redirectCreateApp: false });
  };

  successMsg = () => {
    this.setState({ redirectCreateApp: false });
    this.props.toastifyEnable("info", "App created successfully");
  };
  errMsg = (data) => {
    this.props.toastifyEnable("err", data);
  };

  setPricePlan = async () => {
    let _res = await getPriceDetails();
    this.setState({ priceDetails: _res.data });
  };

  networkListCheck = (val) => {
    if (val == "error") {
      this.setState({ networkListErr: val });
    }
  };

  getBandwidth = async () => {
    try {
      const currentTimestamp = new Date().getTime();
      let bandwidth = await fetchbandwidth(0, currentTimestamp);
      let lastbandwidth = bandwidth.pop();
      this.setState({ bandwidthVal: lastbandwidth.total_bandwidth });
      this.setState({ postCount: bandwidth.length });
    } catch (err) {
      this.setState({ bandwidthVal: 0 });
    }
  };

  getPostCount = async () => {
    try {
      let _res = await getPostCallCount();
      if (typeof _res.data == "string") {
        this.props.toastifyEnable("err", _res.data);
        return;
      }
      this.setState({ postCount: _res.data._count });
    } catch (err) {
      this.props.toastifyEnable("err", err.message);
    }
  };

  getStorage = async () => {
    try {
      var storage = 0;
      storage += (await storageUsed(undefined, 1, undefined)).consumedStorage;
      this.setState({ storageUsedVal: storage });
      this.props.totalStorageUsed(storage);
    } catch (err) {
      this.setState({ storageUsedVal: 0 });
    }
  };

  getNetworks = async () => {
    try {
      let _partofNetwork = await getPartOfNetwork();
      let _partofNetworlVal = _partofNetwork.data;

      if (typeof _partofNetwork == "string" && _partofNetwork) {
        this.props.toastifyEnable("err", _partofNetwork);
        return;
      }
      if (!_partofNetworlVal) {
        this.props.toastifyEnable("err", _partofNetwork);
        return;
      }

      var newPartof = [];
      for (let i = 0; i < _partofNetworlVal.length; i++) {
        let res = await getParticularNetworkDetails(
          _partofNetworlVal[i].network_id
        );
        newPartof.push({
          isActive: _partofNetworlVal[i].isActive,
          network_name: _partofNetworlVal[i].networkName,
          network_id: _partofNetworlVal[i].network_id,
          network_token: _partofNetworlVal[i].network_token,
          type: res.networkType,
        });
      }
      this.setState({ networkList: newPartof });
      // console.log("networkList", this.state.networkList);
    } catch (err) {
      this.setState({ networkList: [] });
    }
  };

  render() {
    if (this.state.networkRedirect) {
      return <Redirect push to="/pricing" />;
    }
    return (
      <div className="container">
        {this.state.redirectCreateApp ? (
          <CreateApp
            show={this.state.redirectCreateApp}
            handleClose={this.handleCloseCreateApp}
            networkList={this.state.networkList}
            successMsg={this.successMsg}
            plan={this.props.plan}
            errMsg={this.errMsg}
          />
        ) : null}

        {!this.props.closeWarning ? (
          <div style={{ marginTop: "30px" }}>
            <div className="alert alert-warning" role="alert" id="cardwarn">
              <Button
                id="closebtn"
                size="sm"
                variant="danger"
                onClick={() => this.props.handleCloseWarning()}
              >
                X
              </Button>
              <div className="warningcss">
                <div>
                  <strong>Warning!</strong>
                  <p style={{ marginBottom: "0px" }}>
                    You are exceeding your 1GB storage. Please upgrade the plan
                    for more access.{" "}
                  </p>
                </div>
                <div>
                  <Button
                    variant="danger"
                    size="sm"
                    style={{ marginTop: "8px", fontWeight: "600" }}
                  >
                    Upgrade!
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="col-md-8">
            <div style={{ marginTop: "30px" }}>
              <p
                style={{ fontSize: "18px", fontWeight: "600" }}
                className="heading-css"
              >
                Your Networks
              </p>
              <NetworkTabs networkListCheck={this.networkListCheck} />
            </div>
            <div>
              <div className="cardcss">
                <p
                  style={{ fontSize: "18px", fontWeight: "600" }}
                  className="heading-css"
                >
                  Your Apps
                </p>
                <Button
                  className="createappbtn button-css"
                  size="small"
                  onClick={this.checkWalletStatus}
                >
                  + Create App
                </Button>
              </div>
              {this.state.appCreateSuccess ? (
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "green",
                  }}
                >
                  App created successfully
                </p>
              ) : null}
              {this.state.createAppErr ? (
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "crimson",
                  }}
                >
                  {this.state.createAppErrVal}
                </p>
              ) : null}

              <YourApps check={this.state.redirectCreateApp} />
            </div>
          </div>
          <div className="col-md-4" style={{ marginTop: "30px" }}>
            <DisplayData
              appCount={this.props.appCount}
              storage={this.state.storageUsedVal}
              postCount={this.state.postCount}
              bandwidth={this.state.bandwidthVal}
            />
          </div>
        </div>
        <div style={{ marginTop: "0px" }} className="homefooter">
          <Footer checkUpgrade={this.checkUpgrade} />
          <div>
            <p className="footercopyright">
              © 2023{" "}
              <a className="link" href="https://www.sarva.ai/" target="_blank">
                Sarva Labs Inc.
              </a>{" "}
              All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    plan: store.price.priceState,
    appCount: store.apps.appCount,
    closeWarning: store.sessionCheck.closeWarning,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSession: () => dispatch(sessionCheck()),
    handleCloseWarning: () => dispatch(closeWarningFunc()),
    priceModalOpen: () => dispatch(priceModalOpen()),
    toastifyEnable: (type, val) => dispatch(toastify(type, val)),
    totalStorageUsed: (value) => dispatch(totalStorageUsed(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
