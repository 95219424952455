import React, { useState } from "react";
import { Card } from "react-bootstrap";
import "./style.css";
import FileExplorer from "./fileexplorer";
import Apps from "./apps";
import Endusers from "./endusers";
import { priceModalOpen } from "../../action";
import { useDispatch, useSelector } from "react-redux";

function RecycleBin() {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedtab] = useState(0);
  const plan = useSelector((state) => state.price.priceState);

  useState(() => {
    if (plan == -1) {
      dispatch(priceModalOpen());
      return;
    } else {
      setSelectedtab(1);
    }
  }, []);

  function setBG(type) {
    setSelectedtab(type);
  }
  return (
    <div className="container">
      <div style={{ marginTop: "30px" }}>
        <div className="row">
          <div className="col-md-4">
            <Card
              body
              className={selectedTab == 1 ? "selectedCard" : "cardtab"}
              onClick={() => setBG(1)}
            >
              Files/Folders
            </Card>
          </div>
          <div className="col-md-4">
            <Card
              body
              className={selectedTab == 2 ? "selectedCard" : "cardtab"}
              onClick={() => setBG(2)}
            >
              Apps
            </Card>
          </div>
          <div className="col-md-4">
            <Card
              body
              className={selectedTab == 3 ? "selectedCard" : "cardtab"}
              onClick={() => setBG(3)}
            >
              Endusers
            </Card>
          </div>
        </div>

        {selectedTab == 1 ? <FileExplorer /> : null}
        {selectedTab == 2 ? <Apps /> : null}
        {selectedTab == 3 ? <Endusers /> : null}
      </div>
    </div>
  );
}

export default RecycleBin;
