import React, { Component } from "react";
import { Table, Spinner } from "react-bootstrap";
import { storageUsed, devStorageUsed } from "../../common/apicalls";
import {
  getParticularNetworkDetails,
  getPartOfNetwork,
} from "../../common/networkapicalls";
import { devAdmindetails } from "../../common/devapicalls";
import { toastify } from "../../action";
import { connect } from "react-redux";
import { bytesToSize, customDateFormat } from "../../common/func";
class Storage extends Component {
  state = {
    networkLevelList: [],
    devLevelList: [],
    decideOwnedPartOfShow: [],
    developerKeyFalseArr: [],
    allStorageConsumption: 0,
  };
  componentDidMount() {
    this.getNetworkLevelStorage();
  }
  getNetworkLevelStorage = async () => {
    try {
      this.setState({ loader: true });
      let networkListVal = await getPartOfNetwork();
      let _newNetworkVal = [];
      if (typeof networkListVal == "string") {
        this.props.toastifyEnable("err", networkListVal);
        return;
      }
      if (!networkListVal) {
        this.props.toastifyEnable("err", networkListVal);
        return;
      }
      let developerKeyFalseArr = [];
      let _devkey = await JSON.parse(sessionStorage.getItem("defAddress"))
        .value;
      var allStorageConsumption = 0;

      for (let i = 0; i < networkListVal.data.length; i++) {
        let _res = await getParticularNetworkDetails(
          networkListVal.data[i].network_id
        );
        if (_res.networkOwnerID) {
          if (_res.networkOwnerID == _devkey) {
            let storage = (
              await storageUsed(networkListVal.data[i].network_id, 4, undefined)
            ).consumedStorage;
            // app storage
            developerKeyFalseArr.push([]);
            let _devstatRes = await devAdmindetails();
            let _appsVal = _devstatRes.data.appList;
            let list = Object.values(_appsVal);
            let appKeys = Object.keys(_appsVal);

            let _appRes = [];
            for (let i = 0; i < appKeys.length; i++) {
              _appRes.push({
                appID: appKeys[i],
                appName: list[i].appName,
                networkID: list[i].networkID,
              });
            }
            let _val = await Promise.all(
              _appRes.map(async (data) => {
                if (data.networkID == networkListVal.data[i].network_id) {
                  data.storage = (
                    await storageUsed(data.networkID, 2, data.appID)
                  ).consumedStorage;
                  if (data.storage == undefined) {
                    data.storage = 0;
                    return data;
                  } else {
                    return data;
                  }
                } else {
                  return undefined;
                }
              })
            );

            let _getDefaultAppStorage = (
              await storageUsed(
                networkListVal.data[i].network_id,
                2
                // Cookies.get("defAddress") + "_defaultApp"
              )
            ).consumedStorage;
            if (_getDefaultAppStorage == undefined) {
              _val.push({
                appName: "Default App",
                storage: 0,
                appID:
                  JSON.parse(sessionStorage.getItem("defAddress")).value +
                  "_defaultApp",
              });
            } else {
              _val.push({
                appName: "Default App",
                storage: _getDefaultAppStorage,
                appID:
                  JSON.parse(sessionStorage.getItem("defAddress")).value +
                  "_defaultApp",
              });
            }
            if (storage == undefined) {
              _res.storage = 0;
            } else {
              _res.storage = storage;
              allStorageConsumption += storage;
            }

            _res.owner = 1;
            _res.appLevel = _val;
          } else {
            let storage = (
              await storageUsed(networkListVal.data[i].network_id, 5, undefined)
            ).consumedStorage;

            // app storage
            developerKeyFalseArr.push([]);
            let _devstatRes = await devAdmindetails();
            let _appsVal = _devstatRes.data.appList;
            let list = Object.values(_appsVal);
            let appKeys = Object.keys(_appsVal);

            let _appRes = [];
            for (let i = 0; i < appKeys.length; i++) {
              _appRes.push({
                appID: appKeys[i],
                appName: list[i].appName,
                networkID: list[i].networkID,
              });
            }
            let _val = await Promise.all(
              _appRes.map(async (data) => {
                if (data.networkID == networkListVal.data[i].network_id) {
                  data.storage = (
                    await storageUsed(data.networkID, 2, data.appID)
                  ).consumedStorage;
                  if (data.storage == undefined) {
                    data.storage = 0;
                    return data;
                  } else {
                    return data;
                  }
                } else {
                  return undefined;
                }
              })
            );

            let _getDefaultAppStorage = (
              await storageUsed(
                networkListVal.data[i].network_id,
                2
                // Cookies.get("defAddress") + "_defaultApp"
              )
            ).consumedStorage;

            if (_getDefaultAppStorage == undefined) {
              _val.push({
                appName: "Default App",
                storage: 0,
                appID:
                  JSON.parse(sessionStorage.getItem("defAddress")).value +
                  "_defaultApp",
              });
            } else {
              _val.push({
                appName: "Default App",
                storage: _getDefaultAppStorage,
                appID:
                  JSON.parse(sessionStorage.getItem("defAddress")).value +
                  "_defaultApp",
              });
            }
            if (storage == undefined) {
              _res.storage = 0;
            } else {
              _res.storage = storage;
              allStorageConsumption += storage;
            }

            _res.owner = 0;
            _res.appLevel = _val;
          }
        }
        _newNetworkVal.push(_res);
      }
      let falseArr = new Array(_newNetworkVal.length).fill(false);
      this.setState({
        networkLevelList: _newNetworkVal,
        devLevelList: falseArr,
        developerKeyFalseArr: developerKeyFalseArr,
        loader: false,
        allStorageConsumption: allStorageConsumption,
      });
    } catch (err) {
      this.setState({
        loader: false,
        networkLevelList: [],
        devLevelList: [],
        decideOwnedPartOfShow: [],
        developerKeyFalseArr: [],
      });
    }
  };

  getDevLevel = (index, type) => {
    // show the dropdown
    let falseDevLevel = this.state.devLevelList;
    falseDevLevel[index] = !falseDevLevel[index];
    let _developerKeyFalseArr = new Array(
      this.state.developerKeyFalseArr.length
    ).fill(false);
    this.setState({
      devLevelList: falseDevLevel,
      developerKeyFalseArr: _developerKeyFalseArr,
    });
  };

  getDeveloperAppDetails = (index, type) => {
    let falseDevLevel = this.state.developerKeyFalseArr;
    falseDevLevel[index] = !falseDevLevel[index];
    this.setState({ developerKeyFalseArr: falseDevLevel });
  };

  render() {
    return (
      <div className="container" style={{ marginTop: "30px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p
            style={{ fontSize: "16px", fontWeight: "600" }}
            className="heading-css"
          >
            Storage used
          </p>
          <p style={{ fontWeight: "600", color: "#000" }}>
            Total Storage Used :{" "}
            <span className="heading-css">
              {bytesToSize(this.state.allStorageConsumption)}
            </span>
          </p>
        </div>
        {!this.state.loader ? (
          <div className="fixedheight" style={{ height: "auto" }}>
            {this.state.networkLevelList.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="network_headerColumns">#</th>
                    <th className="network_headerColumns">Name</th>
                    <th className="network_headerColumns">Network ID</th>
                    <th
                      className="network_headerColumns"
                      style={{ width: "20%" }}
                    >
                      Storage Consumed
                    </th>
                    <th className="network_headerColumns">Type</th>
                    <th className="network_headerColumns"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.networkLevelList.map((data, index) => (
                    <>
                      <tr style={{ cursor: "pointer" }} key={index}>
                        <td className="network__detail_col">{index + 1}</td>
                        <td className="network__detail_col">
                          {data.networkName ? data.networkName : "-"}
                        </td>
                        <td className="network__detail_col">
                          {data.networkID ? data.networkID : "-"}
                        </td>
                        <td
                          className="network__detail_col heading-css"
                          style={{ fontWeight: "600" }}
                        >
                          {bytesToSize(data.storage)}
                        </td>
                        <td
                          className="network__detail_col"
                          style={{
                            color: data.owner == 1 ? "green" : "#000",
                            fontWeight: data.owner == 1 ? "600" : "normal",
                          }}
                        >
                          {data.owner == 1 ? "Owned" : "Partof"}
                        </td>
                        <td
                          className="network__detail_col"
                          onClick={() => this.getDevLevel(index, data.owner)}
                        >
                          {!this.state.devLevelList[index] ? (
                            <div style={{ color: "#000", fontWeight: "600" }}>
                              {
                                <span>
                                  View Details{" "}
                                  <i
                                    className="fa fa-server"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              }
                            </div>
                          ) : (
                            <div style={{ color: "#000", fontWeight: "600" }}>
                              {
                                <span>
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                  Close{" "}
                                </span>
                              }
                            </div>
                          )}
                        </td>
                      </tr>
                      {this.state.devLevelList[index] ? (
                        data.owner == 1 ? (
                          <>
                            {/* <tr>
                              <td colSpan={12} style={{ padding: "0px" }}>
                                <Table
                                  responsive
                                  style={{
                                    fontSize: "12px",
                                    backgroundColor: "#fff",
                                  }}
                                >
                                  <tr>
                                    <th>Developer Key</th>
                                    <th>Last Storage Consumed</th>
                                    <th>Storage Consumed</th>
                                    <th></th>
                                  </tr>
                                  {data.devStorageUsed.length > 0 ? (
                                    data.devStorageUsed.map(
                                      (developer, developerIndex) => (
                                        <>
                                          <tr key={developerIndex}>
                                            <td>{developer.devPubKey}</td>
                                            <td>
                                              {customDateFormat(
                                                developer.lastUpdated
                                              )}
                                            </td>
                                            <td
                                              style={{ fontWeight: "600" }}
                                              className="heading-css"
                                            >
                                              {bytesToSize(
                                                developer.consumedStorage
                                              )}
                                            </td>
                                            <td
                                              className="network__detail_col"
                                              onClick={() =>
                                                this.getDeveloperAppDetails(
                                                  developerIndex,
                                                  data.owner
                                                )
                                              }
                                            >
                                              {!this.state.developerKeyFalseArr[
                                                developerIndex
                                              ] ? (
                                                <div
                                                  style={{
                                                    color: "#000",
                                                    fontWeight: "600",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {
                                                    <span>
                                                      View App Details{" "}
                                                      <i
                                                        className="fa fa-server"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </span>
                                                  }
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    color: "#000",
                                                    fontWeight: "600",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {
                                                    <span>
                                                      <i
                                                        className="fa fa-times"
                                                        aria-hidden="true"
                                                      ></i>
                                                      Close{" "}
                                                    </span>
                                                  }
                                                </div>
                                              )}
                                            </td>
                                          </tr>
                                          {this.state.devLevelList[index] &&
                                          this.state.developerKeyFalseArr[
                                            developerIndex
                                          ] ? (
                                            <>
                                              {developer.appLevel.length ==
                                              0 ? (
                                                <p
                                                  style={{
                                                    fontWeight: "600",
                                                    marginLeft: "12px",
                                                    marginBottom: "8px",
                                                  }}
                                                >
                                                  No apps found
                                                </p>
                                              ) : (
                                                <tr>
                                                  <td
                                                    colSpan={12}
                                                    style={{ padding: "0px" }}
                                                  >
                                                    <Table
                                                      responsive
                                                      style={{
                                                        fontSize: "12px",
                                                        backgroundColor:
                                                          "#eaebeb",
                                                      }}
                                                    >
                                                      <tr>
                                                        <th>App ID</th>
                                                        <th>
                                                          Last Storage Consumed
                                                        </th>
                                                        <th>
                                                          Storage Consumed
                                                        </th>
                                                      </tr>
                                                      {developer.appLevel.map(
                                                        (
                                                          appDetail,
                                                          appDetailIndex
                                                        ) => (
                                                          <tr
                                                            key={appDetailIndex}
                                                          >
                                                            <td>
                                                              {appDetail.appID}
                                                            </td>
                                                            <td>
                                                              {customDateFormat(
                                                                appDetail.lastUpdated
                                                              )}
                                                            </td>
                                                            <td className="heading-css fontweight600">
                                                              {bytesToSize(
                                                                appDetail.consumedStorage
                                                              )}
                                                            </td>
                                                          </tr>
                                                        )
                                                      )}
                                                    </Table>
                                                  </td>
                                                </tr>
                                              )}
                                            </>
                                          ) : null}
                                        </>
                                      )
                                    )
                                  ) : (
                                    <p
                                      style={{
                                        fontWeight: "600",
                                        marginLeft: "12px",
                                        marginBottom: "8px",
                                      }}
                                    >
                                      No data found
                                    </p>
                                  )}
                                </Table>
                              </td>
                            </tr> */}

                            {/* {Remove below code once devStorage implemented} */}
                            <tr>
                              <td colSpan={12} style={{ padding: "0px" }}>
                                <Table
                                  responsive
                                  style={{
                                    fontSize: "12px",
                                    backgroundColor: "#f5f5f5",
                                  }}
                                >
                                  {data.appLevel.length !== 0 ? (
                                    <>
                                      <tr>
                                        <th>App Name</th>
                                        <th>App ID</th>
                                        <th>Storage Consumed</th>
                                      </tr>
                                      <>
                                        {data.appLevel.map(
                                          (appLevelData, appLevelIndex) =>
                                            appLevelData &&
                                            appLevelData.appName ? (
                                              <tr key={appLevelIndex}>
                                                <td>{appLevelData.appName}</td>
                                                <td>{appLevelData.appID}</td>
                                                <td>
                                                  {bytesToSize(
                                                    appLevelData.storage
                                                  )}
                                                </td>
                                              </tr>
                                            ) : (
                                              <></>
                                            )
                                        )}
                                      </>
                                    </>
                                  ) : (
                                    <p
                                      style={{
                                        fontWeight: "600",
                                        marginLeft: "12px",
                                        marginBottom: "8px",
                                        marginTop: "5px",
                                        fontSize: "!2px",
                                      }}
                                    >
                                      No apps found
                                    </p>
                                  )}
                                </Table>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <tr>
                            <td colSpan={12} style={{ padding: "0px" }}>
                              <Table
                                responsive
                                style={{
                                  fontSize: "12px",
                                  backgroundColor: "#f5f5f5",
                                }}
                              >
                                {data.appLevel.length !== 0 ? (
                                  <>
                                    <tr>
                                      <th>App Name</th>
                                      <th>App ID</th>
                                      <th>Storage Consumed</th>
                                    </tr>
                                    <>
                                      {data.appLevel.map(
                                        (appLevelData, appLevelIndex) =>
                                          appLevelData &&
                                          appLevelData.appName ? (
                                            <tr key={appLevelIndex}>
                                              <td>{appLevelData.appName}</td>
                                              <td>{appLevelData.appID}</td>
                                              <td>
                                                {bytesToSize(
                                                  appLevelData.storage
                                                )}
                                              </td>
                                            </tr>
                                          ) : (
                                            <></>
                                          )
                                      )}
                                    </>
                                  </>
                                ) : (
                                  <p
                                    style={{
                                      fontWeight: "600",
                                      marginLeft: "12px",
                                      marginBottom: "8px",
                                      marginTop: "5px",
                                      fontSize: "!2px",
                                    }}
                                  >
                                    No apps found
                                  </p>
                                )}
                              </Table>
                            </td>
                          </tr>
                        )
                      ) : null}
                    </>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No data found</p>
            )}
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <p>Calculating...</p>
            <Spinner animation="border" />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    storage: store.storage.storage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toastifyEnable: (type, val) => dispatch(toastify(type, val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Storage);
