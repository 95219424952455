import React, { Component } from "react";
import { Redirect, NavLink, Link } from "react-router-dom";
import "./header.css";
import User from "../../assets/user.png";
import SessionPopup from "./session";
import { connect } from "react-redux";
import {
  sessionClose,
  getPrice,
  priceModalClose,
  getTokenInitiate,
  firstLoginCheckPricing,
  priceModalOpen,
} from "../../action";
import { ToastContainer, toast } from "react-toastify";
import PricePopup from "../../common/pricemodal";
import { deleteBearerToken } from "../../common/devapicalls";

toast.configure({
  draggable: false,
  closeButton: false,
  position: toast.POSITION.BOTTOM_LEFT,
});

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addClassName: "topnav",
      sessionmodal: false,
    };
  }

  async componentDidMount() {
    await this.props.getTokenTrigger();
    await this.hitgetToken();
    await this.props.getPrice();
  }

  hitgetToken = () => {
    this.timerID = setInterval(() => this.props.getTokenTrigger(), 600000);
  };

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  handlerLogOut = () => {
    try {
      let token = JSON.parse(sessionStorage.getItem("bearerToken")).value;
      deleteBearerToken(token);
      this.props.sessionClose();
      localStorage.clear();
      sessionStorage.removeItem("bearerToken");
      sessionStorage.removeItem("defAddress");
      sessionStorage.removeItem("authToken");
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("pricing");
      sessionStorage.removeItem("pending");

      setTimeout(() => {
        alert("Logging out. You will be redirected to the homepage shortly");
        window.location.href = "/";
      }, 1000);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  auth = async () => {
    const tokenString = sessionStorage.getItem("authToken");

    if (!tokenString) {
      return false;
    }
    const token = JSON.parse(tokenString).value;
    return token && token.length > 10;
  };

  openNav = (e) => {
    e.preventDefault();
    if (this.state.addClassName === "topnav") {
      this.setState({ addClassName: "topnav responsive" });
    } else {
      this.setState({ addClassName: "topnav" });
    }
  };
  handleSessionClose = () => {
    this.props.sessionClose();
  };

  handlePriceClose = () => {
    this.props.priceModalClose();
  };

  render() {
    const authVal = this.auth();
    return (
      <div>
        {authVal ? (
          <div>
            <div>
              <SessionPopup
                show={this.props.sessionCheck}
                handleClose={this.handleSessionClose}
                logout={this.handlerLogOut}
              />
            </div>
            <div>
              {this.props.priceModal ? (
                <PricePopup
                  show={this.props.priceModal}
                  handlePriceClose={this.handlePriceClose}
                />
              ) : null}
            </div>

            <div>
              <ToastContainer draggable={false} closeOnClick={false} />
            </div>

            <div className="container-fluid" id="header">
              <div className="row">
                <div className="col-md-12" style={{ padding: 0 }}>
                  <div
                    className={this.state.addClassName}
                    style={{ height: "100px" }}
                  >
                    <div
                      style={{
                        textAlign: "right",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p className="username">
                        {/* <span style={{ color: "#06fd3f", fontWeight: "600" }}>
                          Hi!
                          {JSON.parse(sessionStorage.getItem("name")).value
                            ? JSON.parse(sessionStorage.getItem("name")).value
                            : null}
                        </span>{" "} */}
                        <span style={{ color: "#06fd3f", fontWeight: "600" }}>
                          Hi!
                          {JSON.parse(sessionStorage.getItem("name"))?.value ||
                            null}
                        </span>
                        {localStorage.getItem("firstname") !== null
                          ? localStorage
                              .getItem("firstname")
                              .charAt(0)
                              .toUpperCase() +
                            localStorage.getItem("firstname").slice(1)
                          : ""}{" "}
                        {localStorage.getItem("lastname") !== null
                          ? localStorage
                              .getItem("lastname")
                              .charAt(0)
                              .toUpperCase() +
                            localStorage.getItem("lastname").slice(1)
                          : ""}
                      </p>
                      <NavLink to="/resources" exact activeClassName="active">
                        Support
                      </NavLink>
                      <a
                        href="https://apidocs.moinet.io/docs/intro"
                        target="_blank"
                      >
                        Docs
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <Link to="/profile" exact style={{ padding: "0px" }}>
                        {" "}
                        <i
                          className="fa fa-cog"
                          aria-hidden="true"
                          style={{ fontSize: "30px" }}
                        ></i>
                        {/* <img
                          src={User}
                          style={{
                            height: "50px",
                            border: "none",
                            background: "transparent",
                          }}
                          className="btn btn-secondary"
                        /> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to={"/login"} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    sessionCheck: store.sessionCheck.sessionCheck,
    priceModal: store.price.priceModal,
    linkClickable: store.price.firstTimeLoginCheck,
    planUpdate: store.price.devStatVal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    firstLoginCheckPricing: () => dispatch(firstLoginCheckPricing()),
    sessionClose: () => dispatch(sessionClose()),
    getPrice: () => dispatch(getPrice()),
    priceModalClose: () => dispatch(priceModalClose()),
    getTokenTrigger: () => dispatch(getTokenInitiate()),
    priceModalOpen: () => dispatch(priceModalOpen()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
