import React from "react";
import { Card, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { priceModalOpen } from "../../action";
import ContactUs from "../pricing/contactus";
class Footer extends React.Component {
  state = {
    redirect: false,
    contactUsPopUp: false,
  };
  handleCheckPricePlan = (val) => {
    if (val === "createnetwork") {
      if (this.props.plan == 0 || this.props.plan == 1) {
        this.props.priceModalOpen();
        return;
      } else {
        this.setState({ redirect: true });
      }
    } else if (val === "endtoend") {
      if (this.props.plan < 4) {
        this.props.priceModalOpen();
        return;
      }
    }
  };

  contactUsClose = () => {
    this.setState({ contactUsPopUp: false });
  };

  render() {
    return !this.state.redirect ? (
      <div>
        {this.state.contactUsPopUp ? (
          <ContactUs
            show={this.state.contactUsPopUp}
            handleClose={this.contactUsClose}
          />
        ) : null}
        <Card className="mb-2 footercardalign">
          <Card.Body>
            <div className="footercard">
              <Button
                className="footerbtn button-css"
                variant="success"
                size="sm"
                onClick={() => this.handleCheckPricePlan("createnetwork")}
              >
                Create Private Network
              </Button>

              <Button
                className="footerbtn button-css"
                variant="info"
                size="sm"
                onClick={() => this.handleCheckPricePlan("endtoend")}
              >
                Enable end-to-end Encryption
              </Button>
              <Button
                className="footerbtn button-css"
                size="sm"
                variant="info"
                onClick={() => this.setState({ contactUsPopUp: true })}
              >
                Want to host node(s)?
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    ) : (
      <Redirect to="/network/create" />
    );
  }
}

const mapStateToProps = (store) => {
  return {
    plan: store.price.priceState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    priceModalOpen: () => dispatch(priceModalOpen()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
