
import React from "react";
import Geocode from "react-geocode";
import GoogleMapReact from 'google-map-react';
import Lock from "../../assets/lock.gif";
import MarkerClusterer from '@googlemaps/markerclustererplus';

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);

// set response language. Defaults to english.
Geocode.setLanguage("en");


class Maps extends React.Component {

    static defaultProps = {
        center: {
            lat: 0,
            lng: 0
        },
        zoom: 0
    };
    state = {
        markers: [],
        nodelist: []
    }

    async componentDidMount() {
        let arr = this.props.nodeValue.nodelist;
        let markers = [];

        for (let i = 0; i < arr.length; i++) {
            let _res = await Geocode.fromAddress(arr[i].region !== "" ? arr[i].region : arr[i].city);
            const { lat, lng } = _res.results[0].geometry.location;
            let newLat = lat + (Math.random() - .5) / 1500;// * (Math.random() * (max - min) + min);
            let newLng = lng + (Math.random() - .5) / 1500;// * (Math.random() * (max - min) + min);
            markers.push({ lat: newLat, lng: newLng, name: arr[i].nodeName, region: arr[i].region !== "" ? arr[i].region : arr[i].city, type: arr[i].nodeType })

        }

        this.setState({ markers: markers, nodelist: this.props.nodeValue });
    }

    renderMarkers = (map, maps) => {

        const _marker = this.state.markers;
        var icon = {
            url: Lock,
            scaledSize: new maps.Size(50, 50), // scaled size
        };

        var _allMarkers = [];

        for (let i = 0; i < _marker.length; i++) {
            const infowindow = new maps.InfoWindow({
                content: '<div id="content">' +
                    '<div id="siteNotice">' +
                    "</div>" +
                    '<div id="bodyContent" style="margin-top:5px;">' +
                    `<p style="font-weight:600;margin-bottom:5px;">Region : ${_marker[i].region}</p>` +
                    `<p style="font-weight:600;margin-bottom:5px;">Name : ${_marker[i].name}</p>` +
                    `<p style="font-weight:600;margin-bottom:5px;">Type : ${_marker[i].type == 0 ? "Private" : "Public"}</p>` +
                    "</div>" +
                    "</div>",
            });
            let marker = new maps.Marker({
                position: { lat: _marker[i].lat, lng: _marker[i].lng },
                map,
                icon: icon
            });
            marker.addListener("click", () => {
                infowindow.open(map, marker);
            });
            _allMarkers.push(marker);
        }

        const markerCluster = new MarkerClusterer(map, _allMarkers, {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        });
    }

    render() {

        return (
            <div>
                <div style={{ height: '65vh', width: '100%' }}>
                    {this.state.markers.length > 0 ? <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                        options={map => ({ mapTypeId: map.MapTypeId.SATELLITE })}
                    >
                    </GoogleMapReact> : null}
                </div>


            </div >
        )
    }
}



export default Maps;

