import { PRICE_STATE, PRICE_MODAL_CLOSE, PRICE_MODAL_OPEN, FIRST_LOGIN_CHECK_PRICING, SET_DEV_STAT, GET_SUBSCRIPTION_OBJECT } from "../action/types";


const initialState = {
    priceState: -2,
    priceModal: false,
    firstTimeLoginCheck: true,
    devStatVal: "",
    freeorbuy: ""
}

export const priceReducers = (state = initialState, action) => {
    switch (action.type) {
        case PRICE_STATE:
            return {
                ...state,
                priceState: action.value
            }
        case PRICE_MODAL_OPEN:
            return {
                ...state,
                priceModal: action.value
            }
        case PRICE_MODAL_CLOSE:
            return {
                ...state,
                priceModal: action.value
            }
        case FIRST_LOGIN_CHECK_PRICING:
            return {
                ...state,
                firstTimeLoginCheck: action.value
            }
        case SET_DEV_STAT:
            return {
                ...state,
                devStatVal: action.value
            }
        case GET_SUBSCRIPTION_OBJECT:
            return {
                ...state,
                freeorbuy: action.value
            }
        default:
            return state;
    }
}
