import {
  PRICE_STATE,
  PRICE_MODAL_CLOSE,
  PRICE_MODAL_OPEN,
  FIRST_LOGIN_CHECK_PRICING,
  SET_DEV_STAT,
  GET_SUBSCRIPTION_OBJECT,
} from "./types";
import { devAdmindetails } from "../common/devapicalls";
import { getSubscription } from "../common/paymentapi";

export const getPrice = () => {
  return async (dispatch) => {
    try {
      let _res = (await devAdmindetails()).data;
      dispatch({ type: SET_DEV_STAT, value: _res ? _res : "" });
      dispatch({ type: PRICE_STATE, value: _res.plan ? _res.plan : 0 });

      if (_res.stripeSubscriptionID !== "") {
        let subscriptionObject = await getSubscription(
          _res.stripeSubscriptionID
        );
        dispatch({
          type: GET_SUBSCRIPTION_OBJECT,
          value: subscriptionObject.data.status,
        });
      } else {
        dispatch({ type: GET_SUBSCRIPTION_OBJECT, value: "" });
      }
    } catch (err) {
      dispatch({ type: GET_SUBSCRIPTION_OBJECT, value: "" });
      dispatch({ type: SET_DEV_STAT, value: "" });
      dispatch({ type: PRICE_STATE, value: 0 });
    }
  };
};

export const priceModalOpen = () => {
  return (dispatch) => {
    dispatch({ type: PRICE_MODAL_OPEN, value: true });
  };
};

export const priceModalClose = () => {
  return (dispatch) => {
    dispatch({ type: PRICE_MODAL_CLOSE, value: false });
  };
};

export const firstLoginCheckPricing = () => {
  return (dispatch) => {
    dispatch({ type: FIRST_LOGIN_CHECK_PRICING, value: false });
    let _pricing = JSON.parse(sessionStorage.getItem("pricing")).value;
    dispatch({
      type: FIRST_LOGIN_CHECK_PRICING,
      value: _pricing == "false" ? false : true,
    });
  };
};
