import axios from "axios";

export const updateDev = async (data) => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "user";
    return (
      await axios({
        url: tempUrl,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: data,
      })
    ).data;
  } catch (err) {
    return err.response.data;
  }
};

export const devStat = async () => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "user";
    return (
      await axios({
        url: tempUrl,
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          operationType: 1,
        },
      })
    ).data;
  } catch (err) {
    return "error";
  }
};

export const devAdmindetails = async () => {
  try {
    return (
      await axios({
        url: process.env.REACT_APP_MOIBIT_API_URL + "user",
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          operationType: 2,
        },
      })
    ).data;
  } catch (err) {
    return "error";
  }
};

export const updatePlan = async () => {
  try {
    return (
      await axios({
        url: process.env.REACT_APP_MOIBIT_API_URL + "user/plan",
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: {
          plan: 0,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const getTokeninfo = async () => {
  try {
    return (
      await axios({
        url: process.env.REACT_APP_MOIBIT_API_URL + "gettokeninfo",
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const registerBearerToken = async (data) => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "user/bearertoken";
    return (
      await axios({
        url: tempUrl,
        method: "POST",
        headers: { Authorization: `Bearer ${data}` },
      })
    ).data;
  } catch (err) {
    return err.response.data;
  }
};

export const getBearerToken = async () => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "user/bearertoken";
    return (
      await axios({
        url: tempUrl,
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
      })
    ).data;
  } catch (err) {
    return err.response.data;
  }
};

export const updateBearerToken = async (data, bool) => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "user/bearertoken";
    return (
      await axios({
        url: tempUrl,
        method: "PUT",
        headers: { Authorization: `Bearer ${data}` },
        data: {
          active: bool,
        },
      })
    ).data;
  } catch (err) {
    return err.response.data;
  }
};

export const deleteBearerToken = async (data) => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "user/bearertoken";
    return (
      await axios({
        url: tempUrl,
        method: "DELETE",
        headers: { Authorization: `Bearer ${data}` },
      })
    ).data;
  } catch (err) {
    return err.response.data;
  }
};
