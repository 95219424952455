import axios from "axios";
import { Crypt } from "hybrid-crypto-js";
const CryptoJS = new Crypt();

export const createAppInMoibit = async (
  _appID,
  _appName,
  _appDesc,
  _maxStorage,
  _replication,
  _signature,
  _nonce,
  _developerKey,
  _networkID,
  _encryptionType,
  _customKey
) => {
  try {
    const tempUrl =
      process.env.REACT_APP_MOIBIT_API_URL + "net/" + _networkID + "/app";
    return (
      await axios({
        url: tempUrl,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: {
          appID: _appID,
          appName: _appName,
          appDescription: _appDesc,
          maxStorage: _maxStorage,
          replication: _replication,
          encryptionType: _encryptionType,
          customKey: _customKey ? _customKey : null,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const randomGenNumFunc = () => {
  return Math.floor(100000 + Math.random() * 900000);
};

export const jsmaEncrypt = async function (_username, _token) {
  try {
    const pubInPem = await window.moi_id.getPublicInPem(_username);
    return {
      moibitKey: await window.moi_id.getUserID(_username),
      digest: await CryptoJS.encrypt(pubInPem, JSON.stringify(_token)),
    };
  } catch (err) {
    throw new Error(err.message);
  }
};

export const inviteUser = async (_username, _payload, _adminAuthToken) => {
  try {
    const iUHeaders = {
      signature: _adminAuthToken.auth_signature,
      nonce: _adminAuthToken.nonce,
      developerKey: _adminAuthToken.id,
      networkID: _payload.network_id,
    };

    let _tokenDigest = await jsmaEncrypt(_username, _payload);

    const storeTokenRes = await axios({
      url: process.env.REACT_APP_MOIBIT_API_URL + "network/sendinvitation",
      method: "POST",
      headers: iUHeaders,

      data: {
        developerKey: _tokenDigest.moibitKey,
        tokenData: _tokenDigest.digest,
      },
    });
    return storeTokenRes.data;
  } catch (e) {
    throw new Error(e.message);
  }
};
