module.exports = {
  PASSWORD_UNDEFINED: "Passphrase cannot be undefined",
  NO_WALLET_INSTANCE: "No Wallet instance found",
  KEYSTORE_UNDEFINED: "Keystore cannot be undefined",
  INVALID_KEYSTORE: "Invalid Seed Phrase",
  INVALID_NAMESPACE: "Invalid NameSpace",
  INVALID_USERNAME: "Invalid username, should start with alphabet",
  INVALID_USERNAME_SIZE:
    "Invalid username, should contain minimum 5 characters",
  WALLET_LOCKED: "Wallet is locked.",
  ALREADY_UNLOCKED: "Wallet was already unlocked",
  WRONG_PASSWORD: "Wrong password. Unable to unlock wallet.",
  UNDEFINED_USERADDRESS: "User address cannot be undefined",
  ZERO_ADDRESS: 0x0000000000000000000000000000000000000000,
  MOI_ID_IGC_PATH: [4210818, 6174, 0, 0],
  MOI_ID_V1_IGC_PATH: [6174, 0, 0, 0],
  MOI_ID_BASE_URL: process.env.REACT_APP_MOI_ID_BASE_URL,
};
