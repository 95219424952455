import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { Button, Spinner } from "react-bootstrap";
import CardSection from "./cardsection";
import { createSubscription } from "../../common/paymentapi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class CheckoutForm extends React.Component {
  state = {
    responseMessage: "",
    msgType: "",
    loader: false,
    clientSecret: "",
  };

  _checkTrailPlanAskingCard = async () => {
    try {
      let _trailPlan = {
        amount: 1 * 100,
        currency: "usd",
        description: "updatePlan_" + this.props.plan,
        operationType: this.props.paymentPlan,
        operationValue: this.props.plan + "",
        line1: "",
        postalCode: "12345",
        city: "Bangalore",
        state: "karnataka",
        country: "india",
        operationInterval:
          this.props.paymentPlan == 0
            ? 1
            : this.props.planInterval == "Yearly"
            ? 12
            : this.props.planInterval == "Monthly"
            ? 1
            : 0,
      };

      if (this.props.userPlan > 0) {
        let result = await createSubscription(_trailPlan);
        let plan = this.props.plan;
        if (result.error) {
          this.showMsg("err", result.error.message);
          this.setState({ loader: false });
          return "error";
        } else {
          if (result.data.status === "trialing") {
            let description = "";
            if (plan == 0) {
              description = "DEVELOPER";
            } else if (plan == 1) {
              description = "INDIVIDUAL";
            } else if (plan == 2) {
              description = "START UP";
            } else if (plan == 3) {
              description = "SMB";
            } else if (plan == 4) {
              description = "ENTERPRISE";
            } else {
              description = "-";
            }
            if (!result.data.pending_setup_intent) {
              this.showMsg("success", "Upgraded to " + plan);
              this.setState({ loader: false });
              this.props.paymentSuccess();
            } else {
              this.setState({ loader: false });
              this.props.closeModal();
              return {
                subscription: result,
              };
            }
          } else if (result.data.status === "active") {
            this.showMsg("success", "Your subscription activated");
            this.setState({ loader: false });
            this.props.paymentSuccess();
          } else {
            this.setState({ loader: false });
            this.props.closeModal();
            return {
              subscription: result,
            };
          }
        }
        return true;
      } else {
        return false;
      }
    } catch (err) {
      this.showMsg("err", err.message);
      this.setState({ loader: false });
    }
  };

  getSubscriptionResult = async (event) => {
    try {
      event.preventDefault();
      this.setState({ loader: true });
      let _checkTrailPeriodASkingCard = await this._checkTrailPlanAskingCard();
      if (
        !_checkTrailPeriodASkingCard &&
        _checkTrailPeriodASkingCard !== "error"
      )
        if (this.props.userPlan <= 0) {
          {
            const { stripe, elements } = this.props;
            const card = elements.getElement(CardElement);
            stripe
              .createPaymentMethod({
                type: "card",
                card: card,
                billing_details: {
                  name: JSON.parse(sessionStorage.getItem("defAddress")).value,
                },
              })
              .then(async (result) => {
                if (result.error) {
                  this.showMsg("err", result.error.message);
                  this.setState({ loader: false });
                } else {
                  let data = {
                    amount: 1 * 100,
                    currency: "usd",
                    description: "updatePlan_" + this.props.plan,
                    operationType: this.props.paymentPlan,
                    operationValue: this.props.plan + "",
                    line1: "",
                    postalCode: "12345",
                    city: "Bangalore",
                    state: "karnataka",
                    country: "india",
                    operationInterval:
                      this.props.planInterval == "Yearly"
                        ? 12
                        : this.props.planInterval == "Monthly"
                        ? 1
                        : 0,
                    // operationInterval: 1,
                    // this.props.paymentPlan == 0
                    //   ? 1
                    //   : this.props.planInterval == "Yearly"
                    //   ? 12
                    //   : this.props.planInterval == "Monthly"
                    //   ? 1
                    //   : 0,
                  };
                  if (this.props.paymentPlan == 0) {
                    this.confirmCardPaymentFuncTrail(
                      this.props.clientSecret,
                      result.paymentMethod.id,
                      data
                    );
                  } else if (this.props.paymentPlan == 1) {
                    this.confirmCardPaymentFunc(
                      this.props.clientSecret,
                      result.paymentMethod.id,
                      data
                    );
                  }
                }
              });
          }
        }
    } catch (e) {
      this.showMsg("err", e.message);
    }
  };

  handlePaymentThatRequiresCustomerAction = ({
    subscription,
    invoice,
    paymentMethodId,
    isRetry,
  }) => {
    if (subscription && subscription.status === "active") {
      // Subscription is active, no customer actions required.
      this.showMsg(
        "success",
        "Your subscription already activated. No action required."
      );
      this.setState({ loader: false });
      this.props.paymentSuccess();
      return { subscription, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.data.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return this.props.stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            this.showMsg("err", result.error.message);
            this.setState({ loader: false });
            // throw result;
            return;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              // Show a success message to your customer.
              this.showMsg("success", "Payment succeeded");
              this.setState({ loader: false });
              this.props.paymentSuccess();
              return {
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        })
        .catch((error) => {
          this.showMsg("err", error.message);
          this.setState({ loader: false });
        });
    } else {
      // No customer action needed.
      return { subscription, paymentMethodId };
      // this.setState({ loader: false });
    }
  };

  onSubscriptionComplete = (result) => {
    // Payment was successful.
    if (result.subscription.data.status === "active") {
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
      this.showMsg("success", "Your subscription activated");
      this.props.paymentSuccess();
      this.setState({ loader: true });
    }
  };

  // buy now

  confirmCardPaymentFunc = async (client_key, paymentMethodId, data) => {
    try {
      this.setState({ loader: true });

      const { stripe, elements, clientSecret } = this.props;
      if (!stripe || !elements) {
        return;
      }
      let _payload = data;
      _payload["paymentMethodID"] = paymentMethodId;

      // new approach
      let result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: paymentMethodId,
      });
      if (result.error) {
        this.showMsg("err", result.error.message);
        this.setState({ loader: false });
        return;
      }
      if (result.setupIntent.status == "succeeded") {
        createSubscription(_payload)
          .then(async (_res) => {
            if (_res.error) {
              this.showMsg("err", _res.error.message);
              this.setState({ loader: false });
              return;
            } else {
              this.setState({ loader: false });
              this.props.closeModal();
              // this.showMsg("success", "Your subscription activated");
              return {
                paymentMethodId: paymentMethodId,
                subscription: _res,
                plan: this.props.plan,
              };
            }
          })
          .then(this.handlePaymentThatRequiresCustomerAction)
          .then(this.onSubscriptionComplete)
          .catch((error) => {
            this.showMsg("err", error.message);
            this.setState({ loader: false });
          });
      }
    } catch (err) {
      this.setState({ loader: true });
      this.showMsg("err", err.message);
    }
  };

  // end buy now

  confirmCardPaymentFuncTrail = async (client_key, paymentMethodId, data) => {
    try {
      this.setState({ loader: true });

      const { stripe, elements, clientSecret } = this.props;
      if (!stripe || !elements) {
        return;
      }
      let _payload = data;
      _payload["paymentMethodID"] = paymentMethodId;

      // new approach
      let result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: paymentMethodId,
      });

      if (result.error) {
        this.showMsg("err", result.error.message);
        this.setState({ loader: false });
        return;
      }
      if (result.setupIntent.status == "succeeded") {
        let _res = await createSubscription(_payload);
        if (_res.error) {
          this.showMsg("err", _res.error.message);
          this.setState({ loader: false });
          return;
        } else {
          if (_res !== "") {
            if (_res.status === "success") {
              this.showMsg("success", "Trail period activated");
              this.setState({ loader: false });
              this.props.paymentSuccess();
            } else {
              this.setState({ loader: false });
              this.props.closeModal();
              return {
                paymentMethodId: paymentMethodId,
                subscription: _res,
              };
            }
          } else {
            this.showMsg(
              "err",
              "Something went wrong. Please try again later."
            );
            this.setState({ loader: false });
          }
        }
      }

      // end new approach
    } catch (err) {
      this.setState({ loader: true });
      this.showMsg("err", err.message);
    }
  };
  showMsg = async (type, message) => {
    let time = 0;
    if (type == "err") {
      time = 6000;
      toast.error(message, {
        autoClose: 10000,
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      time = 3000;
      await toast.info(message, {
        autoClose: 3000,
        position: toast.POSITION.BOTTOM_LEFT,
        className: "heading-css-bg",
      });
    }
    this.setState({ responseMessage: message, msgType: type, loader: false });
    setTimeout(() => {
      this.setState({
        responseMessage: "",
        msgType: "",
      });
    }, time);
  };

  render() {
    return (
      <div>
        <ToastContainer draggable={false} closeOnClick={false} />
        <form onSubmit={this.getSubscriptionResult}>
          {this.props.userPlan <= 0 ? <CardSection /> : null}
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            <Button
              size="sm"
              variant="danger"
              className="paymentbtn button-css"
              onClick={this.props.closeModal}
            >
              Close
            </Button>
            &nbsp;
            {!this.state.loader ? (
              <Button
                type="submit"
                size="sm"
                disabled={!this.props.stripe}
                variant="info"
                className="paymentbtn paynow heading-css-bg"
              >
                {this.props.paymentPlan == 1
                  ? this.props.userPlan <= 1
                    ? "Subscribe"
                    : "Subscribe"
                  : "Subscribe"}
              </Button>
            ) : (
              <Button
                variant="info"
                className="paymentbtn paynow heading-css-bg"
                size="sm"
                disabled
              >
                <Spinner
                  animation="border"
                  style={{ height: "20px", width: "20px" }}
                />
              </Button>
            )}
          </div>
        </form>

        <p
          style={{
            color: this.state.msgType == "err" ? "crimson" : "green",
            fontSize: "12px",
            marginTop: "20px",
          }}
        >
          {this.state.responseMessage}
        </p>
      </div>
    );
  }
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          clientSecret={props.clientSecret}
          closeModal={props.closeModal}
          paymentSuccess={props.paymentSuccess}
          price={props.price}
          plan={props.plan}
          paymentPlan={props.paymentPlan}
          planInterval={props.planInterval}
          userPlan={props.userPlan}
        />
      )}
    </ElementsConsumer>
  );
}
