import React from "react";
import { Button, Modal, Form, Table, Spinner } from "react-bootstrap";
import { getNodeDetails, createNode } from "../../common/nodeapicalls";
import "./style.css";

class AddNodeModal extends React.Component {
  state = {
    nodeName: "",
    nodeIP: "",
    nodeDetails: [],
    showTable: false,
    addloader: false,
    confirmLoader: false,
    fieldsRequired: false,
    errVal: "",
    err: false,
  };

  fetchNodeDetails = async () => {
    try {
      if (this.state.nodeIP !== "" && this.state.nodeName !== "") {
        this.setState({ addloader: true });
        let data = [
          {
            nodeName: this.state.nodeName,
            nodeIP: this.state.nodeIP,
          },
        ];

        let _res = await getNodeDetails(data);

        if (typeof _res == "object") {
          this.setState({
            nodeDetails: _res,
            showTable: true,
            addloader: false,
          });
        } else {
          this.setState({ errVal: _res.data, err: true });
          setTimeout(() => {
            this.setState({ errVal: "", err: false });
          }, 3000);
        }
      } else {
        this.setState({ fieldsRequired: true });
        setTimeout(() => {
          this.setState({ fieldsRequired: false });
        }, 3000);
      }
    } catch (err) {
      //do nothing
    }
  };

  handleConfigure = async () => {
    try {
      this.setState({ confirmLoader: true });
      let data = [
        {
          nodeName: this.state.nodeName,
          nodeIP: this.state.nodeIP,
        },
      ];
      let _res = await createNode(data);
      if (_res.code == "200") {
        this.setState({ confirmLoader: false, showTable: false });
        this.props.successModal();
      }
    } catch (err) {
      this.setState({ confirmLoader: false });
    }
  };

  closeNodeModal = () => {
    this.setState({
      nodeName: "",
      nodeIP: "",
      nodeDetails: [],
      showTable: false,
      addloader: false,
      fieldsRequired: false,
    });
    this.props.closeNodeModal();
  };

  render() {
    return (
      <div>
        <Modal
          show={this.props.addNodeModal}
          centered
          size="lg"
          onHide={this.closeNodeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontWeight: "600" }}>Add Node</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label className="fontweight600">Node name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Desired Node name"
                  onChange={(e) => this.setState({ nodeName: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="fontweight600">Node IP</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Node IP"
                  onChange={(e) => this.setState({ nodeIP: e.target.value })}
                />
              </Form.Group>
            </Form>
            {this.state.fieldsRequired ? (
              <p
                style={{
                  margin: "10px",
                  marginLeft: "0px",
                  color: "crimson",
                  fontSize: "12px",
                }}
              >
                All Fields are Mandatory
              </p>
            ) : null}
            {this.state.err ? (
              <p
                style={{
                  margin: "10px",
                  marginLeft: "0px",
                  color: "crimson",
                  fontSize: "12px",
                }}
              >
                {this.state.errVal}
              </p>
            ) : null}

            {this.state.nodeDetails.length !== 0 && this.state.showTable
              ? this.state.nodeDetails.map((data, index) => (
                  <Table
                    striped
                    bordered
                    hover
                    className="tablecss"
                    key={index}
                  >
                    <tbody>
                      <tr>
                        <th>Node Name</th>
                        <td>{data.nodeName}</td>
                      </tr>
                      <tr>
                        <th>Node IP</th>
                        <td>{data.nodeIP}</td>
                      </tr>

                      <tr>
                        <th>Cloud Provider</th>
                        <td>{data.cloudProvider}</td>
                      </tr>
                      <tr>
                        <th>CPU</th>
                        <td>{data.cpu}</td>
                      </tr>
                      <tr>
                        <th>RAM</th>
                        <td>{data.ram}</td>
                      </tr>
                      <tr>
                        <th>Storage</th>
                        <td>{data.storage}</td>
                      </tr>
                      <tr>
                        <th>Region</th>
                        <td>{data.region}</td>
                      </tr>
                    </tbody>
                  </Table>
                ))
              : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.closeNodeModal}
              className="fontweight600"
            >
              Close
            </Button>
            {!this.state.showTable ? (
              <div>
                {!this.state.addloader ? (
                  <Button
                    variant="info"
                    onClick={this.fetchNodeDetails}
                    className="fontweight600"
                  >
                    Mòi Ping
                  </Button>
                ) : (
                  <Button variant="info" disabled>
                    <Spinner
                      animation="border"
                      style={{ height: "20px", width: "20px" }}
                    />
                  </Button>
                )}
              </div>
            ) : null}

            {this.state.showTable ? (
              <div>
                {!this.state.confirmLoader ? (
                  <Button
                    variant="info"
                    onClick={this.handleConfigure}
                    className="fontweight600"
                  >
                    Add
                  </Button>
                ) : (
                  <Button variant="info" disabled>
                    <Spinner
                      animation="border"
                      style={{ height: "20px", width: "20px" }}
                    />
                  </Button>
                )}
              </div>
            ) : null}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default AddNodeModal;
