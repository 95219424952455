import React from "react";
import { Modal, Spinner, Card, Form } from "react-bootstrap";
import "./style.css";
import "../creditcard/style.css";
import { createSetupIntent, getPublisherKey } from "../../common/paymentapi";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./chekout";
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);//live key

class Payment extends React.Component {
  state = {
    clientSecret: "",
    loader: false,
    planType: "Monthly",
    publisherKey: "",
  };
  handleClose = () => {
    this.props.closeModal();
  };
  async componentDidMount() {
    if (this.props.price !== 0) {
      await this.getPublisherKeyFunc();
      await this.getClientSecret();
    }
  }

  getPublisherKeyFunc = async () => {
    try {
      let _res = await getPublisherKey();
      if (_res.code == 200) {
        const stripePromise = loadStripe(_res.data); //live key
        this.setState({ publisherKey: stripePromise, loader: false });
      } else {
        this.setState({ publisherKey: "", loader: false });
        this.props.errMsg(_res.data);
      }
    } catch (err) {
      console.log(err);
      this.setState({ publisherKey: "", loader: false });
    }
  };

  getClientSecret = async () => {
    try {
      this.setState({ loader: true });
      let data = {
        amount: 2 * 100,
        currency: "usd",
        description: "updatePlan_" + this.props.plan,
        operationType: this.props.paymentPlan,
        operationValue: this.props.plan + "",
        line1: "",
        postalCode: "12345",
        city: "Bangalore",
        state: "karnataka",
        country: "india",
        operationInterval:
          this.props.billingPeriod == "Yearly"
            ? 12
            : this.props.billingPeriod == "Monthly"
            ? 1
            : 0,
        // operationInterval: 1,
      };
      let _res = await createSetupIntent(data);
      if (_res.code == 200) {
        this.setState({ clientSecret: _res.data, loader: false });
      } else {
        this.setState({ clientSecret: "", loader: false });
        this.props.errMsg(_res.data);
      }
    } catch (err) {
      // console.log(err);
      this.setState({ clientSecret: "", loader: false });
    }
  };

  paymentSuccess = () => {
    this.props.paymentSuccess();
  };
  // changePlanType = (e) => {
  //   this.setState({ planType: e.currentTarget.value });
  // };
  render() {
    const plan = this.props.plan;
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          {this.props.paymentPlan == 1 ? (
            <Modal.Title className="cardheader">
              {this.props.freetrail == "active" ? "Upgrade" : "Payment details"}
            </Modal.Title>
          ) : (
            <Modal.Title className="cardheader">
              {this.props.freetrail == "trialing" ? "Upgrade" : "Add Card"}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <div className="product">
              <p className="popup-plan-name heading-css">
                <span style={{ color: "#000" }}>Upgrade to</span>{" "}
                {plan == 1
                  ? "INDIVIDUAL"
                  : plan == 2
                  ? "START UP"
                  : plan == 3
                  ? "BUSINESS"
                  : plan == 4
                  ? "ENTERPRISE"
                  : ""}
              </p>
              {/* {this.props.userPlan <= 1 ? (
                <>
                  {this.props.paymentPlan == 1 ? (
                    <>
                      <p
                        style={{
                          fontWeight: "600",
                          marginBottom: "10px",
                          fontSize: "14px",
                          color: "#000",
                        }}
                      >
                        Choose Your Plan
                      </p>
                      <Card
                        className="cardPlan"
                        style={{
                          border:
                            this.state.planType == "Yearly"
                              ? "2px solid deepskyblue"
                              : "1px solid #CED4DA",
                        }}
                      >
                        <Card.Body className="cardPlan1">
                          <div className="cardPlan2">
                            <div>
                              <Form.Group style={{ marginBottom: "0px" }}>
                                <div class="pretty p-default p-round">
                                  <input
                                    type="radio"
                                    name="radio1"
                                    value="Yearly"
                                    checked={this.state.planType == "Yearly"}
                                    onChange={this.changePlanType}
                                  />
                                  <div class="state p-info-o">
                                    <label className="checkboxlabel">
                                      Yearly
                                    </label>
                                  </div>
                                </div>
                              </Form.Group>
                            </div>
                            <div>
                              <div className="cardPlanText heading-css">
                                {this.props.price["12"].price / 100}$/month
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                        {this.state.planType == "Yearly" ? (
                          <p className="tostext">
                            One year commitment. Please refer{" "}
                            <span>
                              <a
                                href={process.env.REACT_APP_TERMS_OF_SERVICES}
                                target="_blank"
                              >
                                TOS
                              </a>
                            </span>
                          </p>
                        ) : null}
                      </Card>

                      <Card
                        className="cardPlan"
                        style={{
                          border:
                            this.state.planType == "Monthly"
                              ? "2px solid deepskyblue"
                              : "1px solid #CED4DA",
                        }}
                      >
                        <Card.Body className="cardPlan1">
                          <div className="cardPlan2">
                            <div>
                              <Form.Group style={{ marginBottom: "0px" }}>
                                <div class="pretty p-default p-round">
                                  <input
                                    type="radio"
                                    name="radio1"
                                    value="Monthly"
                                    checked={this.state.planType == "Monthly"}
                                    onChange={this.changePlanType}
                                  />
                                  <div class="state p-info-o">
                                    <label className="checkboxlabel">
                                      Monthly
                                    </label>
                                  </div>
                                </div>
                              </Form.Group>
                            </div>
                            <div>
                              <div className="cardPlanText heading-css">
                                {this.props.price["1"].price / 100}$/month
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </>
                  ) : null}
                </>
              ) : null} */}

              {!this.state.loader && this.state.publisherKey !== "" ? (
                <div>
                  <Elements stripe={this.state.publisherKey}>
                    <CheckoutForm
                      clientSecret={this.state.clientSecret}
                      stripe={this.state.publisherKey}
                      closeModal={this.handleClose}
                      paymentSuccess={this.paymentSuccess}
                      price={
                        this.props.billingPeriod == "Yearly"
                          ? parseFloat(this.props.price["12"].price)
                          : parseFloat(this.props.price["1"].price)
                      }
                      // price={
                      //   this.state.planType == "Monthly"
                      //     ? parseFloat(this.props.price["1"].price)
                      //     : null
                      // }
                      plan={this.props.plan}
                      paymentPlan={this.props.paymentPlan}
                      planInterval={this.props.billingPeriod}
                      userPlan={this.props.userPlan}
                    />
                  </Elements>
                </div>
              ) : (
                <div style={{ margin: "20px", textAlign: "center" }}>
                  <Spinner animation="border" />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
export default Payment;
