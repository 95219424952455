import React from "react";
import { Table, Card, Button, Spinner, Modal } from "react-bootstrap";
import {
  acceptNetworkinvitation,
  getParticularNetworkDetails,
} from "../../common/networkapicalls";
import { sessionCheck } from "../../action";
import { connect } from "react-redux";
import "./style.css";

class NetworkPending extends React.Component {
  state = {
    network: [],
    opened: [],
    successMsg: false,
    err: false,
    pendingModal: false,
    pendingNetworkDetails: "",
    loading: false,
  };

  acceptInvitation = async (data) => {
    try {
      this.setState({ loading: true });
      let _res = await acceptNetworkinvitation(data.networkID);
      if (_res.data == "OK") {
        this.setState({ successMsg: true, loading: false });
        this.closeViewDetails();
        this.props.refreshPending();

        setTimeout(() => {
          this.setState({ successMsg: false });
        }, 3000);
      }
    } catch (Err) {
      this.closeViewDetails();
      this.setState({ loading: false });
    }
  };

  viewDetails = async (data) => {
    try {
      let _getNetworkDetails = await getParticularNetworkDetails(
        data.networkID
      );
      this.setState({ pendingNetworkDetails: _getNetworkDetails }, () => {
        this.setState({ pendingModal: true });
      });
    } catch (Err) {
      this.closeViewDetails();
      this.setState({ loading: false });
    }
  };

  closeViewDetails = () => {
    this.setState({ pendingModal: false, pendingNetworkDetails: "" });
  };

  render() {
    return (
      <div className="scrollhomecard">
        <div>
          {this.state.pendingModal ? (
            <Modal
              centered
              show={this.state.pendingModal}
              onHide={this.closeViewDetails}
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title className="pendingModalHeader">
                  {this.state.pendingNetworkDetails.networkName}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <h5 className="network__heading" style={{ fontSize: "14px" }}>
                    Overview :{" "}
                  </h5>
                  <Table striped bordered responsive hover className="tablecss">
                    <tbody>
                      <tr>
                        <th className="network__overviewTHFont">
                          Network Name
                        </th>
                        <td className="network__overviewTColValFont">
                          {this.state.pendingNetworkDetails.networkName}
                        </td>
                      </tr>

                      <tr>
                        <th className="network__overviewTHFont">Network ID</th>
                        <td className="network__overviewTColValFont">
                          {this.state.pendingNetworkDetails.networkID}
                        </td>
                      </tr>

                      <tr>
                        <th className="network__overviewTHFont">
                          Minimum Replication Factor
                        </th>
                        <td className="network__overviewTColValFont">
                          {
                            this.state.pendingNetworkDetails
                              .minReplicationFactor
                          }
                        </td>
                      </tr>

                      <tr>
                        <th className="network__overviewTHFont">
                          Maximum Replication Factor
                        </th>
                        <td className="network__overviewTColValFont">
                          {
                            this.state.pendingNetworkDetails
                              .maxReplicationFactor
                          }
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <h5 className="network__heading" style={{ fontSize: "14px" }}>
                    Node Details :{" "}
                  </h5>
                  <Table responsive striped bordered hover className="tablecss">
                    <thead>
                      <tr>
                        <th className="network_headerColumnsFont">#</th>
                        <th className="network_headerColumnsFont">Node Name</th>
                        <th className="network_headerColumnsFont">Node ID</th>
                        <th className="network_headerColumnsFont">Storage</th>
                        <th className="network_headerColumnsFont">Ram</th>
                        <th className="network_headerColumnsFont">
                          Cloud Provider
                        </th>
                        <th className="network_headerColumnsFont">Region</th>
                        <th className="network_headerColumnsFont">Node Type</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.pendingNetworkDetails.nodelist.map(
                        (data, index) => (
                          <tr key={index}>
                            <td className="network__detail_col">{index + 1}</td>
                            <td className="network__detail_col">
                              {data.nodeName}
                            </td>
                            <td className="network__detail_col">
                              {data.nodeID}
                            </td>
                            <td className="network__detail_col">
                              {data.storage}
                            </td>
                            <td className="network__detail_col">{data.ram}</td>
                            <td className="network__detail_col">
                              {data.cloudProvider}
                            </td>
                            <td className="network__detail_col">
                              {data.region}
                            </td>
                            <td className="network__detail_col">
                              {data.nodeType == 0
                                ? "Private"
                                : data.nodeType == 1
                                ? "Public"
                                : "premium"}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  className="footerbtn button-css"
                  size="sm"
                  onClick={this.closeViewDetails}
                >
                  Close
                </Button>
                {!this.state.loading ? (
                  <Button
                    variant="success"
                    className="footerbtn heading-css-bg"
                    size="sm"
                    onClick={() =>
                      this.acceptInvitation(this.state.pendingNetworkDetails)
                    }
                  >
                    {this.state.pendingNetworkDetails.networkType == 1 &&
                    this.state.pendingNetworkDetails.networkType == 0
                      ? "Accept"
                      : "Join"}
                  </Button>
                ) : (
                  <Button
                    variant="success"
                    className="footerbtn heading-css-bg"
                    disabled
                    size="sm"
                  >
                    {" "}
                    <Spinner
                      animation="border"
                      style={{ height: "20px", width: "20px" }}
                    />
                  </Button>
                )}
              </Modal.Footer>
            </Modal>
          ) : null}
        </div>

        {this.state.successMsg ? (
          <p style={{ fontSize: "12px", fontWeight: "600", color: "green" }}>
            Invitation accepted successfully
          </p>
        ) : null}
        {this.state.err ? (
          <p style={{ fontSize: "12px", fontWeight: "600", color: "crimson" }}>
            Something went wrong. Please try again later
          </p>
        ) : null}
        {this.props.pendingList.length > 0 &&
        this.props.pendingList !== "error" ? (
          this.props.pendingList.map((data, index) =>
            data.networkName !== "" ? (
              <Card
                key={index}
                className="appscard"
                style={{ cursor: "pointer" }}
              >
                <Card.Body style={{ padding: "10px 20px 20px 20px" }}>
                  <div className="cardcss">
                    <div style={{ display: "flex" }}>
                      <div className="appfirstletter heading-css-bg">
                        <p style={{ paddingTop: "7px" }}>
                          {" "}
                          {/* {data.networkName ? data.networkName.charAt(0) : null} */}
                          {data.networkName.charAt(0)}
                        </p>
                      </div>
                      <div style={{ marginTop: "5px" }}>
                        <Card.Title
                          style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            marginBottom: "2px",
                            color: "#000",
                          }}
                        >
                          {data.networkName}
                        </Card.Title>
                        <Card.Text className="carddesccss">
                          {data.networkID}
                        </Card.Text>
                      </div>
                    </div>

                    <div style={{ marginTop: "8px" }}>
                      <Button
                        id={"button" + index}
                        variant="success"
                        size="sm"
                        style={{
                          marginRight: "10px",
                          fontWeight: "600",
                          fontSize: "12px",
                        }}
                        onClick={() => this.viewDetails(data)}
                        className="buttonspin button-css"
                      >
                        View Details
                      </Button>
                      <div style={{ marginTop: "10px" }}>
                        <Spinner
                          animation="border"
                          style={{ height: "20px", width: "20px" }}
                          id={"spinner" + index}
                          className="spinner"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            ) : null
          )
        ) : (
          <p>No data found</p>
        )}

        {this.props.pendingList.length > 0 &&
        this.props.pendingList !== "error" ? null : this.props.pendingList
            .length == 0 ? null : (
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            {" "}
            <Spinner
              animation="border"
              style={{ height: "20px", width: "20px" }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    pendingList: store.home.pendingInvitationList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSession: () => dispatch(sessionCheck()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkPending);
