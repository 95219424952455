import { GET_PENDING_INVITATION_LIST } from "./types";
import { getPendingNetwork } from "../common/apicalls";
import { getTokeninfo, updateDev } from "../common/devapicalls";
import {
  getPublicNetworkTokens,
  getParticularNetworkDetails,
} from "../common/networkapicalls";

import { toastify } from "./session";

export const getTokenInitiate = () => {
  return async (dispatch) => {
    try {
      await getTokeninfo();
      let publictokens = await getPublicNetworkTokens();
      let _resVal = publictokens.data;
      if (!(typeof _resVal == "object")) {
        toastify("err", _resVal);
        return;
      }
      var pendingListArr = [];
      for (let i = 0; i < _resVal.length; i++) {
        let invitation = _resVal[i].accepted;
        if (invitation == false || invitation == "false") {
          // let _res = await getParticularNetworkDetails(
          //   _resVal[i].devToken.network_id
          // );
          // pendingListArr.push(_res);
          pendingListArr.push({
            networkID: _resVal[i].devToken.network_id,
            networkName: _resVal[i].devToken.networkName,
            networkToken: _resVal[i].devToken.network_token,
          });
        }
      }
      return dispatch({
        type: GET_PENDING_INVITATION_LIST,
        value: pendingListArr,
      });
    } catch (err) {
      toastify("err", err.message);
      return dispatch({ type: GET_PENDING_INVITATION_LIST, value: [] });
    }
  };
};
