import React, { Component } from "react";
import { Card, Table, Button } from "react-bootstrap";
import NodeAdd from "./addnodestonetwork";
import { bytesToSize } from "../../common/func";
import DisplayNodeDetails from "./displaynodedetails";
import { connect } from "react-redux";

class NetworkNodes extends Component {
  state = {
    allNodes: [],
    selectedIndex: -1,
    openCloseToggle: false,
    addNodeModal: false,
    owner: false,
    successMsg: false,
    networkName: "",
    displayNodeDetails: false,
    oneNodeData: "",
  };

  async componentDidUpdate(prevProps, prevState, snapsht) {
    if (this.props.nodes.length !== prevProps.nodes.length) {
      let _arr = this.props.nodes;
      let _devkey = await JSON.parse(sessionStorage.getItem("defAddress"))
        .value;
      _arr.filter((data) =>
        data.nodeOwnerID == _devkey
          ? (data.owned = "owned")
          : (data.owned = "partof")
      );
      this.setState({ allNodes: _arr });
    }
  }

  toggleShowHide = (index) => {
    this.setState({
      selectedIndex: index,
      openCloseToggle: !this.state.openCloseToggle,
    });
  };

  closeNodeModal = () => {
    this.setState({ addNodeModal: false });
  };
  successModal = () => {
    this.setState({ addNodeModal: false, successMsg: true });
    setTimeout(() => {
      this.setState({ successMsg: false });
    }, 3000);
  };

  closeNodeDetails = () => {
    this.setState({ displayNodeDetails: false });
  };
  render() {
    return (
      <div style={{ marginTop: "10px" }}>
        {this.state.oneNodeData !== "" ? (
          <DisplayNodeDetails
            displayNodeDetails={this.state.displayNodeDetails}
            closeNodeDetails={this.closeNodeDetails}
            data={this.state.oneNodeData}
          />
        ) : null}
        {this.state.successMsg ? (
          <p
            style={{
              fontWeight: "600",
              marginBottom: "0px",
              color: "green",
              fontSize: "13px",
            }}
          >
            Node added successfully
          </p>
        ) : null}
        <div className="fixedheight" style={{ height: "auto" }}>
          {this.state.allNodes.length !== 0 ? (
            <Table responsive hover style={{ textAlign: "left" }}>
              <thead>
                <tr>
                  <th className="network_headerColumns">#</th>
                  {this.props.peerID.length == 0 ? null : (
                    <th className="network_headerColumns">Node Name</th>
                  )}
                  {this.props.peerID.length == 0 ? null : (
                    <th className="network_headerColumns">Node ID</th>
                  )}
                  {this.props.peerID.length > 0 ? (
                    <th className="network_headerColumns">Peer Name</th>
                  ) : null}
                  {this.props.peerID.length > 0 ? (
                    <th
                      className="network_headerColumns"
                      style={{ width: "19%" }}
                    >
                      Peer ID
                    </th>
                  ) : null}

                  <th className="network_headerColumns">Storage Used</th>
                  <th className="network_headerColumns">Region</th>
                  {this.props.plan >= 3 ? (
                    <th className="network_headerColumns">Node IP</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {this.state.allNodes.map((data, index) => (
                  <tr key={index}>
                    <td className="network__detail_col">{index + 1}</td>
                    {this.props.peerID.length == 0 ? null : (
                      <td className="network__detail_col">{data.nodeName}</td>
                    )}
                    {this.props.peerID.length == 0 ? null : (
                      <td className="network__detail_col">{data.nodeID}</td>
                    )}

                    {this.props.peerID.length > 0 ? (
                      <td className="network__detail_col">
                        {data.nodePeerName ? data.nodePeerName : "-"}
                      </td>
                    ) : null}
                    {this.props.peerID.length > 0 ? (
                      <td className="network__detail_col">
                        {data.nodePeerID ? data.nodePeerID : "-"}
                      </td>
                    ) : null}

                    <td className="network__detail_col">{data.storage}</td>
                    <td className="network__detail_col">
                      {data.region !== "" ? data.region : data.city}
                    </td>
                    {this.props.plan >= 3 ? (
                      data.owned == "owned" ? (
                        <td
                          className="network__detail_col heading-css"
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() =>
                            this.setState({
                              oneNodeData: data,
                              displayNodeDetails: true,
                            })
                          }
                        >
                          {data.nodeIP}
                        </td>
                      ) : (
                        <td className="network__detail_col">-</td>
                      )
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    plan: store.price.priceState,
  };
};

export default connect(mapStateToProps, null)(NetworkNodes);
