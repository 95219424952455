import axios from "axios";

export const listDeletedFiles = async (path, networkID, appID) => {
  try {
    const tempUrl =
      process.env.REACT_APP_MOIBIT_API_URL +
      "net/" +
      networkID +
      "/app/" +
      appID +
      "/file";
    return (
      await axios({
        url: tempUrl,
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          fileOperationType: 6,
          path: path,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const listDeletedFilesperDirectory = async (path, networkID, appID) => {
  try {
    const tempUrl =
      process.env.REACT_APP_MOIBIT_API_URL +
      "net/" +
      networkID +
      "/app/" +
      appID +
      "/file";
    return (
      await axios({
        url: tempUrl,
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          fileOperationType: 7,
          path: path,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const restoreFileOrFolder = async (appID, networkID, data) => {
  try {
    return (
      await axios({
        url:
          process.env.REACT_APP_MOIBIT_API_URL +
          "net/" +
          networkID +
          "/app/" +
          appID +
          "/file/remove",
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: data,
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const getProvenanceDetails = async () => {
  try {
    const getLogsURL = process.env.REACT_APP_MOIBIT_API_URL + "getprovenance";
    return (
      await axios({
        url: getLogsURL,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: {
          developerKey: JSON.parse(sessionStorage.getItem("defAddress")).value,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const getIntermediateShards = async (data) => {
  try {
    const getLogsURL =
      process.env.REACT_APP_MOIBIT_API_URL +
      "net/" +
      data.networkID +
      "/app/" +
      data.appID +
      "/getintermediateshards";
    return (
      await axios({
        url: getLogsURL,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: {
          filename: data.fileName,
          version: data.version,
          intermediatehash: data.hash,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};
