import React, { Component, Fragment } from "react";
import { customDateFormat, bytesToSize } from "../../../common/func";
import {
  OverlayTrigger,
  Tooltip,
  Spinner,
  Modal,
  Button,
  Table,
} from "react-bootstrap";
import "./style.css";
import { lightSpeedIn } from "react-animations";
import Radium from "radium";
import Maps from "./map";
const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const styles = {
  bounce: {
    animation: "x 1s",
    animationName: Radium.keyframes(lightSpeedIn, "lightSpeedIn"),
  },
};
class RenderLayout extends Component {
  constructor(props) {
    super(props);
    const { totalRecords, pageLimit = 30, pageNeighbours = 0 } = this.props;
    this.pageLimit = typeof pageLimit === "number" ? pageLimit : 6;
    this.totalRecords = typeof totalRecords === "number" ? totalRecords : 0;
    this.pageNeighbours =
      typeof pageNeighbours === "number"
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;
    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);
    this.state = {
      disabled: [],
      currentPage: 1,
      renderData: [],
      immutable: false,
      decentralised: false,
      enableMap: false,
      shards: [],
      moibitWorld: "",
    };
  }
  componentDidMount() {
    this.gotoPage(1);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.allData) !== JSON.stringify(this.props.allData)
    ) {
      this.gotoPage(1);
    }
  }
  gotoPage = (page) => {
    const { onPageChanged = (f) => f } = this.props;

    const currentPage = Math.max(0, Math.min(page, this.totalPages));

    const paginationData = {
      currentPage,
      totalPages: this.totalPages,
      pageLimit: this.pageLimit,
      totalRecords: this.totalRecords,
    };
    this.setState({ currentPage }, () => onPageChanged(paginationData));
  };

  handleClick = (page, evt) => {
    evt.preventDefault();
    this.gotoPage(page);
  };

  handleMoveLeft = (evt) => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage - this.pageNeighbours * 2 - 1);
  };

  handleMoveRight = (evt) => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage + this.pageNeighbours * 2 + 1);
  };

  fetchPageNumbers = () => {
    const totalPages = this.totalPages;
    const currentPage = this.state.currentPage;
    const pageNeighbours = this.pageNeighbours;

    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;
    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }
      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };
  thumbNail = (thumbnail) => {
    if (thumbnail !== undefined) {
      if (thumbnail.length > 10) {
        return thumbnail;
      } else {
        return require(`../../../assets/${thumbnail}.png`);
      }
    }
  };

  renderTooltip = (props) => {
    return <Tooltip {...props}>Pinned</Tooltip>;
  };

  copyToClipboard = (str) => {
    document.getElementById("Hash_" + str).innerHTML = "Copied!";
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    document.getElementById("Hash_" + str).value = "Copied";
    setTimeout(() => {
      document.getElementById("Hash_" + str).innerHTML = "Copy";
    }, 1000);
  };

  popOverFunc = () => {
    this.setState({ immutable: !this.state.immutable });
  };

  handleCloseMap = () => {
    this.setState({ enableMap: false, shards: [], moibitWorld: "" });
  };
  enableMapPoup = (data) => {
    this.setState({ enableMap: true, shards: data.shards, moibitWorld: data });
  };
  render() {
    const { totalRecords, pageLimit = 6 } = this.props;
    this.pageLimit = typeof pageLimit === "number" ? pageLimit : 6;
    this.totalRecords = totalRecords;
    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);
    const { currentPage } = this.state;
    const pages = this.fetchPageNumbers();
    return (
      <div style={{ display: this.props.display ? "none" : "block" }}>
        <div>
          <Modal
            show={this.state.enableMap}
            onHide={this.handleCloseMap}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontWeight: "600", fontSize: "16px" }}>
                MoiBit Security
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Maps
                show={this.state.enableMap}
                shards={this.state.shards}
                world={this.state.moibitWorld}
                networkID={this.props.networkID}
                appID={this.props.appID}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary button-css"
                onClick={this.handleCloseMap}
                size="sm"
                style={{ fontWeight: "600" }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div style={{ marginBottom: "20px" }}>
          {this.props.listGridView == 2 ? (
            this.props.renderData.length > 0 ? (
              <div>
                <div className="" style={{ fontSize: "12px" }}>
                  <Table
                    hover
                    responsive
                    className="table-condensed"
                    style={{ opacity: this.props.effectiveLoading }}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Size</th>
                        <th>Modified</th>
                        <th>MòiBit Privacy</th>
                        <th>MoiBit Security</th>
                        <th>MoiBit Trust</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody style={{ fontWeight: "600" }}>
                      {this.props.renderData.length > 0 ? (
                        this.props.renderData.map((val, index) => (
                          <>
                            <tr key={index}>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={
                                  this.props.enablePreviewOnClick
                                    ? () =>
                                        this.props.previewFile(
                                          val.hash,
                                          val.isDir ? 1 : 0,
                                          val.isDir
                                            ? val.directory
                                            : val.path.split("/").pop(),
                                          val.thumbnail,
                                          val.filesize,
                                          val.path,
                                          val.lastUpdated,
                                          val.pinned,
                                          val.version
                                        )
                                    : () => {
                                        alert("Sdf");
                                      }
                                }
                              >
                                {val.isDir ? (
                                  <i
                                    className="fa fa-folder-open"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "30px",
                                      color: "#000",
                                      marginTop: "10px",
                                      paddingLeft: "10px",
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-file heading-css"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "30px",
                                      marginTop: "10px",
                                      paddingLeft: "10px",
                                    }}
                                  ></i>
                                )}
                              </td>
                              <td
                                onClick={
                                  this.props.enablePreviewOnClick
                                    ? () =>
                                        this.props.previewFile(
                                          val.hash,
                                          val.isDir ? 1 : 0,
                                          val.isDir
                                            ? val.directory
                                            : val.path.split("/").pop(),
                                          val.thumbnail,
                                          val.filesize,
                                          val.path,
                                          val.lastUpdated,
                                          val.pinned,
                                          val.version
                                        )
                                    : () => {}
                                }
                              >
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>
                                      {val.isDir
                                        ? val.directory
                                            .slice(0, -1)
                                            .split("/")
                                            .pop()
                                        : val.path.split("/").pop()}
                                    </Tooltip>
                                  }
                                >
                                  <p
                                    className="marginTop15"
                                    style={{
                                      fontSize: "12px",
                                      display: "block",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      marginBottom: "0px",
                                      color: "#000",
                                      width: "auto",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {val.isDir
                                      ? val.directory
                                          .slice(0, -1)
                                          .split("/")
                                          .pop()
                                          .slice(0, 20) +
                                        (val.directory.split("/").pop().length >
                                        20
                                          ? "..."
                                          : "")
                                      : val.path.split("/").pop().slice(0, 20) +
                                        (val.path.split("/").pop().length > 20
                                          ? "..."
                                          : "")}
                                  </p>
                                </OverlayTrigger>
                              </td>
                              <td>
                                {!val.isDir ? (
                                  <p
                                    className="marginTop15"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {bytesToSize(val.filesize)}
                                  </p>
                                ) : null}
                              </td>
                              <td>
                                {!val.isDir ? (
                                  <p
                                    style={{ fontSize: "12px" }}
                                    className="marginTop15"
                                  >
                                    {customDateFormat(val.lastUpdated)}
                                  </p>
                                ) : null}
                              </td>
                              <td>
                                {!val.isDir ? (
                                  <p
                                    className="marginTop15"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {val.mbPrivacy}
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td>
                                {!val.isDir ? (
                                  <p
                                    className="marginTop15 heading-css"
                                    style={{
                                      fontSize: "12px",
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                    }}
                                    onClick={() => this.enableMapPoup(val)}
                                  >
                                    {val.mbSecurity}
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </td>

                              <td>
                                {!val.isDir ? (
                                  <p
                                    className="marginTop15"
                                    style={{
                                      color: !this.props.showHideProff[index]
                                        ? "#8021BF"
                                        : "#000",
                                      textDecoration: !this.props.showHideProff[
                                        index
                                      ]
                                        ? "underline"
                                        : "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.props.viewProvenanceLog(
                                        index,
                                        val.directory,
                                        val.path,
                                        val.lastUpdated
                                      )
                                    }
                                  >
                                    {!this.props.showHideProff[index] ? (
                                      <span>View Details</span>
                                    ) : (
                                      <span>
                                        <i
                                          className="fa fa-times"
                                          aria-hidden="true"
                                        ></i>
                                        Close
                                      </span>
                                    )}
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td>
                                {!val.isDir ? (
                                  <div
                                    className="marginTop15 trashdelete"
                                    onClick={() =>
                                      this.props.deleteFile(
                                        index,
                                        val.isDir,
                                        val.path.split("/").pop(),
                                        val.hash,
                                        bytesToSize(val.filesize),
                                        val.thumbnail == "pdf"
                                          ? require(`../../../assets/pdf.png`)
                                          : val.thumbnail == "txt"
                                          ? require(`../../../assets/txt.png`)
                                          : val.thumbnail == "zip"
                                          ? require(`../../../assets/zip.png`)
                                          : val.thumbnail == "video"
                                          ? require(`../../../assets/video.png`)
                                          : val.thumbnail == "json"
                                          ? require(`../../../assets/json.png`)
                                          : val.thumbnail,
                                        val.version
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-trash-o"
                                      aria-hidden="true"
                                      style={{ fontSize: "16px" }}
                                    ></i>
                                  </div>
                                ) : (
                                  <div
                                    className="marginTop15 trashdelete"
                                    onClick={() =>
                                      this.props.deleteFile(
                                        index,
                                        val.isDir,
                                        val.directory
                                          .slice(0, -1)
                                          .split("/")
                                          .pop(),
                                        val.hash,
                                        bytesToSize(val.filesize),
                                        val.thumbnail == "pdf"
                                          ? require(`../../../assets/pdf.png`)
                                          : val.thumbnail == "txt"
                                          ? require(`../../../assets/txt.png`)
                                          : val.thumbnail == "zip"
                                          ? require(`../../../assets/zip.png`)
                                          : val.thumbnail == "video"
                                          ? require(`../../../assets/video.png`)
                                          : val.thumbnail == "json"
                                          ? require(`../../../assets/json.png`)
                                          : val.thumbnail,
                                        val.version
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-trash-o"
                                      aria-hidden="true"
                                      style={{ fontSize: "16px" }}
                                    ></i>
                                  </div>
                                )}
                              </td>
                            </tr>

                            {/* provenance code */}

                            {!val.isDir && this.props.showHideProff[index] ? (
                              <tr>
                                <td
                                  colSpan={8}
                                  style={{
                                    fontSize: "12px",
                                    backgroundColor: "#f5f5f5",
                                  }}
                                >
                                  {!this.props.provenanceArrLoader[index] ? (
                                    <div>
                                      {this.props.provenanceArr[index] !==
                                      "" ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            margin: "10px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              margin: "5px 0px",
                                            }}
                                          >
                                            <div className="profflabel">
                                              <b>Interaction ID</b>
                                            </div>
                                            <div>
                                              {
                                                this.props.provenanceArr[index]
                                                  .TxnID
                                              }
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              margin: "5px 0px",
                                            }}
                                          >
                                            <div className="profflabel">
                                              <b>Tesseract Number</b>
                                            </div>
                                            <div>
                                              {
                                                this.props.provenanceArr[index]
                                                  .blockNumber
                                              }
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              margin: "5px 0px",
                                            }}
                                          >
                                            <div className="profflabel">
                                              <b>Tesseract Hash</b>
                                            </div>
                                            <div>
                                              {
                                                this.props.provenanceArr[index]
                                                  .blockHash
                                              }
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              margin: "5px 0px",
                                            }}
                                          >
                                            <div className="profflabel">
                                              <b>Verified By</b>
                                            </div>
                                            <div>
                                              {
                                                this.props.provenanceArr[index]
                                                  .verifiedBy
                                              }
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              margin: "5px 0px",
                                            }}
                                          >
                                            <div className="profflabel">
                                              <b>Date</b>
                                            </div>
                                            <div>
                                              {"" +
                                                new Date(
                                                  this.props.provenanceArr[
                                                    index
                                                  ].atTime * 1000
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <p style={{ color: "crimson" }}>
                                          No data found
                                        </p>
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        textAlign: "center",
                                        margin: "10px",
                                      }}
                                    >
                                      <p style={{ marginBottom: "10px" }}>
                                        Fetching...
                                      </p>
                                      <Spinner animation="border" size="sm" />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ) : null}
                            {/* end provenance code */}
                          </>
                        ))
                      ) : (
                        <p style={{ paddingTop: "30px" }}>
                          This folder is empty
                        </p>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            ) : (
              <p style={{ paddingTop: "30px" }}>This folder is empty</p>
            )
          ) : null}
        </div>
        {this.props.renderData.length > 0 ? (
          <div>
            <Fragment>
              <ul className="pagination justify-content-center">
                {pages.map((page, index) => {
                  if (page === LEFT_PAGE)
                    return (
                      <li
                        key={index}
                        className="page-item"
                        style={{ width: "40px" }}
                      >
                        <a
                          className="page-link"
                          href="#"
                          aria-label="Previous"
                          onClick={this.handleMoveLeft}
                          style={{
                            textAlign: "center",
                            background: "#8021BF",
                            border: "1px solid #fff",
                            color: "#fff",
                          }}
                        >
                          <span aria-hidden="true">&laquo;</span>
                          <span className="sr-only">Previous</span>
                        </a>
                      </li>
                    );

                  if (page === RIGHT_PAGE)
                    return (
                      <li
                        key={index}
                        className="page-item"
                        style={{ width: "40px" }}
                      >
                        <a
                          className="page-link"
                          href="#"
                          aria-label="Next"
                          onClick={this.handleMoveRight}
                          style={{
                            textAlign: "center",
                            background: "#8021BF",
                            border: "1px solid #fff",
                            color: "#fff",
                          }}
                        >
                          <span aria-hidden="true">&raquo;</span>
                          <span className="sr-only">Next</span>
                        </a>
                      </li>
                    );

                  return (
                    <li
                      key={index}
                      className={`page-item${
                        currentPage === page ? " active" : ""
                      }`}
                      style={{
                        width: "40px",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={(e) => this.handleClick(page, e)}
                        style={{
                          textAlign: "center",
                          background: "#8021BF",
                          border: "1px solid #fff",
                          color: "#fff",
                        }}
                      >
                        {page}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Fragment>
          </div>
        ) : null}
      </div>
    );
  }
}

export default RenderLayout;
