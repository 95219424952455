module.exports = {
    moiValidationError : function(warningBoolean, msg) {
        if (warningBoolean) {
          throw new Error(msg || 'Assertion failed');
        }
    },
    moiPrivateGetError : function() {
      throw new TypeError("attempted to get private field on non-instance"); 
    },
    moiPrivateSetError : function() {
      throw new TypeError("attempted to set private field on non-instance"); 
    },
    moiIgcPathNotExists : function(notValidNs) {
      throw new ReferenceError("no namespace like `"+notValidNs+"`"); 
    },
    moiAlreadyInNamespace : function(nS) {
      throw new ReferenceError("already member of `"+nS+"`"); 
    },
    userNameAlreadyExists : function(_uN) {
      throw new Error("`+"+_uN+"` username already exists"); 
    },
    userNameAlreadyExists : function() {
      throw new Error("Sorry, This username is already taken"); 
    },
    notAnamespaceMember : function(namespace) {
      throw new Error("Not a member of "+namespace+' namespace')
    },
    validateAuthToken: function(_authToken) {
      if(_authToken.message == undefined || _authToken.signature == undefined) {
        throw new Error('Invalid Auth token')
      }
      if(_authToken.signature.length != 132) {
        throw new Error('Invalid Signature')
      }
    }
}