import React from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { createNode } from "../../common/nodeapicalls";

class CreateNode extends React.Component {
  state = {
    nodeName: "",
    nodeIP: "",
    ram: "",
    cpu: "",
    storage: "",
    cloudProvider: "",
    nodeType: "",
    nodeStatus: "",
    loading: false,
  };

  setValue = (name, value) => {
    this.setState({ [name]: value });
  };

  setNodeType = (e) => {
    this.setState({ nodeType: e.target.value });
  };

  setNodeStatus = (e) => {
    this.setState({ nodeStatus: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({ loading: true });
      let data = {
        nodeName: this.state.nodeName,
        nodeIP: this.state.nodeIP,
        region: "",
        ram: this.state.ram,
        cpu: this.state.cpu,
        storage: this.state.storage,
        internalName: "",
        isPrivateNode: this.state.nodeType == "private" ? true : false,
        isMoibitNode: true,
        nodeOwnerID: "",
        networkIDs: null,
        isActive: this.state.nodeStatus == "up" ? true : false,
      };

      let resposne = await createNode([data]);
      if (resposne == "error") {
        this.setState({ loading: false });
        this.props.openNodePopupFunc();
      } else {
        this.setState({ loading: false });
      }
    } catch (Err) {
      //do nothing
    }
  };
  render() {
    return (
      <div>
        <Modal
          show={this.props.open}
          onHide={this.props.openNodePopupFunc}
          backdrop="static"
          keyboard={false}
          size="lg"
          centered
        >
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Add Node</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Node name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Node name"
                  onChange={(e) => this.setValue("nodeName", e.target.value)}
                  required
                  disabled={this.props.disableName}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Node IP address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Node IP address"
                  onChange={(e) => this.setValue("nodeIP", e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>RAM</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="RAM"
                  required
                  onChange={(e) => this.setValue("ram", e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>CPU</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CPU"
                  onChange={(e) => this.setValue("cpu", e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Storage</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Storage"
                  onChange={(e) => this.setValue("storage", e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Node type</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  onChange={this.setNodeType}
                  required
                >
                  <option value="">--Select--</option>
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <Form.Label>Node Current Status</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  onChange={this.setNodeStatus}
                  required
                >
                  <option value="">--Select--</option>
                  <option value="up">Up</option>
                  <option value="down">Down</option>
                </Form.Control>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              {this.state.loading ? (
                <Button
                  variant="info"
                  style={{
                    fontWeight: "600",
                    background: "#203e65",
                    border: "1px solid #203e65",
                    height: "40px",
                    width: "80px",
                  }}
                  disabled
                >
                  <Spinner
                    animation="border"
                    style={{ height: "20px", width: "20px" }}
                  />
                </Button>
              ) : (
                <Button variant="secondary" type="submit">
                  Add
                </Button>
              )}
              <Button
                variant="secondary"
                onClick={this.props.openNodePopupFunc}
              >
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default CreateNode;
