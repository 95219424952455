import React, { Component } from "react";
import { Modal, Button, Spinner, Card, Form } from "react-bootstrap";
import { updateApp, setReplication } from "../../common/appapicalls";
import { getParticularNetworkDetails } from "../../common/networkapicalls";
import crypto from "crypto";
import "./style.css";
import { Link } from "react-router-dom";

class EditApp extends Component {
  state = {
    appName: "",
    appDesc: "",
    appMaxStorage: 0,
    appReplication: 0,
    loading: false,
    error: false,
    appId: "",
    networkName: "",
    replicationErr: false,
    replicationCountErr: false,
    networkID: "",
    nodeReplicationFactor: 0,
    replicationErrVal: "",
    deactivateLoading: false,
    replicationCopy: 0,
    isActive: false,
    networkRFVal: 0,
    networkRFErr: false,
    encryptionType: 0,
    randomBytes: "",
    selectEncryption: "",
    selectEncryptionText: "",
    showPlan: false,
    networkErr: false,
  };
  setValue = (name, value) => {
    this.setState({ [name]: value, networkRFErr: false });
  };
  componentDidUpdate(previousProps) {
    if (previousProps.editAppData !== this.props.editAppData) {
      this.setState({
        appName: this.props.editAppData.appName,
        appDesc: this.props.editAppData.appDescription,
        appMaxStorage: this.props.editAppData.maxStorage,
        appReplication: this.props.editAppData.replication,
        appId: this.props.editAppData.appID,
        networkName: this.props.editAppData.networkName,
        networkID: this.props.editAppData.networkID,
        replicationCopy: this.props.editAppData.replication,
        isActive: this.props.editAppData.isActive,
        encryptionType: this.props.editAppData.encryptionType,
        customKey: this.props.editAppData.customKey,
      });
    }
  }

  deleteApp = async () => {
    try {
      this.props.removeAppModalState(
        this.state.appId,
        this.state.appName,
        this.state.networkID
      );
    } catch (err) {
      // do nothing
    }
  };

  deactivateApp = async (type) => {
    try {
      let data = {
        appName: this.state.appName,
        isActive: type == 1 ? true : false,
      };
      this.setState({ deactivateLoading: true });
      let _res = await updateApp(data, this.state.networkID, this.state.appId);
      if (_res.data == "app details updated") {
        this.setState({ deactivateLoading: false });
        this.props.editSuccess();
      }
    } catch (err) {
      this.setState({ deactivateLoading: false });
    }
  };

  editAppFunc = async (networkID) => {
    try {
      let data = {
        appName: this.state.appName,
        appDescription: this.state.appDesc,
        replication: parseInt(this.state.appReplication),
        isActive: true,
        encryptionType: parseInt(this.state.encryptionType),
        customKey: this.state.randomBytes,
      };
      let _res;
      if (
        parseInt(this.state.encryptionType) == 0 ||
        parseInt(this.state.encryptionType) == -1
      ) {
        _res = await updateApp(data, networkID, this.state.appId);
      } else if (parseInt(this.state.encryptionType) == 1) {
        if (this.props.plan >= 1) {
          _res = await updateApp(data, networkID, this.state.appId);
        } else {
          setTimeout(() => {
            this.setState({ showPlan: true, loading: false });
          }, 2000);
        }
      } else if (parseInt(this.state.encryptionType) == 3) {
        if (this.props.plan >= 2) {
          _res = await updateApp(data, networkID, this.state.appId);
        } else {
          setTimeout(() => {
            this.setState({ showPlan: true, loading: false });
          }, 2000);
        }
      } else if (
        parseInt(this.state.encryptionType) == 2 ||
        parseInt(this.state.encryptionType) == 4
      ) {
        if (this.props.plan >= 3) {
          _res = await updateApp(data, networkID, this.state.appId);
        } else {
          setTimeout(() => {
            this.setState({ showPlan: true, loading: false });
          }, 2000);
        }
      } else {
        this.setState({ partOferr: true, loading: false });
      }

      // if (this.state.replicationCopy !== this.state.appReplication) {
      // let _res = await updateApp(data, networkID, this.state.appId);
      if (_res.data == "app details updated") {
        this.setState({ loading: false });
        this.props.editSuccess();
      }
    } catch (err) {
      this.setState({
        replicationErrVal: err.response.data.data,
        replicationCountErr: true,
        loading: false,
      });
    }
  };

  selectEncryption = (e) => {
    this.setState(
      {
        encryptionType: e.target.value,
        showPlan: false,
        selectEncryptionText: e.target.selectedOptions[0].text,
        networkRFError: false,
      },
      () => {
        if (this.state.encryptionType == 3) {
          this.generateRandom();
        }
      }
    );
  };

  generateRandom = () => {
    try {
      crypto.randomBytes(16, (err, buf) => {
        if (err) {
          return;
        }
        this.setState({
          randomBytes: buf.toString("hex"),
          customKey: buf.toString("hex"), // Update customKey state with generated key
        });
      });
    } catch (err) {
      // do nothing
    }
  };

  handleEditApp = async (e) => {
    e.preventDefault();
    try {
      this.setState({
        replicationCountErr: false,
        replicationErr: false,
        loading: true,
        replicationErrVal: "",
      });
      let networkID = this.state.networkID;

      let _getNetworkDetails = await getParticularNetworkDetails(networkID);
      if (_getNetworkDetails.data !== "developer validation failed") {
        let nodeLen = _getNetworkDetails.maxReplicationFactor;
        if (this.state.appReplication < 2) {
          this.setState({ replicationErr: true, loading: false });
          return;
        } else if (this.state.appReplication <= nodeLen) {
          await this.editAppFunc(networkID);
        } else {
          this.setState({
            networkRFVal: nodeLen,
            networkRFErr: true,
            loading: false,
          });
        }
      }
    } catch (err) {
      this.setState({ loading: false, error: true });
      setTimeout(() => {
        this.setState({ error: false });
      }, 3000);
    }
  };
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleEditClose}
        centered
      >
        {this.props.editAppData.appName ? (
          <div>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontWeight: "600", fontSize: "14px" }}>
                Edit app
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <Card>
                      <Card.Body>
                        <Form onSubmit={this.handleEditApp}>
                          <Form.Group>
                            <Form.Label
                              style={{ fontWeight: "600", color: "#000" }}
                            >
                              Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Name of the app"
                              className="createappform1"
                              onChange={(e) =>
                                this.setValue("appName", e.target.value)
                              }
                              value={this.state.appName}
                              required
                              disabled
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label
                              style={{ fontWeight: "600", color: "#000" }}
                            >
                              Network
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Name of the app"
                              className="createappform1"
                              value={
                                this.state.networkName == ""
                                  ? "Moi_Net"
                                  : this.state.networkName
                              }
                              required
                              disabled
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label
                              style={{ fontWeight: "600", color: "#000" }}
                            >
                              Description
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows="3"
                              placeholder="About the app"
                              className="createappform1"
                              onChange={(e) =>
                                this.setValue("appDesc", e.target.value)
                              }
                              required
                              value={this.state.appDesc}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label
                              style={{ fontWeight: "600", color: "#000" }}
                            >
                              Replication
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Replication factor"
                              className="createappform1"
                              onChange={(e) =>
                                this.setValue("appReplication", e.target.value)
                              }
                              required
                              value={this.state.appReplication}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label
                              style={{ fontWeight: "600", color: "#000" }}
                            >
                              Encryption Type
                            </Form.Label>
                            <Form.Control
                              placeholder="Encryption Type"
                              className="createappform1"
                              as="select"
                              custom
                              // onChange={this.selectEncryption}
                              onChange={(e) =>
                                this.setValue("encryptionType", e.target.value)
                              }
                              required
                              value={this.state.encryptionType}
                            >
                              <option value="">
                                -- Select encryption type --
                              </option>
                              <option value={-1}>No encryption</option>
                              <option value={0}>Default</option>
                              <optgroup label="Enterprise Encryption(Server)">
                                <option value={1}>Developer key</option>
                                <option value={2}>End User key</option>
                                <option value={3}>Custom key</option>
                              </optgroup>

                              <option value={4}>
                                End-to-End Encryption(Client)
                              </option>
                            </Form.Control>
                          </Form.Group>
                          {parseInt(this.state.encryptionType) == 3 ? (
                            <Form.Group>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Personalised Encryption"
                                  style={{ width: "80%" }}
                                  value={this.state.customKey}
                                />
                                <Button
                                  className="buttonW"
                                  variant="info"
                                  size="sm"
                                  style={{ fontWeight: "600" }}
                                  onClick={this.generateRandom}
                                >
                                  <i
                                    className="fa fa-refresh"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Generate
                                </Button>
                              </div>
                            </Form.Group>
                          ) : null}

                          {this.state.showPlan ? (
                            <div>
                              <Form.Text
                                style={{
                                  margin: "10px",
                                  marginLeft: "0px",
                                  color: "crimson",
                                  fontSize: "14px",
                                }}
                              >
                                Seems like your current plan is{" "}
                                <span style={{ fontWeight: "600" }}>
                                  {this.props.plan == 0
                                    ? "Developer"
                                    : this.props.plan == 2
                                    ? "Start Up"
                                    : this.props.plan == 3
                                    ? "Business"
                                    : this.props.plan == 4
                                    ? "Enterprise"
                                    : null}
                                </span>
                                . To enable{" "}
                                <i style={{ fontWeight: "600" }}>
                                  {this.state.selectEncryptionText}
                                </i>{" "}
                                in your application, you must be under{" "}
                                <span style={{ fontWeight: "600" }}>
                                  {parseInt(this.state.encryptionType) == 1
                                    ? "Start Up"
                                    : parseInt(this.state.encryptionType) == 3
                                    ? "Start Up"
                                    : parseInt(this.state.encryptionType) ==
                                        2 ||
                                      parseInt(this.state.encryptionType) == 4
                                    ? "Business"
                                    : null}
                                </span>{" "}
                                tier.
                              </Form.Text>
                              <div
                                style={{
                                  textAlign: "center",
                                  marginBottom: "15px",
                                }}
                              >
                                <Link to="/pricing">
                                  {" "}
                                  <Button
                                    size="sm"
                                    variant="info"
                                    style={{
                                      fontWeight: "600",
                                      background: "darkblue",
                                      border: "1px solid darkblue",
                                    }}
                                  >
                                    Upgrade to{" "}
                                    {parseInt(this.state.encryptionType) == 1
                                      ? "Start Up"
                                      : parseInt(this.state.encryptionType) == 3
                                      ? "Start Up"
                                      : parseInt(this.state.encryptionType) ==
                                          2 ||
                                        parseInt(this.state.encryptionType) == 4
                                      ? "Business"
                                      : null}
                                  </Button>
                                </Link>
                              </div>
                            </div>
                          ) : null}

                          {this.state.replicationErr ? (
                            <Form.Text
                              style={{
                                margin: "10px",
                                marginLeft: "0px",
                                color: "crimson",
                                fontSize: "12px",
                              }}
                            >
                              {
                                "MoiBit make sure high data availability of the file by keeping every file atleast in 2 copies, So replication factor cannot be < 2"
                              }
                            </Form.Text>
                          ) : null}

                          {this.state.networkRFErr ? (
                            <Form.Text
                              style={{
                                margin: "10px",
                                marginLeft: "0px",
                                color: "crimson",
                                fontSize: "12px",
                              }}
                            >
                              {`Replication factor must be 2 to ${this.state.networkRFVal}`}
                            </Form.Text>
                          ) : null}
                          {this.state.replicationCountErr ? (
                            <Form.Text
                              style={{
                                margin: "10px",
                                marginLeft: "0px",
                                color: "crimson",
                                fontSize: "12px",
                              }}
                            >
                              {this.state.replicationErrVal}
                            </Form.Text>
                          ) : null}

                          {/* deactive button */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ textAlign: "left" }}>
                              {!this.state.deactivateLoading ? (
                                <div>
                                  {this.state.isActive ? (
                                    <Button
                                      size="sm"
                                      type="button"
                                      variant="danger"
                                      className="button-css"
                                      style={{ fontWeight: "600" }}
                                      onClick={() => this.deactivateApp(0)}
                                    >
                                      Deactivate
                                    </Button>
                                  ) : (
                                    <Button
                                      type="button"
                                      size="sm"
                                      variant="success"
                                      className="button-css"
                                      style={{ fontWeight: "600" }}
                                      onClick={() => this.deactivateApp(1)}
                                    >
                                      Activate
                                    </Button>
                                  )}
                                </div>
                              ) : (
                                <Button
                                  variant="info"
                                  size="sm"
                                  style={{
                                    fontWeight: "600",
                                    background: !this.state.isActive
                                      ? "#8021BF"
                                      : "#000",
                                    border: !this.state.isActive
                                      ? "1px solid #8021BF"
                                      : "1px solid #000",
                                    width: "80px",
                                  }}
                                  disabled
                                >
                                  <Spinner
                                    animation="border"
                                    style={{ height: "15px", width: "15px" }}
                                  />
                                </Button>
                              )}
                            </div>
                            {/* end deactivation button */}

                            <div
                              style={{ textAlign: "right" }}
                              style={{ display: "flex" }}
                            >
                              {!this.state.loading ? (
                                <Button
                                  className="button-css"
                                  size="sm"
                                  type="submit"
                                  variant="info"
                                  style={{ fontWeight: "600" }}
                                >
                                  Update
                                </Button>
                              ) : (
                                <Button
                                  variant="info"
                                  size="sm"
                                  className="button-css"
                                  style={{ fontWeight: "600", width: "80px" }}
                                  disabled
                                >
                                  <Spinner
                                    animation="border"
                                    style={{ height: "15px", width: "15px" }}
                                  />
                                </Button>
                              )}
                              &nbsp;
                              {/* delete app button */}
                              <div style={{ textAlign: "right" }}>
                                <div>
                                  <Button
                                    type="button"
                                    size="sm"
                                    variant="info"
                                    className="heading-css-bg heading-css-bg-border"
                                    style={{ fontWeight: "600" }}
                                    onClick={() => this.deleteApp()}
                                  >
                                    <i
                                      className="fa fa-trash-o"
                                      aria-hidden="true"
                                    ></i>
                                  </Button>
                                </div>
                              </div>
                              {/* end delete app button */}
                            </div>
                          </div>
                        </Form>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        ) : (
          <Modal.Body style={{ textAlign: "center" }}>
            {" "}
            <Spinner animation="border" />
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

export default EditApp;
