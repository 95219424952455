const MoiRequestWrapper = require("./reqWrapper");
const { jsmidEncrypt } = require("./crypto-utils");
const Constants = require("../lib/constants");
const axios = require("axios");

const validateDimension = (dimension) => {
  dimension = dimension.toLowerCase();
  if (
    dimension == "basic" ||
    dimension == "finance" ||
    dimension == "health" ||
    dimension == "social"
  ) {
    return dimension;
  } else {
    throw new Error("Invalid dimension: " + dimension);
  }
};

const validateOperation = (operation) => {
  operation = operation.toLowerCase();
  if (operation == "append" || operation == "override") {
    return operation;
  } else {
    throw new Error("Invalid operation: " + operation);
  }
};

const validateScope = (_scope) => {
  _scope = _scope.toLowerCase();
  if (_scope == "public" || _scope == "private") {
    return _scope;
  } else {
    throw new Error("Invalid scope: " + _scope);
  }
};

exports.cuAppInfo = async function (_name, desc, appRefs, requiresBoolJson) {
  let appDetails = {};
  appDetails["name"] = _name;
  appDetails["description"] = desc;
  appDetails["requires"] = requiresBoolJson;
  appDetails["refs"] = appRefs;

  try {
    return await MoiRequestWrapper({
      reqRouteType: "app",
      reqRouteName: "updatedetails",
      reqQueryParams: [],
      reqMethod: "POST",
      reqPayload: {
        appDetails: appDetails,
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }
};

exports.cuAvatar = async function (defAddr, _roleDetails) {
  try {
    return await MoiRequestWrapper({
      reqRouteType: "role",
      reqRouteName: "add",
      reqQueryParams: [
        {
          queryKey: "defAddr",
          queryValue: defAddr,
        },
      ],
      reqMethod: "POST",
      reqPayload: {
        roleDetails: _roleDetails,
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }
};

exports.addProfilePicture = async function (defAddr, profilePicture) {
  try {
    return await MoiRequestWrapper({
      reqRouteType: "identity",
      reqRouteName: "updateprofilepicture",
      reqQueryParams: [
        {
          queryKey: "defAddr",
          queryValue: defAddr,
        },
      ],
      reqMethod: "POST",
      reqPayload: profilePicture,
    });
  } catch (e) {
    throw new Error(e.message);
  }
};

exports.getProfilePicture = async function (defAddr) {
  try {
    const _response = await axios({
      url:
        Constants.MOI_ID_BASE_URL +
        "/identity/getupdatepicture?defAddr=" +
        defAddr,
      method: "GET",
      responseType: "blob",
    });
    return _response.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

exports.cuProfileInfo = async function (
  userID,
  pubInPem,
  digitialMeAttrs,
  description
) {
  let profile = {
    ID: userID,
    description: description,
    updatedDetails: [],
  };

  try {
    for (let cua = 0; cua < digitialMeAttrs.length; cua++) {
      validateDimension(digitialMeAttrs[cua].dimension);
      validateOperation(digitialMeAttrs[cua].operation);
      validateScope(digitialMeAttrs[cua].scope);
      if (digitialMeAttrs[cua].scope == "private") {
        const encValue = {
          value: await jsmidEncrypt(digitialMeAttrs[cua].value, pubInPem),
        };
        Object.assign(digitialMeAttrs[cua], encValue);
      }
    }
    profile.updatedDetails = digitialMeAttrs;

    return await MoiRequestWrapper({
      reqRouteType: "digitalme",
      reqRouteName: "updateProfile",
      reqQueryParams: [
        {
          queryKey: "defAddr",
          queryValue: userID,
        },
      ],
      reqMethod: "POST",
      reqPayload: {
        profileDetails: profile,
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }
};

exports.getProfileInfo = async function (userID, namespace, reqAttrs) {
  try {
    return await MoiRequestWrapper({
      reqRouteType: "digitalme",
      reqRouteName: "getprofiledetails",
      reqQueryParams: [
        {
          queryKey: "defAddr",
          queryValue: userID,
        },
      ],
      reqMethod: "POST",
      reqPayload: undefined,
    });
  } catch (e) {
    throw new Error(e.message);
  }
};

exports.getRolesInfo = async function (userID) {
  try {
    return await MoiRequestWrapper({
      reqRouteType: "role",
      reqRouteName: "list",
      reqQueryParams: [
        {
          queryKey: "defAddr",
          queryValue: userID,
        },
      ],
      reqMethod: "GET",
      reqPayload: undefined,
    });
  } catch (e) {
    throw new Error(e.message);
  }
};

exports.storeMks = async function (
  defaultAddress,
  authJson,
  zkProof,
  mCipherParams
) {
  let authContent = {};
  authContent["version"] = 1;
  authContent["_proof"] = JSON.parse(zkProof);
  authContent["_keystore"] = JSON.parse(authJson);
  authContent["_moiCipherParams"] = mCipherParams;

  try {
    const payload = {
      authJson: authContent,
      moiID: defaultAddress,
    };
    return await MoiRequestWrapper({
      reqRouteType: "auth",
      reqRouteName: "storemks",
      reqQueryParams: [],
      reqMethod: "POST",
      reqPayload: payload,
    });
  } catch (e) {
    if (e.response == undefined) {
      throw new Error(e.message);
    } else {
      //   e.response.data;
      throw new Error(JSON.stringify(e.response.data));
    }
  }
};

const getMoiIDKeystore = async function (
  defaultAddress,
  proofType,
  keystoreAuthToken
) {
  try {
    return await MoiRequestWrapper({
      reqRouteType: "auth",
      reqRouteName: "getmks",
      reqQueryParams: [],
      reqMethod: "POST",
      reqPayload: {
        defAddr: defaultAddress,
        typeOfProof: proofType,
        authToken: keystoreAuthToken,
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }
};

exports.getMks = getMoiIDKeystore;

exports.storePubPem = async function (defaultAddress, pemPub) {
  try {
    return await MoiRequestWrapper({
      reqRouteType: "identity",
      reqRouteName: "storepubpem",
      reqQueryParams: [],
      reqMethod: "POST",
      reqPayload: {
        moiID: defaultAddress,
        pubInPem: pemPub,
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }
};

exports.verifyUser = async function (defAddr) {
  try {
    await getMoiIDKeystore(defAddr, "zk");
    return true;
  } catch (e) {
    throw new Error(e.message);
  }
};

exports.getAsymmPublicKey = async function (defAddr) {
  try {
    return await MoiRequestWrapper({
      reqRouteType: "identity",
      reqRouteName: "getpubinpem",
      reqQueryParams: [
        {
          queryKey: "defAddr",
          queryValue: defAddr,
        },
      ],
      reqMethod: "GET",
      reqPayload: undefined,
    });
  } catch (e) {
    throw new Error(e.message);
  }
};

exports.listAllMoiApps = async function (entity) {
  try {
    return await MoiRequestWrapper({
      reqRouteType: "list",
      reqRouteName: entity,
      reqQueryParams: [],
      reqMethod: "GET",
      reqPayload: undefined,
    });
  } catch (e) {
    throw new Error(e.message);
  }
};
