import axios from "axios";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import React, { Component } from "react";
import { Button, Spinner, Form } from "react-bootstrap";
import Loader from "../../../HOC/loading";
import CreateFileModal from "./createFilemodal";
import Preview from "./previewfile";
import UploadFileModal from "./uploadfilepreview";
import RenderLayout from "./renderlayout";
import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { devAdmindetails } from "../../../common/devapicalls";
import { getProvenanceDetails } from "../../../common/fileapi";
import { getAppDetails } from "../../../common/appapicalls";
import {
  getParticularNetworkDetails,
  getPartOfNetwork,
} from "../../../common/networkapicalls";

import { priceModalOpen } from "../../../action";
import { connect } from "react-redux";
import { isNil } from "lodash";

var toastIdArr = [];

toast.configure({
  draggable: false,
  closeButton: false,
  position: toast.POSITION.BOTTOM_LEFT,
});

const Renderlayout = Loader(RenderLayout);
const PreviewLayout = Loader(Preview);
const CancelToken = axios.CancelToken;
var cancel;
var moibitWorldCancel;

class FileExplorer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: "https://kfs1.moibit.io",
      loading: false,
      previewResult: "",
      contentType: "",
      inputText: "readfilebyhash",
      modal: false,
      allData: [],
      currentData: [],
      currentPage: null,
      totalPages: null,
      totalRecords: 0,
      api_key: "",
      api_secret: "",
      loading2: false,
      path_value: "",
      fileName: "",
      enableEncryption: true,
      previewType: 0,
      currentHash: "",
      fileSize: "",
      imageBlob: "",
      downloadContentType: "",
      typeoffile: "",
      deleteIndex: "",
      root_history: [{ path: "/", link: "/" }],
      uploadFile: "",
      newFolder: "",
      uploadFolder: "",
      uploadFolderFiles: "",
      createFileModal: false,
      editorState: EditorState.createEmpty(),
      createFileName: "",
      createFileNameValidate: true,
      createFileModalType: 0,
      listGridView: 2,
      enablePreviewOnClick: false,
      nameSort: false,
      sizeSort: false,
      createdDate: "",
      pinned: "",
      searchText: "",
      uploadFileSpinner: false,
      uploadFolderSpinner: false,
      isSubmitDisabled: false,

      uploadFilePin: false,
      uploadFolderFilesLoaderButton: false,
      errMsg: false,
      authErr: false,
      apiError: false,
      fileNameDisplay: "",
      apiErrMsg: "",
      fileUploadModal: false,
      uploadFileWithoutBlob: "",
      fileExistsMsg: false,
      fileExistsMsgInRecovery: false,
      previousHashForUpload: "",
      previousHashForCreateFile: "",
      fileExistsMsg1: "",
      fileCreateTypeCheck: "",
      progress: 0,
      enablePathOnClick: false,
      previousListFiles: [],
      thumbNailFile: [],
      progressReadFileDownload: 0,
      previewCloseModalFile: false,
      uploadFileExtension: "",
      borderColorForFileInput: false,
      // new code

      versionArr: [],
      keepPreviousFileUpload: false,
      deleteVersionNum: "",
      openDigitalMeOpen: false,
      digitalMeFileName: "",
      digitalmeVal: "",
      mobitworlderr: false,
      moibitworld: [],
      moiWorldFetChLoader: false,
      oppsErr: false,

      //new state for network and apps

      allNetwork: [],
      allApps: [],
      selectedNetwork: "",
      selectedApp: "",
      selectedAppName: "",
      selectedNetworkName: "",

      replicationFactor: 2,
      uploadChecked: true,
      effectiveLoading: 0.5,
      replicationErr: false,

      replicationFactorFolder: 2,

      moibitWorldReadCancel: false,
      readFileCancel: false,
      falseArr: [],
      provenanceArr: [],
      provenanceArrLoader: [],
      renderLoader: false,
      currentVersion: 1,
    };
  }
  onPageChanged = (data) => {
    const { allData } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentData = allData.slice(offset, offset + pageLimit);
    this.setState({ currentPage, currentData, totalPages });
  };

  getAllPartofNetwork = async () => {
    let _res = await getPartOfNetwork();
    let response = _res.data;
    if (response) {
      if (typeof response !== "string") {
        let newarr = await response.filter((data) => data.networkName !== "");
        this.setState({ allNetwork: newarr });
      } else {
        this.setState({ apiError: true, apiErrMsg: response });
        setTimeout(() => {
          this.setState({ apiError: false, apiErrMsg: "" });
        }, 5000);
      }
    }
  };

  selectNetwork = (e) => {
    if (e == undefined) {
      this.setState(
        {
          allApps: [],
          selectedNetworkName: "",
          selectedApp: "",
          path_value: "",
        },
        async () => {
          let _val = await devAdmindetails();
          let _appsVal = _val.data.appList;
          if (_appsVal !== "error") {
            // let _activeapps = _appsVal.filter((data) => data.isActive == true);
            this.setState({
              // allApps: _activeapps,
              allData: [],
              currentData: [],
              root_history: [{ path: "/", link: "/" }],
              path_value: "",
            });
            if (this.state.selectedApp == "") {
              this.listAllFiles();
            }
          }
        }
      );
    } else {
      var index = e.nativeEvent.target.selectedIndex;
      let networkName = e.nativeEvent.target[index].text;
      this.setState(
        {
          selectedNetwork: e.target.value,
          allApps: [],
          selectedNetworkName: networkName,
          selectedApp: "",
          path_value: "",
        },
        async () => {
          let _val = await devAdmindetails();
          let _appsVal = _val.data.appList;
          let list = Object.values(_appsVal);
          let appKeys = Object.keys(_appsVal);
          let _appRes = [];
          for (let i = 0; i < appKeys.length; i++) {
            _appRes.push({
              appID: appKeys[i],
              appName: list[i].appName,
              networkName: list[i].networkName,
              networkID: list[i].networkID,
              isActive: list[i].isActive,
              isRemoved: list[i].isRemoved,
            });

            let finalArr = [];
            finalArr = [..._appRes];
            if (_appsVal !== "error") {
              let _activeapps = finalArr.filter(
                (data) => data.isActive == true
              );
              this.setState({
                allApps: _activeapps,
                allData: [],
                currentData: [],
                root_history: [{ path: "/", link: "/" }],
                path_value: "",
              });
            }
          }
          if (this.state.selectedApp == "") {
            this.listAllFiles();
          }
        }
      );
    }
  };

  selectApp = (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    let appName = e.nativeEvent.target[index].text;
    this.setState(
      {
        selectedAppName: appName,
        selectedApp: e.target.value,
        currentData: [],
        root_history: [{ path: "/", link: "/" }],
      },
      () => this.listAllFiles()
    );
  };

  componentDidMount() {
    if (this.props.plan == -1) {
      this.props.priceModalOpen();
      return;
    }
    this.getAllPartofNetwork();
    this.selectNetwork(undefined);
  }

  getThumbnail = async (filename, type, size, version) => {
    try {
      if (type == 0) {
        return new Promise((resolve, reject) => {
          if (size < 2000000) {
            axios({
              method: "GET",
              responseType: "blob",
              url:
                process.env.REACT_APP_MOIBIT_API_URL +
                "net/" +
                this.state.selectedNetwork +
                "/app/" +
                this.state.selectedApp +
                "/file",
              params: {
                fileOperationType: 1,
                path: this.state.path_value + "/" + filename,
                version: version,
              },
              headers: {
                Authorization: `Bearer ${
                  JSON.parse(sessionStorage.getItem("bearerToken")).value
                }`,
              },
            })
              .then((response) => {
                if (response.data !== null) {
                  if (
                    response.headers["content-type"] == "image/png" ||
                    response.headers["content-type"] == "image/jpeg" ||
                    response.headers["content-type"] == "image/gif" ||
                    response.headers["content-type"] == "image/x-icon"
                  ) {
                    resolve(
                      window.URL.createObjectURL(new Blob([response.data]))
                    );
                  } else if (
                    response.headers["content-type"] == "application/pdf"
                  ) {
                    resolve("pdf");
                  } else if (
                    response.headers["content-type"] ==
                      "text/plain; charset=utf-8" ||
                    response.headers["content-type"] ==
                      "text/html; charset=utf-8"
                  ) {
                    resolve("txt");
                  } else if (
                    response.headers["content-type"] == "application/zip" ||
                    response.headers["content-type"] ==
                      "application/octet-stream"
                  ) {
                    resolve("zip");
                  } else if (
                    response.headers["content-type"] == "video/mp4" ||
                    response.headers["content-type"] == "video/webm" ||
                    response.headers["content-type"] == "video/avi"
                  ) {
                    resolve("video");
                  } else if (
                    response.headers["content-type"] ==
                      "application/json; charset=UTF-8" ||
                    response.headers["content-type"] ==
                      "text/plain; charset=utf-8" ||
                    response.headers["content-type"] == "text/html"
                  ) {
                    resolve("json");
                  } else if (
                    response.headers["content-type"] ==
                    "text/xml; charset=utf-8"
                  ) {
                    resolve("xml");
                  } else {
                    resolve("zip");
                  }
                }
              })
              .catch((err) => {
                this.setState({ loading2: false, errMsg: true });
              });
          } else {
            resolve("zip");
          }
        });
      } else if (type == 1) {
        return "folder";
      }
    } catch (err) {
      this.setState({ loading2: false, errMsg: true });
    }
  };

  getPrivacyASecurityOfFile = async (file) => {
    try {
      if (!file.isDir) {
        const fileMBAttrs = await axios({
          url:
            process.env.REACT_APP_MOIBIT_API_URL +
            "net/" +
            this.state.selectedNetwork +
            "/app/" +
            this.state.selectedApp +
            "/file",
          method: "GET",
          headers: {
            Authorization: `Bearer ${
              JSON.parse(sessionStorage.getItem("bearerToken")).value
            }`,
          },
          params: {
            fileOperationType: 4,
            path: (file.directory == "/" ? "" : file.directory) + file.path,
            version: file.version,
          },
        });

        let privacy;
        if (fileMBAttrs.data.data.privacy == 0) {
          privacy = "Network Level (Default)";
        } else if (fileMBAttrs.data.data.privacy == 1) {
          privacy = "Developer key encryption";
        } else if (fileMBAttrs.data.data.privacy == 2) {
          privacy = "End user key encryption";
        } else if (fileMBAttrs.data.data.privacy == 3) {
          privacy = "Custom key Encryption";
        } else if (fileMBAttrs.data.data.privacy == 4) {
          privacy = "End-to-End Encryption";
        } else if (fileMBAttrs.data.data.privacy == -1) {
          privacy = "No Encryption";
        } else {
          privacy = "-";
        }
        let pinnedreplicas = [];
        if (typeof fileMBAttrs !== "string") {
          for (let i = 0; i < fileMBAttrs.data.data.replicas.length; i++) {
            if (fileMBAttrs.data.data.replicas[i].status == "pinned") {
              pinnedreplicas = [
                ...pinnedreplicas,
                fileMBAttrs.data.data.replicas[i],
              ];
            }
          }
          let totalSize = 0;
          for (let shard of fileMBAttrs.data.data.shards) {
            totalSize += shard.size;
          }

          let numShards = Math.ceil(totalSize / 262144);
          return {
            replicas: pinnedreplicas,
            shards: fileMBAttrs.data.data.shards,
            mbPrivacy: privacy,
            thumbnail: "zip",
            // mbSecurity: fileMBAttrs.data.data.shards
            //   ? (Math.round(file.filesize / 262158) == 0
            //       ? 1
            //       : Math.round(file.filesize / 262158)) +
            //     " Shard(s), " +
            //     pinnedreplicas.length +
            //     " Replicas"
            //   : null,
            mbSecurity: fileMBAttrs.data.data.shards
              ? numShards + " Shard(s), " + pinnedreplicas.length + " Replicas"
              : null,
            ...file,
          };
        } else {
          return {
            replicas: [],
            shards: [],
            mbPrivacy: privacy,
            thumbnail: "zip",
            mbSecurity: "-",
            ...file,
          };
        }
      } else {
        return { ...file };
      }
    } catch (err) {
      //do nothing
      if (err.response) {
        return err.response.data;
      } else {
        return err.response.data.code;
      }
    }
  };

  listAllFiles = (path, fileType, loadingType) => {
    if (
      this.state.selectedApp == "" ||
      this.state.selectedApp == undefined ||
      this.state.selectedApp == isNil
    ) {
      this.setState({ selectedApp: "default" });
    }
    this.setState({ renderLoader: true });
    if (loadingType == "uploadfile") {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: true });
    }
    if (path == "/") {
      this.setState({
        path_value: "",
        root_history: [],
      });
    }
    if (path == "" && undefined) {
      this.setState({
        path_value: "",
        root_history: [{ path: "/", link: "/" }],
      });
    }
    if (this.state.root_history.length > 1) {
      this.setState({ path_value: path });
    }
    this.setState({ allData: [], currentData: [] }, () => {
      axios({
        method: "GET",
        url:
          process.env.REACT_APP_MOIBIT_API_URL +
          "net/" +
          this.state.selectedNetwork +
          "/app/" +
          this.state.selectedApp +
          "/file",
        params: {
          fileOperationType: 5,
          path: path,
        },
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
      })
        .then(async (response) => {
          if (response.data.data == null || response.data.data == "null") {
            this.setState({
              allData: [],
              currentData: [],
              loading2: false,
              totalRecords: 0,
              enablePathOnClick: true,
              loading: false,
              renderLoader: false,
            });
          } else {
            let result = response.data.data;

            result.sort(
              (x, y) => new Date(y.lastUpdated) - new Date(x.lastUpdated)
            );

            //working thunbnail code
            let _pendingResult = result.map(async (res, index) => {
              // res = await this.getPrivacyASecurityOfFile(res);
              // return res;
              let resp = await this.getPrivacyASecurityOfFile(res);
              if (resp.status == "error") {
                return res;
              } else {
                return resp;
              }
            });
            let _falseArr = new Array(_pendingResult.length).fill(false);
            let _provenanceArr = new Array(_pendingResult.length).fill("");
            let _provenanceArrLoader = new Array(_pendingResult.length).fill(
              false
            );

            Promise.all(_pendingResult).then((res) => {
              this.setState({
                allData: res,
                enablePreviewOnClick: true,
                loading2: false,
                loading: false,
                enablePathOnClick: true,
                effectiveLoading: 1,
                totalRecords: response.data.data.length,
                falseArr: _falseArr,
                provenanceArr: _provenanceArr,
                provenanceArrLoader: _provenanceArrLoader,
                renderLoader: false,
              });
            });

            // let _pendingResults = result.map(async (resp) => {
            //   resp = await this.getPrivacyASecurityOfFile(resp);
            //   console.log("resp.status", resp.status);
            //   if (resp.status == "error") {
            //     return "error";
            //   } else {
            //     return resp;
            //   }
            // });
            // Promise.all(_pendingResults).then((resp) => {
            //   this.setState({
            //     allData: resp,
            //   });
            // });
          }
        })
        .catch((err) => {
          this.setState({
            allData: [],
            currentData: [],
            loading2: false,
            totalRecords: 0,
            enablePathOnClick: true,
            loading: false,
            renderLoader: false,
          });
        });
    });
  };

  mergeByName = (a1, a2) => {
    let result = a1.filter((n) => a2.some((n2) => n.nodeID == n2.nodeID));

    return result;
  };

  mergeByID = (a1, a2) => {
    let result1 = a1.filter((n) => a2.some((n2) => n.nodeID == n2.nodeID));
    let result = [
      ...[result1, a2]
        .reduce(
          (m, a) => (
            a.forEach(
              (o) =>
                (m.has(o.nodeID) && Object.assign(m.get(o.nodeID), o)) ||
                m.set(o.nodeID, o)
            ),
            m
          ),
          new Map()
        )
        .values(),
    ];
    return result;
  };

  previewFile = async (
    hash,
    fileType,
    path,
    thumbnail,
    size,
    fileName,
    createdDate,
    pinned,
    version
  ) => {
    if (thumbnail == undefined) {
      thumbnail = "pdf";
    }

    this.setState({
      contentType: "",
      previewResult: "",
      loading2: false,
      downloadContentType: "",
      fileName: "",
      progressReadFileDownload: 0,
      moiWorldFetChLoader: false,
      digitalmeVal: "",
      readFileCancel: false,
      moibitWorldReadCancel: false,
    });
    this.setState(
      {
        enablePathOnClick: false,
      },
      async () => {
        if (fileType == 0) {
          this.setState({
            contentType: "",
            modal: true,
            loading2: false,
            fileName: path,
            previewType: 0,
            currentHash: hash,
            previewCloseModalFile: true,
            moiWorldFetChLoader: true,
            currentVersion: version,
          });

          await axios({
            method: "GET",
            url:
              process.env.REACT_APP_MOIBIT_API_URL +
              "net/" +
              this.state.selectedNetwork +
              "/app/" +
              this.state.selectedApp +
              "/file",
            params: {
              fileOperationType: 4,
              path: this.state.path_value + fileName,
              version: version,
            },
            headers: {
              Authorization: `Bearer ${
                JSON.parse(sessionStorage.getItem("bearerToken")).value
              }`,
            },
            onDownloadProgress: (progressEvent) => {
              this.setState({ moibitWorldReadCancel: true });
            },
            cancelToken: new CancelToken(function executor(c) {
              moibitWorldCancel = c;
            }),
          })
            .then(async (response) => {
              if (typeof response.data.data !== "string") {
                // get replicas details
                try {
                  let _result = await getParticularNetworkDetails(
                    this.state.selectedNetwork
                  );
                  let peerDetail = _result.peerIDs.map((data) => {
                    return {
                      nodeID: data.nodeID,
                      nodePeerID: data.nodePeerID,
                      peername: data.nodePeerName,
                    };
                  });
                  let _getNodeId = this.mergeByName(
                    peerDetail,
                    response.data.data.replicas
                  );
                  let _nodeDetails = this.mergeByID(
                    _result.nodelist,
                    _getNodeId
                  );

                  // end get replicas details
                  const pinnedReplicas = response.data.data.replicas.filter(
                    (replica) => replica.status == "pinned"
                  );
                  let updatedval = [];
                  for (let i = 0; i < _nodeDetails.length; i++) {
                    for (let j = 0; j < pinnedReplicas.length; j++) {
                      if (_nodeDetails[i].nodeID === pinnedReplicas[j].nodeID) {
                        updatedval = [...updatedval, _nodeDetails[i]];
                      }
                    }
                  }

                  response.data.data.replicas = updatedval;
                  this.setState({
                    digitalMeFileName: fileName,
                    digitalmeVal: response.data.data,
                  });
                } catch (err) {
                  this.setState({
                    digitalMeFileName: fileName,
                    digitalmeVal: response.data.data,
                  });
                }
              } else {
                this.setState({
                  openDigitalMeOpen: false,
                  digitalMeFileName: "",
                  digitalmeVal: "",
                });
              }
            })
            .catch((err) => {
              this.setState({
                openDigitalMeOpen: false,
                digitalMeFileName: "",
                digitalmeVal: "",
                mobitworlderr: true,
              });
              setTimeout(() => {
                this.setState({ mobitworlderr: false });
              }, 3000);
            });

          if (
            thumbnail !== "pdf" &&
            thumbnail !== "txt" &&
            thumbnail !== "zip" &&
            thumbnail !== "video" &&
            thumbnail !== "json" &&
            thumbnail !== ""
          ) {
            this.setState({
              contentType: "img",
              previewResult: thumbnail,
              loading2: false,
              fileSize: size,
              createdDate: createdDate,
              pinned: pinned,
              progressReadFileDownload: 100,
              downloadContentType: "image/png",
              moiWorldFetChLoader: false,
            });
          } else {
            axios({
              method: "GET",
              responseType: "blob",
              url:
                process.env.REACT_APP_MOIBIT_API_URL +
                "net/" +
                this.state.selectedNetwork +
                "/app/" +
                this.state.selectedApp +
                "/file",
              params: {
                fileOperationType: 1,
                path: this.state.path_value + fileName,
                version: version,
              },
              headers: {
                Authorization: `Bearer ${
                  JSON.parse(sessionStorage.getItem("bearerToken")).value
                }`,
              },
              onDownloadProgress: (progressEvent) => {
                let downloadPercentage = Math.round(
                  (progressEvent.loaded * 100) / size
                );
                this.setState({
                  progressReadFileDownload: downloadPercentage,
                  readFileCancel: true,
                });
              },
              cancelToken: new CancelToken(function executor(c) {
                cancel = c;
              }),
            })
              .then((response) => {
                var fileNameSplit1 = fileName.split(".").slice(0, -1).join(".");

                var extension1 = fileName.split(".").pop();
                if (extension1 == fileName) {
                  extension1 = "";
                }
                if (fileNameSplit1 == "") {
                  fileNameSplit1 = fileName;
                }

                if (response.data !== null) {
                  this.setState({
                    downloadContentType: "",
                    fileSize: size,
                    createdDate: createdDate,
                    pinned: pinned,
                    enablePathOnClick: true,
                  });
                  if (
                    response.headers["content-type"] ==
                    "text/html; charset=utf-8"
                  ) {
                    var file = new File([response.data], fileName, {
                      type: "text/plain; charset=utf-8",
                      lastModified: Date.now(),
                    });
                    var read = new FileReader();
                    read.readAsText(file);
                    read.onload = () => {
                      this.setState({
                        contentType: "html",
                        previewResult: read.result,
                        loading2: false,
                        downloadContentType: response.headers["content-type"],
                        fileName: fileName,
                      });
                    };
                  } else if (
                    response.headers["content-type"] ==
                      "text/plain; charset=utf-8" ||
                    response.headers["content-type"] ==
                      "application/javascript; charset=utf-8" ||
                    response.headers["content-type"] ==
                      "application/json; charset=utf-8" ||
                    response.headers["content-type"] ==
                      "application/json; charset=UTF-8"
                  ) {
                    var file = new File([response.data], fileName, {
                      type: "text/plain; charset=utf-8",
                      lastModified: Date.now(),
                    });
                    var read = new FileReader();
                    read.readAsText(file);
                    read.onload = () => {
                      try {
                        let x = JSON.parse(read.result);
                        this.setState({
                          contentType: "txt",
                          previewResult: EditorState.createWithContent(
                            convertFromRaw(x)
                          ),
                          loading2: false,
                          downloadContentType: response.headers["content-type"],
                          fileName: fileName,
                        });
                      } catch (error) {
                        this.setState({
                          contentType: "pdf",
                          previewResult: window.URL.createObjectURL(
                            response.data
                          ),
                          loading2: false,
                          downloadContentType: response.headers["content-type"],
                          fileName: fileName,
                        });
                      }
                    };
                  } else if (
                    response.headers["content-type"] == "image/png" ||
                    response.headers["content-type"] == "image/jpeg" ||
                    response.headers["content-type"] == "image/gif"
                  ) {
                    this.setState({
                      contentType: "img",
                      previewResult: window.URL.createObjectURL(
                        new Blob([response.data])
                      ),
                      loading2: false,
                      downloadContentType: response.headers["content-type"],
                      fileName: fileName,
                    });
                  } else if (
                    response.headers["content-type"] == "application/pdf" ||
                    response.headers["content-type"] == "video/mp4" ||
                    response.headers["content-type"] == "video/webm"
                  ) {
                    this.setState({
                      contentType: "pdf",
                      previewResult: window.URL.createObjectURL(response.data),
                      loading2: false,
                      downloadContentType: response.headers["content-type"],
                      fileName: fileName,
                    });
                  } else if (
                    response.headers["content-type"] ==
                      "application/json; charset=UTF-8" ||
                    response.headers["content-type"] == "text/html" ||
                    response.headers["content-type"] ==
                      "application/json; charset=utf-8"
                  ) {
                    this.setState({
                      contentType: "json",
                      previewResult: response.data,
                      loading2: false,
                      downloadContentType: response.headers["content-type"],
                      fileName: fileName,
                    });
                  } else if (
                    response.headers["content-type"] == "application/zip" ||
                    response.headers["content-type"] ==
                      "application/octet-stream"
                  ) {
                    this.setState({
                      contentType: "zip",
                      previewResult: window.URL.createObjectURL(response.data),
                      loading2: false,
                      downloadContentType: response.headers["content-type"],
                      fileName: fileName,
                    });
                  } else {
                    this.setState({
                      contentType: "img",
                      previewResult: window.URL.createObjectURL(
                        new Blob([response.data])
                      ),
                      loading2: false,
                      downloadContentType: response.headers["content-type"],
                      fileName: fileName,
                    });
                  }
                  //Working code ends
                  this.setState({ moiWorldFetChLoader: false });
                }
              })
              .catch((err) => {
                this.setState({ moiWorldFetChLoader: false });
              });
          }
        } else if (fileType == 1) {
          this.setState(
            { loading: true, allData: [], enablePreviewOnClick: true },
            async () => {
              let x = path.slice(0, -1);
              if (x.charAt(0) == "/") {
                x.charAt(0).slice(1);
              }
              await this.listAllFiles(x, fileType);
              if (this.state.path_value == "") {
                this.setState({ root_history: [{ path: "/", link: "/" }] });
              }
              let temp_arr = x.split("/");
              let pathVal = " > " + temp_arr[temp_arr.length - 1];
              let val = { path: pathVal, link: x };
              this.setState({
                root_history: [...this.state.root_history, val],
              });
              this.setState(
                {
                  path_value: x,
                },
                async () => {
                  this.setState({ loading: false });
                }
              );
            }
          );
        }
      }
    );
  };
  closeModal = () => {
    this.setState({ modal: false });
  };

  loadingFunc = (data) => {
    this.setState({ loading2: data });
  };

  deleteFile = (index, type, fileName, hash, size, blob, version) => {
    this.setState({
      contentType: "",
      modal: true,
      loading2: false,
      fileName: fileName,
      previewType: 2,
      currentHash: hash,
      fileSize: size,
      imageBlob: blob,
      typeoffile: type,
      deleteIndex: index,
      previewCloseModalFile: true,
      deleteVersionNum: version,
    });
  };

  deleteFileVersion = (index, type, fileName, hash, size, blob, version) => {
    this.setState({ deleteVersionNum: version });

    if (size < 20000) {
      axios({
        method: "GET",
        responseType: "blob",
        url:
          process.env.REACT_APP_MOIBIT_API_URL +
          "net/" +
          this.state.selectedNetwork +
          "/app/" +
          this.state.selectedApp +
          "/file",
        params: {
          fileOperationType: 1,
          path: this.state.path_value + "/" + fileName,
          version: version,
        },
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
      })
        .then((response) => {
          if (response.data !== null) {
            let preview;
            if (
              response.headers["content-type"] == "image/png" ||
              response.headers["content-type"] == "image/jpeg" ||
              response.headers["content-type"] == "image/gif" ||
              response.headers["content-type"] == "image/x-icon"
            ) {
              preview = window.URL.createObjectURL(new Blob([response.data]));
            } else if (response.headers["content-type"] == "application/pdf") {
              preview = "zip";
            } else if (
              response.headers["content-type"] == "text/plain; charset=utf-8" ||
              response.headers["content-type"] == "text/html; charset=utf-8"
            ) {
              preview = "txt";
            } else if (
              response.headers["content-type"] == "application/zip" ||
              response.headers["content-type"] == "application/octet-stream"
            ) {
              preview = "zip";
            } else if (
              response.headers["content-type"] == "video/mp4" ||
              response.headers["content-type"] == "video/webm" ||
              response.headers["content-type"] == "video/avi"
            ) {
              preview = "video";
            } else if (
              response.headers["content-type"] ==
                "application/json; charset=UTF-8" ||
              response.headers["content-type"] == "text/plain; charset=utf-8" ||
              response.headers["content-type"] == "text/html"
            ) {
              preview = "json";
            } else if (
              response.headers["content-type"] == "text/xml; charset=utf-8"
            ) {
              preview = "xml";
            } else {
              preview = "zip";
            }
            this.setState({
              versionArr: [],
              contentType: "",
              modal: true,
              loading2: false,
              fileName: fileName,
              previewType: 2,
              currentHash: hash,
              fileSize: size,
              imageBlob: preview,
              typeoffile: type,
              deleteIndex: index,
              previewCloseModalFile: true,
            });
          }
        })
        .catch((err) => {
          this.setState({ loading2: false, errMsg: true });
        });
    } else {
      this.setState({
        versionArr: [],
        contentType: "",
        modal: true,
        loading2: false,
        fileName: fileName,
        previewType: 2,
        currentHash: hash,
        fileSize: size,
        imageBlob: "zip",
        typeoffile: type,
        deleteIndex: index,
        previewCloseModalFile: true,
      });
    }
  };

  setFolderPath = async (path, index) => {
    this.setState({ enablePathOnClick: false, allData: [] }, async () => {
      path.length = index + 1;
      if (path !== undefined) {
        await this.listAllFiles(path[index].link, "");
        this.setState({ root_history: path });
      }
    });
  };

  deleteFileFromMoibit1 = (fileName, type, index, deletetype, version) => {
    var data;
    var path = this.state.path_value + "/" + fileName;
    if (path.charAt(0) == "/") {
      path.charAt(0).slice(1);
    }
    if (!type) {
      data = {
        path: path,
        version: version,
        isdir: false,
        operationType: 0,
      };
    } else {
      data = {
        path: path,
        isdir: true,
        operationType: 0,
      };
    }
    axios({
      method: "post",
      url:
        process.env.REACT_APP_MOIBIT_API_URL +
        "net/" +
        this.state.selectedNetwork +
        "/app/" +
        this.state.selectedApp +
        "/file/remove",
      data: data,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("bearerToken")).value
        }`,
      },
    })
      .then((response) => {
        if ((response.data.message = "removed successfully")) {
          this.setState({ modal: false });
          this.listAllFiles(this.state.path_value, "");
        }
      })
      .catch((err) => {
        let error;
        if (err.response) {
          error = err.response.data.message;
        } else {
          error = err.message;
        }
        this.setState(
          { modal: false, apiError: true, apiErrMsg: error },
          () => {}
        );
        setTimeout(() => {
          this.setState({ apiError: false, apiErrMsg: "" });
        }, 5000);
      });
  };

  createFileModal = (type) => {
    if (this.props.plan <= 1) {
      this.setState({
        replicationFactor: 2,
      });
    } else if (this.props.plan >= 2) {
      if (
        this.state.selectedApp !== "" &&
        this.state.selectedApp !== "default"
      ) {
        console.log("from here");
        let _res = getAppDetails(
          this.state.selectedApp,
          this.state.selectedNetwork
        );
        this.setState({ replicationFactor: _res.replication });
      } else {
        this.setState({
          replicationFactor: 2,
        });
      }
    }

    if (type == 1) {
      this.setState({
        createFileModalType: type,
        createFileModal: true,
        createFileName: "",
      });
    }
    if (type == 2) {
      this.setState({
        createFileModalType: type,
        createFileModal: true,
        createFileName: "",
      });
    }
  };

  handleModalClose = () => {
    this.setState({
      createFileModal: false,
      editorState: EditorState.createEmpty(),
      fileExistsMsg1: false,
      fileExistsMsgInRecovery: false,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState: editorState,
    });
  };
  createFileName = (e) => {
    if (e.target.value == "") {
      this.setState({ createFileNameValidate: true, createFileName: "" });
    } else {
      this.setState({
        createFileName: e.target.value,
        createFileNameValidate: false,
      });
    }
  };

  submitEditor = async (type) => {
    var url;
    var method;
    var val;
    var check = 0;
    var keepPrevious = false;
    try {
      if (type == 1) {
        if (this.state.createFileName == "") {
          this.notifyErr("File Name cannot be empty");
          this.setState({ createFileNameValidate: false });
        } else {
          let _res = await axios({
            method: "GET",
            // url: process.env.REACT_APP_MOIBIT_API_URL + "file",
            url:
              process.env.REACT_APP_MOIBIT_API_URL +
              "net/" +
              this.state.selectedNetwork +
              "/app/" +
              this.state.selectedApp +
              "/file",
            params: {
              fileOperationType: 3,
              path:
                this.state.path_value +
                "/" +
                this.state.createFileName +
                ".txt",
            },
            headers: {
              Authorization: `Bearer ${
                JSON.parse(sessionStorage.getItem("bearerToken")).value
              }`,
            },
          });
          // if (_res.data.data.hash !== "") {
          if (_res.data.message !== "file don't exist") {
            if (_res.data.data.hash != "") {
              this.setState({
                fileExistsMsg1: true,
              });
              return;
            } else {
              this.setState({
                fileExistsMsgInRecovery: true,
              });
              return;
            }
          }

          this.setState({
            fileExistsMsg1: false,
            previousHashForCreateFile: "",
          });
          const blocks = convertToRaw(
            this.state.editorState.getCurrentContent()
          );
          if (blocks.blocks[0].text !== "") {
            url =
              process.env.REACT_APP_MOIBIT_API_URL +
              "net/" +
              this.state.selectedNetwork +
              "/app/" +
              this.state.selectedApp +
              "/file?fileOperationType=3";
            method = "POST";
            val = {
              fileName:
                this.state.path_value +
                "/" +
                this.state.createFileName +
                ".txt",
              text: JSON.stringify(blocks),
              createFolders: true,
              keepPrevious: false,
              replication: this.state.replicationFactor,
            };
          } else {
            this.notifyErr("File is empty, its not created");
            this.setState({ createFileNameValidate: false });
          }
        }
      }
      if (type == 2) {
        let queryParams = encodeURIComponent(this.state.createFileName);
        if (queryParams == "") {
          this.notifyErr("Folder Name cannot be empty");
          this.setState({ createFileNameValidate: false });
        } else {
          url =
            process.env.REACT_APP_MOIBIT_API_URL +
            "net/" +
            this.state.selectedNetwork +
            "/app/" +
            this.state.selectedApp +
            "/dir/makedir?path=" +
            this.state.path_value +
            "/" +
            queryParams;
          method = "POST";
        }
      }
      if (type == 3) {
        const blocks = convertToRaw(this.state.editorState.getCurrentContent());
        url =
          process.env.REACT_APP_MOIBIT_API_URL +
          "net/" +
          this.state.selectedNetwork +
          "/app/" +
          this.state.selectedApp +
          "/file?fileOperationType=3";
        method = "POST";
        val = {
          fileName:
            this.state.path_value + "/" + this.state.createFileName + ".txt",
          text: JSON.stringify(blocks),
          createFolders: true,
          keepPrevious: false,
          replication: this.state.replicationFactor,
        };
      }
      if (type == 4) {
        this.setState({ fileExistsMsg1: false });
        if (this.state.fileCreateTypeCheck !== "directory") {
          const blocks = convertToRaw(
            this.state.editorState.getCurrentContent()
          );
          url = url =
            process.env.REACT_APP_MOIBIT_API_URL +
            "net/" +
            this.state.selectedNetwork +
            "/app/" +
            this.state.selectedApp +
            "/file?fileOperationType=3";
          method = "POST";
          val = {
            fileName:
              this.state.path_value + "/" + this.state.createFileName + ".txt",
            text: JSON.stringify(blocks),
            createFolders: true,
            keepPrevious: true,
            replication: this.state.replicationFactor,
          };
        } else {
          check = 2;
        }
      }

      let header = {
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("bearerToken")).value
        }`,
      };
      let param = {
        networkID: this.state.selectedNetwork,
        appID: this.state.selectedApp,
      };
      if (this.state.createFileName !== "") {
        if (check == 0) {
          axios({
            method: method,
            url: url.toString(),
            responseType: "json",
            data: val,
            headers: header,
            params: param,
          })
            .then(async (response) => {
              if (type == 1 || type == 2 || type == 3 || type == 4) {
                this.setState({
                  createFileModal: false,
                  editorState: EditorState.createEmpty(),
                  createFileName: "",
                  fileExistsMsg1: false,
                });
              }
              if (type == 2) {
                this.notify("Folder created successfully");
              }
              if (type == 1 || type == 3 || type == 4) {
                this.notify("File created successfully");
              }
              await this.listAllFiles(this.state.path_value, "");
            })
            .catch((err) => {
              if (err.response.data.code == 1021) {
                this.notifyErr("Directory already exists");
                this.setState({
                  createFileModal: false,
                  fileExistsMsg1: false,
                  createFileName: "",
                  editorState: EditorState.createEmpty(),
                });
              } else {
                this.setState({
                  createFileModal: false,
                  editorState: EditorState.createEmpty(),
                  createFileName: "",
                  apiError: true,
                  apiErrMsg: err.message ? err.message : "Something went wrong",
                  fileExistsMsg1: false,
                });
              }
              setTimeout(() => {
                this.setState({ apiError: false, apiErrMsg: "" });
              }, 5000);
            });
        } else if (check == 2) {
          this.setState({
            apiError: true,
            apiErrMsg: "Directory already exists",
            createFileModal: false,
            fileExistsMsg1: false,
            createFileName: "",
            editorState: EditorState.createEmpty(),
          });
          setTimeout(() => {
            this.setState({ apiError: false, apiErrMsg: "" });
          }, 5000);
        }
      } else {
        this.setState({ createFileName: "", createFileNameValidate: false });
      }
    } catch (err) {
      this.setState({
        createFileModal: false,
        editorState: EditorState.createEmpty(),
        createFileName: "",
      });
    }
  };

  upload = async (uploadType) => {
    var url;
    var payload;
    var method;
    var newFileName;
    var replicationFac;
    if (uploadType == "uploadfile") {
      this.setState({ uploadFileSpinner: true, progress: 0 });
      if (this.state.uploadFileWithoutBlob.size == 0) {
        this.notifyErr("Your file is 0 bytes, cannot be uploaded");
        this.setState({
          uploadFile: "",
          uploadFileSpinner: false,
        });
        return;
      } else {
        let _networkRes = await getParticularNetworkDetails(
          this.state.selectedNetwork
        );
        let maxReplicationCount = _networkRes.maxReplicationFactor;

        if (this.state.uploadFileExtension !== "") {
          newFileName =
            this.state.fileNameDisplay + "." + this.state.uploadFileExtension;
        } else {
          newFileName = this.state.fileNameDisplay;
        }

        if (
          this.state.replicationFactor == "" ||
          this.state.replicationFactor < 2
        ) {
          this.notifyErr(
            "Replication factor should be 2 to " + maxReplicationCount
          );
          return;
        } else if (maxReplicationCount >= this.state.replicationFactor) {
          replicationFac = this.state.replicationFactor;
        } else {
          this.notifyErr(
            "Replication factor should be 2 to " + maxReplicationCount
          );
          return;
        }

        var formData = new FormData();
        formData.append("fileName", this.state.path_value + "/" + newFileName);
        formData.append("file", this.state.uploadFileWithoutBlob);
        formData.append("createFolders", false);
        formData.append("isdir", false);
        formData.append("keepPrevious", this.state.keepPreviousFileUpload);
        formData.append("replication", replicationFac);

        this.setState({ fileUploadModal: false });

        if (!this.state.uploadChecked) {
          formData.append("encryptionType", -1);
        }

        url =
          process.env.REACT_APP_MOIBIT_API_URL +
          "net/" +
          this.state.selectedNetwork +
          "/app/" +
          this.state.selectedApp +
          "/file?fileOperationType=1";
        payload = formData;
        method = "POST";
      }
    } else if (uploadType == "uploadfolder") {
      this.setState({ uploadFolderSpinner: true });
      let _networkRes = await getParticularNetworkDetails(
        this.state.selectedNetwork
      );
      let maxReplicationCount = _networkRes.maxReplicationFactor;
      if (
        this.state.replicationFactorFolder == "" ||
        this.state.replicationFactorFolder < 2
      ) {
        this.notifyErr(
          "Replication factor should be 2 to " + maxReplicationCount
        );
        this.setState({ uploadFolderSpinner: false });
        return;
      } else if (maxReplicationCount >= this.state.replicationFactorFolder) {
        replicationFac = this.state.replicationFactorFolder;
      } else {
        this.notifyErr(
          "Replication factor should be 2 to " + maxReplicationCount
        );
        this.setState({ uploadFolderSpinner: false });
        return;
      }

      let formData = this.state.uploadFolderFiles;
      formData.delete("replication");
      formData.append("replication", this.state.replicationFactorFolder);
      url =
        process.env.REACT_APP_MOIBIT_API_URL +
        "net/" +
        this.state.selectedNetwork +
        "/app/" +
        this.state.selectedApp +
        "/file?fileOperationType=2";
      payload = this.state.uploadFolderFiles;
      method = "POST";
    }
    let toastId = null;

    axios({
      method: method,
      url: url,
      data: payload,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("bearerToken")).value
        }`,
      },
      onUploadProgress: async (progressEvent) => {
        var percentage = await Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        const progress = (await progressEvent.loaded) / progressEvent.total;
        if (toastId === null) {
          if (progress == 1) {
            toastId = await toast.info("Upload in Progress...", {
              autoClose: 1000,
              position: toast.POSITION.BOTTOM_LEFT,
              className: "heading-css-bg",
            });
          } else {
            toastId = await toast.info("Upload in Progress...", {
              progress: progress,
              autoClose: 1000,
              draggable: false,
              closeButton: false,
              position: toast.POSITION.BOTTOM_LEFT,
              className: "heading-css-bg",
            });
            toastIdArr.push({
              name: newFileName,
              id: toastId,
            });
          }
        } else {
          toast.update(toastId, {
            progress: progress,
            type: toast.TYPE.INFO,
            render: (
              <div>
                {toastIdArr.map((item) => {
                  return (
                    <div>
                      <p>{item.id == toastId ? item.name : ""}</p>
                      <p>
                        {item.id == toastId
                          ? "Upload in Progress..." + "(" + percentage + "%)"
                          : ""}
                      </p>
                    </div>
                  );
                })}
              </div>
            ),
            draggable: false,
            closeButton: false,
            position: toast.POSITION.BOTTOM_LEFT,
            className: "heading-css-bg",
          });
        }
      },
    })
      .then(async (response) => {
        await toast.done(toast.id);
        await this.notify(
          uploadType == "uploadfile"
            ? "File uploaded successfully"
            : "Folder uploaded successfully"
        );
        document.getElementById("uploadfileinput").value = "";
        await this.listAllFiles(this.state.path_value, "", "uploadfile");
        this.setState({
          uploadFolder: "",
          uploadFile: "",
          uploadFileSpinner: false,
          uploadFolderSpinner: false,
          uploadFolderFiles: "",
          fileExistsMsg: false,
          replicationFactor: 2,
          uploadChecked: true,
          replicationFactorFolder: 2,
        });
      })
      .catch((err) => {
        if (err.response) {
          this.setState({
            apiError: true,
            uploadFolder: "",
            uploadFile: "",
            uploadFileSpinner: false,
            uploadFolderSpinner: false,
            uploadFolderFiles: "",
            apiErrMsg:
              err.response.data.message !== undefined
                ? err.response.data.message !== undefined
                  ? err.response.data.message == "socket hang up"
                    ? "Something went wrong. Please try again later or if you seen this many times, please report this error at hello@moibit.io"
                    : err.response.data.message
                  : "Something went wrong. Please try again later or if you seen this many times, please report this error at hello@moibit.io"
                : null,
          });
        } else {
          this.setState({ apiError: true, apiErrMsg: err.message });
        }
        setTimeout(() => {
          this.setState({ apiError: false, apiErrMsg: "" });
        }, 5000);
      });
  };

  uploadFolderHandler = async (folder) => {
    this.setState({
      uploadFolderFiles: "",
      uploadFolderFilesLoaderButton: true,
    });
    var formData = new FormData();

    let filesUploaded = folder.target.files;

    formData.append(
      "folderName",
      this.state.path_value == "" ? "/" : this.state.path_value
    );
    formData.append("keepPrevious", true);
    for (let i = 0; i < filesUploaded.length; i++) {
      if (filesUploaded[i].name !== ".DS_Store") {
        formData.append("file", filesUploaded[i]);
      }
    }
    if (filesUploaded.length > 0) {
      this.setState({
        uploadFolderFiles: formData,
        uploadFolderFilesLoaderButton: false,
        uploadFile: "",
        newFolder: "",
      });
    } else {
      this.setState({
        uploadFolderFiles: "",
        uploadFolderFilesLoaderButton: false,
        uploadFile: "",
        newFolder: "",
      });
    }
    if (this.state.selectedApp !== "" && this.state.selectedApp !== "default") {
      let _res = await getAppDetails(
        this.state.selectedApp,
        this.state.selectedNetwork
      );
      this.setState({ replicationFactorFolder: _res.replication });
    }
  };

  sortBy = async (type) => {
    if (type == "name") {
      if (this.state.nameSort) {
        this.setState({
          currentData: this.state.currentData.sort((a, b) => b.Name - a.Name),
          nameSort: !this.state.nameSort,
        });
      } else {
        this.setState({
          currentData: this.state.currentData.sort((a, b) => a.Name - b.Name),
          nameSort: !this.state.nameSort,
        });
      }
    }
    if (type == "size") {
    }
  };

  pinUnpin = (type, name) => {
    var url;
    if (type == "Unpin") {
      url = this.state.api_url + "/moibit/v0/removepin";
    } else if (type == "Pin") {
      url = this.state.api_url + "/moibit/v0/addpin";
    }
    axios({
      method: "POST",
      url: url,
      data: { fileName: this.state.path_value + "/" + name },
      headers: {
        api_key: this.state.api_key,
        api_secret: this.state.api_secret,
      },
    })
      .then((response) => {
        this.listAllFiles(this.state.path_value, "");
      })
      .catch((err) => {
        this.setState({ apiError: true, apiErrMsg: err.response.data.data });
        setTimeout(() => {
          this.setState({ apiError: false, apiErrMsg: "" });
        }, 5000);
      });
  };

  charge = () => {
    document.body.onfocus = () => {
      this.cancelClick();
    };
  };
  cancelClick() {
    let x = document.getElementById("uploaddir");
    if (x.value.length) {
      this.setState({ uploadFolderFilesLoaderButton: true });
    } else {
      this.setState({ uploadFolderFilesLoaderButton: false });
    }
    document.body.onfocus = null;
    x.value = "";
  }

  handleModalCloseFile = () => {
    document.getElementById("uploadfileinput").value = "";
    this.setState({
      fileUploadModal: false,
      uploadFile: "",
      uploadFileWithoutBlob: "",
      fileNameDisplay: "",
      fileExistsMsg: false,
      previousHashForUpload: "",
    });
  };

  keepBothFileUpload = () => {
    this.setState({ keepPreviousFileUpload: true }, () => {
      this.upload("uploadfile");
    });
  };
  keepBothFileUpload1 = (fileName) => {
    var newFileName2;
    if (this.state.uploadFileExtension !== "") {
      newFileName2 =
        this.state.fileNameDisplay + "." + this.state.uploadFileExtension;
    } else {
      newFileName2 = this.state.fileNameDisplay;
    }

    this.setState({ uploadFileSpinner: true, progress: 0 });
    var url = this.state.api_url + "/moibit/v0/addpin";
    axios({
      method: "POST",
      url: url,
      data: { fileName: this.state.path_value + "/" + newFileName2 },
      headers: {
        api_key: this.state.api_key,
        api_secret: this.state.api_secret,
      },
    })
      .then((res) => {
        if (res.data.meta.message == "pinned successfully") {
          this.upload("uploadfile");
        }
      })
      .catch((err) => {
        this.setState({ apiError: true, apiErrMsg: err.response.data.data });
        setTimeout(() => {
          this.setState({
            apiError: false,
            apiErrMsg: "",
            fileExistsMsg: false,
          });
        }, 5000);
      });
  };

  replaceFileUpload = () => {
    this.setState(
      { keepPreviousFileUpload: false, fileExistsMsgInRecovery: false },
      () => {
        this.upload("uploadfile");
      }
    );
  };

  replaceFileUpload1 = () => {
    this.setState({ progress: 0 });

    var newFileName3;
    if (this.state.uploadFileExtension !== "") {
      newFileName3 =
        this.state.fileNameDisplay + "." + this.state.uploadFileExtension;
    } else {
      newFileName3 = this.state.fileNameDisplay;
    }

    let path = this.state.path_value + "/" + newFileName3;
    let url = this.state.api_url + "/moibit/v0/filestatus";
    axios({
      method: "POST",
      url: url,
      data: { path: path },
      headers: {
        api_key: this.state.api_key,
        api_secret: this.state.api_secret,
      },
    })
      .then((response) => {
        if (
          response.data.data.pinned == true ||
          response.data.data.pinned == "true"
        ) {
          let removeUrl = this.state.api_url + "/moibit/v0/removepin";
          axios({
            method: "POST",
            url: removeUrl,
            data: { fileName: this.state.path_value + "/" + newFileName3 },
            headers: {
              api_key: this.state.api_key,
              api_secret: this.state.api_secret,
            },
          }).then((res) => {
            if (res.data.meta.code == 200) {
              this.upload("uploadfile");
            }
          });
        } else {
          this.upload("uploadfile");
        }
      })
      .catch((err) => {
        this.setState({ apiError: true, apiErrMsg: err.response.data.data });
        setTimeout(() => {
          this.setState({
            apiError: false,
            apiErrMsg: "",
            fileExistsMsg: false,
          });
        }, 5000);
      });
  };

  checkFileExists = async (keepPrevious) => {
    try {
      this.setState({
        previousHashForUpload: "",
        keepPreviousFileUpload: keepPrevious,
      });
      var newFileName1;
      if (this.state.uploadFileExtension !== "") {
        newFileName1 =
          this.state.fileNameDisplay + "." + this.state.uploadFileExtension;
      } else {
        newFileName1 = this.state.fileNameDisplay;
      }
      if (this.state.fileNameDisplay !== "") {
        let _res = await axios({
          method: "GET",
          url:
            process.env.REACT_APP_MOIBIT_API_URL +
            "net/" +
            this.state.selectedNetwork +
            "/app/" +
            this.state.selectedApp +
            "/file",

          params: {
            fileOperationType: 3,
            path: this.state.path_value + "/" + newFileName1,
          },
          headers: {
            Authorization: `Bearer ${
              JSON.parse(sessionStorage.getItem("bearerToken")).value
            }`,
          },
        });
        // if (_res.data.data.hash !== "") {
        if (_res.data.message !== "file don't exist") {
          if (_res.data.data.hash != "") {
            this.setState({
              fileExistsMsg: true,
              keepPreviousFileUpload: keepPrevious,
            });
            return;
          } else {
            this.setState({
              fileExistsMsgInRecovery: true,
              keepPreviousFileUpload: keepPrevious,
            });
            return;
          }
        } else {
          this.upload("uploadfile");
        }
      } else {
        this.setState({ borderColorForFileInput: true });
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.data) {
        this.setState({
          fileUploadModal: false,
          apiError: true,
          apiErrMsg: err.response.data.message,
        });
        setTimeout(() => {
          this.setState({ apiError: false, apiErrMsg: "" });
        }, 3000);
      }
    }
  };
  uploadFileNameChange = (e) => {
    this.setState({
      fileNameDisplay: e.target.value,
      fileExistsMsg: false,
      borderColorForFileInput: false,
    });
  };

  uploadFileReplica = (e) => {
    this.setState({ replicationFactor: e.target.value });
  };

  uploadFileCheckbox = (e) => {
    this.setState({ uploadChecked: e.target.checked });
  };

  notify = (message) =>
    toast.info(message, {
      autoClose: 3000,
      draggable: false,
      closeButton: false,
      position: toast.POSITION.BOTTOM_LEFT,
      className: "heading-css-bg",
    });

  notifyErr = (message) =>
    toast.error(message, {
      autoClose: 3000,
      draggable: false,
      closeButton: false,
      position: toast.POSITION.BOTTOM_LEFT,
    });

  previewCloseModalFile1 = (modalType, fileType) => {
    if (modalType == 0) {
      if (this.state.progressReadFileDownload !== 100) {
        this.setState({ readFileCancel: false, moibitWorldReadCancel: false });
        moibitWorldCancel();
        cancel();
      }
    } else if (modalType == 2) {
      if (fileType == 1) {
        this.notify("Folder removed successfully");
      } else if (fileType == undefined) {
        this.notify("File removed successfully");
      }
    }
    this.setState({ previewCloseModalFile: false, versionArr: [] });
  };

  getVersions = (fileName) => {
    try {
      let path = this.state.path_value + "/" + fileName;
      axios({
        method: "GET",
        url:
          process.env.REACT_APP_MOIBIT_API_URL +
          "net/" +
          this.state.selectedNetwork +
          "/app/" +
          this.state.selectedApp +
          "/file",
        params: {
          fileOperationType: 2,
          path: path,
        },
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
      })
        .then((response) => {
          this.setState({ versionArr: response.data.data });
        })
        .catch((err) => {
          //do nothing
        });
    } catch (Err) {
      //do nothing
    }
  };

  openDigitalMe = (showStatus, fileName, version) => {
    this.setState({
      openDigitalMeOpen: showStatus,
      digitalmeVal: "",
      digitalMeFileName: fileName,
    });
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_MOIBIT_API_URL +
        "net/" +
        this.state.selectedNetwork +
        "/app/" +
        this.state.selectedApp +
        "/file",
      params: {
        fileOperationType: 4,
        path: this.state.path_value + fileName,
        version: version,
      },
      headers: {
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("bearerToken")).value
        }`,
      },
    })
      .then((response) => {
        let pinnedreplicas = [];
        for (let i = 0; i < response.data.data.replica.length; i++) {
          if (response.data.data.replica[i].status == "pinned") {
            pinnedreplicas = [...pinnedreplicas, response.data.data[i]];
          }
        }
        this.setState({
          digitalMeFileName: fileName,
          digitalmeVal: response.data,
        });
      })
      .catch((err) => {
        this.setState({
          openDigitalMeOpen: false,
          digitalMeFileName: "",
          digitalmeVal: "",
          mobitworlderr: true,
        });
        setTimeout(() => {
          this.setState({ mobitworlderr: false });
        }, 3000);
      });
  };

  closeDigitalMe = () => {
    this.setState({ openDigitalMeOpen: false });
  };

  uploadFileModalOpen = async (e, extension, fileNameSplit) => {
    this.setState({ replicationFactor: 2 });
    let val = e.target.files;
    if (this.state.selectedApp !== "" && this.state.selectedApp !== "default") {
      let _res = await getAppDetails(
        this.state.selectedApp,
        this.state.selectedNetwork
      );
      this.setState({ replicationFactor: _res.replication });
    }
    this.setState({
      fileUploadModal: true,
      uploadFile: URL.createObjectURL(val[0]),
      uploadFileWithoutBlob: val[0],
      newFolder: "",
      uploadFolder: "",
      fileNameDisplay: fileNameSplit,
      uploadFileExtension: extension,
    });
  };

  // View provenance Log

  viewProvenanceLog = async (index, directory, fileName, lastUpdatedDate) => {
    try {
      let _duplicate = this.state.falseArr;
      _duplicate[index] = !_duplicate[index];
      let _duplicateProvenanceArr = this.state.provenanceArr;
      if (_duplicateProvenanceArr[index] !== "") {
        this.setState({
          falseArr: _duplicate,
          provenanceArr: _duplicateProvenanceArr,
        });
        return;
      }
      let _duplicateLoader = this.state.provenanceArrLoader;
      _duplicateLoader[index] = !_duplicateLoader[index];
      this.setState(
        { falseArr: _duplicate, provenanceArrLoader: _duplicateLoader },
        async () => {
          try {
            let _file = "";
            if (directory == "/") {
              _file = (directory == "/" ? "" : directory) + fileName;
            }
            if (directory !== "/") {
              // once entity is added properly use below
              // let dir = directory.replace("/", "");
              // _file = dir + fileName;
              _file = "";
            }
            let _resProvenace = await getProvenanceDetails();
            if (_resProvenace.code == 200) {
              let provenanceList = _resProvenace.data.logs;

              // let a = provenanceList.filter((data) => {
              //   return /^Total: \d+ files$/.test(data.entity);
              // });
              if (_resProvenace.data._count == 0) {
                // this.setState({
                //   provenanceArr: this.state.provenanceArr,
                //   provenanceArrLoader: this.state.provenanceArrLoader,
                // });
                await toast.error("Unable to get chain info", {
                  autoClose: 4000,
                  position: toast.POSITION.BOTTOM_LEFT,
                });
              } else {
                let _result;
                if (_file == "") {
                  _result = provenanceList.filter((data) => data.entity);
                } else {
                  _result = provenanceList.filter(
                    (data) => data.entity == _file
                  );
                }
                _duplicateProvenanceArr[index] = _result[0];
                let _duplicateLoaderCheck = this.state.provenanceArrLoader;
                _duplicateLoaderCheck[index] = !_duplicateLoaderCheck[index];
                this.setState({
                  provenanceArr: _duplicateProvenanceArr,
                  provenanceArrLoader: _duplicateLoaderCheck,
                });
              }
            } else {
              this.setState({
                provenanceArr: this.state.provenanceArr,
                provenanceArrLoader: this.state.provenanceArrLoader,
              });
              await toast.error(_resProvenace.data, {
                autoClose: 4000,
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          } catch (Err) {
            this.setState({
              provenanceArr: this.state.provenanceArr,
              provenanceArrLoader: this.state.provenanceArrLoader,
            });
            await toast.error(Err.message, {
              autoClose: 4000,
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        }
      );
    } catch (Err) {
      this.setState({
        provenanceArr: this.state.provenanceArr,
        provenanceArrLoader: this.state.provenanceArrLoader,
      });
      await toast.error(Err.message, {
        autoClose: 4000,
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  render() {
    return this.state.authErr ? (
      <div className="container">
        {" "}
        <div className="col-md-12 homecontainer">
          <p>
            Something went wrong. Please refresh the screen and try again later.
          </p>
        </div>{" "}
      </div>
    ) : (
      <div
        className="container"
        style={{ marginTop: "20px", marginBottom: "0px" }}
        id="fileexplorerContainer"
      >
        <div className="row">
          <div className="col-md-5">
            <div style={{ display: "flex" }}>
              <p
                className="heading-css"
                style={{ marginTop: "5px", fontWeight: "600" }}
              >
                Network : &nbsp;&nbsp;&nbsp;
              </p>
              <Form.Group>
                <Form.Control
                  as="select"
                  custom
                  onChange={this.selectNetwork}
                  required
                  style={{ fontSize: "14px" }}
                >
                  <option value="">--Select Network--</option>
                  {this.state.allNetwork.map((data, index) => {
                    if (data.network_id != "moinet") {
                      return (
                        <option key={index} value={data.network_id}>
                          {data.networkName}
                        </option>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </Form.Control>
              </Form.Group>
            </div>
          </div>
          <div className="col-md-5">
            <div style={{ display: "flex" }}>
              <p
                className="heading-css"
                style={{ marginTop: "5px", fontWeight: "600" }}
              >
                Apps : &nbsp;&nbsp;&nbsp;
              </p>
              <Form.Group>
                <Form.Control
                  as="select"
                  custom
                  onChange={this.selectApp}
                  required
                  style={{ fontSize: "14px" }}
                >
                  <option value="">-- Default App --</option>

                  {this.state.allApps !== "error" &&
                  this.state.allApps !== undefined &&
                  this.state.allApps.length > 0
                    ? this.state.allApps.map((data, index) => {
                        if (data.networkID == this.state.selectedNetwork) {
                          return (
                            <option key={index} value={data.appID}>
                              {data.appName}
                            </option>
                          );
                        } else {
                          return <></>;
                        }
                      })
                    : null}
                </Form.Control>
              </Form.Group>
            </div>
          </div>
        </div>

        <ToastContainer draggable={false} closeOnClick={false} />
        <div className="row">
          {this.state.selectedNetwork !== "" ? (
            <div className="col-md-12" style={{ marginBottom: "10px" }}>
              <div className="row">
                <div className="col-md-2" style={{ marginBottom: "20px" }}>
                  <label
                    className="btn btn-primary btntxt heading-css-bg"
                    style={{ width: "100%", cursor: "pointer" }}
                  >
                    <i className="fa fa-upload" aria-hidden="true"></i> Upload
                    file
                    <input
                      id="uploadfileinput"
                      type="file"
                      hidden
                      onChange={(e) => {
                        var files = e.target.files[0];
                        if (files) {
                          var fileNameSplit = files.name
                            .split(".")
                            .slice(0, -1)
                            .join(".");

                          var extension = files.name.split(".").pop();
                          if (extension == files.name) {
                            extension = "";
                          }
                          if (fileNameSplit == "") {
                            fileNameSplit = files.name;
                          }
                          this.uploadFileModalOpen(e, extension, fileNameSplit);
                        }
                      }}
                    />
                  </label>
                </div>
                <div className="col-md-2" style={{ marginBottom: "20px" }}>
                  <button
                    type="button"
                    className="btn btn-outline-primary btntxt heading-css heading-css-bg-border fileexplorer-btn"
                    style={{ width: "100%" }}
                    onClick={() => {
                      this.setState({
                        uploadFile: "",
                        uploadFolderFiles: "",
                        previousHashForCreateFile: "",
                        fileExistsMsg1: false,
                      });
                      this.createFileModal(1);
                    }}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i> Create
                    text file
                  </button>
                </div>

                <div className="col-md-2" style={{ marginBottom: "20px" }}>
                  <label
                    className="btn btn-outline-primary btntxt heading-css heading-css-bg-border fileexplorer-btn"
                    style={{ width: "100%", cursor: "pointer" }}
                    onClick={async () => {
                      this.setState({
                        uploadFolderFilesLoaderButton: true,
                        uploadFolderFiles: "",
                      });
                      var x = document.getElementById("uploaddir");
                      // x.onclick = () => {
                      //   this.charge();
                      // };
                      this.cancelClick();
                    }}
                    disabled
                  >
                    {this.state.uploadFolderFilesLoaderButton ? (
                      <i
                        className="fa fa-spinner fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i className="fa fa-folder" aria-hidden="true"></i>
                    )}{" "}
                    Upload folder{" "}
                    <input
                      id="uploaddir"
                      directory=""
                      webkitdirectory=""
                      mozdirectory=""
                      type="file"
                      hidden
                      style={{ display: "none" }}
                      onChange={(e) => {
                        this.uploadFolderHandler(e);
                      }}
                    />
                  </label>
                </div>

                <div className="col-md-2" style={{ marginBottom: "20px" }}>
                  <button
                    type="button"
                    className="btn btn-outline-primary btntxt heading-css heading-css-bg-border fileexplorer-btn"
                    style={{ width: "100%" }}
                    onClick={() => {
                      this.setState({ uploadFile: "", uploadFolderFiles: "" });
                      this.createFileModal(2);
                    }}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i> New folder
                  </button>
                </div>
                <div
                  className="col-md-1"
                  style={{ marginBottom: "20px" }}
                ></div>

                <div
                  className="col-md-1"
                  style={{ marginBottom: "20px" }}
                ></div>
              </div>
            </div>
          ) : null}

          {this.state.selectedNetwork !== "" ? (
            <div className="col-md-12">
              <div className="row">
                {/* Working ends file upload code */}
                <div className="col-md-2"></div>
                <div className="col-md-2"></div>
                {this.state.uploadFolderFiles !== "" ? (
                  <div className="col-md-2" style={{ marginBottom: "10px" }}>
                    <input
                      className="form-control"
                      placeholder="Replication Factor"
                      style={{ marginBottom: "10px", fontSize: "12px" }}
                      onChange={(e) =>
                        this.setState({
                          replicationFactorFolder: e.target.value,
                        })
                      }
                      // value={this.state.replicationFactorFolder}
                      value={
                        this.state.selectedApp === ""
                          ? 2
                          : this.state.replicationFactorFolder
                      }
                      disabled={this.props.plan <= 1}
                    />
                    <button
                      type="button"
                      className="btn btn-secondary button-css"
                      // disabled={this.state.uploadFolderSpinner}
                      disabled={
                        this.state.uploadFolderSpinner ||
                        this.state.isSubmitDisabled
                      }
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      onClick={() => this.upload("uploadfolder")}
                    >
                      {" "}
                      {this.state.uploadFolderSpinner ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : null}{" "}
                      Submit
                    </button>
                  </div>
                ) : (
                  <div className="col-md-2"></div>
                )}
              </div>
            </div>
          ) : null}

          {this.state.selectedNetwork !== "" ? (
            <div className="col-md-8">
              <h4 style={{ display: "flex" }}>
                <Button
                  className="btn btn-outline-info btntxt heading-css heading-css-bg-border fileexplorer-btn"
                  style={{
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  onClick={
                    this.state.enablePathOnClick
                      ? () => this.listAllFiles("/", "")
                      : () => {}
                  }
                >
                  <i className="fa fa-home" aria-hidden="true"></i>{" "}
                  {this.state.selectedApp == ""
                    ? "Default"
                    : this.state.selectedAppName +
                      " " +
                      "(" +
                      this.state.selectedNetworkName +
                      ")"}{" "}
                </Button>
                &nbsp;{" "}
                {this.state.loading
                  ? null
                  : this.state.root_history.map((val, index) => {
                      return (
                        <span
                          style={{ color: "#237ece", cursor: "pointer" }}
                          onClick={
                            this.state.enablePathOnClick
                              ? () =>
                                  this.setFolderPath(
                                    this.state.root_history,
                                    index
                                  )
                              : () => {}
                          }
                          key={index}
                        >
                          {val.path !== "/" ? (
                            <div>
                              <span style={{ margin: "5px" }}>
                                <i
                                  className="fa fa-caret-right"
                                  aria-hidden="true"
                                  style={{ color: "#000" }}
                                ></i>
                              </span>{" "}
                              <span>
                                <Button
                                  variant="outline-info btntxt heading-css heading-css-bg-border fileexplorer-btn"
                                  size="sm"
                                  style={{ fontWeight: "600" }}
                                  // onClick={() =>
                                  //   this.listAllFiles(
                                  //     val.path.split(">")[1],
                                  //     ""
                                  //   )
                                  // }
                                  onClick={
                                    this.state.enablePathOnClick
                                      ? () =>
                                          this.setFolderPath(
                                            this.state.root_history,
                                            index
                                          )
                                      : () => {}
                                  }
                                >
                                  {val.path.split(">")[1]}
                                </Button>
                              </span>
                              &nbsp;
                            </div>
                          ) : null}
                        </span>
                      );
                    })}
              </h4>
            </div>
          ) : null}

          {this.state.mobitworlderr ? (
            <p style={{ color: "crimson", fontSize: "14px" }}>
              Something went wrong. Please try again later
            </p>
          ) : null}

          <div
            className="col-md-12"
            style={{
              overflowY: "hidden",
              scrollBehavior: "smooth",
              marginTop: "20px",
            }}
          >
            {this.state.uploadFile !== "" ? (
              <UploadFileModal
                fileUploadModal={this.state.fileUploadModal}
                uploadFile={this.state.uploadFile}
                fileNameDisplay={this.state.fileNameDisplay}
                uploadFileWithoutBlob={this.state.uploadFileWithoutBlob}
                handleModalCloseFile={this.handleModalCloseFile}
                checkFileExists={this.checkFileExists}
                fileExistsMsg={this.state.fileExistsMsg}
                fileExistsMsgInRecovery={this.state.fileExistsMsgInRecovery}
                previousHashForUpload={this.state.previousHashForUpload}
                uploadFileNameChange={this.uploadFileNameChange}
                uploadFileSpinner={this.state.uploadFileSpinner}
                replaceFileUpload={this.replaceFileUpload}
                keepBothFileUpload={this.keepBothFileUpload}
                uploadprogress={this.state.progress}
                uploadFileExtension={this.state.uploadFileExtension}
                borderColorForFileInput={this.state.borderColorForFileInput}
                uploadFileReplica={this.uploadFileReplica}
                replicationFactor={this.state.replicationFactor}
                uploadFileCheckbox={this.uploadFileCheckbox}
                uploadChecked={this.state.uploadChecked}
                plan={this.props.plan}
              />
            ) : null}

            {this.state.apiError ? (
              <p style={{ color: "red" }}>{this.state.apiErrMsg}</p>
            ) : null}
            {this.state.createFileModal ? (
              <CreateFileModal
                handleModalClose={this.handleModalClose}
                createFileModal={this.state.createFileModal}
                onEditorStateChange={this.onEditorStateChange}
                editorState={this.state.editorState}
                createFileNames={this.state.createFileName}
                submitEditor={this.submitEditor}
                createFileName={this.createFileName}
                createFileNameValidate={this.state.createFileNameValidate}
                createFileModalType={this.state.createFileModalType}
                submitFolderCreate={this.submitFolderCreate}
                fileExistsMsg1={this.state.fileExistsMsg1}
                fileExistsMsgInRecovery={this.state.fileExistsMsgInRecovery}
              />
            ) : null}

            {this.state.previewCloseModalFile ? (
              <Preview
                createdDate={this.state.createdDate}
                pinned={this.state.pinned}
                currenthash={this.state.currentHash}
                type={this.state.previewType}
                show={this.state.modal}
                onHide={this.closeModal}
                contenttype={this.state.contentType}
                result={this.state.previewResult}
                loader={this.state.loading2}
                page="fileexplorer"
                filename={this.state.fileName}
                filesize={this.state.fileSize}
                imageblob={this.state.imageBlob}
                downloadcontenttype={this.state.downloadContentType}
                deletefilefrommoibit={this.deleteFileFromMoibit1}
                typeoffile={this.state.typeoffile}
                deleteindex={this.state.deleteIndex}
                deletetype={this.state.deleteType}
                previewFile={this.previewFile}
                progressReadFileDownload={this.state.progressReadFileDownload}
                previewCloseModalFile1={this.previewCloseModalFile1}
                showCloseModal={this.state.previewCloseModalFile}
                getVersions={this.getVersions}
                versionArr={this.state.versionArr}
                deleteFileVersion={this.deleteFileVersion}
                deleteVersionNum={this.state.deleteVersionNum}
                openDigitalMe={this.openDigitalMe}
                digitalmeVal={this.state.digitalmeVal}
                selectedNetwork={this.state.selectedNetwork}
                selectedApp={this.state.selectedApp}
                readFileCancel={this.state.readFileCancel}
                moibitWorldReadCancel={this.state.moibitWorldReadCancel}
                path={this.state.path_value}
                version={this.state.currentVersion}
                networkID={this.state.selectedNetwork}
                appID={this.state.selectedApp}
              />
            ) : null}

            {!this.state.renderLoader ? (
              <div>
                {this.state.allData.length > 0 ? (
                  <Renderlayout
                    totalRecords={this.state.totalRecords}
                    page="fileexplorer"
                    pageLimit={8}
                    pageNeighbours={0}
                    onPageChanged={this.onPageChanged}
                    renderData={this.state.currentData}
                    previewFile={this.previewFile}
                    display={this.state.loading2}
                    moibitworld={this.state.moibitworld}
                    allData={this.state.allData}
                    getThumbnail={this.getThumbnail}
                    api_key={this.state.api_key}
                    api_secret={this.state.api_secret}
                    loadingLoader={this.loadingFunc}
                    deleteFile={this.deleteFile}
                    listGridView={this.state.listGridView}
                    sortBy={this.sortBy}
                    enablePreviewOnClick={this.state.enablePreviewOnClick}
                    nameSort={this.state.nameSort}
                    sizeSort={this.state.sizeSort}
                    pinUnpin={this.pinUnpin}
                    currentPage={this.state.currentPage}
                    openDigitalMe={this.openDigitalMe}
                    networkID={this.state.selectedNetwork}
                    appID={this.state.selectedApp}
                    effectiveLoading={this.state.effectiveLoading}
                    viewProvenanceLog={this.viewProvenanceLog}
                    showHideProff={this.state.falseArr}
                    provenanceArr={this.state.provenanceArr}
                    provenanceArrLoader={this.state.provenanceArrLoader}
                  />
                ) : (
                  <p>This folder is empty</p>
                )}
                {this.state.errMsg ? (
                  <p>Something went wrong. Please try again later.</p>
                ) : null}
              </div>
            ) : (
              <div style={{ textAlign: "center", margin: "10px" }}>
                <p style={{ marginBottom: "10px" }}>Fetching...</p>
                <Spinner animation="border" />
              </div>
            )}
          </div>

          {this.state.oppsErr ? (
            <div id="mydiv">
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Oops &#128528; You are not part of Moi-net yet. If you want to
                access this file explorer, Please join Moi-net.
              </p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    plan: store.price.priceState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    priceModalOpen: () => dispatch(priceModalOpen()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileExplorer);
