import axios from "axios";

export const getPriceDetails = async () => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "getpricingplan";
    return (
      await axios({
        url: tempUrl,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: {},
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const getPublisherKey = async () => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "getpublisherkey";
    return (
      await axios({
        url: tempUrl,
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const createSetupIntent = async (data) => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "createSetupIntent";
    return (
      await axios({
        url: tempUrl,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: data,
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const createSubscription = async (data) => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "createsubscription";
    return (
      await axios({
        url: tempUrl,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: data,
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};
export const getSubscription = async (data) => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "subscription";
    return (
      await axios({
        url: tempUrl,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: {
          devPubKey: JSON.parse(sessionStorage.getItem("defAddress")).value,
          subID: data,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const cancelSubscription = async () => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "cancelsubscription";
    return (
      await axios({
        url: tempUrl,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const createPaymentIntent = async (data) => {
  try {
    const tempUrl =
      process.env.REACT_APP_MOIBIT_API_URL + "createpaymentintent";
    return (
      await axios({
        url: tempUrl,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: data,
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const getPaymentInfo = async () => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "paymentinfo";
    return (
      await axios({
        url: tempUrl,
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        // data: { devPubKey: _developerKey, pi: data },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const getInvoice = async (data) => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "invoice";
    return (
      await axios({
        url: tempUrl,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: {
          devPubKey: JSON.parse(sessionStorage.getItem("defAddress")).value,
          invoiceID: data,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};
