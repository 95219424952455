exports.noop = function() {}


exports.getParsedCipher = function(_keystore) {
    const parsedKs = JSON.parse(_keystore)
    return parsedKs['Crypto'].ciphertext;
}

exports.getIGCPathArray = function(igcAccParams) {
    let _igcPathArray = [];
    _igcPathArray[0] = igcAccParams.bcID;
    _igcPathArray[1] = igcAccParams.orgID;
    _igcPathArray[2] = igcAccParams.appID;
    _igcPathArray[3] = igcAccParams.usrGrpID;
    return _igcPathArray;
}

exports.getUserNameAvailability = async (_username,usersList) => {
    try {
        if (_username == "") return false;
        let userArrValue = usersList.filter(userName => userName == _username);
        return userArrValue.length == 0;
    }
    catch (err) {
        throw new Error(err);
    }
}

exports.isNamespaceMember = (accounts,reqNamespace) => {
    const filteredNamespaceR = accounts.filter(acc => acc.namespace == reqNamespace);
    return filteredNamespaceR.length != 0 ? true : false;
}

exports.getUserStatusInNamespace = (accounts,reqNamespace) => {
    const filteredNamespaceR = accounts.filter(acc => acc.namespace == reqNamespace);
    if(filteredNamespaceR.length != 0) {
        return {
            isMember: true,
            isActive: filteredNamespaceR[0].isActive
        }
    }else {
        return {
            isMember: false,
            isActive: false
        }
    }
}

exports.btoa = (str) => {
    var buffer;

    if (str instanceof Buffer) {
        buffer = str;
    } else {
        buffer = Buffer.from(str.toString(), 'binary');
    }

    return buffer.toString('base64');
}


exports.getActionType = (typeIndex) => {
    let action;
    switch(typeIndex) {
        case '0' : action = 'Joined'; break;
        case '1' : action = 'Authenticated'; break;
        case '2' : action = 'Verified'; break;
        case '3' : action = 'Created'; break;
        case '-1' : action = 'Revoked'; break;
    }
    return action;
}

exports.bc = require('./bc-utils');

exports.moibit = require('./moibit-utils');

exports.admin = require('./admin-utils');

exports.crypto = require('./crypto-utils');

exports.general = require('./general');