import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "./style.css";
import { devAdmindetails } from "../../common/devapicalls";
import { getPartOfNetwork } from "../../common/networkapicalls";

function NetworkAppDropdown(props) {
  const [allNetwork, setAllNetwork] = useState([]);
  const [allApps, setAllApps] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState("");

  useEffect(() => {
    getAllPartofNetwork();
  }, []);

  async function getAllPartofNetwork() {
    let _res = await getPartOfNetwork();
    let response = _res.data;
    if (response) {
      if (typeof response !== "string") {
        let newarr = await response.filter((data) => data.networkName !== "");
        setAllNetwork(newarr);
      }
    }
  }

  async function selectNetwork(e) {
    var index = e.nativeEvent.target.selectedIndex;
    let networkName = e.nativeEvent.target[index].text;
    let selectedNetworks = e.target.value;
    setSelectedNetwork(selectedNetworks);
    await setAllApps([]);
    let _val = await devAdmindetails();
    let _appsVal = _val.data.appList;
    let list = Object.values(_appsVal);
    let appKeys = Object.keys(_appsVal);
    let _appRes = [];
    if (appKeys.length != 0) {
      for (let i = 0; i < appKeys.length; i++) {
        _appRes.push({
          appID: appKeys[i],
          appName: list[i].appName,
          networkName: list[i].networkName,
          networkID: list[i].networkID,
          isActive: list[i].isActive,
          isRemoved: list[i].isRemoved,
        });

        let finalArr = [];
        finalArr = [..._appRes];
        if (_appsVal !== "error") {
          let _activeapps = finalArr.filter((data) => data.isActive == true);
          await setAllApps(_activeapps);
          props.networkDetail({ name: networkName, id: selectedNetworks });
        }
      }
    } else {
      props.networkDetail({
        name: networkName,
        id: selectedNetworks,
      });
    }
  }

  async function selectApp(e) {
    var index = e.nativeEvent.target.selectedIndex;
    let appName = e.nativeEvent.target[index].text;
    props.appDetail({ name: appName, id: e.target.value });
  }

  return (
    <div className="row" style={{ marginTop: "20px" }}>
      <div className="col-md-6">
        <div className="displayflexcss">
          <div className="dropdwnlabel heading-css">
            Network : &nbsp;&nbsp;&nbsp;
          </div>
          <div>
            <Form.Group>
              <Form.Control
                as="select"
                custom
                onChange={(event) => selectNetwork(event)}
                required
                style={{ fontSize: "14px" }}
              >
                <option value="">--Select Network--</option>
                {allNetwork.length !== 0
                  ? allNetwork.map((data, index) => (
                      <option value={data.network_id} key={index}>
                        {data.networkName}
                      </option>
                    ))
                  : null}
              </Form.Control>
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="displayflexcss">
          <div className="dropdwnlabel heading-css">
            Apps : &nbsp;&nbsp;&nbsp;
          </div>
          <div>
            <Form.Group>
              <Form.Control
                as="select"
                custom
                required
                onChange={selectApp}
                style={{ fontSize: "14px" }}
              >
                <option value="">-- Default App --</option>
                {allApps !== "error" &&
                allApps !== undefined &&
                allApps.length > 0
                  ? allApps.map((data, index) => {
                      if (data.networkID == selectedNetwork) {
                        return (
                          <option key={index} value={data.appID}>
                            {data.appName}
                          </option>
                        );
                      } else {
                        return <></>;
                      }
                    })
                  : null}
              </Form.Control>
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NetworkAppDropdown;
