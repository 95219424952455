import React from "react";
import "./style.css";
import Networks from "./network";

class Network extends React.Component {

    render() {
        return (

            <div className="container" style={{ marginTop: "30px" }}>
                <Networks />
            </div>

        );
    }
}

export default Network;