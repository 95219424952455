import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { priceModalClose } from "../action";

class PriceModal extends React.Component {
  state = {
    networkRedirect: false,
    priceModelPopUp: false,
  };

  pricemodelClose = () => {
    this.setState({ priceModelPopUp: false });
  };

  render() {
    if (this.state.networkRedirect) {
      return <Redirect push to="/pricing" />;
    }
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.handleClose} centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                style={{ fontSize: "50px" }}
              ></i>
            </div>

            <p
              style={{
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "35px",
                fontWeight: "600",
                color: "#122c50",
              }}
            >
              {" "}
              Want to get more premium access?
            </p>
            <p
              style={{
                fontSize: "16px",
                textAlign: "center",
                fontWeight: "600",
                color: "#122c50",
              }}
            >
              Please Upgrade your plan
            </p>
            <div style={{ textAlign: "center" }}>
              <Button
                variant="info"
                size="sm"
                className="heading-css-bg fontweight600"
                onClick={() => this.setState({ networkRedirect: true })}
              >
                Upgrade
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    show: store.price.priceModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleClose: () => dispatch(priceModalClose()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceModal);
