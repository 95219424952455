import React from "react";
import { Form, Card, Col, Row, Button, Table, Modal, Spinner } from "react-bootstrap";
import { getKeysToBeDeleted, recoveryStorage } from "../../common/apicalls";
import { connect } from "react-redux";
import { toastify } from "../../action";
import "./style.css";

const dropDownVal = [
    { name: "Developer level", val: 0 },
    { name: "Developer network level", val: 1 },
    { name: "End-user level", val: 2 },
    { name: "End-user app level", val: 3 },
]


class MMARecoveryStorage extends React.Component {
    state = {
        selectLevel: -1,
        developerKey: "",
        endUserKey: "",
        networkID: "",
        appID: "",
        listData: [],
        loadingModal: false
    }

    onChangeType = (e) => {
        this.setState({
            selectLevel: e.target.value,
            developerKey: "",
            endUserKey: "",
            networkID: "",
            appID: ""
        })
    }
    setValue = (name, value) => {
        this.setState({ [name]: value, listData: [] });
    }


    handleSubmit = async () => {
        try {
            let selectLevel = parseInt(this.state.selectLevel);
            let payload;
            if (selectLevel == 0) {
                payload = {
                    delOperationType: selectLevel,
                    devPubKey: this.state.developerKey
                }
            } else if (selectLevel == 1) {
                payload = {
                    delOperationType: selectLevel,
                    devPubKey: this.state.developerKey,
                    networkID: this.state.networkID
                }
            } else if (selectLevel == 2) {
                payload = {
                    delOperationType: selectLevel,
                    endUserID: this.state.endUserKey

                }
            } else if (selectLevel == 3) {
                payload = {
                    delOperationType: selectLevel,
                    endUserID: this.state.endUserKey,
                    appID: this.state.appID

                }
            }
            let _res = await getKeysToBeDeleted(payload);
            if (typeof (_res.data) !== "string" && _res.data) {
                let listData = _res.data;
                this.setState({ listData });
            } else {
                this.props.toastifyEnable("err", _res.data);
            }
        }
        catch (err) {
            this.props.toastifyEnable("err", err.message);
        }
    }

    handleRecoverStorage = async (data) => {
        try {
            this.setState({ loadingModal: true });
            let _res = await recoveryStorage(data);
            if (typeof (_res.data) !== "string" && _res) {
                this.setState({ loadingModal: false, listData: [] });
                if (_res.meta.code == 200) {
                    this.props.toastifyEnable("info", "Free up storage successfully");
                }
            } else {
                this.props.toastifyEnable("err", _res.data);
                this.setState({ loadingModal: false, listData: [] });
            }
        }
        catch (err) {
            this.setState({ loadingModal: false });
            this.props.toastifyEnable("err", err.message);
        }
    }

    render() {
        return (
            <div className="container" style={{ marginTop: "30px" }}>

                {this.state.loadingModal ? <Modal show={this.state.loadingModal} centered >
                    <Modal.Body style={{ textAlign: "center" }}>
                        <Spinner animation="border" variant="primary" />
                    </Modal.Body>
                </Modal> : null}


                <div className="row">
                    <div className="col-md-4">
                        <Form.Group >
                            <Form.Label style={{ fontWeight: "600" }}>MMA</Form.Label>
                            <Form.Control as="select" onChange={this.onChangeType}>
                                <option value="" >--Select type--</option>
                                {dropDownVal.map((data, index) => (
                                    <option value={data.val} key={index}>{data.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </div>


                    <div className="col-md-8">
                        <div>
                            {this.state.selectLevel < 0 ? null :
                                <Card className="cardstyle">
                                    <Card.Body>


                                        {this.state.selectLevel == 0 ? <Row>
                                            <Col>
                                                <Form.Control placeholder="Developer Key"
                                                    value={this.state.developerKey}
                                                    onChange={(e) => this.setValue("developerKey", e.target.value)}
                                                />
                                            </Col>
                                        </Row> : null}


                                        {this.state.selectLevel == 1 ? <Row>
                                            <Col>
                                                <Form.Control placeholder="Developer Key"
                                                    value={this.state.developerKey}
                                                    onChange={(e) => this.setValue("developerKey", e.target.value)}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Control placeholder="Network ID"
                                                    value={this.state.networkID}
                                                    onChange={(e) => this.setValue("networkID", e.target.value)}
                                                />
                                            </Col>
                                        </Row> : null}


                                        {this.state.selectLevel == 2 ? <Row>
                                            <Col>
                                                <Form.Control placeholder="End-user Key"
                                                    value={this.state.endUserKey}
                                                    onChange={(e) => this.setValue("endUserKey", e.target.value)}
                                                />
                                            </Col>
                                        </Row> : null}

                                        {this.state.selectLevel == 3 ? <Row>
                                            <Col>
                                                <Form.Control placeholder="End-user Key"
                                                    value={this.state.endUserKey}
                                                    onChange={(e) => this.setValue("endUserKey", e.target.value)}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Control placeholder="App ID"
                                                    value={this.state.appID}
                                                    onChange={(e) => this.setValue("appID", e.target.value)}
                                                />
                                            </Col>
                                        </Row> : null}
                                        <div style={{ marginTop: "20px", textAlign: "center" }}>
                                            <Button variant="info" size="sm" style={{ fontWeight: "600", width: "150px" }} onClick={this.handleSubmit}>Submit</Button>
                                        </div>

                                    </Card.Body>
                                </Card>}



                        </div>



                    </div>
                </div>
                <div style={{ marginTop: "30px" }}>
                    {this.state.listData.length > 0 ?
                        <Table bordered style={{ fontSize: "12px" }} responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Developer Key</th>
                                    <th>Network ID</th>
                                    <th>Enduser ID</th>
                                    <th>App ID</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                {this.state.listData.map((data, index) => {

                                    let details = data.split("_");
                                    return (<tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{details[0]}</td>
                                        <td>{details[1]}</td>
                                        <td>{details[3]}</td>
                                        <td>{details[2]}</td>
                                        <td><Button variant="info" size="sm" className="freeupstorage" onClick={() => this.handleRecoverStorage(data)}>Free up storage</Button></td>
                                    </tr>)


                                })}
                            </tbody>
                        </Table> : <p>No data found.</p>}


                </div>
            </div >
        )
    }
}




const mapDispatchToProps = (dispatch) => {
    return {
        toastifyEnable: (type, val) => dispatch(toastify(type, val))
    }
}



export default connect(null, mapDispatchToProps)(MMARecoveryStorage);
