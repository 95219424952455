import React, { Component } from "react";
import Maps from "./map";
import NetworkForm from "./networkform";
import "./style.css";
import { Form, Button, Modal, Spinner, Table } from "react-bootstrap";
import DisplayConfig from "./config";
import { createNetwork } from "../../common/networkapicalls";

import { toastify } from "../../action";
import { connect } from "react-redux";
import CreateNode from "./createnode";
import { Redirect } from "react-router-dom";

class Network extends Component {
  state = {
    nodeValue: [],
    modal: false,
    networkName: "",
    nodes: 2,
    replication: 2,
    loading: false,
    nodeSelectErr: false,
    createNetworkErrShow: false,
    createNetworkErr: "",
    clearNodes: "",
    replicationZeroErr: false,
    replicationOneMsg: false,
    openNodePopup: false,

    minimumReplicationFactor: 2,
    maximumReplicationFactor: 2,
    maxRFError: false,
    minRFError: false,
    replicationZeroErr1: false,
    disableName: false,
    networkID: "",
    totalUpdateNodes: 0,
    partofNodes: 0,
    partOfNodeVal: [],
    totalUpdateNodesErr: false,
    numberofNodesErr: false,
    disableNetworkBtn: false,

    requestPopup: false,
    upto: false,
    selectedNetworkType: "",
    createdNetworkID: "",
    redirectNetworkPage: false,
    maxErrRF: false,

    showNwkType: false,

    redirectPricingPage: false,
  };

  componentDidMount() {
    if (this.props.plan < 2) {
      this.setState({ redirectPricingPage: true });
      return;
    }
  }

  nodeCheckBoxFunc = (value) => {
    let newObj = [];
    for (let obj of value) {
      newObj.push({ name: obj[0], value: obj[1].value, data: obj[1].data });
    }
    let _filter = newObj.filter(function (s) {
      return s.value;
    }).length;
    let checkArr = [];
    for (let i = 0; i < newObj.length; i++) {
      if (newObj[i].value) {
        checkArr.push(newObj[i]);
      }
    }
    let _checkPremiumNode = checkArr.some((val) => val.data.nodeType == 2);

    this.setState({ maxRFError: false });
    this.setState({ replicationZeroErr1: false });
    if (_filter < 2) {
      this.setState({ replicationZeroErr1: true });
    }

    this.setState({
      nodeValue: newObj,
      nodes: _filter,
      maximumReplicationFactor: _filter,
      showNwkType: _checkPremiumNode,
    });
  };
  handleClose = () => {
    this.setState({ modal: false });
  };

  disableName = (
    networkData,
    totalUpdateNodes,
    partofNodesCount,
    admin,
    partofNodes
  ) => {
    this.setState({
      minimumReplicationFactor: networkData.minReplicationFactor,
      maximumReplicationFactor: networkData.maxReplicationFactor,
      networkName: networkData.networkName,
      nodes: networkData.nodelist.length,
      networkID: networkData.networkID,
      disableName: true,
      totalUpdateNodes: totalUpdateNodes,
      partofNodes: partofNodes,
      disableNetworkBtn: !admin,
      partOfNodeVal: partofNodes,
    });
  };

  goToNetwork = () => {
    this.setState({
      minimumReplicationFactor: 2,
      maximumReplicationFactor: 3,
      networkName: "",
      nodes: 3,
      networkID: "",
      disableName: false,
      disableNetworkBtn: false,
      publicNodes: [],
    });
  };

  setTotalNodeCount = (val, publicNodes) => {
    this.setState({ totalNodeCountVal: val, publicNodes: publicNodes });
  };
  handleSubmitNetwork = async (e) => {
    e.preventDefault();
    try {
      this.setState({
        repicationErr: false,
        createNetworkErr: "",
        createNetworkErrShow: false,
        maxErrRF: false,
      });

      if (!this.state.disableName) {
        if (this.state.replication < 2) {
          this.setState({ replicationZeroErr: true });
          return;
        }
        if (
          this.state.maximumReplicationFactor <
          this.state.minimumReplicationFactor
        ) {
          this.setState({ maxErrRF: true });
          return;
        }
        if (this.state.nodeValue.length < this.state.replication) {
          this.setState({ repicationErr: true });
          return;
        }

        if (this.state.maxRFError) {
          return;
        }
        if (this.state.minRFError) {
          return;
        }
        if (this.state.replicationZeroErr1) {
          return;
        }

        let selectedNodeArr = await this.state.nodeValue.filter(
          (selectedNodeMetaInfo) => {
            if (selectedNodeMetaInfo.value) {
              return {
                nodeID: selectedNodeMetaInfo.data.nodeID,
              };
            }
          }
        );

        let finalNodeArr = selectedNodeArr.map((data1) => {
          return {
            nodeID: data1.data.nodeID,
            nodeType: data1.data.userDefinedNodeType,
            isPremiumNode: data1.data.nodeType == 2 ? true : false,
          };
        });

        let payload;
        this.setState({ loading: true });
        payload = {
          networkName: this.state.networkName.toLowerCase(),
          nodeList: finalNodeArr,
          MinReplicationFactor: parseInt(this.state.minimumReplicationFactor),
          MaxReplicationFactor: parseInt(this.state.maximumReplicationFactor),
          networkType: parseInt(this.state.selectedNetworkType),
        };

        let toatifyVal =
          "Relax.Creating " +
          this.state.networkName +
          " Network in progress. Will notify once done.";
        this.props.toastifyEnable("info", toatifyVal);
        let res;

        res = await createNetwork(payload);
        if (res.code == 200) {
          this.props.toastifyEnable("info", "Network created successfully...");
          this.setState(
            {
              loading: false,
              nodeValue: [],
              networkName: "",
              nodes: 3,
              replication: 2,
              nodeSelectErr: false,
              clearNodes: "clear",
              networkID: "",
              disableName: false,
              redirectNetworkPage: true,
            },
            () => this.setState({ clearNodes: "" })
          );
        } else {
          this.setState({
            loading: false,
            createNetworkErr: res.data
              ? res.data[0].nodeError !== ""
                ? res.data[0].nodeError
                : res.message
              : res.message,
            createNetworkErrShow: true,
          });

          setTimeout(() => {
            this.setState({
              loading: false,
              createNetworkErr: "",
              createNetworkErrShow: false,
            });
          }, 3000);
          // this.props.toastifyEnable(
          //   "err",
          //   res.data
          //     ? res.data[0].nodeError !== ""
          //       ? res.data[0].nodeError
          //       : res.meta.message
          //     : res.meta.message
          // );
          this.props.toastifyEnable("err", res.message);
        }
      }
    } catch (Err) {
      console.log(Err);
      this.setState({ loading: false });
    }
  };

  nodeSelectErrFalse = () => {
    this.setState({ nodeSelectErr: false });
  };

  openNodePopupFunc = () => {
    this.setState({ openNodePopup: !this.state.openNodePopup });
  };

  handleCloseRequestPopup = () => {
    this.setState({ requestPopup: false, loading: false });
  };
  render() {
    if (this.state.redirectNetworkPage) {
      return <Redirect to="/network" />;
    } else {
      return this.state.redirectPricingPage ? (
        <Redirect to="/pricing" />
      ) : (
        <div className="container">
          <div>
            <Modal
              show={this.state.requestPopup}
              onHide={this.handleCloseRequestPopup}
              backdrop="static"
              keyboard={false}
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontWeight: "600", fontSize: "14px" }}>
                  Create Network
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Maps
                  nodeValue={this.state.nodeValue}
                  message={true}
                  min={this.state.minimumReplicationFactor}
                  max={this.state.maximumReplicationFactor}
                  network={this.state.networkName}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={this.handleCloseRequestPopup}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div>
            <CreateNode
              open={this.state.openNodePopup}
              openNodePopupFunc={this.openNodePopupFunc}
            />
          </div>

          <div>
            <Modal
              show={this.state.modal}
              onHide={this.handleClose}
              backdrop="static"
              keyboard={false}
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontWeight: "600", fontSize: "14px" }}>
                  Network preview
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Maps
                  nodeValue={this.state.nodeValue}
                  message={false}
                  disableBtn={this.state.disableNetworkBtn}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={this.handleClose}
                  className="button-css"
                  style={{ fontWeight: "600", fontSize: "14px" }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <Form onSubmit={this.handleSubmitNetwork}>
            <div className="row">
              <div
                className="col-md-6 mapareacard"
                style={{ marginTop: "30px" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontWeight: "600" }} className="heading-css">
                    {!this.state.disableName
                      ? "Create Network"
                      : this.state.networkName}
                  </p>
                  {this.state.disableName ? (
                    <Button
                      variant="dark"
                      size="sm"
                      style={{ fontWeight: "600", height: "30px" }}
                      onClick={this.goToNetwork}
                    >
                      Go to Network
                    </Button>
                  ) : null}
                </div>

                {this.state.disableNetworkBtn ? null : (
                  <Form.Group>
                    <Form.Group>
                      <Form.Label className="formcss">
                        Enter network name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter network name"
                        onChange={(e) =>
                          this.setState({ networkName: e.target.value })
                        }
                        value={this.state.networkName}
                        disabled={this.state.disableName}
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="formcss">
                        Minimum Replication factor
                      </Form.Label>

                      <Form.Control
                        type="number"
                        placeholder="Minimum Replication factor"
                        required
                        value={this.state.minimumReplicationFactor}
                        onChange={(e) => {
                          let x = e.target.value;
                          this.setState(
                            {
                              minimumReplicationFactor: x,
                              minRFError: false,
                              replicationZeroErr: false,
                            },
                            () => {
                              if (x > this.state.maximumReplicationFactor) {
                                this.setState({ minRFError: true });
                              }
                              if (x < 2) {
                                this.setState({ replicationZeroErr: true });
                              }
                            }
                          );
                        }}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="formcss">
                        Maximum Replication factor
                      </Form.Label>

                      <Form.Control
                        type="number"
                        placeholder="Maximum Replication factor"
                        required
                        value={this.state.maximumReplicationFactor}
                        onChange={(e) => {
                          let x = e.target.value;
                          this.setState(
                            {
                              maximumReplicationFactor: x,
                              replicationZeroErr1: false,
                              maxRFError: false,
                            },
                            () => {
                              if (this.state.nodes < x) {
                                this.setState({ maxRFError: true });
                              }
                              if (x < 2) {
                                this.setState({ replicationZeroErr1: true });
                              }
                            }
                          );
                        }}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="formcss">
                        Network Type &nbsp;
                      </Form.Label>

                      <select
                        className="form-control"
                        required
                        onChange={(e) => {
                          this.setState({
                            selectedNetworkType: e.target.value,
                          });
                        }}
                      >
                        <option value="">--Select Network Type--</option>
                        <option value={0}>Open network </option>
                        <option value={1}>Permission network</option>
                      </select>
                    </Form.Group>

                    {this.state.minRFError ? (
                      <Form.Text className="text-muted replicationclr">
                        Minimum replication factor cant be greater than maximum
                        replication factor
                      </Form.Text>
                    ) : null}

                    {this.state.maxErrRF ? (
                      <Form.Text className="text-muted replicationclr">
                        Maximum replication factor cant be lesser than Minimum
                        replication factor
                      </Form.Text>
                    ) : null}

                    {this.state.replicationZeroErr ? (
                      <Form.Text className="text-muted replicationclr">
                        Minimum Replication factor cant be less than 2
                      </Form.Text>
                    ) : null}
                    {this.state.replicationZeroErr1 ? (
                      <Form.Text className="text-muted replicationclr">
                        Maximum Replication factor cant be less than 2
                      </Form.Text>
                    ) : null}

                    {this.state.maxRFError ? (
                      <Form.Text className="text-muted replicationclr">
                        Replication factor should be less than or equal to node
                        count
                      </Form.Text>
                    ) : null}

                    {this.state.totalUpdateNodesErr ? (
                      <Form.Text className="text-muted replicationclr">
                        Please select nodes within {this.state.totalUpdateNodes}
                      </Form.Text>
                    ) : null}

                    {this.state.numberofNodesErr ? (
                      <Form.Text className="text-muted replicationclr">
                        Please check number of nodes
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                )}
                <div>
                  <DisplayConfig
                    nodeValue={this.state.nodeValue}
                    nodeCount={this.state.nodes}
                  />
                </div>
                {this.state.createNetworkErrShow ? (
                  <p style={{ fontSize: "12px", color: "crimson" }}>
                    {this.state.createNetworkErr}
                  </p>
                ) : null}
                {this.state.disableNetworkBtn ? null : (
                  <div className="text-center">
                    {this.state.loading ? (
                      <Button
                        variant="info"
                        disabled
                        size="sm"
                        className="button-css fontweight600"
                      >
                        <Spinner
                          animation="border"
                          style={{ height: "20px", width: "20px" }}
                        />
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        size="sm"
                        variant="info"
                        style={{ fontWeight: "600" }}
                        className="button-css"
                      >
                        {!this.state.disableName
                          ? "Create Network"
                          : "Update Network"}
                      </Button>
                    )}{" "}
                    <Button
                      variant="info"
                      size="sm"
                      style={{ fontWeight: "600" }}
                      onClick={() => this.setState({ modal: true })}
                      disabled={this.state.loading}
                      className="heading-css-bg"
                    >
                      Preview Network
                    </Button>{" "}
                  </div>
                )}

                {!this.state.disableNetworkBtn ? null : (
                  <Maps
                    nodeValue={this.state.partofNodes}
                    message={true}
                    min={this.state.minimumReplicationFactor}
                    max={this.state.maximumReplicationFactor}
                    network={this.state.networkName}
                    preview={"preview"}
                    disableBtn={this.state.disableNetworkBtn}
                  />
                )}

                {!this.state.disableNetworkBtn ? null : (
                  <div>
                    <Table
                      hover
                      responsive
                      borderless
                      style={{
                        animationDuration: "0.8s",
                        animationTimingFunction:
                          "cubic-bezier(.86, .03, .53, 1.01)",
                        fontSize: "12px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Region</th>
                          <th>Node IP</th>
                          <th>Cloud Providers</th>
                          <th>Storage</th>
                          <th>C.P.U</th>
                          <th>Ram</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.partOfNodeVal.map((data, index) => (
                          <tr
                            style={{ borderBottom: "1px solid #c0c0c0" }}
                            key={index}
                          >
                            <td style={{ color: "blue" }} className="tabletd">
                              {data.region}
                            </td>
                            <td className="tabletd">
                              {data.nodeIP ? data.nodeIP : "-"}
                            </td>
                            <td className="tabletd">
                              {data.cloudProvider ? data.cloudProvider : "-"}
                            </td>
                            <td className="tabletd">
                              {data.storage ? data.storage : "-"}
                            </td>
                            <td className="tabletd">
                              {data.cpu ? data.cpu : "-"}
                            </td>
                            <td className="tabletd">
                              {data.ram ? data.ram : "-"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </div>

              <div className="col-md-6" style={{ marginTop: "50px" }}>
                <NetworkForm
                  upto={this.state.upto}
                  nodeCheckBoxFunc={this.nodeCheckBoxFunc}
                  nodeSelectErr={this.state.nodeSelectErr}
                  nodeSelectErrFalse={this.nodeSelectErrFalse}
                  clearNodes={this.state.clearNodes}
                  disableName={this.disableName}
                  updateNetwork={this.state.disableName}
                  setTotalNodeCount={this.setTotalNodeCount}
                />
              </div>
            </div>
          </Form>
        </div>
      );
    }
  }
}

const mapStateToProps = (store) => {
  return {
    plan: store.price.priceState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toastifyEnable: (type, val) => dispatch(toastify(type, val)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Network);
