let nuid = require('@nuid/zk');

/**
 * @param {Object} intermediateProof // proof to create challenge
 * @param {String} _passPhrase // secret phrase to solve challenge
 * @returns 
 */
exports.validate = function(intermediateProof,_passPhrase) {
    let oneTimeChallenge = nuid.challengeFromCredential(intermediateProof);
    let generatedProof = nuid.proofFromSecret(oneTimeChallenge, _passPhrase);

    let aToken = generatedProof;
    const authStatus = nuid.proofIsVerified(generatedProof)
    delete aToken['pub'];
    delete aToken['keyfn'];
    return {
        authToken: aToken, // authentication token required for getting wallet keystore
        authStatus: authStatus
    }
}

exports.generate = function(_passPhrase) {
    let intermediateProofResolver= nuid.proofFromSecret(_passPhrase);
    var saltedZkProof = nuid.credentialFromProof(intermediateProofResolver);
    let zkProofDocument = JSON.stringify(saltedZkProof);
    return zkProofDocument;
}
