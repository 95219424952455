const { randomBytes } = require('crypto')
const aesjs = require('aes-js');
const secp256k1 = require('secp256k1')
const { Crypt } = require("hybrid-crypto-js");
const CryptoJS = new Crypt();

exports.getMoiCipherParams = function(_secKey) {
    const rawSecKey = Buffer.from(_secKey.substring(2),'hex');
    let pubKey = secp256k1.publicKeyCreate(rawSecKey).slice(1);

    const iv = randomBytes(16);
    const secretBytesForPub = randomBytes(16);
    const secretBytesForPriv = randomBytes(32);
    
    const aesCbcPub = new aesjs.ModeOfOperation.cbc(pubKey, iv);
    const aesCbcPriv = new aesjs.ModeOfOperation.cbc(rawSecKey, iv);

    const cipherWithPub = aesCbcPub.encrypt(secretBytesForPub);
    const cipherWithPriv = aesCbcPriv.encrypt(secretBytesForPriv);

    return {
        iv: aesjs.utils.hex.fromBytes(iv),
        cipher: {
            pub: aesjs.utils.hex.fromBytes(cipherWithPub),
            priv: aesjs.utils.hex.fromBytes(cipherWithPriv),
        }
    }
}

exports.jsmidEncrypt = async function(secString,pubInPem) {
    try {
        return (await CryptoJS.encrypt(pubInPem,secString));
    }catch(err) {
        throw new Error(err.message)
    }
}

exports.jsmidDecrypt = async function(privKeyInPem,_secDigest) {
    try {
        let _token = await CryptoJS.decrypt(privKeyInPem,_secDigest);
        return _token.message;
    }catch(err) {
        throw new Error(err.message)
    }
}
