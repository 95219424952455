const axios = require('axios');
const MoiRequestWrapper = require('./reqWrapper');
const {mEncode} = require('./general');

exports.provisionNewApp = async function(_igcPathParams,_nameSpace,_createdBy,_appReference) {
    try {    
        let encodedAppName = mEncode('moiApp'+_nameSpace);
        return await MoiRequestWrapper({
            reqRouteType: 'app',
            reqRouteName: 'add',
            reqQueryParams: [],
            reqMethod: 'POST',
            reqPayload: {
                _igcPath: _igcPathParams,
                _nameSpace: encodedAppName,
                _appOwner: _createdBy,
                _appID: _appReference
            }
        })
    }catch(e) {
        throw new Error(e.message);
    }
}

exports.registerUser = async function(_username,_address) {
    try {    
        return await MoiRequestWrapper({
            reqRouteType: 'identity',
            reqRouteName: 'register',
            reqQueryParams: [],
            reqMethod: 'POST',
            reqPayload: {
                "_address": _address,
                "_username": _username
            }
        })
    }catch(e) {
        throw new Error(e.message);
    }
}

exports.provisionNewAccount = async function(defAddress,derived,nameSpace,authToken) {
    try {    
        let encodedAppName = mEncode('moiApp'+nameSpace);
        return await MoiRequestWrapper({
            reqRouteType: 'app',
            reqRouteName: 'adduser',
            reqQueryParams: [],
            reqMethod: 'POST',
            reqPayload:  {
                _default: defAddress,
                _derived: derived,
                _nameSpace: encodedAppName,
                _requireAuth: false
            },
            reqHeaders: authToken
        })
    }catch(e) {
        throw new Error(e.message);
    }
}

exports.revokeAccess = async function(defAddress,nameSpace,userAT) {
    try {    
        let encodedAppName = mEncode('moiApp'+nameSpace);
        return await MoiRequestWrapper({
            reqRouteType: 'identity',
            reqRouteName: 'revoke',
            reqQueryParams: [],
            reqMethod: 'POST',
            reqPayload: {
                _address: defAddress,
                _nameSpace: encodedAppName
            },
            reqHeaders: userAT
        })
    }catch(e) {
        throw new Error(e.message);
    }
}
