import React, { Component } from "react";
import { Card, Form, Button, Modal, Spinner } from "react-bootstrap";
import "./style.css";
import { getParticularNetworkDetails } from "../../common/networkapicalls";
import { Link } from "react-router-dom";
import crypto from "crypto";
import { randomGenNumFunc, createAppInMoibit } from "./utils";
import { toastify } from "../../action";

class CreateApp extends Component {
  state = {
    appName: "",
    appDesc: "",
    appMaxStorage: 0,
    appReplication: 2,
    loading: false,
    error: false,
    selectNetwork: "",
    selectEncryption: "",
    selectEncryptionText: "",
    showPlan: false,
    replicationErr: false,
    replicationFactor: 0,
    replicationCountErr: false,
    plan: "",
    partOferr: false,
    partOferrVal: "",
    replicationErrVal: "",
    networkReplicationFactorVal: 0,
    networkRFError: false,
    randomBytes: "",
    createAppErr: false,
  };

  setValue = (name, value) => {
    this.setState({ [name]: value, networkRFError: false });
  };

  selectNetwork = (e) => {
    let x = e.target.value;
    if (x !== "Moi_Net") {
      this.setState({
        selectNetwork: x.split("_")[0],
        replicationFactor: x.split("_")[1],
        partOferr: false,
      });
    } else {
      this.setState({ selectNetwork: x, partOferr: false });
    }
  };

  selectEncryption = (e) => {
    this.setState(
      {
        selectEncryption: e.target.value,
        showPlan: false,
        selectEncryptionText: e.target.selectedOptions[0].text,
        networkRFError: false,
      },
      () => {
        if (this.state.selectEncryption == 3) {
          this.generateRandom();
        }
      }
    );
  };

  createAppFunc = async () => {
    try {
      this.setState({ loading: true });
      let networkID = this.state.selectNetwork;
      let _getNetworkDetails = await getParticularNetworkDetails(networkID);
      if (_getNetworkDetails.data !== "developer validation failed") {
        if (
          this.state.appReplication <= _getNetworkDetails.maxReplicationFactor
        ) {
          // if (this.props.plan >= 1) {
          //   this.state.appReplication = 2;
          // } else {
          //   this.state.appReplication = parseInt(this.state.appReplication);
          // }
          let options = {
            appName: this.state.appName,
            appDesc: this.state.appDesc,
            appReplication: parseInt(this.state.appReplication),
            signature: JSON.parse(
              sessionStorage.getItem("authToken")
            ).value.split("_")[1],
            appNonce: JSON.parse(
              sessionStorage.getItem("authToken")
            ).value.split("_")[0],
            defAddress: JSON.parse(sessionStorage.getItem("defAddress")).value,
            network: this.state.selectNetwork,
            encryptionType: parseInt(this.state.selectEncryption),
            customKey: this.state.randomBytes,
          };
          let _createAppRes = await this.createAppFunctionCall(options);
          if (_createAppRes == true) {
            this.setState({ loading: false });
            this.props.successMsg();
          } else {
            this.setState({ loading: false });
            this.props.errMsg(_createAppRes);
          }
        } else {
          this.setState({
            networkReplicationFactorVal:
              _getNetworkDetails.maxReplicationFactor,
            networkRFError: true,
            loading: false,
          });
        }
      }
    } catch (err) {
      this.setState({
        replicationErrVal: err.message,
        replicationCountErr: true,
        loading: false,
      });
    }
  };

  createAppFunctionCall = async (options) => {
    try {
      let igcAccountParameters = {
        bcID: 4210818,
        orgID: 1729,
        appID: randomGenNumFunc().toString(),
        usrGrpID: 0,
      };

      let _resValue = await window.moi_id.createMoiApp(
        igcAccountParameters,
        options.appName
      );
      if (_resValue) {
        await window.moi_id.updateAppDetails(
          options.appName,
          options.appDesc,
          {}
        );
        let _res = await createAppInMoibit(
          _resValue,
          options.appName,
          options.appDesc,
          options.appMaxStorage,
          options.appReplication,
          options.signature,
          options.appNonce,
          options.defAddress,
          options.network,
          options.encryptionType,
          options.customKey
        );
        if (_res.code == 200) {
          toastify("info", "App Created successfully");
          return true;
        } else if (_res.code == 523) {
          toastify("err", "App name already exists");
          this.setState({ createAppErr: true, loading: false });
          return _res.message;
        } else {
          toastify("err", _res.message);
          return _res;
        }
      }
    } catch (err) {
      //do nothing
    }
  };

  handleCreateApp = async (e) => {
    e.preventDefault();
    try {
      this.setState({
        replicationCountErr: false,
        replicationErr: false,
        replicationErrVal: "",
      });
      if (this.state.appReplication < 2) {
        this.setState({ replicationErr: true, loading: false });
        return;
      }

      // working code
      if (
        this.state.selectEncryption == 0 ||
        this.state.selectEncryption == -1
      ) {
        await this.createAppFunc();
      } else if (this.state.selectEncryption == 1) {
        if (this.props.plan >= 1) {
          await this.createAppFunc();
        } else {
          setTimeout(() => {
            this.setState({ showPlan: true, loading: false });
          }, 2000);
        }
      } else if (this.state.selectEncryption == 3) {
        if (this.props.plan >= 2) {
          await this.createAppFunc();
        } else {
          setTimeout(() => {
            this.setState({ showPlan: true, loading: false });
          }, 2000);
        }
      } else if (
        this.state.selectEncryption == 2 ||
        this.state.selectEncryption == 4
      ) {
        if (this.props.plan >= 3) {
          await this.createAppFunc();
        } else {
          setTimeout(() => {
            this.setState({ showPlan: true, loading: false });
          }, 2000);
        }
      } else {
        this.setState({ partOferr: true, loading: false });
      }
    } catch (err) {
      this.setState({ loading: false, error: true });
      setTimeout(() => {
        this.setState({ error: false });
      }, 3000);
    }
  };

  generateRandom = () => {
    try {
      crypto.randomBytes(16, (err, buf) => {
        if (err) {
          return;
        }
        this.setState({ randomBytes: buf.toString("hex") });
      });
    } catch (err) {
      // do nothing
    }
  };

  handleClose = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <p className="createapplabel">Create App</p>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Card>
                  <Card.Body>
                    <Form onSubmit={this.handleCreateApp}>
                      <Form.Group>
                        <Form.Label>Name of the app</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) =>
                            this.setValue("appName", e.target.value)
                          }
                          required
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>About the app</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          onChange={(e) =>
                            this.setValue("appDesc", e.target.value)
                          }
                          required
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Control
                          as="select"
                          custom
                          onChange={this.selectNetwork}
                          required
                        >
                          <option value="">--Select Network--</option>
                          <optgroup label="Open Network">
                            {this.props.networkList.length > 0 &&
                            this.props.networkList !== "error" ? (
                              this.props.networkList.map((data, index) =>
                                data.network_name !== "" &&
                                (data.type == 0 || data.type == 2) ? (
                                  <option
                                    value={
                                      data.network_id +
                                      "_" +
                                      data.replication_factor
                                    }
                                    key={index}
                                  >
                                    {data.network_name}
                                  </option>
                                ) : null
                              )
                            ) : (
                              <option value="">--None--</option>
                            )}
                          </optgroup>

                          <optgroup label="Permissioned Network">
                            {this.props.networkList.length > 0 &&
                            this.props.networkList !== "error" ? (
                              this.props.networkList.map((data, index) =>
                                data.network_name !== "" &&
                                (data.type == 1 || data.type == 3) ? (
                                  <option
                                    value={
                                      data.network_id +
                                      "_" +
                                      data.replication_factor
                                    }
                                    key={index}
                                  >
                                    {data.network_name}
                                  </option>
                                ) : null
                              )
                            ) : (
                              <option value="">--None--</option>
                            )}
                          </optgroup>
                        </Form.Control>

                        {this.state.partOferr ? (
                          <Form.Text
                            style={{
                              margin: "10px",
                              marginLeft: "0px",
                              color: "crimson",
                              fontSize: "12px",
                            }}
                          >
                            {"You are not part of this network"}
                          </Form.Text>
                        ) : null}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Replication factor</Form.Label>
                        <Form.Control
                          type="number"
                          onChange={(e) =>
                            this.setValue("appReplication", e.target.value)
                          }
                          required
                          value={this.state.appReplication}
                          disabled={this.props.plan <= 1}
                        />
                      </Form.Group>

                      {this.state.selectEncryption == 3 ? (
                        <Form.Group>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Form.Control
                              type="text"
                              placeholder="Personalised Encryption"
                              style={{ width: "80%" }}
                              value={this.state.randomBytes}
                            />
                            <Button
                              variant="info"
                              size="sm"
                              style={{ fontWeight: "600" }}
                              onClick={this.generateRandom}
                            >
                              <i
                                className="fa fa-refresh"
                                aria-hidden="true"
                              ></i>{" "}
                              Generate
                            </Button>
                          </div>
                        </Form.Group>
                      ) : null}

                      <Form.Group>
                        <Form.Control
                          as="select"
                          custom
                          onChange={this.selectEncryption}
                          required
                        >
                          <option value="">-- Select encryption type --</option>
                          <option value={-1}>No encryption</option>
                          <option value={0}>Default</option>
                          <optgroup label="Enterprise Encryption(Server)">
                            <option value={1}>Developer key</option>
                            <option value={2}>End User key</option>
                            <option value={3}>Custom key</option>
                          </optgroup>

                          <option value={4}>
                            End-to-End Encryption(Client)
                          </option>
                        </Form.Control>
                      </Form.Group>

                      {this.state.replicationErr ? (
                        <Form.Text
                          style={{
                            margin: "10px",
                            marginLeft: "0px",
                            color: "crimson",
                            fontSize: "12px",
                          }}
                        >
                          {
                            "MoiBit make sure high data availability of the file by keeping every file atleast in 2 copies, So replication factor cannot be < 2"
                          }
                        </Form.Text>
                      ) : null}
                      {this.state.networkRFError ? (
                        <Form.Text
                          style={{
                            margin: "10px",
                            marginLeft: "0px",
                            color: "crimson",
                            fontSize: "12px",
                          }}
                        >
                          {`Replication factor must be 2 to ${this.state.networkReplicationFactorVal}`}
                        </Form.Text>
                      ) : null}

                      {this.state.replicationCountErr ? (
                        <Form.Text
                          style={{
                            margin: "10px",
                            marginLeft: "0px",
                            color: "crimson",
                            fontSize: "12px",
                          }}
                        >
                          {this.state.replicationErrVal}
                        </Form.Text>
                      ) : null}

                      {this.state.showPlan ? (
                        <div>
                          <Form.Text
                            style={{
                              margin: "10px",
                              marginLeft: "0px",
                              color: "crimson",
                              fontSize: "14px",
                            }}
                          >
                            Seems like your current plan is{" "}
                            <span style={{ fontWeight: "600" }}>
                              {this.props.plan == 0
                                ? "Developer"
                                : this.props.plan == 2
                                ? "Start Up"
                                : this.props.plan == 3
                                ? "Business"
                                : this.props.plan == 4
                                ? "Enterprise"
                                : null}
                            </span>
                            . To enable{" "}
                            <i style={{ fontWeight: "600" }}>
                              {this.state.selectEncryptionText}
                            </i>{" "}
                            in your application, you must be under{" "}
                            <span style={{ fontWeight: "600" }}>
                              {this.state.selectEncryption == 1
                                ? "Start Up"
                                : this.state.selectEncryption == 3
                                ? "Start Up"
                                : this.state.selectEncryption == 2 ||
                                  this.state.selectEncryption == 4
                                ? "Business"
                                : null}
                            </span>{" "}
                            tier.
                          </Form.Text>
                          <div
                            style={{
                              textAlign: "center",
                              marginBottom: "15px",
                            }}
                          >
                            <Link to="/pricing">
                              {" "}
                              <Button
                                size="sm"
                                variant="info"
                                style={{
                                  fontWeight: "600",
                                  background: "darkblue",
                                  border: "1px solid darkblue",
                                }}
                              >
                                Upgrade to{" "}
                                {this.state.selectEncryption == 1
                                  ? "Start Up"
                                  : this.state.selectEncryption == 3
                                  ? "Start Up"
                                  : this.state.selectEncryption == 2 ||
                                    this.state.selectEncryption == 4
                                  ? "Business"
                                  : null}
                              </Button>
                            </Link>
                          </div>
                        </div>
                      ) : null}

                      <p className="createappnote">
                        The app creation is powered by
                        <span style={{ fontWeight: "600" }}> Moi_ID</span>. So
                        we highly recommended you to let your users to have
                        Moi_ID like you for more personalization and user's
                        privacy.
                      </p>
                      <div style={{ textAlign: "right" }}>
                        {!this.state.loading ? (
                          <Button
                            type="submit"
                            size="sm"
                            variant="info"
                            style={{ fontWeight: "600" }}
                            className="button-css"
                          >
                            Create
                          </Button>
                        ) : (
                          <Button
                            variant="info"
                            style={{ fontWeight: "600" }}
                            size="sm"
                            disabled
                            className="button-css"
                          >
                            <Spinner
                              animation="border"
                              style={{ height: "20px", width: "20px" }}
                            />
                          </Button>
                        )}
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CreateApp;
