import { GET_PENDING_INVITATION_LIST } from "../action/types";

const initialState = {
  pendingInvitationList: [],
};

export const Home = (state = initialState, action) => {
  switch (action.type) {
    case GET_PENDING_INVITATION_LIST:
      return {
        ...state,
        pendingInvitationList: action.value,
      };
    default:
      return state;
  }
};
