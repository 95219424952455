import React from "react";
import Logo from "../../assets/moibit_logo.png";
import "./style.css";
import { NavLink } from "react-router-dom";
import { sessionClose, firstLoginCheckPricing, toastify } from "../../action";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import Default from "../../assets/default.png";
import { deleteBearerToken } from "../../common/devapicalls";

class Sidebar extends React.Component {
  state = {
    listClickable: true,
  };

  handlerLogOut = () => {
    try {
      let token = JSON.parse(sessionStorage.getItem("bearerToken")).value;
      deleteBearerToken(token);
      this.props.sessionClose();
      localStorage.clear();
      sessionStorage.removeItem("bearerToken");
      sessionStorage.removeItem("defAddress");
      sessionStorage.removeItem("authToken");
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("pricing");
      sessionStorage.removeItem("pending");

      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  render() {
    return (
      <div className="nav-side-menu">
        <div className="brand">
          <div>
            <a href="https://www.moibit.io" target="_blank">
              {" "}
              <img
                src={Logo}
                style={{
                  height: "120px",
                  width: "120px",
                  objectFit: "contain",
                }}
              />
            </a>
          </div>
        </div>

        <i
          className="fa fa-bars fa-2x toggle-btn"
          data-toggle="collapse"
          data-target="#menu-content"
        ></i>

        <div className="menu-list">
          <div style={{ textAlign: "center", margin: "20px" }}>
            <img
              className="profile-pic-box"
              src={Default}
              alt="Profile image"
            />
          </div>

          <ul id="menu-content" className="menu-content collapse out">
            <li
              data-toggle="collapse"
              data-target="#console"
              className="collapsed"
            >
              <a href="#">
                <i className="fa fa-globe fa-lg"></i> Developer console{" "}
                <span className="arrow"></span>
              </a>
            </li>
            {this.props.listClickable ? (
              <ul className="sub-menu collapse" id="console">
                <li>
                  <NavLink to="/home" exact activeClassName="active">
                    <i className="fa fa-home fa-lg"></i>{" "}
                    <span style={{ padding: "5px" }}>Console</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/nodes" exact activeClassName="active">
                    <i className="fa fa-square-o fa-lg"></i>{" "}
                    <span style={{ padding: "5px" }}>Nodes</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/network" exact activeClassName="active">
                    <i className="fa fa-sitemap fa-lg"></i>{" "}
                    <span style={{ padding: "5px" }}>Network</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/pos" exact activeClassName="active">
                    <i className="fa fa-list fa-lg"></i>{" "}
                    <span style={{ padding: "5px" }}>Proof of Storage</span>
                  </NavLink>
                </li>
              </ul>
            ) : (
              <ul className="sub-menu collapse" id="console">
                <li>
                  <span>
                    <i className="fa fa-home fa-lg"></i>{" "}
                    <span style={{ padding: "5px" }}>Console</span>
                  </span>
                </li>
                <li>
                  <span>
                    <i className="fa fa-square-o fa-lg"></i>{" "}
                    <span style={{ padding: "5px" }}>Nodes</span>
                  </span>
                </li>

                <li>
                  <span>
                    <i className="fa fa-sitemap fa-lg"></i>{" "}
                    <span style={{ padding: "5px" }}>Network</span>
                  </span>
                </li>
                <li>
                  <span>
                    <i className="fa fa-list fa-lg"></i>{" "}
                    <span style={{ padding: "5px" }}>Proof of Storage</span>
                  </span>
                </li>
              </ul>
            )}

            <li
              data-toggle="collapse"
              data-target="#playground"
              className="collapsed"
            >
              <a href="#">
                <i className="fa fa-cogs" aria-hidden="true"></i> Playground{" "}
                <span className="arrow"></span>
              </a>
            </li>

            {this.props.listClickable ? (
              <ul className="sub-menu collapse" id="playground">
                <li>
                  <NavLink to="/fileexplorer" exact activeClassName="active">
                    <i className="fa fa-folder-open-o fa-lg"></i>{" "}
                    <span style={{ padding: "5px" }}>File Explorer</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/restore" exact activeClassName="active">
                    <i className="fa fa-refresh fa-lg"></i>{" "}
                    <span style={{ padding: "5px" }}>Recovery storage</span>
                  </NavLink>
                </li>
              </ul>
            ) : (
              <ul className="sub-menu collapse" id="playground">
                <li>
                  <span>
                    <i className="fa fa-folder-open-o fa-lg"></i>{" "}
                    <span style={{ padding: "5px" }}>File Explorer</span>
                  </span>
                </li>

                <li>
                  <span>
                    <i className="fa fa-refresh fa-lg"></i>{" "}
                    <span style={{ padding: "5px" }}>Recovery storage</span>
                  </span>
                </li>
              </ul>
            )}

            {this.props.listClickable ? (
              <li>
                <NavLink to="/pricing" exact activeClassName="active">
                  <i className="fa fa-money fa-lg"></i>{" "}
                  <span style={{ padding: "5px" }}>Pricing</span>
                </NavLink>
              </li>
            ) : (
              <li>
                <span class="marginLeft15">
                  <i className="fa fa-money fa-lg"></i>{" "}
                  <span style={{ padding: "5px" }}>Pricing</span>
                </span>
              </li>
            )}

            {this.props.listClickable ? (
              <li>
                <NavLink to="/payment" exact activeClassName="active">
                  <i className="fa fa-history" aria-hidden="true"></i>
                  <span style={{ padding: "5px" }}>Payment History</span>
                </NavLink>
              </li>
            ) : (
              <li>
                <span class="marginLeft15">
                  <i className="fa fa-history" aria-hidden="true"></i>
                  <span style={{ padding: "5px" }}>Payment History</span>
                </span>
              </li>
            )}

            {/* {this.props.listClickable ? 
                            <li>
                                <NavLink to="/migration" exact activeClassName="active">
                                    <i className="fa fa-long-arrow-right"></i>  <span style={{ padding: "5px" }}>Migration</span>
                                </NavLink>
                            </li> :
                            <li>
                                <span class="marginLeft15">
                                    <i className="fa fa-long-arrow-right"></i>  <span style={{ padding: "5px" }}>Migration</span>
                                </span>
                            </li>
                        } */}

            {this.props.listClickable ? (
              <li>
                <NavLink to="/resources" exact activeClassName="active">
                  <i className="fa fa-flag-o fa-lg"></i>{" "}
                  <span style={{ padding: "5px" }}>Resources</span>
                </NavLink>
              </li>
            ) : (
              <li>
                {" "}
                <span class="marginLeft15">
                  <i className="fa fa-flag-o fa-lg"></i>{" "}
                  <span style={{ padding: "5px" }}>Resources</span>
                </span>
              </li>
            )}

            {this.props.listClickable ? (
              <li>
                <NavLink
                  to="/"
                  exact
                  activeClassName="active"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handlerLogOut();
                  }}
                >
                  <i className="fa fa-power-off fa-lg"></i>{" "}
                  <span style={{ padding: "5px" }}>Logout</span>
                </NavLink>
              </li>
            ) : (
              <li>
                <span class="marginLeft15">
                  <i className="fa fa-power-off fa-lg"></i>{" "}
                  <span style={{ padding: "5px" }}>Logout</span>
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    sessionCheck: store.sessionCheck.sessionCheck,
    listClickable: store.price.firstTimeLoginCheck,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sessionClose: () => dispatch(sessionClose()),
    firstLoginCheckPricing: () => dispatch(firstLoginCheckPricing()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
