import axios from "axios";

export const storageUsed = async (
  networkID,
  storageLevel,
  appID,
  enduserID
) => {
  try {
    if (networkID == undefined) {
      networkID = "default";
    }
    if (appID == undefined) {
      appID = "default";
    }
    if (enduserID == undefined) {
      enduserID = "default";
    }
    let header;
    let param;
    if (storageLevel == 1) {
      header = {
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("bearerToken")).value
        }`,
      };
    } else if (storageLevel == 2) {
      if (appID == undefined) {
        header = {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        };
      } else {
        header = {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        };
      }
    } else if (storageLevel == 4) {
      header = {
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("bearerToken")).value
        }`,
      };
    } else if (storageLevel == 5) {
      header = {
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("bearerToken")).value
        }`,
      };
    }
    return (
      await axios({
        url:
          process.env.REACT_APP_MOIBIT_API_URL +
          "net/" +
          networkID +
          "/app/" +
          appID +
          "/enduser/" +
          enduserID +
          "/storageused",
        method: "POST",
        headers: header,
        params: param,
        data: {
          level: storageLevel,
        },
      })
    ).data.data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

// getkeystobedeleted
export const getKeysToBeDeleted = async (data) => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "getkeystobedeleted";
    return (
      await axios({
        url: tempUrl,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: data,
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

//recoverystorage
export const recoveryStorage = async (data) => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "recoverstorage";
    return (
      await axios({
        url: tempUrl,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: { keys: [data] },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const getPostCallCount = async () => {
  try {
    const getLogsURL = process.env.REACT_APP_MOIBIT_API_URL + "getprovenance";
    return (
      await axios({
        url: getLogsURL,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: {
          developerKey: JSON.parse(sessionStorage.getItem("defAddress")).value,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const contactUs = async (data) => {
  try {
    const tempUrl = process.env.REACT_APP_MOI_ID_API_URL + "misc/contactus";
    return (
      await axios({
        url: tempUrl,
        method: "POST",
        data: data,
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const fetchbandwidth = async (start_time, end_time) => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "fetchbandwidth";
    return (
      await axios({
        url: tempUrl,
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          start_time: start_time,
          end_time: end_time,
        },
      })
    ).data.data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};
