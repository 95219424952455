import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { getPrice } from "../../action";
import { connect } from "react-redux";

toast.configure({
  draggable: false,
  closeButton: false,
  position: toast.POSITION.BOTTOM_LEFT,
});
class CreditCard extends Component {
  state = {
    plan: 0,
    successMessage: false,
    redirect: false,
  };
  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    this.setState({ plan: params.get("plan") });
  }

  upgradePlan = async () => {
    try {
      await axios({
        url: process.env.REACT_APP_MOIBIT_API_URL + "developer/updateplan",
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: {
          plan: parseInt(this.state.plan),
        },
      });
      toast.info("Plan upgraded successfully", {
        autoClose: 2000,
        draggable: false,
        closeButton: false,
        position: toast.POSITION.BOTTOM_LEFT,
      });
      this.props.getPrice();
      setTimeout(() => {
        this.setState({ redirect: true });
      }, 3000);
    } catch (err) {}
  };
  render() {
    return this.state.redirect ? (
      <Redirect to="/pricing" />
    ) : (
      <div className="col-md-6 offset-md-3" style={{ marginTop: "40px" }}>
        <ToastContainer draggable={false} closeOnClick={false} />
        <div className="card card-outline-secondary">
          <div className="card-body">
            <h3 className="text-center">Payment details</h3>
            <hr />
            <div style={{ display: "flex" }}>
              {" "}
              <div style={{ fontWeight: "bold" }}>Upgrading to &nbsp;</div>{" "}
              <div>
                {" "}
                {this.state.plan == 1 ? (
                  <p style={{ color: "blueviolet" }}>Basic</p>
                ) : this.state.plan == 2 ? (
                  <p style={{ color: "yellowgreen" }}>Gold</p>
                ) : this.state.plan == 3 ? (
                  <p style={{ color: "yellowgreen" }}>Premium</p>
                ) : this.state.plan == 4 ? (
                  <p style={{ color: "yellowgreen" }}>Enterprise</p>
                ) : null}
              </div>
            </div>
            planName
            <div className="form-group">
              <label for="cc_name">Card Holder's Name</label>
              <input
                type="text"
                className="form-control"
                id="cc_name"
                pattern="\w+ \w+.*"
                title="First and last name"
              />
            </div>
            <div className="form-group">
              <label>Card Number</label>
              <input
                type="text"
                className="form-control"
                autocomplete="off"
                maxlength="20"
                pattern="\d{16}"
                title="Credit card number"
              />
            </div>
            <div className="form-group row">
              <label className="col-md-4">Expiry Date</label>
              <label className="col-md-4"></label>
              <label className="col-md-4">CVV</label>
              <div className="col-md-4">
                <select className="form-control" name="cc_exp_mo" size="0">
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>
              <div className="col-md-4">
                <select className="form-control" name="cc_exp_yr" size="0">
                  <option>2018</option>
                  <option>2019</option>
                  <option>2020</option>
                  <option>2021</option>
                  <option>2022</option>
                </select>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  autocomplete="off"
                  maxlength="3"
                  pattern="\d{3}"
                  title="Three digits at back of your card"
                  placeholder="CVC"
                />
              </div>
            </div>
            <hr />
            <div className="form-group row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <button
                  type="submit"
                  className="btn btn-success btn-lg btn-block"
                  style={{ background: "#3E4F8B", border: "1px solid #3E4F8B" }}
                  onClick={this.upgradePlan}
                >
                  Add Credit Card
                </button>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPrice: () => dispatch(getPrice()),
  };
};

export default connect(null, mapDispatchToProps)(CreditCard);
