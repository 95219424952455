import React, { Component } from "react";
import { memoryStore } from "../../index";
import { Card, Navbar, Nav, Form, Button, Spinner } from "react-bootstrap";
import { zoomInLeft } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { Redirect } from "react-router-dom";
import "./style.css";
import Cookie from "js-cookie";
import Logo from "../../assets/logo_moiid.png";
import { updateDev, registerBearerToken } from "../../common/devapicalls";
import base64url from "base64url";

const styles = {
  bounce: {
    animation: "x 2s",
    animationName: Radium.keyframes(zoomInLeft, "zoomInLeft"),
  },
};

class Login extends Component {
  state = {
    username: "",
    password: "",
    loading: false,
    invalidCreds: false,
    redirectsHome: false,
    redirectAuth: false,
    checkMoiNet: false,
    encodedToken: "",
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await sessionStorage.removeItem("name");
    this.setState({ loading: true }, async () => {
      try {
        let _loginCheck = await window.moi_id.login(
          this.state.username.toLowerCase(),
          this.state.password,
          { unlockWallet: true }
        );
        if (_loginCheck) {
          try {
            let _getAccountsRes = await window.moi_id.getAccounts();
            let _moibitCheck = _getAccountsRes.filter(
              (data) => data.namespace == "moibit"
            );
            if (_moibitCheck.length > 0) {
              let currentDate = new Date();
              let updatedNonceTime = currentDate.getTime() / 1000 + 86400;
              let numString = updatedNonceTime.toString();
              let updatedNonce = numString.split(".");
              let _res = await window.moi_id.getAuthToken("" + updatedNonce[0]);
              let authToken = _res.message + "_" + _res.signature;

              // Store values in in-memory object
              const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours from now

              sessionStorage.setItem(
                "authToken",
                JSON.stringify({ value: authToken, expiresAt: expirationTime })
              );
              sessionStorage.setItem(
                "defAddress",
                JSON.stringify({ value: _res.id, expiresAt: expirationTime })
              );
              sessionStorage.setItem(
                "name",
                JSON.stringify({
                  value: this.state.username,
                  expiresAt: expirationTime,
                })
              );
              sessionStorage.setItem(
                "pending",
                JSON.stringify({ value: false, expiresAt: expirationTime })
              );
              sessionStorage.setItem(
                "pricing",
                JSON.stringify({ value: true, expiresAt: expirationTime })
              );

              // base64URL encoding
              const defAddress = _res.id;
              const nonce = _res.message;
              const signature = _res.signature;
              const name =
                this.state.username + "-NameGeneratedFromBrowserDuringLogin";
              const obj = {
                defAddress,
                nonce,
                signature,
                name,
              };

              const KEY = JSON.stringify(obj);
              let encodedKey = base64url(KEY);
              let res = await registerBearerToken(encodedKey);
              this.setState({
                encodedToken: encodedKey,
              });

              sessionStorage.setItem(
                "bearerToken",
                JSON.stringify({ value: encodedKey, expiresAt: expirationTime })
              );

              this.setState({ loading: false, redirectsHome: true });
            } else {
              this.setState({ redirectAuth: true });
            }
          } catch (err) {
            //namespace error
            this.setState({ redirectAuth: true });
          }
        } else {
          this.setState({ loading: false, invalidCreds: true });

          setTimeout(() => {
            this.setState({ invalidCreds: false });
          }, 5000);
        }
      } catch (err) {
        this.setState({ loading: false, invalidCreds: true });

        setTimeout(() => {
          this.setState({ invalidCreds: false });
        }, 5000);
      }
    });
  };
  setValue = (name, value) => {
    this.setState({ [name]: value });
  };

  auth = async () => {
    const tokenString = sessionStorage.getItem("authToken");

    if (!tokenString) {
      return false;
    }
    const token = JSON.parse(tokenString).value;
    return token && token.length > 10 && (await Cookie.get("joinedmoibit"));
  };

  render() {
    const authVal = this.auth();
    if (!(authVal == "true")) {
      if (this.state.redirectAuth) {
        return (
          <Redirect
            to={
              "moibit/join?name=" +
              this.state.username +
              "&id=" +
              this.state.checkMoiNet
            }
          />
        );
      } else if (this.state.redirectsHome) {
        return <Redirect to="/home" />;
      } else {
        return (
          <div style={{ overflow: "hidden", height: "auto" }}>
            <div className="galaxy">
              <div className="stars"></div>
            </div>
            <>
              <Navbar
                variant="dark"
                style={{ backgroundColor: "transparent", margin: "0px 4vw" }}
              >
                <Navbar.Brand href="#home">
                  <img
                    src={require("../../assets/moibit_logo.png")}
                    style={{ width: "120px" }}
                  />
                </Navbar.Brand>
                <Nav className="mr-auto"></Nav>
                <p
                  style={{
                    marginTop: "1rem",
                    color: "#fff",
                    fontWeight: "600",
                  }}
                >
                  Don't have Moi_ID?{" "}
                  <a
                    href={
                      process.env.REACT_APP_MOI_ID_URL +
                      "?redirect=" +
                      process.env.REACT_APP_MOIBIT_URL
                    }
                    target="_blank"
                  >
                    <span style={{ color: "#06fd3f", marginLeft: "2px" }}>
                      Build now
                    </span>
                  </a>
                </p>
              </Navbar>
            </>

            <div className="container">
              <div className="row" style={{ marginTop: "25px" }}>
                <div className="col-md-6" id="showbannerimg">
                  <div className="imgalign">
                    <img
                      src={require("../../assets/banner.png")}
                      alt="..."
                      className="imgval"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <StyleRoot>
                    <div style={styles.bounce}>
                      <Card className="loginCard">
                        <Card.Body>
                          <Card.Title className="text-center">
                            <img
                              src={Logo}
                              style={{
                                width: "150px",
                                height: "150px",
                                objectFit: "contain",
                              }}
                            />
                          </Card.Title>

                          <Form
                            className="loginform"
                            onSubmit={this.handleSubmit}
                            style={{ marginBottom: "5px" }}
                          >
                            <Form.Control
                              type="text"
                              placeholder="Username"
                              className="logintextfield"
                              required
                              onChange={(e) =>
                                this.setValue("username", e.target.value)
                              }
                              autoFocus
                            />
                            <Form.Control
                              type="password"
                              placeholder="Password"
                              className="logintextfield"
                              style={{ marginTop: "0px" }}
                              required
                              onChange={(e) =>
                                this.setValue("password", e.target.value)
                              }
                            />
                            {!this.state.loading ? (
                              <Button
                                type="submit"
                                className="loginsubmit-login"
                                size="sm"
                                style={{ fontWeight: "600" }}
                              >
                                LOGIN
                              </Button>
                            ) : (
                              <div
                                style={{
                                  textAlign: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <Spinner
                                  animation="border"
                                  variant="primary"
                                  style={{ height: "1.5rem", width: "1.5rem" }}
                                />
                              </div>
                            )}
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "10px",
                                fontSize: "12px",
                                color: "#222",
                              }}
                            >
                              Problem with password?
                              <a
                                href={
                                  process.env.REACT_APP_MOI_ID_URL +
                                  "?redirect=" +
                                  process.env.REACT_APP_MOIBIT_URL
                                }
                                target="_blank"
                                style={{ color: "#e9534c", fontWeight: "600" }}
                              >
                                {" "}
                                Reset here
                              </a>
                            </div>
                            {this.state.invalidCreds ? (
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "10px",
                                  fontSize: "14px",
                                  color: "#e9534c",
                                  fontWeight: "600",
                                }}
                              >
                                Authentication failed
                              </p>
                            ) : null}
                          </Form>
                        </Card.Body>
                      </Card>
                    </div>
                  </StyleRoot>
                </div>

                <div className="col-md-6" id="showimg">
                  <div className="imgalign">
                    <img
                      src={require("../../assets/banner.png")}
                      alt="..."
                      className="imgval"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid section2 footerval">
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-8" style={{ paddingLeft: "50px" }}>
                  <div style={{ margin: "10px 50px 40px 50px" }}>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#122c50",
                      }}
                    >
                      WHY MOIBIT
                    </div>
                    <div
                      style={{
                        fontSize: "22px",
                        fontWeight: "800",
                        marginTop: "10px",
                        color: "#000",
                      }}
                      className="slogan"
                    >
                      Your Data. Your Way.
                    </div>
                    <p style={{ fontSize: "14px", color: "#000" }}>
                      MóiBit provides easy to use file storage API’s and
                      reliable infrastructure for building decentralized
                      applications that keep data safe, secure and private
                    </p>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
              <div className="text-center" style={{ fontSize: "12px" }}>
                <a
                  href="https://www.aicumen.com"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  {" "}
                  <p style={{ color: "#000" }}>
                    © {new Date().getFullYear()} Sarva labs Inc. All rights are
                    reserved.
                  </p>
                </a>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return <Redirect to="/home" />;
    }
  }
}

export default Login;
