import { SELECT_CHECKBOX_CREATE_NETWORK } from "../action/types";


const initialState = {
    nodeCheckBox: []
}

export const networkReducers = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_CHECKBOX_CREATE_NETWORK:
            return {
                ...state,
                nodeCheckBox: action.value
            }
        default:
            return state;
    }
}
