import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import "../pricing/style.css";

const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,

    style: {
        base: {
            color: "#303238",
            fontSize: "16px",
            fontFamily: "sans-serif",
            fontSmoothing: "antialiased",
            "::placeholder": {
                color: "#CFD7DF"
            }
        },
        invalid: {
            color: "#e5424d",
            ":focus": {
                color: "#303238"
            }
        }
    }
};

function CardSection() {
    return (
        <div style={{ margin: '2px' }}>
            <label for="card-element" style={{ fontWeight: "600", marginBottom: "0px", fontSize: "14px" }}>
                Card Details
            </label>
            <div id="card-element">
                <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>
        </div>
    );
}

export default CardSection;