import React, { Component, useState, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import ReactJSON from "react-json-view";
import Loader from "../../../HOC/loading";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw } from "draft-js";
import { customDateFormat } from "../../../common/func";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { bytesToSize } from "../../../common/func";
import { getIntermediateShards } from "../../../common/fileapi";
import "./style.css";

const iframe_content = {
  top: "0",
  left: "0",
  width: "100%",
  height: "300px",
  border: "0",
};

const bytesToSize1 = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

const downloadFile = async (blob, type, name, contenttype, props) => {
  if (contenttype == "txt") {
    const val = convertToRaw(blob.getCurrentContent());
    const blocks = val.blocks;
    const value = await blocks
      .map((block) => (!block.text.trim() && "\n") || block.text)
      .join("\n");
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(value)
    );
    element.setAttribute("download", name);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  } else {
    if (contenttype == "img") {
      if (!name.includes(".")) {
        name = name + ".png";
      }
    }
    var a = document.createElement("a");
    a.href = blob;
    a.download = name;
    a.click();
  }
};

class previewFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      imageError: false,
      showRegion: false,
      showSecurity: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.filename !== this.props.filename) {
      this.setState({ imageError: false });
    }
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        dialogClassName="modal-90w"
        centered
        backdrop="static"
        keyboard={false}
        show={this.props.showCloseModal}
        onHide={() => this.props.previewCloseModalFile1()}
      >
        {!this.props.loader ? (
          <div>
            <Modal.Header
              closeButton={this.props.type !== 0 ? true : false}
              style={{ textAlign: "center" }}
            >
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{ wordBreak: "break-word", fontSize: "16px" }}
              >
                {this.props.filename}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body
              style={{
                height: this.props.type == 2 ? "auto" : "400px",
                overflowY: "scroll",
              }}
            >
              {this.props.type == 0 ? (
                <div
                  style={{
                    padding: "2px",
                    background: "#fff",
                    textAlign:
                      this.props.contenttype == "img" ? "center" : "left",
                    wordBreak: "break-word",
                  }}
                >
                  {this.props.contenttype == "img" ? (
                    <div>
                      {this.state.imageError ? (
                        <p>No preview available. Please download the file.</p>
                      ) : null}
                      <img
                        src={this.props.result}
                        style={{ height: "100%", width: "100%" }}
                        onError={(e) => {
                          this.setState({ imageError: true });
                          e.target.src = require(`../../../assets/default_img.svg`);
                        }}
                      />
                    </div>
                  ) : this.props.contenttype == "pdf" ? (
                    <iframe
                      style={iframe_content}
                      src={this.props.result}
                      gesture="media"
                      allowFullScreen={true}
                    ></iframe>
                  ) : this.props.contenttype == "txt" ? (
                    <div style={{ color: "#000" }}>
                      <Editor
                        editorState={this.props.result}
                        toolbarHidden
                        readOnly={true}
                      />
                    </div>
                  ) : this.props.contenttype == "zip" ? (
                    <p>No preview available. Please download the file.</p>
                  ) : null}

                  {this.props.contenttype == "html" ? (
                    <p>{this.props.result}</p>
                  ) : this.props.contenttype == "img" ||
                    this.props.contenttype == "pdf" ||
                    this.props.contenttype == "txt" ? null : this.props
                      .result !== "" ? (
                    this.props.contenttype == "zip" ? null : (
                      <ReactJSON src={this.props.jsonresult} />
                    )
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "14px" }}>Please wait...</p>
                      <div
                        style={{
                          height: "100px",
                          width: "100px",
                          marginLeft: "43%",
                        }}
                      >
                        <CircularProgressbar
                          value={this.props.progressReadFileDownload}
                          text={`${this.props.progressReadFileDownload}%`}
                          maxValue={100}
                          styles={buildStyles({
                            strokeLinecap: "butt",
                            textSize: "14px",
                            pathTransitionDuration: 0.5,
                            textColor: "#3e98c7",
                            trailColor: "#d6d6d6",
                            backgroundColor: "#3e98c7",
                          })}
                          strokeWidth={7}
                        />
                      </div>
                    </div>
                  )}

                  {this.props.digitalmeVal !== "" ? (
                    <div
                      style={{
                        textAlign: "left",
                        marginTop: "20px",
                        color: "#000",
                        width: "max-content",
                      }}
                    >
                      {this.props.contenttype !== "" ? (
                        <table>
                          <tbody>
                            <tr>
                              <td style={{ fontWeight: "800" }}>Size</td>
                              <td
                                style={{ fontSize: "12px", fontWeight: "600" }}
                              >
                                {" "}
                                {bytesToSize(this.props.filesize)}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ fontWeight: "800" }}>Date</td>
                              {this.props.createdDate !== "" &&
                              this.props.createdDate !== undefined ? (
                                <td
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {customDateFormat(this.props.createdDate)}
                                </td>
                              ) : null}
                            </tr>

                            <tr>
                              <td style={{ fontWeight: "800" }}>
                                MòiBit privacy
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  paddingLeft: "40px",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                }}
                              >
                                <div className="form-check">
                                  {this.props.digitalmeVal.privacy == 0 ? (
                                    <label className="form-check-label">
                                      &nbsp; Network Level (Default)
                                    </label>
                                  ) : this.props.digitalmeVal.privacy == 1 ? (
                                    <label className="form-check-label">
                                      &nbsp; Developer Controlled
                                    </label>
                                  ) : this.props.digitalmeVal.privacy == 2 ? (
                                    <label className="form-check-label">
                                      &nbsp; User Controlled
                                    </label>
                                  ) : this.props.digitalmeVal.privacy == -1 ? (
                                    <label className="form-check-label">
                                      &nbsp; No Encryption
                                    </label>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td style={{ fontWeight: "800" }}>
                                MòiBit security
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ fontWeight: "800" }}
                                className="heading-css"
                              >
                                {" "}
                                Replica(s) :{" "}
                                <span style={{ color: "#000" }}>
                                  {this.props.digitalmeVal.replicas
                                    ? this.props.digitalmeVal.replicas.length
                                    : null}
                                </span>{" "}
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "#000",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      showRegion: !this.state.showRegion,
                                    })
                                  }
                                >
                                  {" "}
                                  {this.state.showRegion
                                    ? "(View less...)"
                                    : "(View More...)"}
                                </span>
                              </td>
                            </tr>

                            <tr>
                              {this.state.showRegion ? (
                                <td
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    paddingTop: "10px",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  {this.props.digitalmeVal.replicas &&
                                  this.props.digitalmeVal.replicas.length == 0
                                    ? null
                                    : this.props.digitalmeVal.replicas.map(
                                        (data, index) => {
                                          return data ? (
                                            <div
                                              key={index}
                                              style={{ marginBottom: "20px" }}
                                            >
                                              <p
                                                style={{ marginBottom: "0px" }}
                                              >
                                                Peer Name :{" "}
                                                {data.peername
                                                  ? data.peername
                                                  : "-"}
                                              </p>
                                              <p
                                                style={{ marginBottom: "0px" }}
                                              >
                                                Peer ID :{" "}
                                                {data.nodePeerID
                                                  ? data.nodePeerID
                                                  : "-"}
                                              </p>
                                              <p
                                                style={{ marginBottom: "0px" }}
                                              >
                                                Region :{" "}
                                                {data.region
                                                  ? data.region
                                                  : "-"}
                                              </p>
                                              <p
                                                style={{ marginBottom: "0px" }}
                                              >
                                                Cloud Provider :{" "}
                                                {data.cloudProvider
                                                  ? data.cloudProvider
                                                  : "-"}
                                              </p>
                                            </div>
                                          ) : (
                                            <p style={{ marginBottom: "0px" }}>
                                              Something went wrong. Plese try
                                              again later
                                            </p>
                                          );
                                        }
                                      )}
                                </td>
                              ) : null}
                            </tr>

                            {this.props.digitalmeVal.shards.length > 0 ? (
                              <tr>
                                <td
                                  style={{ fontWeight: "800" }}
                                  className="heading-css"
                                >
                                  Shards :{" "}
                                  <span style={{ color: "#000" }}>
                                    {this.props.digitalmeVal.shards.length}
                                  </span>{" "}
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      cursor: "pointer",
                                      color: "#000",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        showSecurity: !this.state.showSecurity,
                                      })
                                    }
                                  >
                                    {this.state.showRegion
                                      ? "(View less...)"
                                      : "(View More...)"}
                                  </span>
                                </td>
                              </tr>
                            ) : null}
                            <tr>
                              {this.state.showSecurity ? (
                                <td
                                  style={{
                                    fontSize: "12px",
                                    paddingTop: "10px",
                                    fontWeight: "600",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  {this.props.digitalmeVal.shards.map(
                                    (data, index) => (
                                      <TreeNode
                                        items={this.props.digitalmeVal.shards}
                                        id={index}
                                        hash={data.hash}
                                        size={data.size}
                                        networkID={this.props.networkID}
                                        appID={this.props.appID}
                                        fileName={
                                          this.props.path == "/"
                                            ? this.props.path
                                            : this.props.path +
                                              this.props.filename
                                        }
                                        version={this.props.version}
                                      />
                                    )
                                  )}
                                </td>
                              ) : null}
                            </tr>
                          </tbody>
                        </table>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              ) : this.props.type == 1 ? (
                this.props.result.length > 0 ? (
                  <div>
                    <table style={{ width: "100%" }}>
                      <div
                        style={{
                          overflowY: "scroll",
                          height: "400px",
                          padding: "20px",
                          fontSize: "small",
                        }}
                      >
                        <tr>
                          <th>Hash</th>
                          <th>Size</th>
                          <th>Time added</th>
                        </tr>
                        {this.props.result.map((val) => (
                          <tr style={{ cursor: "pointer" }}>
                            <td
                              style={{
                                color:
                                  this.props.currenthash == val.hash
                                    ? "green"
                                    : "#000",
                              }}
                              onClick={() =>
                                this.props.previewFile(
                                  val.hash,
                                  0,
                                  "",
                                  "",
                                  val.size,
                                  this.props.filename,
                                  val.timeAdded,
                                  val.pinned ? "Yes" : "No"
                                )
                              }
                            >
                              {val.hash}
                            </td>
                            <td
                              style={{
                                color:
                                  this.props.currenthash == val.hash
                                    ? "green"
                                    : "#000",
                              }}
                            >
                              {bytesToSize(val.size)}
                            </td>
                            <td
                              style={{
                                color:
                                  this.props.currenthash == val.hash
                                    ? "green"
                                    : "#000",
                              }}
                            >
                              {customDateFormat(val.timeAdded)}
                            </td>
                          </tr>
                        ))}
                      </div>
                    </table>
                  </div>
                ) : (
                  <p>No versions found</p>
                )
              ) : this.props.type == 2 ? (
                <div style={{ padding: "20px" }} className="row">
                  <div className="col-md-2">
                    {this.props.typeoffile ? (
                      <i
                        className="fa fa-folder-open"
                        aria-hidden="true"
                        style={{
                          fontSize: "60px",
                          color: "#f8c646",
                          marginTop: "12px",
                        }}
                      ></i>
                    ) : (
                      <img
                        src={this.props.imageblob}
                        style={{
                          height: "100px",
                          width: "100px",
                          objectFit: "contain",
                        }}
                        onError={(e) =>
                          (e.target.src = require(`../../../assets/default_img.svg`))
                        }
                      />
                    )}
                  </div>
                  <div style={{ paddingLeft: "20px" }} className="col-md-10">
                    {this.props.typeoffile ? (
                      <p>
                        <span style={{ color: "#000" }}>
                          Are you sure you want to remove this folder?
                        </span>
                      </p>
                    ) : (
                      <div>
                        {" "}
                        <p>
                          <span style={{ color: "#000" }}>
                            Are you sure you want to remove this file?
                          </span>
                        </p>
                        <p>
                          <span style={{ color: "#000", fontSize: "12px" }}>
                            Don't worry, you can always recover the file from
                            Recovery Storage.
                          </span>
                        </p>
                      </div>
                    )}
                    {this.props.typeoffile ? <div></div> : null}
                    <div style={{ textAlign: "right" }}>
                      <Button
                        variant="danger"
                        size="sm"
                        disabled={this.state.loading}
                        onClick={async () => {
                          this.setState({ loading: true });
                          await this.props.deletefilefrommoibit(
                            this.props.filename,
                            this.props.typeoffile,
                            this.props.deleteindex,
                            this.props.deletetype,
                            this.props.deleteVersionNum !== ""
                              ? this.props.deleteVersionNum
                              : -1
                          );
                          this.props.previewCloseModalFile1(
                            this.props.type,
                            this.props.typeoffile
                          );
                          setTimeout(() => {
                            this.setState({ loading: false });
                          }, 2000);
                        }}
                        style={{ marginRight: "10px", fontWeight: "600" }}
                        className="heading-css-bg"
                      >
                        {this.state.loading ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : null}
                        Yes
                      </Button>
                      <Button
                        variant="secondary"
                        style={{ fontWeight: "600" }}
                        size="sm"
                        disabled={this.state.loading}
                        className="button-css"
                        onClick={this.props.previewCloseModalFile1}
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}
            </Modal.Body>
          </div>
        ) : null}
        <Modal.Body>
          {this.props.versionArr.length > 0 ? (
            <div className="versioncard">
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  textAlign: "center",
                  textAlign: "center",
                }}
              >
                Versions
              </p>
              {this.props.versionArr.map((data, index) => {
                return (
                  <div className="versionbtn" key={index}>
                    <Button
                      variant="outline-info version-css"
                      size="sm"
                      style={{
                        fontSize: "12px",
                        height:
                          this.props.versionArr.length == 1 ? "auto" : "30px",
                        width: "20%",
                      }}
                      onClick={() =>
                        this.props.previewFile(
                          data.hash,
                          0,
                          this.props.filename,
                          "",
                          data.filesize,
                          this.props.filename,
                          data.lastUpdated,
                          "",
                          data.version
                        )
                      }
                    >
                      {this.props.versionArr.length == 1
                        ? "Current version"
                        : "Version " + data.version}
                    </Button>
                    <p className="versionptag">{bytesToSize(data.filesize)}</p>
                    <p className="versionptag">
                      {customDateFormat(data.lastUpdated)}
                    </p>
                    <i
                      className="fa fa-trash-o versiondeleteicon"
                      aria-hidden="true"
                      onClick={async () => {
                        await this.props.deleteFileVersion(
                          0,
                          0,
                          this.props.filename,
                          data.hash,
                          data.filesize,
                          this.props.contenttype == "pdf"
                            ? require(`../../../assets/pdf.png`)
                            : this.props.contenttype == "txt"
                            ? require(`../../../assets/txt.png`)
                            : this.props.contenttype == "zip"
                            ? require(`../../../assets/zip.png`)
                            : this.props.contenttype == "video"
                            ? require(`../../../assets/video.png`)
                            : this.props.contenttype == "json"
                            ? require(`../../../assets/json.png`)
                            : this.props.imageblob,
                          data.version
                        );
                      }}
                    ></i>
                  </div>
                );
              })}
            </div>
          ) : null}
        </Modal.Body>

        {this.props.type == 0 ? (
          <Modal.Footer>
            {this.props.readFileCancel && this.props.moibitWorldReadCancel ? (
              <Button
                size="sm"
                style={{ fontWeight: "600", color: "#fff" }}
                variant="outline-secondary"
                className="button-css"
                onClick={() =>
                  this.props.previewCloseModalFile1(this.props.type, undefined)
                }
              >
                <i className="fa fa-close" aria-hidden="true"></i> Close
              </Button>
            ) : null}
            {this.props.downloadcontenttype !== "" ? (
              <Button
                size="sm"
                style={{ fontWeight: "600", color: "#fff" }}
                variant="outline-info"
                onClick={() =>
                  this.props.getVersions(this.props.filename.replace("/", ""))
                }
                className="heading-css-bg"
              >
                {" "}
                <i className="fa fa-code-fork" aria-hidden="true"></i> Versions
              </Button>
            ) : null}
            {this.props.downloadcontenttype !== "" ? (
              <Button
                size="sm"
                style={{ fontWeight: "600", color: "#fff" }}
                variant="outline-success"
                className="heading-css-bg"
                onClick={() =>
                  downloadFile(
                    this.props.result,
                    this.props.downloadcontenttype,
                    this.props.filename,
                    this.props.contenttype,
                    this.props
                  )
                }
              >
                <i className="fa fa-cloud-download" aria-hidden="true"></i>{" "}
                Download
              </Button>
            ) : null}
          </Modal.Footer>
        ) : null}
      </Modal>
    );
  }
}

function fetchChildShards(props) {
  return new Promise(async (resolve, reject) => {
    let data = {
      networkID: props.networkID,
      appID: props.appID,
      fileName: props.fileName,
      version: props.version,
      hash: props.hash,
    };
    let _res = await getIntermediateShards(data);

    if (_res.meta.code == 200) {
      resolve(_res.data.shards.map((childId) => childId));
    } else {
      reject(new Error("Error fetching child shards"));
    }
  });
}

function fetchChildNodes(props) {
  return fetchChildShards(props);
}

function TreeNode(props) {
  // The nodes, or `null` if we don't have them yet
  const [childNodes, setChildNodes] = useState(null);
  // Flag for whether this node is expanded
  const [expanded, setExpanded] = useState(false);
  // Flag for whether we're fetching child nodes
  const [fetching, setFetching] = useState(false);
  // Flag for whether child node fetch failed
  const [failed, setFailed] = useState(false);

  // Toggle our display of child nodes
  const toggleExpanded = useCallback(() => {
    setExpanded(!expanded);
    if (!expanded && !childNodes && !fetching) {
      setFailed(false);
      setFetching(true);
      fetchChildNodes(props)
        .then((nodes) =>
          setChildNodes(
            nodes.map((node) => (
              <TreeNode {...props} hash={node.hash} size={node.size} />
            ))
          )
        )
        .catch((error) => setFailed(true))
        .finally(() => setFetching(false));
    }
  }, [expanded, childNodes, fetching]);

  return (
    <div class="treenode">
      {props.size > 262158 ? (
        <Button
          onClick={toggleExpanded}
          size="sm"
          className="btn btn-info"
          style={{ marginBottom: "10px", fontWeight: "600" }}
        >
          {expanded ? (
            <i className="fa fa-minus" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-plus" aria-hidden="true"></i>
          )}
        </Button>
      ) : null}
      <span
        style={{
          color: props.size > 262158 ? "blue" : "#000",
          paddingLeft: "10px",
          fontSize: props.size > 262158 ? "14px" : "12px",
          fontWeight: props.size > 262158 ? "600" : "normal",
        }}
      >
        {props.hash}
      </span>
      {failed && expanded && (
        <div className="failed">Error fetching child shards</div>
      )}
      {fetching && (
        <div className="loading" style={{ color: "#000", fontSize: "12px" }}>
          Loading...
        </div>
      )}
      {!failed &&
        !fetching &&
        expanded &&
        childNodes &&
        (childNodes.length > 0 ? (
          childNodes
        ) : (
          <div class="none">No shards found.</div>
        ))}
    </div>
  );
}

export default Loader(previewFile);
