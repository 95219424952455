import { SESSION_CHECK, CLOSE_WARNING, UNLOCK_WALLET } from "../action/types";


const initialState = {
    sessionCheck: false,
    closeWarning: true,
    walletLoading: false,
    walletError: false

}

export const sessionCheckFunc = (state = initialState, action) => {
    switch (action.type) {
        case SESSION_CHECK:
            return {
                ...state,
                sessionCheck: action.value
            }
        case CLOSE_WARNING: {
            return {
                ...state,
                closeWarning: action.value
            }
        }
        case UNLOCK_WALLET: {
            return {
                ...state,
                walletLoading: action.loading,
                walletError: action.error
            }
        }
        default:
            return state;
    }
}
