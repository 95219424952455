import React, { Component } from "react";
import { Card, Button, Form } from "react-bootstrap";
import "./style.css";

class MoiWorld extends Component {
    render() {
        return (
            <div className="container">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <p style={{ color: "#3E5EB1", fontSize: "20px", fontWeight: "600", marginTop: "20px" }}>Welcome to Moibit World</p>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <Form.Group>
                            <Form.Control type="text" placeholder="Search by filename" className="" />

                        </Form.Group>
                    </div>
                </div>
                <div className="row" style={{ marginTop: "20px" }}>
                    <div className="col-md-4" style={{ position: "absolute", left: "120px" }}>
                        <Card className="cardcss1" style={{ marginBottom: "70px" }}>
                            <Card.Body>
                                <Card.Title>Card Title</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the bulk of
                                    the card's content.
                        </Card.Text>
                                <Button variant="primary">Go somewhere</Button>
                            </Card.Body>
                        </Card>

                        <Card className="cardcss1">
                            <Card.Body>
                                <Card.Title>Card Title</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the bulk of
                                    the card's content.
                        </Card.Text>
                                <Button variant="primary">Go somewhere</Button>
                            </Card.Body>
                        </Card>


                    </div>





                    <div className="col-md-4 centeralign" >
                        <div className="">
                            <ul id="circle" style={{ marginTop: "300px", zIndex: 1, background: "#5ccfad" }}>
                                <li >
                                    <a href="#" >
                                        <div className="content first" >
                                            <div className="icon">
                                                <p className="text4">Immutability</p>
                                            </div>
                                        </div>
                                        <div className="background" ></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <div className="content second">
                                            <div className="icon">
                                                <p className="text4">Decentralisation</p>
                                            </div>
                                        </div>
                                        <div className="background"></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <div className="content third">
                                            <div className="icon">
                                                <p className="text4">privacy</p>
                                            </div>
                                        </div>
                                        <div className="background"></div>
                                    </a>
                                </li>


                                <li>
                                    <a href="#">
                                        <div className="content fourth">
                                            <div className="icon">
                                                <p className="text4">Security</p>
                                            </div>
                                        </div>
                                        <div className="background"></div>
                                    </a>
                                </li>


                            </ul>
                        </div>
                    </div>


                    <div className="col-md-4" style={{ position: "absolute", right: "120px" }}>
                        <Card className="cardcss1" style={{ marginBottom: "70px" }}>
                            <Card.Body>
                                <Card.Title>Card Title</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the bulk of
                                    the card's content.
                        </Card.Text>
                                <Button variant="primary">Go somewhere</Button>
                            </Card.Body>
                        </Card>

                        <Card className="cardcss1" >
                            <Card.Body>
                                <Card.Title>Card Title</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the bulk of
                                    the card's content.
                        </Card.Text>
                                <Button variant="primary">Go somewhere</Button>
                            </Card.Body>
                        </Card>
                    </div>

                </div>

            </div>

        )
    }
}

export default MoiWorld;