import axios from "axios";
import { updateDev, registerBearerToken } from "../../common/devapicalls";
import base64url from "base64url";

export const addUserToMoibit = async (username, email) => {
  try {
    let currentDate = new Date();
    let updatedNonceTime = currentDate.getTime() / 1000 + 86400;
    let numString = updatedNonceTime.toString();
    let updatedNonce = numString.split(".");
    let authToken = await window.moi_id.getAuthToken("" + updatedNonce[0]);
    let registerPayload = {
      _signature: authToken.signature,
      _devAddress: authToken.id,
      _enduserID: undefined,
      _cipherKey: authToken.signature.substring(44, 108),
      _nonce: authToken.message,
      _typeOfUser: 1,
      _appID: undefined,
      _networkID: undefined,
      _isMoiIDUser: true,
    };

    Object.keys(registerPayload).forEach(
      (key) => registerPayload[key] === undefined && delete registerPayload[key]
    );

    const tempUrl =
      process.env.REACT_APP_MOIBIT_API_URL + "user/registertomoibit";
    const captureRes = await axios({
      url: tempUrl,
      method: "POST",
      data: registerPayload,
    });

    if (captureRes.data.data) {
      let val = await window.moi_id.addUserToApp("moibit", false, undefined);

      if (val) {
        let setToken = authToken.message + "_" + authToken.signature;

        // Store values in in-memory object
        const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours from now
        sessionStorage.setItem(
          "authToken",
          JSON.stringify({ value: setToken, expiresAt: expirationTime })
        );
        sessionStorage.setItem(
          "defAddress",
          JSON.stringify({ value: authToken.id, expiresAt: expirationTime })
        );
        sessionStorage.setItem(
          "name",
          JSON.stringify({
            value: username,
            expiresAt: expirationTime,
          })
        );
        sessionStorage.setItem(
          "pending",
          JSON.stringify({ value: true, expiresAt: expirationTime })
        );
        sessionStorage.setItem(
          "email",
          JSON.stringify({
            value: email,
            expiresAt: expirationTime,
          })
        );

        sessionStorage.setItem(
          "pricing",
          JSON.stringify({ value: false, expiresAt: expirationTime })
        );

        // base64URL encoding
        const defAddress = authToken.id;
        const nonce = authToken.message;
        const signature = authToken.signature;
        const name = username + "-NameGeneratedFromBrowserDuringLogin";
        const obj = {
          defAddress,
          nonce,
          signature,
          name,
        };

        const KEY = JSON.stringify(obj);
        let encodedKey = base64url(KEY);
        let res = await registerBearerToken(encodedKey);

        sessionStorage.setItem(
          "bearerToken",
          JSON.stringify({ value: encodedKey, expiresAt: expirationTime })
        );
        let data = {
          name: await JSON.parse(sessionStorage.getItem("name")).value,
          email: await JSON.parse(sessionStorage.getItem("email")).value,
        };
        await updateDev(data);
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};
