import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Spinner } from "react-bootstrap";
import NetworkAppDropdown from "./networkdropdown";
import {
  listDeletedFiles,
  restoreFileOrFolder,
  listDeletedFilesperDirectory,
} from "../../common/fileapi";
import { timeDiffCalc, bytesToSize } from "../../common/func";
import { useDispatch } from "react-redux";
import { toastify } from "../../action";
import { isNil } from "lodash";

function FileExplorer() {
  const [allData, setAllData] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [selectedNetworkName, setSelectedNetworkName] = useState("");
  const [selectedAppName, setSelectedAppName] = useState("");
  const [selectedApp, setSelectedApp] = useState("");
  const [restoreSpinner, setRestoreSpinner] = useState(false);

  const dispatch = useDispatch();

  async function networkDetail(data) {
    await setSelectedApp("");
    await setSelectedAppName("");
    await setSelectedNetwork(data.id);
    await setSelectedNetworkName(data.name);
  }

  async function appDetail(data) {
    await setSelectedApp(data.id);
    await setSelectedAppName(data.name);
  }

  useEffect(() => {
    listAllFiles("/");
  }, [selectedApp, selectedNetwork]);

  async function listAllFiles(path) {
    try {
      if (
        selectedApp == "" ||
        selectedApp == undefined ||
        selectedApp == isNil
      ) {
        await setSelectedApp("default");
      }
      await setAllData([]);
      let _res = await listDeletedFiles(path, selectedNetwork, selectedApp);
      if (typeof _res.data != "string" && _res.data) {
        setAllData(_res.data);
      }
    } catch (err) {
      setAllData([]);
    }
  }

  async function listDeletedAllFiles(path) {
    try {
      if (
        selectedApp == "" ||
        selectedApp == undefined ||
        selectedApp == isNil
      ) {
        await setSelectedApp("default");
      }
      await setAllData([]);
      let _res = await listDeletedFilesperDirectory(
        path,
        selectedNetwork,
        selectedApp
      );
      if (typeof _res.data != "string" && _res.data) {
        setAllData(_res.data);
      }
    } catch (err) {
      setAllData([]);
    }
  }

  async function restoreFileFolder(val) {
    try {
      await setRestoreSpinner(true);
      let type = val.isDir;
      let data;
      if (!type) {
        data = {
          path: (val.directory == "/" ? "" : val.directory) + val.path,
          version: val.version,
          isdir: false,
          operationType: 1,
        };
      } else {
        data = {
          path: val.directory == "/" ? "" : val.directory,
          isdir: true,
          operationType: 1,
        };
      }

      let _res = await restoreFileOrFolder(selectedApp, selectedNetwork, data);
      if (_res.code == 200) {
        if (!val.isDir) {
          dispatch(toastify("info", "File restored successfully"));
        } else {
          dispatch(toastify("info", "Folder restored successfully"));
        }
        listAllFiles("/");
      } else {
        dispatch(toastify("err", _res.data));
      }
      await setRestoreSpinner(false);
    } catch (err) {
      dispatch(toastify("err", err.message));
      await setRestoreSpinner(false);
    }
  }

  return (
    <div>
      <NetworkAppDropdown appDetail={appDetail} networkDetail={networkDetail} />
      <Modal show={restoreSpinner} centered>
        <Modal.Body style={{ textAlign: "center" }}>
          <Spinner
            animation="border"
            variant="primary"
            style={{ color: "#8021BF" }}
          />
        </Modal.Body>
      </Modal>
      <div style={{ marginTop: "20px" }}>
        {allData.length !== 0 && allData.length !== undefined ? (
          <Table
            striped
            style={{ fontWeight: "600", fontSize: "14px" }}
            className="table-condensed1"
          >
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Size</th>
                <th>Recovery Time</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {allData.length > 0 &&
                allData.map((data, index) => (
                  <tr key={index}>
                    {data.isDir ? (
                      <td>
                        <i
                          className="fa fa-folder-open"
                          aria-hidden="true"
                          style={{
                            fontSize: "25px",
                            color: "#1B1C1D",
                            marginTop: "10px",
                          }}
                          onClick={() => listDeletedAllFiles(data.directory)}
                        ></i>
                      </td>
                    ) : (
                      <td>
                        <i
                          className="fa fa-file heading-css"
                          aria-hidden="true"
                          style={{
                            fontSize: "25px",
                            color: "#f8c646",
                            marginTop: "10px",
                          }}
                        ></i>
                      </td>
                    )}
                    {data.directory == "/" ? (
                      <td className="tdalign">
                        {(data.directory == "/" ? "" : data.directory) +
                          data.path}
                      </td>
                    ) : data.isDir == true ? (
                      <td
                        className="tdalign"
                        onClick={() => listDeletedAllFiles(data.directory)}
                      >
                        {/* 
                        {data.directory == "/" ? (
                          <td className="tdalign">
                            {(data.directory == "/" ? "" : data.directory) +
                              data.path}
                          </td>
                        ) : data.isDir == true ? (
                          <td
                            className="tdalign"
                            onClick={() => listDeletedAllFiles(data.directory)}
                          >
                            {data.directory}
                          </td>
                        ) : null} */}
                        {data.directory}
                      </td>
                    ) : data.directory !== "/" ? (
                      <td className="tdalign">{data.directory + data.path}</td>
                    ) : null}

                    <td className="tdalign">
                      {data.isDir ? "-" : bytesToSize(data.filesize)}
                    </td>
                    <td className="tdalign">
                      {data.isDir ? "-" : timeDiffCalc(data.recoveryTime)}
                    </td>
                    <td>
                      <Button
                        variant="dark button-css"
                        size="sm"
                        style={{ fontWeight: "600", width: "100%" }}
                        onClick={() => restoreFileFolder(data)}
                      >
                        Restore
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          <p>No data found </p>
        )}
      </div>
    </div>
  );
}

export default FileExplorer;
