import React, { Component } from "react";
import Sidebar from "../sidebar";
import Header from "../header";

class Layout extends Component {
    render() {
        return (
            <div className="container-fluid" id="dashboardMob" >
                <div className="row">
                    <div className="col-md-2" id="sidebar" style={{ padding: 0 }} >
                        <Sidebar />
                    </div>
                    <div className="col-md-10" style={{ padding: 0 }}>
                        <div className="container-fluid" style={{ minHeight: '100vh', backgroundColor: "#faf9f8" }}>
                            <div className="row">
                                <div className="col-md-12" style={{ padding: 0 }}>
                                    <div >
                                        <Header />
                                    </div>
                                </div>

                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Layout;