import React, { Component } from "react";
import ReactJSON from "react-json-view";
import axios from "axios";
import HTTPSnippet from "httpsnippet";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";

const iframe_content = {
  top: "0",
  left: "0",
  width: "100%",
  height: "600px",
  border: "0",
};

let endpoint = [
  {
    name: "Write File",
    endpoint: "writefile",
  },
  {
    name: "Write Text To File",
    endpoint: "writetexttofile",
  },
  {
    name: "Read File",
    endpoint: "readfile",
  },
  {
    name: "Read File by Hash",
    endpoint: "readfilebyhash",
  },
  {
    name: "Remove Files",
    endpoint: "remove",
  },
  {
    name: "List Files",
    endpoint: "listfiles",
  },
  {
    name: "Add Pin",
    endpoint: "addpin",
  },
  {
    name: "Remove Pin",
    endpoint: "removepin",
  },
  {
    name: "File Status",
    endpoint: "filestatus",
  },
  {
    name: "Storage Used",
    endpoint: "storageused",
  },
];

const language = [
  {
    name: "Node.js",
  },
  {
    name: "Javascript",
  },
  {
    name: "Java",
  },
  {
    name: "Go",
  },
  {
    name: "Ruby",
  },
  {
    name: "PHP",
  },
  {
    name: "C#",
  },
  {
    name: "Swift",
  },
  {
    name: "Python",
  },
  {
    name: "Shell",
  },
];

class ApiExplorer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: "https://kfs1.moibit.io",
      api_key: "12D3KooWHVyeH2RTahDyXrufDN9xiNdT7Gt6uECEPpUsjBLKt2PN",
      api_secret:
        "08011240cac49c8a72f78a38f6d75c9a237b350ef4fbfafffd21d89899416f06d696530a72279247f68b689b1c3e1451077e8dbd25b0852f2fa0a1417c8e5f92d72751ad",
      jsonResult: "",
      inputText: "",
      loading: false,
      htmlElement: "",
      boxShadow: false,
      method: "",
      fileName: "",
      text: "",
      hash: "",
      file: [],
      pin: false,
      createFolder: false,
      createUpdate: true,
      msg: "No record found",
      contentType: "",
      languageSnippet: [],
      languageSnippetCode: "",
      langaugeColor: "#fff",
      languageSelect: "",
      editorState1: EditorState.createEmpty(),
      imageError: false,
      authErr: false,
      errColor: false,
      displayView: true,
      removeAll: true,
    };
  }

  order = (a, b) => {
    return a < b ? -1 : a > b ? 1 : 0;
  };
  async componentDidMount() {
    if (this.props.api_key !== "error") {
      this.setState({}, async () => {
        var temp_arr = [];

        var language_sort_arr = await language
          .map((data) => data.name)
          .sort(this.order);
        for (let i = 0; i < language_sort_arr.length; i++) {
          temp_arr.push({ name: language_sort_arr[i] });
        }
        this.setState({ languageSnippet: temp_arr });
      });
    } else {
      this.setState({ authErr: true });
    }
  }
  populateCodeSnippet = async (language) => {
    var type;
    var payload;
    this.setState({ languageSelect: language });
    if (language == "Node.js") {
      language = "node";
    } else if (language == "C#") {
      language = "csharp";
    }
    let language_case = language.toLowerCase();

    var data;
    if (this.state.inputText == "writefile") {
      type = "application/x-www-form-urlencoded";
      let val = [
        {
          name: "pinVersion",
          value: this.state.pin,
        },
        {
          name: "createFolders",
          value: this.state.createFolder,
        },
        {
          name: "file",
          value: this.state.file,
          contentType: "multipart/form-data",
        },
      ];

      payload = {
        mimeType: type,
        params: val,
      };
    } else {
      type = "application/json";
      data = JSON.stringify(await this.getPayloadData());
      payload = {
        mimeType: type,
        text: data,
      };
    }

    var snippet1 = new HTTPSnippet({
      method: this.state.method.toUpperCase(),
      url: this.state.api_url + "/moibit/v0/" + this.state.inputText,
      headers: [
        {
          name: "api_key",
          value: this.state.api_key,
          comment: "",
        },
        {
          name: "api_secret",
          value: this.state.api_secret,
          comment: "",
        },
        {
          name: "Content-Type",
          value: type,
          comment: "",
        },
      ],
      postData: payload,
    });
    this.setState({
      languageSnippetCode: snippet1.convert(language_case),
      langaugeColor: "#000",
    });
  };

  getPayloadData = () => {
    switch (this.state.inputText) {
      case "writefile":
        var formData = new FormData();
        if (this.state.fileName !== "") {
          formData.append("fileName", this.state.fileName);
        }
        formData.append("file", this.state.file);
        formData.append("pinVersion", this.state.pin);
        formData.append("createFolders", this.state.createFolder);
        return formData;
      case "filestatus":
        return { path: this.state.fileName };
      case "listfiles":
        return { path: this.state.fileName };
      case "writetexttofile":
        return {
          fileName: this.state.fileName,
          text: JSON.stringify(
            convertToRaw(this.state.editorState1.getCurrentContent())
          ),
          create: this.state.createUpdate,
          createFolders: this.state.createFolder,
          pinVersion: this.state.pin + "",
        };
      case "readfilebyhash":
        return {
          hash: this.state.hash,
        };
      case "readfile":
        return {
          fileName: this.state.fileName,
        };
      case "remove":
        return {
          path: this.state.fileName,
          recursive:
            this.state.pin == "true" || this.state.pin == true ? true : false,
          allVersions:
            this.state.removeAll == "true" || this.state.removeAll == true
              ? true
              : false,
        };
      case "addpin":
        if (!document.getElementById("textField_Hash").disabled) {
          return { hash: this.state.hash };
        } else if (!document.getElementById("textField_File Name").disabled) {
          return {
            fileName: this.state.fileName,
          };
        }
      case "removepin":
        if (!document.getElementById("textField_Hash").disabled) {
          return { hash: this.state.hash };
        } else if (!document.getElementById("textField_File Name").disabled) {
          return {
            fileName: this.state.fileName,
          };
        }
      default:
        return {};
    }
  };

  validateField = () => {
    switch (this.state.inputText) {
      case "writefile":
        if (this.state.file == "") {
          document.getElementById("textField_File").style.borderColor = "red";
          return "failed";
        }
        break;
      case "writetexttofile":
        if (this.state.fileName == "" || this.state.text == "") {
          if (this.state.fileName == "") {
            document.getElementById("textField_File name").style.borderColor =
              "red";
            return "failed";
          }
        }
        break;
      case "readfilebyhash":
        if (this.state.hash == "") {
          document.getElementById("textField_Hash").style.borderColor = "red";
          return "failed";
        }
        break;

      case "remove":
        if (this.state.fileName == "") {
          document.getElementById("textField_Path").style.borderColor = "red";
          return "failed";
        }
        break;
      case "readfile":
        if (this.state.fileName == "") {
          document.getElementById("textField_File name").style.borderColor =
            "red";
          return "failed";
        }
        break;
      case "addpin":
        if (!document.getElementById("textField_Hash").disabled) {
          if (this.state.hash == "") {
            document.getElementById("textField_Hash").style.borderColor = "red";
            return "failed";
          }
        } else if (!document.getElementById("textField_File Name").disabled) {
          if (this.state.fileName == "") {
            document.getElementById("textField_File Name").style.borderColor =
              "red";
            return "failed";
          }
        }
      case "removepin":
        if (!document.getElementById("textField_Hash").disabled) {
          if (this.state.hash == "") {
            document.getElementById("textField_Hash").style.borderColor = "red";
            return "failed";
          }
        } else if (!document.getElementById("textField_File Name").disabled) {
          if (this.state.fileName == "") {
            document.getElementById("textField_File Name").style.borderColor =
              "red";
            return "failed";
          }
        }
      default:
        return "success";
    }
  };

  getJSONFunc = async (e) => {
    try {
      e.preventDefault();
      let val = await this.validateField();
      this.setState({ loading: true, jsonResult: "", imageError: false });
      if (val == "failed") {
        this.setState({ loading: false });
      } else {
        if (this.state.inputText !== "") {
          axios({
            method: this.state.method,
            url: this.state.api_url + "/moibit/v0/" + this.state.inputText,
            responseType:
              this.state.inputText === "readfilebyhash" ||
              this.state.inputText === "readfile"
                ? "blob"
                : "json",
            data: await this.getPayloadData(),
            headers: {
              api_key: this.state.api_key,
              api_secret: this.state.api_secret,
            },
          })
            .then(async (response) => {
              if (response.data !== null) {
                if (
                  response.headers["content-type"] ==
                  "text/plain; charset=utf-8"
                ) {
                  var file = new File(
                    [response.data],
                    (await this.getPayloadData()).fileName,
                    {
                      type: "text/plain; charset=utf-8",
                      lastModified: Date.now(),
                    }
                  );
                  var read = new FileReader();
                  read.readAsText(file);
                  read.onload = () => {
                    try {
                      let x = JSON.parse(read.result);
                      this.setState({
                        contentType: "txt",
                        jsonResult: EditorState.createWithContent(
                          convertFromRaw(x)
                        ),
                        loading: false,
                      });
                    } catch (error) {
                      this.setState({
                        contentType: "pdf",
                        jsonResult: window.URL.createObjectURL(response.data),
                        loading: false,
                      });
                    }
                  };
                } else if (
                  response.headers["content-type"] == "image/png" ||
                  response.headers["content-type"] == "image/jpeg" ||
                  response.headers["content-type"] == "image/gif"
                ) {
                  this.setState({
                    contentType: "img",
                    jsonResult: window.URL.createObjectURL(
                      new Blob([response.data])
                    ),
                    loading: false,
                  });
                } else if (
                  response.headers["content-type"] == "application/pdf" ||
                  response.headers["content-type"] ==
                    "text/plain; charset=utf-8" ||
                  response.headers["content-type"] == "video/mp4" ||
                  response.headers["content-type"] == "video/webm"
                ) {
                  this.setState({
                    contentType: "pdf",
                    jsonResult: window.URL.createObjectURL(response.data),
                    loading: false,
                  });
                } else if (
                  response.headers["content-type"] ==
                  "application/json; charset=UTF-8"
                ) {
                  this.setState({
                    contentType: "json",
                    jsonResult: response.data,
                    loading: false,
                  });
                } else {
                  this.setState({
                    contentType: "zip",
                    jsonResult: window.URL.createObjectURL(response.data),
                    loading: false,
                  });
                }
              }
              this.setState({ displayView: false });

              this.populateCodeSnippet("Javascript");
            })
            .catch((err) => {
              this.emptyAllFields();
              this.setState({
                loading: false,
              });
              if (
                this.state.inputText == "readfile" ||
                this.state.inputText == "readfilebyhash"
              ) {
                this.setState({
                  errColor: true,
                  msg: "No record found",
                  jsonResult: "",
                  contentType: "",
                });
              } else {
                if (
                  err.response.data.data ===
                    "error communicating with network (try again if you think there's nothing wrong with your request)" ||
                  err.response.data.data == "invalid path or hash" ||
                  err.response.data.data == "invalid path or pin" ||
                  err.response.data.data == "file does not exist"
                ) {
                  this.setState({
                    errColor: true,
                    msg: err.response.data.data,
                    jsonResult: "",
                    contentType: "",
                  });
                }
              }
            });
        }
      }
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  emptyAllFields = () => {
    let data = ["File", "File name", "Path", "Text", "Hash", "File name"];
    for (let i = 0; i < data.length; i++) {
      let x = document.getElementById("textField_" + data[i]);
      if (x) {
        x.value = "";
      }
    }
    this.setState({
      fileName: "",
      text: "",
      hash: "",
      file: [],
      pin: false,
      createFolder: false,
      createUpdate: true,
    });
  };

  inputTextFields = (len) => {
    let val = [];
    for (let i = 0; i < len.length; i++) {
      val.push(
        <div className="col-md-3" key={len[i].label}>
          <div className="form-group">
            <label className="col-form-label">
              {len[i].label}{" "}
              {len[i].mandatory ? (
                <i
                  className="fa fa-asterisk"
                  aria-hidden="true"
                  style={{ color: "red", fontSize: "8px" }}
                ></i>
              ) : null}
            </label>
            <input
              className="form-control"
              type={len[i].type}
              placeholder={len[i].label}
              required={len[i].mandatory}
              id={"textField_" + len[i].label}
              onChange={
                len[i].type == "text"
                  ? (e) => {
                      document.getElementById(
                        "textField_" + len[i].label
                      ).style = "none";
                      len[i].label == "File Name" ||
                      len[i].label == "File name" ||
                      len[i].label == "Path"
                        ? this.setState({ fileName: e.target.value })
                        : len[i].label == "Hash"
                        ? this.setState({ hash: e.target.value })
                        : this.setState({ text: e.target.value });
                    }
                  : len[i].type == "file"
                  ? (e) => {
                      document.getElementById(
                        "textField_" + len[i].label
                      ).style = "none";
                      this.setState({ file: e.target.files[0] });
                    }
                  : null
              }
              disabled={len[i].disabled}
            />
          </div>
        </div>
      );
    }
    return val;
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState1: editorState });
  };

  selectInputField = (len) => {
    let val = [];
    for (let i = 0; i < len.length; i++) {
      val.push(
        <option value={len[i]} key={len[i]}>
          {len[i]}
        </option>
      );
    }
    return val;
  };

  selectEndpoint = async (e) => {
    this.setState({
      inputText: await e.target.value,
      jsonResult: "",
      boxShadow: true,
      fileName: "",
      file: [],
      pin: false,
      createFolder: false,
      createUpdate: true,
      htmlElement: "",
      contentType: "",
      languageSnippetCode: "",
      msg: "No record Found",
      errColor: false,
      editorState1: EditorState.createEmpty(),
    });
    let data = ["File", "File name", "Path", "Text", "Hash", "File name"];
    for (let i = 0; i < data.length; i++) {
      let x = document.getElementById("textField_" + data[i]);
      if (x) {
        document.getElementById("textField_" + data[i]).style = "none";
      }
    }

    switch (this.state.inputText) {
      case "writefile":
        return this.setState({
          htmlElement: (
            <div className="row">
              {this.inputTextFields([
                {
                  label: "File name",
                  type: "text",
                  mandatory: false,
                  disabled: false,
                },
              ])}
              {this.inputTextFields([
                {
                  label: "File",
                  type: "file",
                  mandatory: true,
                  disabled: false,
                },
              ])}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label">Pin Version</label>
                  <select
                    className="custom-select"
                    onChange={(e) => this.setState({ pin: e.target.value })}
                  >
                    {this.selectInputField(["false", "true"])}
                  </select>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label">Create Folders</label>
                  <select
                    className="custom-select"
                    onChange={(e) =>
                      this.setState({ createFolder: e.target.value })
                    }
                  >
                    {this.selectInputField(["false", "true"])}
                  </select>
                </div>
              </div>
            </div>
          ),
          method: "post",
        });

      case "listfiles":
        return this.setState({
          htmlElement: (
            <div className="row">
              {this.inputTextFields([
                {
                  label: "Path",
                  type: "text",
                  mandatory: false,
                  disabled: false,
                },
              ])}
            </div>
          ),
          method: "post",
        });
      case "storageused":
        return this.setState({ htmlElement: "", method: "get" });
      case "filestatus":
        return this.setState({
          htmlElement: (
            <div className="row">
              {this.inputTextFields([
                {
                  label: "Path",
                  type: "text",
                  mandatory: false,
                  disabled: false,
                },
              ])}
            </div>
          ),
          method: "post",
        });
      case "readfilebyhash":
        return this.setState({
          htmlElement: (
            <div className="row">
              {this.inputTextFields([
                {
                  label: "Hash",
                  type: "text",
                  mandatory: true,
                  disabled: false,
                },
              ])}
            </div>
          ),
          method: "post",
        });

      case "readfile":
        return this.setState({
          htmlElement: (
            <div className="row">
              {this.inputTextFields([
                {
                  label: "File name",
                  type: "text",
                  mandatory: true,
                  disabled: false,
                },
              ])}
            </div>
          ),
          method: "post",
        });
      case "addpin":
        return this.addPinRemovePin();
      case "removepin":
        return this.addPinRemovePin();

      case "remove":
        return this.setState({
          htmlElement: (
            <div className="row">
              {this.inputTextFields([
                { label: "Path", type: "text", mandatory: true },
              ])}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label">Recursive</label>
                  <select
                    className="custom-select"
                    onChange={(e) => this.setState({ pin: e.target.value })}
                  >
                    {this.selectInputField(["false", "true"])}
                  </select>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label">All Versions</label>
                  <select
                    className="custom-select"
                    onChange={(e) =>
                      this.setState({ removeAll: e.target.value })
                    }
                  >
                    {this.selectInputField(["true", "false"])}
                  </select>
                </div>
              </div>
            </div>
          ),
          method: "post",
        });
      case "writetexttofile":
        return this.setState({
          htmlElement: (
            <div className="row">
              {this.inputTextFields([
                { label: "File name", type: "text", mandatory: true },
              ])}
              <div
                style={{ background: "#fff", padding: "15px", height: "200px" }}
              >
                <Editor
                  placeholder="Write a note..."
                  defaultEditorState={this.state.editorState1}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={this.onEditorStateChange}
                  toolbar={{
                    options: ["inline", "fontSize", "fontFamily", "textAlign"],
                  }}
                />
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label">Create</label>
                  <select
                    className="custom-select"
                    onChange={(e) =>
                      this.setState({ createUpdate: e.target.value })
                    }
                  >
                    {this.selectInputField(["true", "false"])}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label">Pin Version</label>
                  <select
                    className="custom-select"
                    onChange={(e) => this.setState({ pin: e.target.value })}
                  >
                    {this.selectInputField(["false", "true"])}
                  </select>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label">Create Folders</label>
                  <select
                    className="custom-select"
                    onChange={(e) =>
                      this.setState({ createFolder: e.target.value })
                    }
                  >
                    {this.selectInputField(["false", "true"])}
                  </select>
                </div>
              </div>
            </div>
          ),
          method: "post",
        });

      default:
        return this.setState({ htmlElement: "" });
    }
  };

  addPinRemovePin = () => {
    return this.setState({
      htmlElement: (
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label className="col-form-label">Parameters</label>
              <select
                className="custom-select"
                onChange={(e) => {
                  document.getElementById("textField_File Name").style = "none";
                  document.getElementById("textField_Hash").style = "none";
                  let x =
                    e.target.value == "--Select--"
                      ? "select"
                      : e.target.value == "hash"
                      ? "File Name"
                      : "Hash";
                  if (x == "File Name") {
                    document.getElementById("textField_Hash").disabled = false;
                    document.getElementById(
                      "textField_File Name"
                    ).disabled = true;
                  } else if (x == "Hash") {
                    document.getElementById("textField_Hash").disabled = true;
                    document.getElementById(
                      "textField_File Name"
                    ).disabled = false;
                  } else if (x == "select") {
                    document.getElementById("textField_Hash").disabled = true;
                    document.getElementById(
                      "textField_File Name"
                    ).disabled = true;
                  }

                  this.setState({ text: e.target.value });
                }}
              >
                {this.selectInputField(["--Select--", "hash", "fileName"])}
              </select>
            </div>
          </div>

          {this.inputTextFields([
            { label: "Hash", type: "text", mandatory: true, disabled: true },
          ])}
          {this.inputTextFields([
            {
              label: "File Name",
              type: "text",
              mandatory: true,
              disabled: true,
            },
          ])}
        </div>
      ),
      method: "post",
    });
  };

  render() {
    return this.state.authErr ? (
      <div className="container">
        {" "}
        <div className="col-md-12 homecontainer">
          <p>
            Something went wrong. Please refresh the screen and try again later.
          </p>
        </div>{" "}
      </div>
    ) : (
      <div
        className="container"
        style={{ marginTop: "10px", marginBottom: "50px" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ margin: "20px" }}>
            <div
              onClick={() =>
                this.setState({ displayView: !this.state.displayView })
              }
              style={{
                justifyContent: "space-between",
                cursor: "pointer",
                display: "flex",
                boxShadow: this.state.displayView
                  ? "none"
                  : "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                padding: this.state.displayView ? "0px" : "10px",
                borderRadius: "10px",
                marginBottom: this.state.displayView ? "0px" : "20px",
              }}
            >
              <div style={{ marginTop: "5px" }}>
                <h4
                  style={{
                    fontSize: "20px",
                    color: "#3e5eb1",
                    fontWeight: "600",
                  }}
                >
                  Input Endpoint & Parameters
                </h4>
              </div>
              <div
                style={{
                  fontSize: "25px",
                  paddingRight: "40px",
                  color: "#49b9ec",
                  marginTop: "0px",
                  cursor: "pointer",
                }}
              >
                <i
                  className={
                    this.state.displayView
                      ? "fa fa-caret-down"
                      : "fa fa-caret-right"
                  }
                  aria-hidden="true"
                ></i>
              </div>
            </div>
            <div
              style={{ display: this.state.displayView ? "block" : "none" }}
              id="input_box"
            >
              <div
                className="col-md-12"
                style={{
                  color: "#000",
                  padding: "25px",
                  borderRadius: 0,
                  borderWidth: "1px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  boxShadow: this.state.boxShadow
                    ? "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                    : 0,
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      marginTop: "5px",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    {this.state.api_url + "/moibit/v0/"}
                  </p>
                  <div
                    style={{ display: "", margin: 0 }}
                    className="form-inline"
                  >
                    <div style={{ paddingLeft: "20px" }}>
                      <select
                        className="custom-select"
                        style={{ width: "200px" }}
                        onChange={this.selectEndpoint}
                        value={this.state.inputText}
                      >
                        <option value="Choose endpoints">
                          Choose endpoints
                        </option>
                        {endpoint.map((val) => (
                          <option value={val.endpoint} key={val.name}>
                            {val.endpoint}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {this.state.inputText !== "" &&
                  this.state.inputText !== "Choose endpoints" ? (
                    <div style={{ paddingLeft: "20px", paddingTop: "6px" }}>
                      <button
                        type="button"
                        className="btn btn-outline-info"
                        style={{ display: "flex" }}
                        disabled={this.state.loading}
                        onClick={(e) => this.getJSONFunc(e, this.state.method)}
                      >
                        {" "}
                        {this.state.loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginTop: "3px" }}
                          ></span>
                        ) : null}
                        &nbsp;<span>Submit</span>
                      </button>
                    </div>
                  ) : null}
                </div>

                <div className="row">
                  <div className="col-md-12">
                    {this.state.inputText !== "" &&
                    this.state.inputText !== "Choose endpoints" ? (
                      <div style={{ marginTop: "10px" }}>
                        <form
                          style={{ width: "auto", textAlign: "left" }}
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {this.state.htmlElement}
                        </form>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <h4
                  style={{
                    marginBottom: "20px",
                    fontSize: "20px",
                    color: "#3e5eb1",
                    fontWeight: "600",
                  }}
                >
                  Output
                </h4>
                <div
                  style={{
                    padding: "40px",
                    background: "#fff",
                    textAlign:
                      this.state.contentType == "img" ? "center" : "left",
                    wordBreak: "break-word",
                    height: " 500px",
                    overflowY: "scroll",
                  }}
                >
                  {this.state.contentType == "img" ? (
                    <div>
                      {this.state.imageError ? (
                        <p>
                          No preview available. Please download the file from
                          the file explorer.
                        </p>
                      ) : null}
                      <img
                        src={this.state.jsonResult}
                        style={{ height: "300px" }}
                        onError={(e) => {
                          this.setState({ imageError: true });
                        }}
                      />
                    </div>
                  ) : this.state.contentType == "pdf" ? (
                    <iframe
                      title={"asdas"}
                      style={iframe_content}
                      src={this.state.jsonResult}
                      gesture="media"
                      allowFullScreen={true}
                    ></iframe>
                  ) : this.state.contentType == "txt" ? (
                    <div style={{ color: "#000" }}>
                      <Editor
                        editorState={this.state.jsonResult}
                        toolbarHidden
                        readOnly={true}
                      />
                    </div>
                  ) : null}
                  {this.state.contentType == "zip" ? (
                    <p>
                      No preview available. Please download the file in the file
                      explorer.
                    </p>
                  ) : this.state.contentType == "img" ||
                    this.state.contentType == "pdf" ||
                    this.state.contentType == "txt" ? null : this.state
                      .jsonResult !== "" ? (
                    <ReactJSON src={this.state.jsonResult} />
                  ) : (
                    <p style={{ color: this.state.errColor ? "red" : "" }}>
                      {this.state.msg}
                    </p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <h4
                  style={{
                    marginBottom: "20px",
                    fontSize: "20px",
                    color: "#3e5eb1",
                    fontWeight: "600",
                  }}
                >
                  Code snippet
                </h4>
                <div
                  style={{
                    padding: "20px",
                    background: "#fff",
                    textAlign:
                      this.state.contentType == "img" ? "left" : "left",
                    wordBreak: "break-word",
                    height: "500px",
                    overflowY: "scroll",
                  }}
                >
                  {this.state.languageSnippetCode !== "" ? (
                    <div>
                      <div className="input-group">
                        <select
                          className="custom-select"
                          onChange={(e) =>
                            this.populateCodeSnippet(e.target.value)
                          }
                          value={this.state.languageSelect}
                        >
                          <option>--Choose language--</option>
                          {this.state.languageSnippet.map((data) => (
                            <option
                              value={data.name}
                              key={data.name}
                              selected={this.state.languageSelect}
                            >
                              {data.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <pre
                          style={{
                            padding: "20px",
                            color: "#fff",
                            fontSize: "16px",
                            background: this.state.langaugeColor,
                          }}
                        >
                          <code>{this.state.languageSnippetCode}</code>
                        </pre>
                      </div>
                    </div>
                  ) : (
                    <p>No record found</p>
                  )}
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApiExplorer;
