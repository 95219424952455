import axios from "axios";

export const getAppDetails = async (appID, networkID) => {
  try {
    const tempUrl =
      process.env.REACT_APP_MOIBIT_API_URL +
      "net/" +
      networkID +
      "/app/" +
      appID;
    return (
      await axios({
        url: tempUrl,
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
      })
    ).data.data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const updateApp = async (data, networkID, appID) => {
  try {
    return (
      await axios({
        url:
          process.env.REACT_APP_MOIBIT_API_URL +
          "net/" +
          networkID +
          "/app/" +
          appID,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: data,
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const removeAppMoiBit = async (appID, networkID) => {
  try {
    return (
      await axios({
        url:
          process.env.REACT_APP_MOIBIT_API_URL +
          "net/" +
          networkID +
          "/app/" +
          appID,
        method: "DELETE",
        headers: {
          Authorization: "***",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          remove: true,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const restoreAppMoiBit = async (appID, networkID) => {
  try {
    return (
      await axios({
        url:
          process.env.REACT_APP_MOIBIT_API_URL +
          "net/" +
          networkID +
          "/app/" +
          appID,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          remove: false,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};
