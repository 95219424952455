import React from "react";
import { Modal, ProgressBar, Spinner } from "react-bootstrap";
import { storageUsed } from "../../common/apicalls";
import {
  getParticularNetworkDetails,
  getPartOfNetwork,
} from "../../common/networkapicalls";

import { bytesToSize } from "../../common/func";

class StorageDetails extends React.Component {
  state = {
    storageArr: [],
    storageLoader: false,
  };
  componentDidMount() {
    this.getStorage();
  }

  getStorage = async () => {
    try {
      this.setState({ storageLoader: true });
      let _res = await getPartOfNetwork();
      if (typeof _res.data !== "string") {
        let response = _res.data;
        let arr = [];
        let nodeArr = [];
        for (let i = 0; i < response.length; i++) {
          arr.push(await getParticularNetworkDetails(response[i].network_id));
        }
        for (let i = 0; i < arr.length; i++) {
          let nodeLen = arr[i].nodelist;
          let storage = 0;
          for (let j = 0; j < nodeLen.length; j++) {
            storage += parseInt(nodeLen[j].storage.replace(/[^0-9]/g, ""), 10);
          }
          nodeArr.push({
            networkID: arr[i].networkID,
            totalStorage: storage * 1000000,
            networkName: arr[i].networkName,
          });
        }
        let usedStorage = [];
        for (let i = 0; i < response.length; i++) {
          usedStorage.push({
            networkID: response[i].network_id,
            usedStorage: (await storageUsed(response[i].network_id, 2))
              .consumedStorage,
          });
        }
        let finalArr = nodeArr.map((item, i) =>
          Object.assign({}, item, usedStorage[i])
        );
        this.setState({ storageArr: finalArr, storageLoader: false });
      }
    } catch (err) {
      this.setState({ storageLoader: false });
    }
  };
  render() {
    return (
      <div>
        <Modal show={this.props.show} centered onHide={this.props.storageClose}>
          <Modal.Header closeButton style={{ fontWeight: "600" }}>
            Storage used by all developers
          </Modal.Header>
          <Modal.Body>
            {!this.state.storageLoader ? (
              <div>
                {this.state.storageArr.length > 0 ? (
                  this.state.storageArr.map((data, index) => (
                    <div key={index}>
                      <div
                        className="storagelabel"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>{data.networkName}</p>
                      </div>
                      <div
                        className="storageeachlabel"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <ProgressBar
                          style={{ minWidth: "70%" }}
                          max={data.totalStorage}
                          now={data.usedStorage}
                        />
                        <p>{bytesToSize(data.usedStorage)} Used</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p style={{ fontWeight: "600", fontSize: "12px" }}>
                    No data found
                  </p>
                )}
              </div>
            ) : (
              <div style={{ margin: "20px", textAlign: "center" }}>
                <Spinner animation="border" />
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default StorageDetails;
