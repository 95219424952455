import React, { Component } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { unLockWallet } from "../../action";
class Session extends Component {
  state = {
    password: "",
    passwordErr: false,
  };
  checkPassword = () => {
    if (this.state.password == "") {
      this.setState({ passwordErr: true });
      return;
    }
    this.props.unLockWallet(this.state.password);
  };
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: "600", fontSize: "16px" }}>
            {"Wallet locked!"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p style={{ fontSize: "14px", color: "#000" }}>
              Please enter your password to continue.
            </p>

            <div>
              <div className="input-group mb-2">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  style={{ fontSize: "14px" }}
                  onChange={(e) =>
                    this.setState({
                      password: e.target.value,
                      passwordErr: false,
                    })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.checkPassword();
                    }
                  }}
                />
              </div>
              {this.state.passwordErr ? (
                <p
                  style={{
                    marginBottom: "0px",
                    fontSize: "12px",
                    color: "crimson",
                  }}
                >
                  Please enter password
                </p>
              ) : null}

              {this.props.error ? (
                <p
                  style={{
                    marginBottom: "0px",
                    fontSize: "12px",
                    color: "crimson",
                  }}
                >
                  Invalid Credentials
                </p>
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!this.props.loading ? (
            <Button
              variant="danger"
              onClick={this.checkPassword}
              size="sm"
              style={{ fontWeight: "600" }}
              className="button-css"
              onKeyDown={(e) => e.key == "Enter"}
            >
              Unlock
            </Button>
          ) : (
            <Button
              variant="danger"
              disabled
              size="sm"
              style={{ fontWeight: "600" }}
              className="button-css"
            >
              <Spinner
                animation="border"
                style={{ height: "15px", width: "15px" }}
              />
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    sessionCheck: store.sessionCheck.sessionCheck,
    loading: store.sessionCheck.walletLoading,
    error: store.sessionCheck.walletError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    unLockWallet: (password) => dispatch(unLockWallet(password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Session);
