const MoiRequestWrapper = require('../utils/reqWrapper');

exports.setSQsOfUser = async function(moi_id_address,content) {
    try {
        return await MoiRequestWrapper({
            reqRouteType: 'auth',
            reqRouteName: 'sq/add',
            reqQueryParams: [],
            reqMethod: 'POST',
            reqPayload: {
                sqJSON: content,
                defAddr: moi_id_address
            }
        })
    }catch(e) {
        throw new Error(e.message);
    }    
}

exports.getSQOfUser = async function(moi_id_address) {
    try {
        return await MoiRequestWrapper({
            reqRouteType: 'auth',
            reqRouteName: 'sq/get',
            reqQueryParams: [{
                queryKey: 'defAddr',
                queryValue: moi_id_address
            }],
            reqMethod: 'GET',
            reqPayload: undefined
        })
    } catch(e) {
        if(e.message = "couldn't find hash for given file") {
            return {
                udqs: {},
                default: {}
            };
        }
        throw new Error(e.message);
    }    
}
