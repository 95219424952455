import React, { Component } from "react";
import { Nav, Navbar, Spinner, Image, Form, Button } from "react-bootstrap";
import { Redirect, Link } from "react-router-dom";
import "./style.css";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import * as legoData from "../../legoloading.json";
import * as doneData from "../../doneloading.json";
import { addUserToMoibit } from "./utils";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: legoData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOptions2 = {
  loop: false,
  autoplay: true,
  animationData: doneData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
class Authentication extends Component {
  state = {
    spinner: false,
    redirectHome: false,
    redirectLogin: false,
    name: "",
    emailStatus: false,
    emailVal: "",
    joinNet: false,
    selectMoiNet: false,
    joinNowSpinner: false,
    networkErr: false,
    networkErrVal: "",
    refreshEnable: false,
    loading: false,
    done: false,
    loading1: false,
    done1: false,
    loading2: false,
    done2: false,
    closeCancelBtn: false,
    checkbox: true,
  };
  async componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    this.setState({ name: params.get("name"), joinNet: false }, () => {
      this.getProfile();
    });
  }

  enableRefresh = () => {
    setTimeout(() => {
      this.setState({ refreshEnable: true });
    }, 3000);
  };
  getProfile = async () => {
    try {
      let userDefaultAddress = await window.moi_id.getUserID(
        this.state.name.toLowerCase()
      );
      let _res = await window.moi_id.getProfiledetails(userDefaultAddress);
      this.setState(
        {
          emailStatus: _res.dimensions.basic.email.verified,
          emailVal: _res.dimensions.basic.email.value,
        },
        () => {
          if (!this.state.emailStatus) {
            this.setState({ refreshEnable: true });
          } else {
            this.setState({ refreshEnable: false });
          }
        }
      );
    } catch (err) {
      // console.log(err);
    }
  };
  handleAuthUser = () => {
    this.setState({ spinner: true, loading: true, done: false }, async () => {
      this.setState({ loading: false, done: true, loading1: true });

      setTimeout(() => {
        this.setState({ loading1: false, done1: true, loading2: true });
      }, 3000);

      setTimeout(async () => {
        let val = await addUserToMoibit(this.state.name, this.state.emailVal);
        if (val) {
          this.setState({
            spinner: false,
            redirectHome: true,
            loading2: false,
            done2: true,
          });
        } else {
          this.setState({ redirectLogin: true, spinner: false });
        }
      }, 6000);
    });
  };

  refreshProfile = async () => {
    await this.getProfile();
  };

  counterFiveSec = async () => {
    var timeleft = 5;
    var downloadTimer = setInterval(
      async function () {
        timeleft--;
        if (document.getElementById("countdowntimer")) {
          document.getElementById("countdowntimer").textContent = timeleft;
          if (timeleft <= 0) {
            clearInterval(downloadTimer);
            sessionStorage.clear();
            localStorage.clear();
            this.setState({ redirectLogin: true });
          }
        }
      }.bind(this),
      1000
    );
  };

  activateButton = (element) => {
    this.setState({ checkbox: !this.state.checkbox });
  };
  render() {
    if (this.state.redirectHome) {
      return <Redirect to="/pricing" />;
    }
    if (this.state.redirectLogin) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div>
          <div className="galaxy">
            <div className="stars"></div>
          </div>
          <div className="container">
            {!this.state.joinNet ? (
              <div
                className="authcentered"
                style={{
                  boxShadow: "1px 4px 10px #a6a6a6",
                  borderRadius: "10px",
                  background: "#fff",
                }}
              >
                <div
                  className="card"
                  style={{ border: "none", width: "40rem", padding: "0px 10%" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      padding: "30px 10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <img
                      src={require("../../assets/moibit_logo.png")}
                      style={{ width: "120px" }}
                    />
                  </div>
                  <div className="card-body" style={{ margin: "0 3rem" }}>
                    <center tex>
                      {this.state.name !== "" ? (
                        <h1 style={{ color: "#000" }}>
                          Hi!{" "}
                          {this.state.name.charAt(0).toUpperCase() +
                            this.state.name.slice(1)}{" "}
                          &#128075;
                        </h1>
                      ) : null}
                    </center>
                    <p style={{ color: "#000", fontSize: "14px" }}>
                      {" "}
                      On clicking continue, You will be joining{" "}
                      <b className="heading-css">MoiBit</b> and authorized to
                      share following data:
                    </p>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "14px",
                        justifyContent: "space-between",
                      }}
                    >
                      <li style={{ fontWeight: "800" }} className="heading-css">
                        {this.state.emailVal == ""
                          ? "No email specified"
                          : this.state.emailVal}
                      </li>
                      {this.state.emailVal == "" ? null : this.state
                          .emailStatus ? (
                        <p style={{ color: "green", fontWeight: "600" }}>
                          Verified
                        </p>
                      ) : (
                        <p style={{ color: "crimson", fontWeight: "600" }}>
                          Not Verified
                        </p>
                      )}
                    </div>
                    <div>
                      {this.state.emailVal == "" ? (
                        <p
                          style={{
                            color: "#000",
                            marginBottom: "0px",
                            marginTop: "15px",
                            fontSize: "12px",
                          }}
                        >
                          Seems like you didn't added or verified your email in
                          Moi_ID. MoiBit needs your email and also to be
                          verified before joining.{" "}
                        </p>
                      ) : null}
                    </div>
                    {this.state.refreshEnable ? (
                      <div
                        style={{
                          marginTop: "20px",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={this.enableRefresh}
                      >
                        <a
                          href={
                            process.env.REACT_APP_MOI_ID_URL +
                            "?redirect=" +
                            process.env.REACT_APP_MOIBIT_URL
                          }
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          <p style={{ fontWeight: "600", color: "blue" }}>
                            Click here to verify your Email
                          </p>
                        </a>
                      </div>
                    ) : null}

                    {this.state.emailStatus ? (
                      <>
                        <input
                          type="checkbox"
                          name="terms"
                          id="terms"
                          onChange={this.activateButton}
                        />
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#000",
                            marginLeft: "10px",
                          }}
                        >
                          By clicking continue, I hereby:
                        </span>
                        <ul>
                          <li>
                            <span style={{ fontSize: "12px", color: "#000" }}>
                              Agree and consent to the{" "}
                              <span style={{ textDecoration: "underline" }}>
                                <a
                                  href={process.env.REACT_APP_TERMS_OF_SERVICES}
                                  target="_blank"
                                >
                                  Terms and Conditions
                                </a>
                              </span>{" "}
                              and the{" "}
                              <span style={{ textDecoration: "underline" }}>
                                <a
                                  href={process.env.REACT_APP_PRIVACY_POLICY}
                                  target="_blank"
                                >
                                  Privacy Policy
                                </a>
                              </span>
                            </span>
                          </li>
                        </ul>
                      </>
                    ) : null}

                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {!this.state.spinner ? (
                        <div>
                          <Button
                            variant="info"
                            className="heading-css-bg"
                            size="sm"
                            onClick={this.handleAuthUser}
                            disabled={this.state.checkbox}
                          >
                            Continue
                          </Button>
                        </div>
                      ) : null}
                      &nbsp;
                      {!this.state.spinner ? (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={this.refreshProfile}
                        >
                          <i
                            className="fa fa-refresh"
                            aria-hidden="true"
                            style={{
                              marginTop: "5px",
                              fontSize: "20px",
                              color: "#000",
                            }}
                          ></i>
                        </div>
                      ) : null}
                    </div>

                    {/* new code starts */}
                    <div>
                      {this.state.spinner ? (
                        <div>
                          <FadeIn>
                            <div className="d-flex">
                              {this.state.loading ? (
                                <Lottie
                                  options={defaultOptions}
                                  height={30}
                                  width={30}
                                  style={{ margin: "0px" }}
                                />
                              ) : null}

                              {this.state.done ? (
                                <Lottie
                                  options={defaultOptions2}
                                  height={70}
                                  width={70}
                                  style={{ margin: "0px" }}
                                />
                              ) : null}
                              <h3
                                className="enhance-loader"
                                style={{
                                  fontSize: "18px",
                                  display: "flex",
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                              >
                                Setting up MòiBit Account
                              </h3>
                            </div>
                          </FadeIn>

                          <FadeIn>
                            <div className="d-flex">
                              {this.state.loading1 ? (
                                <Lottie
                                  options={defaultOptions}
                                  height={30}
                                  width={30}
                                  style={{ margin: "0px" }}
                                />
                              ) : null}

                              {this.state.done1 ? (
                                <Lottie
                                  options={defaultOptions2}
                                  height={70}
                                  width={70}
                                  style={{ margin: "0px" }}
                                />
                              ) : null}
                              <h3
                                className="enhance-loader"
                                style={{
                                  fontSize: "18px",
                                  display: "flex",
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                              >
                                Provisioning
                              </h3>
                            </div>
                          </FadeIn>

                          <FadeIn>
                            <div className="d-flex">
                              {this.state.loading2 ? (
                                <Lottie
                                  options={defaultOptions}
                                  height={30}
                                  width={30}
                                  style={{ margin: "0px" }}
                                />
                              ) : null}

                              {this.state.done2 ? (
                                <Lottie
                                  options={defaultOptions2}
                                  height={70}
                                  width={70}
                                  style={{ margin: "0px" }}
                                />
                              ) : null}
                              <h3
                                className="enhance-loader"
                                style={{
                                  fontSize: "18px",
                                  display: "flex",
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                              >
                                Creating MòiBit
                              </h3>
                            </div>
                          </FadeIn>
                        </div>
                      ) : null}

                      <div style={{ marginTop: "30px" }}>
                        <Link to="/login">
                          <span
                            style={{ color: "chartreuse", marginLeft: "2px" }}
                          ></span>{" "}
                          <p
                            style={{
                              color: "#a6a6a6",
                              fontWeight: "600",
                              textAlign: "center",
                            }}
                          >
                            Cancel
                          </p>
                        </Link>
                      </div>
                      <div>
                        {/* <p
                          style={{
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          Powered by 10ME
                        </p> */}

                        <p style={{ fontSize: "12px", textAlign: "center" }}>
                          Powered by&nbsp;{" "}
                          <span style={{ fontSize: "12px" }}>10</span>
                          <span style={{ fontSize: "14px" }}>M</span>
                          <span
                            style={{
                              fontSize: "14px",
                              position: "relative",
                              top: "0px",
                            }}
                          >
                            &#x018E;
                          </span>
                        </p>
                      </div>
                    </div>
                    {/* new code end */}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  }
}

export default Authentication;
