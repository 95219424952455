import React from "react";
import { getPaymentInfo } from "../../common/paymentapi";
import { devAdmindetails } from "../../common/devapicalls";
import { Table } from "react-bootstrap";
import PaymentPopUp from "./payment";
import { customDateFormat } from "../../common/func";
import Invoice from "./invoice";
import { priceModalOpen } from "../../action";
import { connect } from "react-redux";

class Payment extends React.Component {
  state = {
    paymentInfo: [],
    openPaymentModal: false,
    amount: 0,
    id: "",
    invoiceOpen: false,
    invoiceData: "",
  };
  componentDidMount() {
    if (this.props.plan == -1) {
      this.props.priceModalOpen();
      return;
    }
    this.getPaymentInfoDetails();
  }
  getPaymentInfoDetails = async () => {
    try {
      let _res = await getPaymentInfo();
      if (typeof _res.data == "object") {
        this.setState({ paymentInfo: _res.data.reverse() });
      }
    } catch (Err) {
      this.setState({ paymentInfo: [] });
    }

    // try {
    //   let _res = (await devAdmindetails()).data;
    //   if (_res.stripeSubscriptionID !== "") {
    //     let paymentRespObject = await getPaymentInfo(_res.stripeSubscriptionID);
    //     if (typeof paymentRespObject.data == "object") {
    //       this.setState({ paymentInfo: paymentRespObject.data.reverse() });
    //     }
    //   }
    // } catch (Err) {
    //   this.setState({ paymentInfo: [] });
    // }
  };

  closeModal = () => {
    this.setState({ openPaymentModal: false, amount: 0 });
  };
  paymentSuccess = () => {
    this.setState({ openPaymentModal: false, amount: 0 });
    setTimeout(() => {
      this.getPaymentInfoDetails();
    }, 3000);
  };
  payNow = (id, amount) => {
    this.setState({ openPaymentModal: true, id: id, amount: amount });
  };
  invoiceModalOpen = (data) => {
    this.setState({ invoiceOpen: true, invoiceData: data });
  };
  invoiceModalClose = () => {
    this.setState({ invoiceOpen: false, invoiceData: "" });
  };
  render() {
    return (
      <div className="container" style={{ marginTop: "30px" }}>
        {this.state.openPaymentModal && this.state.id !== "" ? (
          <PaymentPopUp
            show={this.state.openPaymentModal}
            closeModal={this.closeModal}
            price={this.state.amount}
            paymentSuccess={this.paymentSuccess}
            id={this.state.id}
          />
        ) : null}
        {this.state.invoiceOpen ? (
          <Invoice
            show={this.state.invoiceOpen}
            invoiceModalClose={this.invoiceModalClose}
            invoiceData={this.state.invoiceData}
          />
        ) : null}
        <div>
          <h3
            style={{
              fontWeight: "600",
              fontSize: "16px",
              marginBottom: "20px",
            }}
            className="heading-css"
          >
            Payment History
          </h3>
          {this.state.paymentInfo.length > 0 ? (
            <Table style={{ fontSize: "14px" }} responsive>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Description</th>
                  <th>Due Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Invoice</th>
                </tr>
              </thead>
              <tbody>
                {this.state.paymentInfo.map((data, index) => {
                  let finalDate = customDateFormat(data.dueDate);
                  var description;
                  if (data.type == 0) {
                    if (data.description !== "") {
                      let plan = data.description.split("_")[1];
                      if (plan == 0) {
                        description = "DEVELOPER";
                      } else if (plan == 1) {
                        description = "INDIVIDUAL";
                      } else if (plan == 2) {
                        description = "START UP";
                      } else if (plan == 3) {
                        description = "BUSINESS";
                      } else if (plan == 4) {
                        description = "ENTERPRISE";
                      } else {
                        description = "-";
                      }
                    }
                  } else if (data.type == 1) {
                    if (data.description !== "") {
                      description = data.description;
                    } else {
                      description = "-";
                    }
                  }
                  return (
                    <tr key={index}>
                      <td>
                        {data.type == 0 ? "Plan upgrade" : "Monthly payment"}
                      </td>
                      <td className="heading-css fontweight600">
                        {description ? description : "-"}
                      </td>
                      <td>{finalDate}</td>
                      {data.type == 0 ? (
                        <td>${data.amount / 100}</td>
                      ) : (
                        <td>${data.invoice.amountDue}</td>
                      )}
                      {!data.paid ? (
                        <td
                          style={{
                            fontSize: "14px",
                            color: "#d78738",
                            fontWeight: "600",
                          }}
                        >
                          <i
                            class="fa fa-exclamation-circle"
                            aria-hidden="true"
                          ></i>
                          &nbsp;Open
                        </td>
                      ) : (
                        <td
                          style={{
                            fontSize: "14px",
                            color: "green",
                            fontWeight: "600",
                          }}
                        >
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>
                          &nbsp;Paid
                        </td>
                      )}
                      {data.type == 0 ? (
                        <td>
                          <a href={data.reciptURL} target="_blank">
                            <span
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              className="heading-css fontweight600"
                            >
                              View Invoice
                            </span>
                          </a>
                        </td>
                      ) : (
                        <td>
                          <span
                            style={{
                              color: "#007bff",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => this.invoiceModalOpen(data.invoice)}
                          >
                            View Invoice
                          </span>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <p>No data found</p>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    plan: store.price.priceState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    priceModalOpen: () => dispatch(priceModalOpen()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
