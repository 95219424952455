import React from "react";
import { Card } from "react-bootstrap";
import { bytesToSize, kFormatter } from "../../common/func";
import "./style.css";
import StorageDetails from "./storagedetails";
import { Redirect } from "react-router-dom";

class DisplayData extends React.Component {
  state = {
    displayStorage: false,
    displayBandwidth: false,
  };

  handleStorageClose = () => {
    this.setState({ displayStorage: false });
  };

  render() {
    return this.state.displayStorage ? (
      <Redirect to="/storage" />
    ) : this.state.displayBandwidth ? (
      <Redirect to="/bandwidth" />
    ) : (
      <div>
        <div className="" style={{ marginTop: "20px" }}>
          <div className="col-md-12">
            <p
              style={{ fontSize: "18px", fontWeight: "600" }}
              className="heading-css"
            >
              Your Activity
            </p>
            <Card
              className="mb-2 ripple"
              style={{
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                cursor: "pointer",
              }}
              onClick={() => this.setState({ displayStorage: true })}
            >
              <Card.Body>
                <div className="cardcss">
                  <div>
                    <Card.Title
                      style={{ fontSize: "20px", fontWeight: "600" }}
                      className="heading-css"
                    >
                      {isNaN(this.props.storage)
                        ? bytesToSize(0)
                        : bytesToSize(this.props.storage)}
                    </Card.Title>
                    <Card.Text className="carddesccss">
                      {"Storage Used"}
                    </Card.Text>
                  </div>
                  <div className="iconcss">
                    <Card.Title>
                      <i
                        className={"fa fa-server heading-css"}
                        aria-hidden="true"
                        style={{ fontSize: "30px" }}
                      ></i>
                    </Card.Title>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div className="col-md-12">
            <Card
              className="mb-2"
              style={{
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <Card.Body>
                <div className="cardcss">
                  <div>
                    <Card.Title
                      className="heading-css"
                      style={{ fontSize: "20px", fontWeight: "600" }}
                    >
                      {kFormatter(this.props.appCount)}
                    </Card.Title>
                    <Card.Text className="carddesccss">
                      {"No.of Apps"}
                    </Card.Text>
                  </div>
                  <div className="iconcss">
                    <Card.Title>
                      <i
                        className={"fa fa-file heading-css"}
                        aria-hidden="true"
                        style={{ fontSize: "30px" }}
                      ></i>
                    </Card.Title>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div className="col-md-12">
            <Card
              className="mb-2"
              style={{
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <Card.Body>
                <div className="cardcss">
                  <div>
                    <Card.Title
                      className="heading-css"
                      style={{ fontSize: "20px", fontWeight: "600" }}
                    >
                      {this.props.postCount}
                    </Card.Title>
                    <Card.Text className="carddesccss">
                      {"API Request"}
                    </Card.Text>
                  </div>
                  <div className="iconcss">
                    <Card.Title>
                      <i
                        className={"fa fa-arrow-up heading-css"}
                        aria-hidden="true"
                        style={{ fontSize: "30px" }}
                      ></i>
                    </Card.Title>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div className="col-md-12">
            <Card
              className="mb-2 ripple"
              style={{
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                cursor: "pointer",
              }}
              onClick={() => this.setState({ displayBandwidth: true })}
            >
              <Card.Body>
                <div className="cardcss">
                  <div>
                    <Card.Title
                      style={{ fontSize: "20px", fontWeight: "600" }}
                      className="heading-css"
                    >
                      {isNaN(this.props.bandwidth.toFixed(8))
                        ? 0
                        : this.props.bandwidth.toFixed(8)}{" "}
                      GB
                    </Card.Title>
                    <Card.Text className="carddesccss">{"Bandwidth"}</Card.Text>
                  </div>
                  <div className="iconcss">
                    <Card.Title>
                      <i
                        className={"fa fa-tachometer heading-css"}
                        aria-hidden="true"
                        style={{ fontSize: "30px" }}
                      ></i>
                    </Card.Title>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default DisplayData;
