import axios from "axios";
import { memoryStore } from "../index";

export const createNode = async (data) => {
  try {
    return (
      await axios({
        url: process.env.REACT_APP_MOIBIT_API_URL + "nodes",
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: data,
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const getNode = async (NodeID) => {
  try {
    const tempUrl =
      process.env.REACT_APP_MOIBIT_API_URL + "nodes" + "/" + NodeID;
    return (
      await axios({
        url: tempUrl,
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          operationType: 1,
          // nodeID: NodeID,
        },
      })
    ).data.data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

// list all nodes developer owned
export const listallOwnedNodes = async () => {
  try {
    return (
      await axios({
        url: process.env.REACT_APP_MOIBIT_API_URL + "nodes",
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          operationType: 2,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

// get node deatils
export const getNodeDetails = async (data) => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "nodes";
    return (
      await axios({
        url: tempUrl,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: data,
        params: {
          operationType: 3,
        },
      })
    ).data.data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const dedicatedNodeList = async () => {
  try {
    const tempUrl = process.env.REACT_APP_MOIBIT_API_URL + "nodes/premiumnodes";
    return (
      await axios({
        url: tempUrl,
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};
