import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import PrivateRoute from "./HOC/privateroute";
import Landing from "./component/landing";
import Login from "./component/login";
import Layout from "./component/layout";
import Home from "./component/home";
import Pricing from "./component/pricing";
// import Migration from "./component/migration";
import Resources from "./component/resources";
import CreateApp from "./component/home/createapp";
import CreditCard from "./component/creditcard";
import MoiWorld from "./component/moiworld";
import FileExplorer from "./component/f1/fileexplorer/fileexplorer";
import ApiExplorer from "./component/apiexplorer";
import Profile from "./component/profile";
import Authentication from "./component/auth";
import Network from "./component/network1";
import DisplayNetwork from "./component/network1/displaynetwork";
import Network1 from "./component/network";
import ProvenanceLog from "./component/provenanceLog";
import Nodes from "./component/nodes";
import RecycleBin from "./component/recyclebin";
import MMARecoveryStorage from "./component/MMA";
import Payment from "./component/payment";
import Storage from "./component/storage";
import Bandwidth from "./component/bandwidth";

import { Init } from "./reducers";
const store = Init();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/moibit/join" component={Authentication} />
            <div>
              <Layout>
                <PrivateRoute path="/home" exact component={Home} />
                <PrivateRoute path="/pricing" exact component={Pricing} />
                {/* <PrivateRoute path="/migration" exact component={Migration} /> */}
                <PrivateRoute path="/payment" exact component={Payment} />
                <PrivateRoute exact path="/network" component={Network} />
                <PrivateRoute path="/network/create" component={Network1} />
                <PrivateRoute
                  exact
                  path="/network/view/:id"
                  component={DisplayNetwork}
                />
                <PrivateRoute path="/pos" exact component={ProvenanceLog} />
                <PrivateRoute path="/resources" exact component={Resources} />
                <PrivateRoute path="/moibitworld" exact component={MoiWorld} />
                <PrivateRoute
                  path="/fileexplorer"
                  exact
                  component={FileExplorer}
                />
                <PrivateRoute
                  path="/apiexplorer"
                  exact
                  component={ApiExplorer}
                />
                <PrivateRoute path="/profile" exact component={Profile} />
                <PrivateRoute path="/network1" exact component={Network1} />
                <PrivateRoute path="/nodes" exact component={Nodes} />
                <PrivateRoute path="/restore" exact component={RecycleBin} />
                <PrivateRoute path="/storage" exact component={Storage} />
                <PrivateRoute path="/bandwidth" exact component={Bandwidth} />

                <PrivateRoute
                  path="/mma"
                  exact
                  component={MMARecoveryStorage}
                />
                <PrivateRoute
                  path="/home/createapp"
                  exact
                  component={CreateApp}
                />
              </Layout>
            </div>
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
