const MoiIDAsymmetry = require("human-crypto-keys");
const algorithm = {
    id: 'rsa',
    modulusLength: 2048,
    publicExponent: 65537,
    method: 'PRIMEINC'
};

exports.getPublicKeyInPem = async function(mnenomic) {
    try {
        const keyPairFromMnemonic = await MoiIDAsymmetry.getKeyPairFromMnemonic(mnenomic, algorithm);
        return keyPairFromMnemonic.publicKey;
    }catch(e) {
        throw new Error(e.message);
    }
}

exports.getPrivateKeyInPem = async function(mnenomic) {
    try {
        const keyPairFromMnemonic = await MoiIDAsymmetry.getKeyPairFromMnemonic(mnenomic, algorithm);
        return keyPairFromMnemonic.privateKey;
    }catch(e) {
        throw new Error(e.message);
    }
}

