"use strict";
const lodash = require("lodash");
Object.defineProperty(exports, "__esModule", {
  value: true,
});

let HdWallet = require("./wallet");
let MAsymmetry = require("./asymm");
let Utils = require("./utils");
let MoiError = require("./lib/errors");
let Constants = require("./lib/constants");

const Zkp = require("./zk");

function _privateMapGet(receiver, privateMap) {
  if (!privateMap.has(receiver)) {
    MoiError.moiPrivateGetError();
  }
  var descriptor = privateMap.get(receiver);
  if (descriptor.get) {
    return descriptor.get.call(receiver);
  }
  return descriptor.value;
}

function _privateMapSet(receiver, privateMap, value) {
  if (!privateMap.has(receiver)) {
    MoiError.moiPrivateSetError();
  }
  var descriptor = privateMap.get(receiver);
  if (descriptor.set) {
    descriptor.set.call(receiver, value);
  } else {
    descriptor.value = value;
  }
  return value;
}

var _hdw = new WeakMap();
var _userList = new WeakMap();
var _auth = new WeakMap();
var _checks = new WeakMap();
var _igc = new WeakMap();
let _keystore_ = {};
let walletUnlocked = {};
let defaultAccount = {};
let currentNameSpace = {};
let currentNamespaceSpecificAddress = {};

class Moi_ID {
  constructor() {
    this.zkp = Zkp;
    this.sq = require("./sq");
    this.utils = {
      mEncode: Utils.general.mEncode,
      mDecode: Utils.general.mDecode,
    };

    _checks.set(walletUnlocked, false);

    _userList.set(this, {
      value: void 0,
    });

    /* setting HDW with currently loaded wallet for signing, verification etc */
    _hdw.set(this, {
      value: void 0,
    });

    /* setting IGC with currently loaded namespace account */
    _igc.set(this, {
      value: void 0,
    });

    _auth.set(this, {
      value: async (encryptionKey) => {
        const _tempHdw = _privateMapGet(this, _hdw);
        return await _tempHdw.getAccountWrapper(encryptionKey);
      },
    });
  }

  /**
   *
   * @param {string} _uN ASCII string that user use as username to login
   * @param {string} _pP Any set of characters that serves as password for user.
   * @returns {address} moi_id address of the user
   */
  async create(_uN, _pP) {
    MoiError.moiValidationError(
      _uN.length < 5,
      Constants.INVALID_USERNAME_SIZE
    );
    _uN = _uN.toLowerCase();
    let firstLetterAlphabetRegExp = new RegExp(/^[a-z]/);
    MoiError.moiValidationError(
      !firstLetterAlphabetRegExp.test(_uN),
      Constants.INVALID_USERNAME
    );
    try {
      /* check for username already exists */
      if (await this.isUserNameAvailable(_uN)) {
        const hdwInstance = new HdWallet();
        hdwInstance.createInstance();
        _privateMapSet(this, _hdw, hdwInstance);

        /* Wallet Keystore generation */
        const ks = await _privateMapGet(this, _auth).call(this, _pP);

        /* Zero-knowledge proof generation */
        const _zkProof = Zkp.generate(_pP);

        /* Loading Moi_ID index wallet */
        hdwInstance.deriveAccountFromPath(
          Constants.MOI_ID_V1_IGC_PATH,
          true,
          1
        );
        _privateMapSet(this, _hdw, hdwInstance);

        /* MoiCipher Params generation */
        const mCipherParams = Utils.crypto.getMoiCipherParams(
          hdwInstance.getKey()
        );

        const defaultMoiIdAddress = hdwInstance.getID();
        await Utils.moibit.storeMks(
          defaultMoiIdAddress,
          ks,
          _zkProof,
          mCipherParams
        );

        _auth.set(defaultAccount, defaultMoiIdAddress);
        _checks.set(walletUnlocked, true);

        return defaultMoiIdAddress;
      } else {
        MoiError.userNameAlreadyExists();
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   *
   * @param {string} userName
   * @param {address} userMoiIdAddress
   * @returns setup account for user in Moi_ID contract
   */
  async registerUser(userName, userMoiIdAddress) {
    try {
      userName = userName.toLowerCase();
      let respFromIDM = await Utils.admin.registerUser(
        userName,
        userMoiIdAddress
      );
      const publicKeyInPem = await MAsymmetry.getPublicKeyInPem(this.getGDF());
      await Utils.moibit.storePubPem(userMoiIdAddress, publicKeyInPem);
      _igc.set(currentNameSpace, "default");
      _igc.set(currentNamespaceSpecificAddress, userMoiIdAddress);
      return respFromIDM;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   *
   * @param {string} _uN user's username
   * @param {string} _pP user's password
   * @param {Object} options
   * @param {Boolean} unlockWallet tells to unlock the wallet at the time of login or not
   * @param {String} nameSpace name of the app to which user should directly been login to.
   * @returns {Bool} login successful boolean
   */
  async login(_uN, _pP, options = {}) {
    _uN = _uN.toLowerCase();
    try {
      const defAddr = await Utils.bc.getUserDefAddr(_uN);
      console.log("UserID: ", defAddr);
      if (defAddr == Constants.ZERO_ADDRESS) {
        throw new Error("Invalid username");
      }
      _auth.set(defaultAccount, defAddr);
      const ksResponse = await Utils.moibit.getMks(defAddr, "zk");

      const validationParams = Zkp.validate(ksResponse._proof, _pP);
      if (validationParams.authStatus) {
        let ks = await Utils.moibit.getMks(
          defAddr,
          "keystore",
          validationParams.authToken
        );
        if (options.unlockWallet) {
          const hdwInstance = new HdWallet();
          await hdwInstance.createfromWrapper(ks._keystore, _pP);

          let pathToBeUsed;
          if (ks.version === 1) {
            pathToBeUsed = Constants.MOI_ID_V1_IGC_PATH;
          } else {
            pathToBeUsed = Constants.MOI_ID_IGC_PATH;
          }
          hdwInstance.deriveAccountFromPath(pathToBeUsed, true, ks.version);

          _privateMapSet(this, _hdw, hdwInstance);
          _checks.set(walletUnlocked, true);

          if (options.nameSpace) {
            if (options.nameSpace == "moibit") {
              return await this.useAccount(
                options.nameSpace,
                false,
                ks.version
              );
            } else {
              return await this.useAccount(options.nameSpace, true, 1);
            }
          }

          _igc.set(currentNameSpace, "default");
          _igc.set(currentNamespaceSpecificAddress, defAddr);
        } else {
          _auth.set(_keystore_, ks._keystore);
          _checks.set(walletUnlocked, false);
        }
        return true;
      } else {
        return false;
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   *
   * @param {String} username
   * @param {String} password
   * @param {String array of length 12} _gdf
   * @returns provisioned moi_id address for user
   */
  async importFromGDF(_uN, _pP, _gdf) {
    _uN = _uN.toLowerCase();
    try {
      if (await this.isUserNameAvailable(_uN)) {
        const hdwInstance = new HdWallet();
        hdwInstance.createFromSeedphrase(_gdf);

        _privateMapSet(this, _hdw, hdwInstance);

        /* Wallet Keystore generation */
        const ks = await _privateMapGet(this, _auth).call(this, _pP);

        /* Zero-knowledge proof generation */
        const _zkProof = Zkp.generate(_pP);

        /* Loading Moi_ID index wallet */
        hdwInstance.deriveAccountFromPath(
          Constants.MOI_ID_V1_IGC_PATH,
          true,
          1
        );

        /* MoiCipher Params generation */
        const mCipherParams = Utils.crypto.getMoiCipherParams(
          hdwInstance.getKey()
        );

        const defaultMoiIdAddress = hdwInstance.getID();
        await Utils.moibit.storeMks(
          defaultMoiIdAddress,
          ks,
          _zkProof,
          mCipherParams
        );

        _auth.set(defaultAccount, defaultMoiIdAddress);
        _checks.set(walletUnlocked, true);
        _igc.set(currentNameSpace, "default");
        _igc.set(currentNamespaceSpecificAddress, defaultMoiIdAddress);
        return defaultMoiIdAddress;
      } else {
        MoiError.userNameAlreadyExists();
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async unlock(_pP) {
    try {
      if (_checks.get(walletUnlocked) !== undefined) {
        if (!_checks.get(walletUnlocked)) {
          const hdwInstance = new HdWallet();
          await hdwInstance.createfromWrapper(_auth.get(_keystore_), _pP);

          let targetedMOIIDPath;
          if (_auth.get(_keystore_).version === 1) {
            targetedMOIIDPath = Constants.MOI_ID_V1_IGC_PATH;
          } else {
            targetedMOIIDPath = Constants.MOI_ID_IGC_PATH;
          }

          hdwInstance.deriveAccountFromPath(
            targetedMOIIDPath,
            true,
            _auth.get(_keystore_).version
          );

          _privateMapSet(this, _hdw, hdwInstance);
          _checks.set(walletUnlocked, true);
          /* need to add _igc attributes */
        } else {
          MoiError.moiValidationError(true, Constants.ALREADY_UNLOCKED);
        }
      } else {
        MoiError.moiValidationError(true, Constants.NOT_LOGIN_YET);
      }
    } catch (e) {
      MoiError.moiValidationError(true, e.message);
    }
  }
  async getAccounts() {
    try {
      const _id = _auth.get(defaultAccount);
      if (_id) {
        let accountsFromContract = await Utils.bc.getMoiDetails(_id);
        return accountsFromContract.map((accountParams) => {
          return {
            isImported: accountParams[0],
            accountAddress: accountParams[1],
            keyDigest: accountParams[2],
            namespace:
              accountParams[3] != "default"
                ? Utils.general.mDecode(accountParams[3], true)
                : accountParams[3],
            isActive: accountParams[4],
          };
        });
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   *
   * @param {Array} igcAccountParameters
   * [0] = bcID;
   * [1] = orgID;
   * [2] = appID;
   * [3] = usrGrpID;
   * @param {String} nameSpace name of the moiApp
   * @returns
   */
  async createMoiApp(igcAccountParameters, nameSpace) {
    nameSpace = nameSpace.toLowerCase();
    const appOwner = _auth.get(defaultAccount);
    MoiError.moiValidationError(
      nameSpace === undefined,
      Constants.INVALID_NAMESPACE
    );
    try {
      if (_checks.get(walletUnlocked)) {
        const igcParams = Utils.getIGCPathArray(igcAccountParameters);
        const _tempHdw = _privateMapGet(this, _hdw);
        _tempHdw.deriveAccountFromPath(igcParams, true, 1);
        const _appID = _tempHdw.getID();
        await Utils.admin.provisionNewApp(
          igcParams,
          nameSpace,
          appOwner,
          _appID
        );
        return _appID;
      } else {
        MoiError.moiValidationError(true, Constants.WALLET_LOCKED);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   *
   * @param {String} appName
   * @param {String} desc
   * @param {Object} requries
   * @param {Object} appRefs eg., { 'website': 'https://www.moibit.io', 'Product': 'https://dashboard.moibit.io' }
   * @returns
   */
  async updateAppDetails(appName, desc, requries, appRefs) {
    try {
      appName = appName.toLowerCase();
      return await Utils.moibit.cuAppInfo(appName, desc, appRefs, requries);
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * @param {String} appName
   * @returns all the app details
   */
  async getAppDetails(appName, ownerToken, appNeedsOwnerPermission) {
    MoiError.moiValidationError(
      appName === undefined,
      Constants.INVALID_NAMESPACE
    );
    try {
      appName = appName.toLowerCase();
      if (ownerToken != undefined) {
        ownerToken["message"] = Utils.general.complicateIt(ownerToken.message);
      }
      let appInfo = await Utils.bc.getNamespaceDetails(
        appName,
        appNeedsOwnerPermission,
        ownerToken
      );
      if (_checks.get(walletUnlocked)) {
        let allApps = await this.getAccounts();
        let targetedApp;
        for (let appIndex = 0; appIndex < allApps.length; appIndex++) {
          if (allApps[appIndex].namespace == appName) {
            targetedApp = allApps[appIndex];
          }
        }
        if (targetedApp) {
          appInfo["isActive"] = targetedApp.isActive;
        }
        return appInfo;
      } else {
        return appInfo;
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }

  getPrivateKey() {
    if (_checks.get(walletUnlocked)) {
      return _privateMapGet(this, _hdw).getKey();
    } else {
      MoiError.moiValidationError(true, Constants.WALLET_LOCKED);
    }
  }

  /**
   * @returns all the signature and the message that got signed
   */
  async getAuthToken(message) {
    try {
      let messageToBeSigned = "" + new Date().getTime();
      if (message && message !== "") {
        messageToBeSigned = message;
      }
      const signedMessage = await Utils.general.signMessage(
        this.getPrivateKey(),
        messageToBeSigned
      );
      return {
        message: messageToBeSigned,
        signature: signedMessage,
        id: _privateMapGet(this, _hdw).getID(),
      };
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * @param {String} nameSpace
   * @param {Boolean} addAndLoad // Loads IGC account after adding user to app.
   * @returns
   */
  async addUserToApp(nameSpace, addAndLoad, ownerToken) {
    MoiError.moiValidationError(
      nameSpace === undefined,
      Constants.INVALID_NAMESPACE
    );
    nameSpace = nameSpace.toLowerCase();
    // MoiError.validateAuthToken(ownerToken);
    if (_checks.get(walletUnlocked)) {
      const _id = _auth.get(defaultAccount);
      try {
        let namespaceStats;
        if (ownerToken != undefined) {
          namespaceStats = await this.getAppDetails(
            nameSpace,
            ownerToken,
            true
          ); // throws error if not owner token
        } else {
          namespaceStats = await this.getAppDetails(
            nameSpace,
            ownerToken,
            false
          ); // throws error if not owner token
        }

        if (!Utils.isNamespaceMember(await this.getAccounts(), nameSpace)) {
          // check for required attributes
          const igcParams = namespaceStats.igcPath;
          if (igcParams.length !== 0) {
            let targetedDerivedAccountAddress;
            if (addAndLoad) {
              _privateMapGet(this, _hdw).deriveAccountFromPath(
                igcParams,
                true,
                1
              );
              targetedDerivedAccountAddress = _privateMapGet(
                this,
                _hdw
              ).getID();
              _igc.set(currentNameSpace, nameSpace);
              _igc.set(
                currentNamespaceSpecificAddress,
                targetedDerivedAccountAddress
              );
            } else {
              const targetedDerivedAccount = _privateMapGet(
                this,
                _hdw
              ).deriveAccountFromPath(igcParams, false, 1);
              targetedDerivedAccountAddress = targetedDerivedAccount.address;
            }

            await Utils.admin.provisionNewAccount(
              _id,
              targetedDerivedAccountAddress,
              nameSpace,
              ownerToken
            );
            return targetedDerivedAccountAddress;
          } else {
            MoiError.moiIgcPathNotExists(nameSpace);
          }
        } else {
          MoiError.moiAlreadyInNamespace(nameSpace);
        }
      } catch (e) {
        throw new Error(e.message);
      }
    } else {
      MoiError.moiValidationError(true, Constants.WALLET_LOCKED);
    }
  }

  /**
   * @param {String} nameSpace
   * @returns
   */
  async useAccount(nameSpace, loadWallet, version) {
    nameSpace = nameSpace.toLowerCase();
    try {
      MoiError.moiValidationError(
        nameSpace === undefined,
        Constants.INVALID_NAMESPACE
      );
      const namespaceDetails = await Utils.bc.getNamespaceDetails(
        nameSpace,
        false
      );
      const igcParams = namespaceDetails.igcPath;
      if (igcParams.length == 0) {
        MoiError.moiIgcPathNotExists(nameSpace);
      } else {
        const allAccounts = await this.getAccounts();
        const userStatusInApp = Utils.getUserStatusInNamespace(
          allAccounts,
          nameSpace
        );
        MoiError.moiValidationError(
          !userStatusInApp.isMember,
          "User not registered to this app."
        );
        MoiError.moiValidationError(
          !userStatusInApp.isActive,
          "User revoked access from this app."
        );
        if (_checks.get(walletUnlocked)) {
          if (loadWallet) {
            _privateMapGet(this, _hdw).deriveAccountFromPath(
              igcParams,
              true,
              version
            );
            const namespaceSpecificAddr = _privateMapGet(this, _hdw).getID();
            _igc.set(currentNameSpace, nameSpace);
            _igc.set(currentNamespaceSpecificAddress, namespaceSpecificAddr);
            return {
              defaultAddr: _auth.get(defaultAccount),
              accountSpecificAddress: namespaceSpecificAddr,
            };
          } else {
            return {
              defaultAddr: _auth.get(defaultAccount),
              accountSpecificAddress: _auth.get(defaultAccount),
            };
          }
        } else {
          MoiError.moiValidationError(true, Constants.NO_WALLET_INSTANCE);
        }
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * @returns the currently loaded IGC
   */
  getCurrentIGCDetails() {
    return {
      nameSpace: _igc.get(currentNameSpace),
      address: _igc.get(currentNamespaceSpecificAddress),
      defaultAccount: _auth.get(defaultAccount),
    };
  }

  /**
   * @returns the seedPhrase of login user
   */
  getGDF() {
    if (_checks.get(walletUnlocked)) {
      return _privateMapGet(this, _hdw).getSeedPhrase();
    } else {
      MoiError.moiValidationError(true, Constants.WALLET_LOCKED);
    }
  }

  /**
   *
   * @param {String} nameSpace
   */
  async revoke(nameSpace) {
    nameSpace = nameSpace.toLowerCase();
    if (_checks.get(walletUnlocked)) {
      try {
        const userAuthToken = await this.getAuthToken();
        userAuthToken["message"] = Utils.general.complicateIt(
          userAuthToken.message
        );
        await Utils.admin.revokeAccess(
          _auth.get(defaultAccount),
          nameSpace,
          userAuthToken
        );
      } catch (E) {
        throw new Error(E.message);
      }
    } else {
      MoiError.moiValidationError(true, Constants.WALLET_LOCKED);
    }
  }

  /**
   * @param {*} _username
   * @returns
   */
  async isUserNameAvailable(_username) {
    _username = _username.toLowerCase();
    let currentUsers = _privateMapGet(this, _userList);
    if (currentUsers == undefined) {
      const _moiIDUsers = await Utils.bc.getUsersList();
      const decodedUsernames = _moiIDUsers.map((_encUserName) =>
        Utils.general.mDecode(_encUserName, false)
      );
      _privateMapSet(this, _userList, decodedUsernames);
      currentUsers = decodedUsernames;
    }
    return Utils.getUserNameAvailability(_username, currentUsers);
  }

  /**
   * @param {String} _uN
   * @param {String} _pP
   * @param {String} newPassword
   */
  async changePassword(_uN, _pP, newPassword) {
    _uN = _uN.toLowerCase();
    try {
      const defAddr = await Utils.bc.getUserDefAddr(_uN);
      if (defAddr === Constants.ZERO_ADDRESS) {
        throw new Error("Invalid username");
      }
      _auth.set(defaultAccount, defAddr);

      const ksResponse = await Utils.moibit.getMks(defAddr, "zk");
      const validationParams = Zkp.validate(ksResponse._proof, _pP);

      if (validationParams.authStatus) {
        const ks = await Utils.moibit.getMks(
          defAddr,
          "keystore",
          validationParams.authToken
        );
        let hdwInstance = new HdWallet();
        await hdwInstance.createfromWrapper(ks._keystore, _pP);
        _privateMapSet(this, _hdw, hdwInstance);

        /* Wallet Keystore generation */
        const _updatedWalletKS = await _privateMapGet(this, _auth).call(
          this,
          newPassword
        );

        /* Zero-knowledge proof generation */
        const _zkProof = Zkp.generate(newPassword);

        let targetedMOIIDPath;
        if (ks.version === 1) {
          targetedMOIIDPath = Constants.MOI_ID_V1_IGC_PATH;
        } else {
          targetedMOIIDPath = Constants.MOI_ID_IGC_PATH;
        }

        hdwInstance.deriveAccountFromPath(targetedMOIIDPath, true, ks.version);

        _privateMapSet(this, _hdw, hdwInstance);
        _checks.set(walletUnlocked, true);

        await Utils.moibit.storeMks(
          defAddr,
          _updatedWalletKS,
          _zkProof,
          ks._moiCipherParams
        );

        return true;
      } else {
        throw new Error("Invalid Old Password");
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * This function need to be called during reset password and then reset
   * @param {String[]} gdf
   * @returns
   */
  async verifyGDF(gdf) {
    try {
      const hdwInstance = new HdWallet();
      hdwInstance.createFromSeedphrase(gdf);

      /* Checking in Version 1 MOI ID PATH : valid for users signed up on and after 10th December, 2021 */
      const _currentHDW_V1 = hdwInstance.deriveAccountFromPath(
        Constants.MOI_ID_V1_IGC_PATH,
        false,
        1
      );
      let userMoiIdAddress = _currentHDW_V1.address;

      if (await Utils.moibit.verifyUser(userMoiIdAddress)) {
        _privateMapSet(this, _hdw, hdwInstance);
        _auth.set(defaultAccount, userMoiIdAddress);
        return userMoiIdAddress;
      }

      const _currentHDW_V0 = hdwInstance.deriveAccountFromPath(
        Constants.MOI_ID_IGC_PATH,
        false,
        1
      );
      userMoiIdAddress = _currentHDW_V0.address;

      if (await Utils.moibit.verifyUser(userMoiIdAddress)) {
        _privateMapSet(this, _hdw, hdwInstance);
        _auth.set(defaultAccount, userMoiIdAddress);
        return userMoiIdAddress;
      }

      return false;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * @param {String} newPassword
   */
  async resetPassword(newPassword) {
    try {
      const defAddr = _auth.get(defaultAccount);

      if (defAddr != undefined) {
        const ks = await Utils.moibit.getMks(defAddr, "cipherParams");

        /* Wallet Keystore generation */
        const _updatedWalletKS = await _privateMapGet(this, _auth).call(
          this,
          newPassword
        );

        /* Zero-knowledge proof generation */
        const _zkProof = Zkp.generate(newPassword);

        const _tempHdw = _privateMapGet(this, _hdw);
        let targetedMOIIDPath;
        if (ks.version === 1) {
          targetedMOIIDPath = Constants.MOI_ID_V1_IGC_PATH;
        } else {
          targetedMOIIDPath = Constants.MOI_ID_IGC_PATH;
        }
        _tempHdw.deriveAccountFromPath(targetedMOIIDPath, true, 1);

        _privateMapSet(this, _hdw, _tempHdw);
        _checks.set(walletUnlocked, true);

        await Utils.moibit.storeMks(
          defAddr,
          _updatedWalletKS,
          _zkProof,
          ks._moiCipherParams
        );
      } else {
        throw new Error("This operation needs GDF to be verified first");
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * @param {Address} userAddress
   * @returns all the interactions of the user
   */
  async getUserInteractions(userAddress) {
    try {
      const userInteractions = await Utils.bc.userInteractions(userAddress);
      let targetedIxns = [];
      for (let i = 0; i < userInteractions.length; i++) {
        let iX = userInteractions[i];
        function getFinalNamespace() {
          try {
            if (
              iX.namespace !== "Moi_ID" &&
              iX.namespace !== "Digital Me" &&
              iX.nameSpace !== "MOI Net"
            ) {
              iX["namespace"] = Utils.general.mDecode(iX.namespace, true);
            }
            return iX["namespace"];
          } catch (e) {
            if (e.message === "Non-base58 character") {
              return iX["namespace"];
            }
          }
        }

        iX["namespace"] = getFinalNamespace();
        targetedIxns[i] = iX;
      }
      return targetedIxns;
    } catch (e) {
      console.log(e.message);
      throw new Error(e.message);
    }
  }

  /**
   * @returns all the apps that user owned and partOf
   */
  async getUserApps() {
    try {
      let allApps = await this.getAccounts();
      if (allApps) {
        allApps.splice(0, 1);
        let ownedApps = [];
        let partOfApps = [];
        for (let roleIndex = 0; roleIndex < allApps.length; roleIndex++) {
          let appInfo = await Utils.bc.getNamespaceDetails(
            allApps[roleIndex].namespace,
            false
          );
          appInfo["isActive"] = allApps[roleIndex].isActive;
          if (appInfo.owner == _auth.get(defaultAccount)) {
            ownedApps.push(appInfo);
          } else {
            partOfApps.push(appInfo);
          }
        }
        return { ownedApps, partOfApps };
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   *
   * @param {Array} profileAttrArray : Array of ProfileAttr Object
   * ProfileAttr
   * dimension: basic/financial/health/social
   * operation: append/override
   * name: <attribute name>
   * value: <un-entrypted attribute's value
   * scope: public/private
   */
  async cuProfile(profileAttrArray, description) {
    MoiError.moiValidationError(
      !_checks.get(walletUnlocked),
      Constants.WALLET_LOCKED
    );
    try {
      const publicKeyInPem = await MAsymmetry.getPublicKeyInPem(this.getGDF());
      return await Utils.moibit.cuProfileInfo(
        _auth.get(defaultAccount),
        publicKeyInPem,
        profileAttrArray,
        description
      );
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * @param {Address}
   * @param {String} nameSpace
   * @param {ObjectArray} requiredAttrs
   * @param dimension: basic/financial/health/social
   * @param attribute: <attribute name>
   * @returns
   */
  async getProfiledetails(defaultAccount, nameSpace, requiredAttrs) {
    try {
      return await Utils.moibit.getProfileInfo(defaultAccount);
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async getUserID(_uN) {
    try {
      return await Utils.bc.getUserDefAddr(_uN);
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async getPublicInPem(_uN) {
    try {
      const _defAddr = await Utils.bc.getUserDefAddr(_uN);
      return await Utils.moibit.getAsymmPublicKey(_defAddr);
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async getPrivateInPem() {
    MoiError.moiValidationError(
      !_checks.get(walletUnlocked),
      Constants.WALLET_LOCKED
    );
    try {
      const privkeyInpem = await MAsymmetry.getPrivateKeyInPem(this.getGDF());
      return privkeyInpem;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async getAllUsers() {
    try {
      let _userEncodedArr = await Utils.bc.getUsersList();
      let _decodedUserName = _userEncodedArr.map((item) =>
        Utils.general.mDecode(item, false)
      );
      return _decodedUserName;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async saveRole(defaultAccount, roleDetails) {
    try {
      await Utils.moibit.cuAvatar(defaultAccount, roleDetails);
      return true;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async getAllInteractRoles(defaultAccount) {
    try {
      return await Utils.moibit.getRolesInfo(defaultAccount);
    } catch (e) {
      throw new Error(e.message);
    }
  }

  isWalletUnlocked() {
    return _checks.get(walletUnlocked);
  }

  async checkForDuplicationAttr(attr) {
    try {
      const _rress = await Utils.bc.checkForDuplicate(attr);
      return _rress.result;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async updateProfilePicture(profilePicture) {
    try {
      MoiError.moiValidationError(
        !_checks.get(walletUnlocked),
        Constants.WALLET_LOCKED
      );
      const _rress = await Utils.moibit.addProfilePicture(
        _auth.get(defaultAccount),
        profilePicture
      );
      return _rress.result;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async getProfilePicture(defAddr) {
    try {
      return await Utils.moibit.getProfilePicture(defAddr);
    } catch (e) {
      // throw new Error(e.message);
    }
  }

  async listAllApps() {
    try {
      const _rress = await Utils.moibit.listAllMoiApps("apps");
      return _rress.map((_encodedApp) =>
        Utils.general.mDecode(_encodedApp, true)
      );
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async fetchDefaultMoiIDAddress(_uN) {
    _uN = _uN.toLowerCase();
    return await Utils.bc.getUserDefAddr(_uN);
  }

  /* returns options to be shown during step3 in signup process */
  getBackupChallengeOptions() {
    try {
      if (_checks.get(walletUnlocked)) {
        const arraySequence = [...Array(12).keys()].map((i) => i + 1);
        const pickedOrder = lodash.sampleSize(arraySequence, 2);
        const currentSeed = _privateMapGet(this, _hdw)
          .getSeedPhrase()
          .split(" ");
        const optionNumbers = [0, 1, 2, 3];
        const wrongOptions = optionNumbers
          .slice(1)
          .map((_) => lodash.sampleSize(currentSeed, 2).join(","));
        let backUpChallengeOptions = wrongOptions;
        backUpChallengeOptions.splice(
          lodash.sample(optionNumbers),
          0,
          currentSeed[pickedOrder[0] - 1] +
            "," +
            currentSeed[pickedOrder[1] - 1]
        );
        return {
          options: backUpChallengeOptions,
          targetedOrder: pickedOrder,
        };
      } else {
        MoiError.moiValidationError(true, Constants.WALLET_LOCKED);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   *
   * @param {Array} targetOrder correct order for the challenge
   * @param {Array} userSelectedOption option that user selected
   * @returns
   */
  validateChallenge(targetOrder, userSelectedOption) {
    try {
      if (_checks.get(walletUnlocked)) {
        const currentSeed = _privateMapGet(this, _hdw)
          .getSeedPhrase()
          .split(" ");
        userSelectedOption = userSelectedOption.split(",");

        if (
          currentSeed[targetOrder[0] - 1] == userSelectedOption[0] &&
          currentSeed[targetOrder[1] - 1] == userSelectedOption[1]
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        MoiError.moiValidationError(true, Constants.WALLET_LOCKED);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * @param {Object} data payload to be signed
   * @returns
   */
  async sign(data) {
    try {
      const signedMessage = await Utils.general.signMessage(
        this.getPrivateKey(),
        data
      );
      return {
        payload: data,
        signature: signedMessage,
        address: _igc.get(currentNamespaceSpecificAddress),
      };
    } catch (e) {
      throw new Error(e.message);
    }
  }

  getDefaultMoiIDAddress() {
    return _auth.get(defaultAccount);
  }
  /* moi-nft specific */

  getSpecificIGCPathID(igcParams) {
    const pathSpecificWallet = _privateMapGet(this, _hdw).deriveAccountFromPath(
      igcParams,
      false,
      1
    );
    return pathSpecificWallet.address;
  }

  /**
   *
   * @param {Array} _srp 12 word seed phrase
   * @param {Array} _igcPath [6174, 4096, 0, 0] : can be any IGC path of the namespace
   */
  deriveWalletFromSeedPhrase(_srp, _igcPath) {
    const hdwInstance = new HdWallet();
    hdwInstance.createFromSeedphrase(_srp);
    hdwInstance.deriveAccountFromPath(_igcPath, true, 1);
    _checks.set(walletUnlocked, true);
    _privateMapSet(this, _hdw, hdwInstance);
  }

  async getPrivateAttribute(attrDigest) {
    if (_checks.get(walletUnlocked)) {
      const privateKeyInPemFormat = await this.getPrivateInPem();
      try {
        return await Utils.crypto.jsmidDecrypt(
          privateKeyInPemFormat,
          attrDigest
        );
      } catch (e) {
        throw new Error(e.message);
      }
    }
  }
}

module.exports = Moi_ID;
