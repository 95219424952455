import React from "react";
import {
  Card,
  Form,
  Button,
  Table,
  ProgressBar,
  Modal,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import "./style.css";
import "pretty-checkbox/src/pretty-checkbox.scss";
import {
  getParticularNetworkDetails,
  getNetworkNodes,
  getPartOfNetwork,
} from "../../common/networkapicalls";
import {
  listallOwnedNodes,
  dedicatedNodeList,
} from "../../common/nodeapicalls";

import GCP from "../../assets/gcp.webp";
import AWS from "../../assets/aws.png";
import Cloud from "../../assets/cloud.png";
import DO from "../../assets/do.svg";
import CheckBox from "../../assets/check.png";

class NetworkForm extends React.Component {
  state = {
    opened: [],
    checkedNodes: new Map(),
    nodeList: [],
    privateNode: [],
    nodeSelectErr: false,
    networks: [],
    partOfNodes: [],
    enablePartOf: false,
    getAllListNode: [],
    _availableNodes: [],
    totalUpdateNodes: 0,
    allNodesCount: 0,
    selectedIndex: -1,
    openCloseToggle: false,
    selectedIndex1: -1,
    openCloseToggle1: false,
    totalNodeCount: 0,

    premiumNodeModal: false,
    checkedValue: "",
    modalData: "",
    loader: false,
    nodeType: false,
  };

  toggleShowHide = (id) => {
    if (this.state.opened.indexOf(id) != -1) {
      // remove from array
      this.setState({ opened: this.state.opened.filter((o) => o !== id) });
    } else {
      this.setState({ opened: [...this.state.opened, id] });
    }
  };

  componentDidMount() {
    this.getNetworkNodes();
  }

  getNetworkValues = async (data) => {
    try {
      let _data = await getParticularNetworkDetails(data.network_id);
      let _res = await listallOwnedNodes();

      let _getNetworks = _data.nodelist;
      let _listallOwnedNodes = _res.data;
      let _availableNodes = _listallOwnedNodes.filter(
        (x) => !_getNetworks.filter((y) => y.nodeIP === x.nodeIP).length
      );

      this.setState(
        {
          partOfNodes: _data.nodelist,
          enablePartOf: true,
          _availableNodes: _availableNodes,
        },
        () => {
          this.setState({
            totalUpdateNodes:
              this.state.partOfNodes.length + _availableNodes.length,
          });
        }
      );
      this.props.disableName(
        _data,
        this.state.totalUpdateNodes,
        this.state.partOfNodes.length,
        data.admin,
        this.state.partOfNodes
      );
    } catch (err) {
      //do nothing
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps.clearNodes !== this.props.clearNodes) {
      if (this.props.clearNodes == "clear") {
        this.setState({ checkedNodes: new Map(), opened: [], partOfNodes: [] });
      }
    }
  }

  checkMoiNet = async () => {
    let joinedMoinet = false;
    let devStatVal = (await getPartOfNetwork()).data;
    for (let i = 0; i < devStatVal.length; i++) {
      if (devStatVal[i].networkName == "moinet") {
        joinedMoinet = true;
        break;
      }
    }
    return joinedMoinet ? "" : "error";
  };

  randomNumberGen0to9 = () => {
    return Math.floor(Math.random() * 5);
  };

  getNetworkNodes = async () => {
    try {
      this.setState({ loader: true });
      let _publicNodes;
      let _res;
      let _dedicated = [];
      if (this.props.plan >= 3) {
        _publicNodes = await getNetworkNodes();
        _res = await listallOwnedNodes();
        _dedicated = await dedicatedNodeList();
      } else {
        _publicNodes = await getNetworkNodes();
      }

      let _uniquePublicNodes = [];
      let _uniquePublicSet = new Set();

      if (this.props.plan >= 3) {
        if (_publicNodes.code == 200) {
          if (_publicNodes.data == null) {
            _publicNodes.data = [];
          } else {
            _publicNodes.data.map((data, index) => {
              if (!_uniquePublicSet.has(data.nodeID)) {
                _uniquePublicNodes.push(data);
                _uniquePublicSet.add(data.nodeID);
              }
            });
          }
        } else {
          _publicNodes.data = [];
        }

        if (_res.code == 200) {
          if (_res.data == null) {
            _res.data = [];
          } else {
            _res.data.map((data, index) => {
              if (!_uniquePublicSet.has(data.nodeID)) {
                _uniquePublicNodes.push(data);
                _uniquePublicSet.add(data.nodeID);
              }
            });
          }
        } else {
          _res.data = [];
        }

        if (_dedicated.code == 200) {
          if (_dedicated.data == null) {
            _dedicated.data = [];
          } else {
            _dedicated.data.map((data, index) => {
              if (!_uniquePublicSet.has(data.nodeID)) {
                _uniquePublicNodes.push(data);
                _uniquePublicSet.add(data.nodeID);
              }
            });
          }
        } else {
          _dedicated.data = [];
        }

        if (_res.data !== undefined && _res.data !== null) {
          let privateNodes = _res.data.filter(
            (data) => data.isPrivateNode == true
          );

          let _devkey = await JSON.parse(sessionStorage.getItem("defAddress"))
            .value;
          let networkListVal = [];

          let publicNodesToDisplay = [];
          for (let i = 0; i < _uniquePublicNodes.length; i++) {
            if (_devkey == _uniquePublicNodes[i].nodeOwnerID) {
              publicNodesToDisplay.push(_uniquePublicNodes[i]);
            } else if (!_uniquePublicNodes[i].isPrivateNode) {
              publicNodesToDisplay.push(_uniquePublicNodes[i]);
            }
          }
          let nodeCount = publicNodesToDisplay.length + privateNodes.length;
          this.setState({
            nodeList: publicNodesToDisplay,
            privateNode: privateNodes ? privateNodes : [],
            networks: networkListVal,
            totalNodeCount: publicNodesToDisplay.length,
            loader: false,
          });
          this.props.setTotalNodeCount(nodeCount, publicNodesToDisplay);
        }
      }
      // developer with starter, gold, basic plan
      else {
        _publicNodes.map((data, index) => {
          if (!_uniquePublicSet.has(data.nodeID)) {
            _uniquePublicNodes.push(data);
            _uniquePublicSet.add(data.nodeID);
          }
        });

        if (_publicNodes !== undefined) {
          let privateNodes = _publicNodes.filter(
            (data) => data.isPrivateNode == true
          );
          let _devkey = await JSON.parse(sessionStorage.getItem("defAddress"))
            .value;
          let networkListVal = [];

          let publicNodesToDisplay = [];
          for (let i = 0; i < _uniquePublicNodes.length; i++) {
            if (_devkey == _uniquePublicNodes[i].nodeOwnerID) {
              publicNodesToDisplay.push(_uniquePublicNodes[i]);
            } else if (!_uniquePublicNodes[i].isPrivateNode) {
              publicNodesToDisplay.push(_uniquePublicNodes[i]);
            }
          }

          let nodeCount = publicNodesToDisplay.length + privateNodes.length;

          this.setState({
            nodeList: publicNodesToDisplay,
            privateNode: privateNodes ? privateNodes : [],
            networks: networkListVal,
            totalNodeCount: publicNodesToDisplay.length,
            loader: false,
          });
          this.props.setTotalNodeCount(nodeCount);
        }
      }
    } catch (Err) {
      this.setState({ nodeList: [], loader: false });
    }
  };

  handlePremiumCheckBox = (args) => {
    const item = args.name;
    args.data.userDefinedNodeType =
      this.state.checkedValue == ""
        ? args.data.nodeType
        : this.state.checkedValue === "private"
        ? 0
        : 1;
    const isChecked = true;
    this.setState(
      (prevState) => ({
        checkedNodes: prevState.checkedNodes.set(item, {
          value: isChecked,
          data: args.data,
        }),
      }),
      () => {
        this.setState({
          modalData: "",
          premiumNodeModal: false,
          checkedValue: "",
        });
        this.props.nodeCheckBoxFunc(this.state.checkedNodes);
      }
    );
  };

  handleCheckBox = (e, data, type) => {
    if (type !== "update") {
      this.setState({
        modalData: "",
        premiumNodeModal: false,
        checkedValue: "",
      });
      this.props.nodeSelectErrFalse();
      const item = e.target.name;
      const isChecked = e.target.checked;
      data.userDefinedNodeType = data.nodeType;
      if (data.nodeType == 2) {
        if (isChecked) {
          let modalData = {
            name: item,
            data: data,
          };
          this.setState({ premiumNodeModal: true, modalData: modalData });
          return;
        } else {
          let obj = this.state.checkedNodes;
          obj.delete(item);
          this.setState({ checkedNodes: obj }, () => {
            this.props.nodeCheckBoxFunc(this.state.checkedNodes);
          });
        }
      } else {
        this.setState(
          (prevState) => ({
            checkedNodes: prevState.checkedNodes.set(item, {
              value: isChecked,
              data: data,
            }),
          }),
          () => {
            this.props.nodeCheckBoxFunc(this.state.checkedNodes);
          }
        );
      }
    } else {
      this.props.nodeSelectErrFalse();
      const item = e.target.name;
      const isChecked = e.target.checked;
      this.setState(
        (prevState) => ({
          checkedNodes: prevState.checkedNodes.set(item, {
            value: isChecked,
            data: data,
          }),
        }),
        () => {
          let val = [...this.state.checkedNodes.values()];
          let selectCount = val.filter(function (s) {
            return s.value;
          }).length;
          if (
            selectCount + this.state.partOfNodes.length <=
            this.state.totalUpdateNodes
          ) {
            this.setState({ nodeSelectErr: false });
            this.props.nodeCheckBoxFunc(this.state.checkedNodes);
          } else {
            this.setState({ nodeSelectErr: true });
          }
        }
      );
    }
  };

  toggleShowHide = (index) => {
    this.setState({
      selectedIndex: index,
      openCloseToggle: !this.state.openCloseToggle,
    });
  };

  toggleShowHide1 = (index) => {
    this.setState({
      selectedIndex1: index,
      openCloseToggle1: !this.state.openCloseToggle1,
    });
  };

  premiumModalClose = () => {
    this.setState({ premiumNodeModal: false, nodeType: false });
  };

  setPremiumNodeType = (e) => {
    this.setState({ checkedValue: e.target.value, nodeType: true });
  };

  handlePremiumCheckedSubmit = () => {
    this.handlePremiumCheckBox(this.state.modalData);
    this.setState({ premiumNodeModal: false, nodeType: false });
  };

  render() {
    return (
      <div>
        {this.state.premiumNodeModal ? (
          <Modal
            show={this.state.premiumNodeModal}
            onHide={this.premiumModalClose}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  wordBreak: "break-all",
                  whiteSpace: "normal",
                }}
              >
                {this.state.modalData.name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Please select the node type
              <div style={{ marginTop: "10px", display: "grid" }}>
                <label className="radio-label">
                  <input
                    type="radio"
                    value="private"
                    checked={this.state.checkedValue === "private"}
                    onChange={this.setPremiumNodeType}
                  />
                  &nbsp; Private
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    value="public"
                    checked={this.state.checkedValue === "public"}
                    onChange={this.setPremiumNodeType}
                  />
                  &nbsp; Public
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="info"
                onClick={this.handlePremiumCheckedSubmit}
                size="sm"
                style={{ fontWeight: "600" }}
                className="heading-css-bg"
                disabled={this.state.nodeType !== true}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}

        <Card className="mapareacard mapareacardshadow">
          <Card.Body style={{ margin: "10px" }}>
            {!this.props.updateNetwork ? (
              <div>
                <p style={{ fontWeight: "600" }} className="heading-css">
                  Nodes
                </p>
                {!this.state.loader ? (
                  <>
                    {this.state.nodeList.length == 0 ? (
                      <p>No nodes available</p>
                    ) : (
                      this.state.nodeList.map((data, index) => {
                        return (
                          <div key={index}>
                            <Card
                              style={{ marginBottom: "10px", border: "none" }}
                            >
                              <Card.Body style={{ padding: "10px" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ marginTop: "5px" }}>
                                    <Form.Group style={{ marginBottom: "0px" }}>
                                      <div className="pretty p-image p-plain">
                                        <input
                                          type="checkbox"
                                          className="styled"
                                          type="checkbox"
                                          id={"id_" + data.nodeID}
                                          name={data.region + "_" + data.nodeID}
                                          checked={
                                            this.state.checkedNodes.get(
                                              data.region + "_" + data.nodeID
                                            )
                                              ? this.state.checkedNodes.get(
                                                  data.region +
                                                    "_" +
                                                    data.nodeID
                                                ).value
                                              : false || false
                                          }
                                          onChange={(e) =>
                                            this.handleCheckBox(e, data)
                                          }
                                          disabled={
                                            data.devNetworks == undefined
                                              ? false
                                              : data.devNetworks.length >= 3
                                              ? true
                                              : false
                                          }
                                        />
                                        <div className="state">
                                          <img
                                            class="image"
                                            src={CheckBox}
                                            style={{
                                              height: "20px",
                                              width: "20px",
                                              objectFit: "contain",
                                            }}
                                          />

                                          <label className="chklabelclr">
                                            {(
                                              data.nodeName +
                                              " - " +
                                              data.region +
                                              " - " +
                                              (data.nodeType == 0
                                                ? "Private"
                                                : data.nodeType == 1
                                                ? "Public"
                                                : `Premium${
                                                    data.userDefinedNodeType ==
                                                    0
                                                      ? "(Private)"
                                                      : data.userDefinedNodeType ==
                                                        1
                                                      ? "(Public)"
                                                      : ""
                                                  }`)
                                            ).toUpperCase()}
                                          </label>
                                        </div>
                                      </div>
                                    </Form.Group>
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <div>
                                      <img
                                        src={
                                          data.cloudProvider
                                            .toLowerCase()
                                            .includes("aws")
                                            ? AWS
                                            : data.cloudProvider
                                                .toLowerCase()
                                                .includes("google")
                                            ? GCP
                                            : data.cloudProvider
                                                .toLowerCase()
                                                .includes("digital")
                                            ? DO
                                            : Cloud
                                        }
                                        className="nodelistimg"
                                      />
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "14px",
                                        marginTop: "0px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => this.toggleShowHide(index)}
                                    >
                                      <i
                                        className={
                                          this.state.selectedIndex == index &&
                                          this.state.openCloseToggle
                                            ? "fa fa-chevron-down heading-css"
                                            : "fa fa-chevron-right heading-css"
                                        }
                                        aria-hidden="true"
                                        style={{
                                          fontSize: "16px",
                                          marginTop: "9px",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                  <ProgressBar
                                    variant="info"
                                    now={
                                      data.devNetworks == undefined
                                        ? 0
                                        : data.devNetworks.length >= 3
                                        ? 100
                                        : (data.devNetworks.length / 3) * 100
                                    }
                                    label={`${
                                      data.devNetworks == undefined
                                        ? 0
                                        : data.devNetworks.length >= 3
                                        ? 100
                                        : Math.round(
                                            (data.devNetworks.length / 3) * 100
                                          )
                                    }% of node used`}
                                    style={{ fontWeight: "600" }}
                                    className={`progress-bar-color5`}
                                  />
                                </div>
                                <div>
                                  <div
                                    style={{
                                      display:
                                        this.state.selectedIndex == index &&
                                        this.state.openCloseToggle
                                          ? "block"
                                          : "none",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <Table
                                      striped
                                      bordered
                                      hover
                                      className="tablecss"
                                    >
                                      <tbody>
                                        <tr>
                                          <th>Node Name</th>
                                          <td>{data.nodeName}</td>
                                        </tr>
                                        {data.nodeOwnerID ==
                                        JSON.parse(
                                          sessionStorage.getItem("defAddress")
                                        ).value ? (
                                          <tr>
                                            <th>Node IP</th>
                                            <td>{data.nodeIP}</td>
                                          </tr>
                                        ) : null}

                                        <tr>
                                          <th>Cloud Provider</th>
                                          <td>{data.cloudProvider}</td>
                                        </tr>
                                        <tr>
                                          <th>CPU</th>
                                          <td>{data.cpu}</td>
                                        </tr>
                                        <tr>
                                          <th>RAM</th>
                                          <td>{data.ram}</td>
                                        </tr>
                                        <tr>
                                          <th>Storage</th>
                                          <td>{data.storage}</td>
                                        </tr>
                                        <tr>
                                          <th>Region</th>
                                          <td>{data.region}</td>
                                        </tr>

                                        <tr>
                                          <th>City</th>
                                          <td>{data.city}</td>
                                        </tr>
                                        <tr>
                                          <th>Country</th>
                                          <td>{data.country}</td>
                                        </tr>
                                        <tr>
                                          <th>Timezone</th>
                                          <td>{data.timeZone}</td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        );
                      })
                    )}
                  </>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <p>Fetching Nodes...</p>
                    <Spinner animation="border" />
                  </div>
                )}
              </div>
            ) : null}

            {this.props.nodeSelectErr ? (
              <p
                style={{
                  color: "crimson",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              >
                Please select {this.props.upto ? "upto" : ""}{" "}
                {this.props.upto
                  ? this.state.totalNodeCount
                  : this.props.nodeCount}{" "}
                nodes
              </p>
            ) : null}

            {/* Available Nodes */}

            {this.state.enablePartOf ? (
              this.props.updateNetwork ? (
                <div>
                  <h4
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "cadetblue",
                    }}
                  >
                    Available Nodes
                  </h4>

                  {this.state._availableNodes.length > 0 ? (
                    this.state._availableNodes.map((data, index) => {
                      return (
                        <div key={index}>
                          <Form.Group>
                            <div className="pretty p-default p-round">
                              <input
                                type="checkbox"
                                className="styled"
                                type="checkbox"
                                id={"id_" + data.nodeID}
                                name={data.region + "_" + data.nodeID}
                                checked={
                                  this.state.checkedNodes.get(
                                    data.region + "_" + data.nodeID
                                  )
                                    ? this.state.checkedNodes.get(
                                        data.region + "_" + data.nodeID
                                      ).value
                                    : false || false
                                }
                                onChange={(e) =>
                                  this.handleCheckBox(e, data, "update")
                                }
                              />
                              <div className="state p-primary">
                                <label className="chklabelclr">
                                  {(
                                    data.region +
                                    " - " +
                                    data.nodeIP
                                  ).toUpperCase()}
                                </label>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      );
                    })
                  ) : (
                    <p>No nodes available</p>
                  )}
                </div>
              ) : null
            ) : null}

            {/* End available nodes */}

            {this.state.enablePartOf ? (
              this.props.updateNetwork ? (
                <div>
                  <h4
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "cadetblue",
                    }}
                  >
                    Nodes Already Taken
                  </h4>

                  {this.state.partOfNodes.length > 0 ? (
                    this.state.partOfNodes.map((data, index) => {
                      return (
                        <div key={index}>
                          <Form.Group>
                            <div className="pretty p-default p-round">
                              <input
                                type="checkbox"
                                className="styled"
                                type="checkbox"
                                id={"id_" + data.nodeID}
                                name={data.region + "_" + data.nodeID}
                                checked={true}
                                disabled
                              />
                              <div className="state p-primary">
                                <label className="chklabelclr">
                                  {(
                                    data.region +
                                    " - " +
                                    data.nodeIP
                                  ).toUpperCase()}
                                </label>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      );
                    })
                  ) : (
                    <p>No nodes available</p>
                  )}
                </div>
              ) : null
            ) : null}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    plan: store.price.priceState,
  };
};

export default connect(mapStateToProps, null)(NetworkForm);
