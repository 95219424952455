import React, { Component } from "react";
import {
  Card,
  Table,
  Modal,
  Spinner,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { toastify } from "../../action";
import { connect } from "react-redux";
import axios from "axios";

const bandwidthlogs = [];

const style = {
  paper: {
    padding: "10px",
    margin: "5px",
    marginBottom: "0px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "10px",
  },
};

class Bandwidth extends Component {
  state = {
    bandwidthlogs: bandwidthlogs,
    effectiveLoading: 0.2,
    inDetailsRows: [],
    fromTs: "",
    toTs: "",
    selectedOperationType: -1,
    loader: false,
  };
  componentDidMount() {
    let _today = new Date();
    let currentMonth = _today.getMonth() + 1;
    currentMonth = currentMonth < 10 ? "0" + currentMonth : currentMonth;
    const currentToDate =
      _today.getFullYear() +
      "-" +
      currentMonth +
      "-" +
      (_today.getDate() < 10 ? "0" + _today.getDate() : _today.getDate());

    let before2Days = _today.getTime() - 172800000;

    before2Days = new Date(before2Days);
    let before2DaysMonth = before2Days.getMonth() + 1;
    before2DaysMonth =
      before2DaysMonth < 10 ? "0" + before2DaysMonth : before2DaysMonth;

    const currentFromDate =
      before2Days.getFullYear() +
      "-" +
      before2DaysMonth +
      "-" +
      (before2Days.getDate() < 10
        ? "0" + before2Days.getDate()
        : before2Days.getDate());
    this.setState({ fromTs: currentFromDate, toTs: currentToDate });
    setTimeout(() => {
      this.fetchBandwidthFromNetwork();
    }, 500);
  }

  fetchBandwidthFromNetwork = async () => {
    this.setState({ effectiveLoading: 0.2 });
    const getLogsURL = process.env.REACT_APP_MOIBIT_API_URL + "fetchbandwidth";

    let requestedFromDate = new Date(this.state.fromTs);
    let requestedToDate = new Date(this.state.toTs);

    requestedFromDate.setHours(0, 0, 0, 0);
    requestedToDate.setHours(23, 59, 59, 999);

    try {
      let currentUserLogs = await axios({
        url: getLogsURL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          start_time: parseInt(requestedFromDate.getTime() / 1000),
          end_time: parseInt(requestedToDate.getTime() / 1000),
          operation_type:
            this.state.selectedOperationType == "-1"
              ? undefined
              : parseInt(this.state.selectedOperationType),
        },
      });

      let filteredLogs = currentUserLogs.data.data;
      let falseArr;
      if (this.state.selectedOperationType == "-1") {
        falseArr = new Array(currentUserLogs.data.data.length).fill(false);
        this.setState({
          bandwidthlogs: currentUserLogs.data.data,
          inDetailsRows: falseArr,
          effectiveLoading: 1,
        });
      } else {
        filteredLogs = filteredLogs.filter((log) => {
          return (
            log.operation_type === parseInt(this.state.selectedOperationType)
          );
        });
        falseArr = new Array(filteredLogs.length).fill(false);
        this.setState({
          bandwidthlogs: filteredLogs,
          inDetailsRows: falseArr,
          effectiveLoading: 1,
        });
      }
    } catch (e) {
      this.setState({
        bandwidthlogs: [],
        inDetailsRows: [],
        effectiveLoading: 1,
      });
    }
  };

  determineActionType = (actionType) => {
    let targetActionType;
    switch (actionType) {
      // File operations
      case 1:
        targetActionType = "File Read";
        break;
      case 2:
        targetActionType = "File Version";
        break;
      case 3:
        targetActionType = "File Status";
        break;
      case 4:
        targetActionType = "Moibit World";
        break;
      case 5:
        targetActionType = "List File";
        break;
      case 6:
        targetActionType = "List Files per Directory";
        break;
      case 7:
        targetActionType = "List Deleted Files";
        break;
      case 8:
        targetActionType = "List Deleted Files per Directory";
        break;
      case 9:
        targetActionType = "Create Directory";
        break;
      case 10:
        targetActionType = "Storage Used";
        break;
      case 11:
        targetActionType = "Delete File";
        break;
      case 12:
        targetActionType = "Restore File";
        break;
      case 18:
        targetActionType = "Write File";
        break;
      case 19:
        targetActionType = "Write Multiple Files";
        break;
      case 20:
        targetActionType = "Write Text File";
        break;

      // Developer operations
      case 21:
        targetActionType = "Create Developer";
        break;
      case 22:
        targetActionType = "Update Developer";
        break;
      case 23:
        targetActionType = "Get Developer Details";
        break;
      case 24:
        targetActionType = "Delete Developer";
        break;
      case 25:
        targetActionType = "List Developer";
        break;

      // Node operations
      case 41:
        targetActionType = "Create Node";
        break;
      case 42:
        targetActionType = "Update Node";
        break;
      case 43:
        targetActionType = "Get Node Details";
        break;
      case 44:
        targetActionType = "Delete Node ";
        break;
      case 45:
        targetActionType = "List Nodes";
        break;
      case 46:
        targetActionType = "List Owned Nodes";
        break;
      case 47:
        targetActionType = "Validate Nodes";
        break;
      case 48:
        targetActionType = "List Premium Nodes";
        break;

      // Network opertions
      case 61:
        targetActionType = "Create Network";
        break;
      case 62:
        targetActionType = "Update Network";
        break;
      case 63:
        targetActionType = "Get Network Details";
        break;
      case 64:
        targetActionType = "Delete Network";
        break;
      case 65:
        targetActionType = "List Networks";
        break;
      case 66:
        targetActionType = "Accept Network Invitation";
        break;
      case 67:
        targetActionType = "List Owned Networks";
        break;
      case 68:
        targetActionType = "List Partoff Networks";
        break;
      case 69:
        targetActionType = "List Network Token";
        break;
      case 70:
        targetActionType = "List Nodes in Networks";
        break;
      case 71:
        targetActionType = "Send Network Invitation";
        break;
      case 72:
        targetActionType = "Network GC";
        break;

      // App operations
      case 81:
        targetActionType = "Create App";
        break;
      case 82:
        targetActionType = "Update App";
        break;
      case 83:
        targetActionType = "Get App Details";
        break;
      case 84:
        targetActionType = "Delete App";
        break;
      case 85:
        targetActionType = "List App";
        break;

      // Enduser operations
      case 101:
        targetActionType = "Create Enduser";
        break;
      case 102:
        targetActionType = "Update Enduser";
        break;
      case 103:
        targetActionType = "Get Enduser Details";
        break;
      case 104:
        targetActionType = "Delete Enduser";
        break;
      case 105:
        targetActionType = "List Enduser";
        break;

      // Auth operations
      case 121:
        targetActionType = "Create AuthKey";
        break;
      case 122:
        targetActionType = "Update AuthKey";
        break;
      case 123:
        targetActionType = "Get AuthKey Details";
        break;
      case 124:
        targetActionType = "Delete AuthKey";
        break;
      case 125:
        targetActionType = "List AuthKey";
        break;

      // Payment operations
      case 141:
        targetActionType = "Create Payment Intent";
        break;
      case 142:
        targetActionType = "Get Publisher Key";
        break;
      case 143:
        targetActionType = "Create Subscription";
        break;
      case 144:
        targetActionType = "Update Subscription";
        break;
      case 145:
        targetActionType = "Get Subscription";
        break;
      case 146:
        targetActionType = "Delete Subscription";
        break;
      case 147:
        targetActionType = "Get Payment Info";
        break;
      case 148:
        targetActionType = "Create Setup Intent";
        break;
      case 149:
        targetActionType = "Get Invoice";
        break;
      case 150:
        targetActionType = "Webhook";
        break;
      case 151:
        targetActionType = "AutoPay";
        break;
    }

    return targetActionType;
  };

  determineLogCosmetics = (interactionAction) => {
    let targetedLC;
    switch (interactionAction) {
      // file operation
      case 1:
        targetedLC = {
          bgColor: "#3034da",
          className: "fa fa-readme",
        };
        break;
      case 2:
        targetedLC = {
          bgColor: "#bbb9bd",
          className: "fa fa-file-lines",
        };
        break;
      case 3:
        targetedLC = {
          bgColor: "#3034da",
          className: "fa fa-file-circle-exclamation",
        };
        break;
      case 4:
        targetedLC = {
          bgColor: "#bbb9bd",
          className: "fa fa-file-shield",
        };
        break;
      case 5:
        targetedLC = {
          bgColor: "#c7323e",
          className: "fas fa-list",
        };
        break;
      case 6:
        targetedLC = {
          bgColor: "#00807b",
          className: "fa fa-folder-open",
        };
        break;
      case 7:
        targetedLC = {
          bgColor: "#c7323e",
          className: "fa fa-trash-list",
        };
        break;
      case 8:
        targetedLC = {
          bgColor: "orange",
          className: "fa fa-folder-tree",
        };
        break;
      case 9:
        targetedLC = {
          bgColor: "#00807b",
          className: "fa fa-folder",
        };
        break;
      case 10:
        targetedLC = {
          bgColor: "black",
          className: "fa fa-database",
        };
        break;
      case 11:
        targetedLC = {
          bgColor: "red",
          className: "fa fa-cut",
        };
        break;
      case 12:
        targetedLC = {
          bgColor: "#00807b",
          className: "fas fa-trash-restore",
        };
        break;
      case 18:
        targetedLC = {
          bgColor: "#582bfc",
          className: "fa fa-file",
        };
        break;
      case 19:
        targetedLC = {
          bgColor: "#582bfc",
          className: "fa fa-folder",
        };
        break;
      case 20:
        targetedLC = {
          bgColor: "#582bfc",
          className: "fa fa-file-pen",
        };
        break;

      // Developer
      case 21:
        targetedLC = {
          bgColor: "#582bfc",
          className: "fa fa-user",
        };
        break;
      case 22:
        targetedLC = {
          bgColor: "orange",
          className: "fas fa-use-plus",
        };
        break;
      case 23:
        targetedLC = {
          bgColor: "black",
          className: "fa fa-address-book",
        };
        break;
      case 24:
        targetedLC = {
          bgColor: "red",
          className: "fa fa-user-slash",
        };
        break;
      case 25:
        targetedLC = {
          bgColor: "#bbb9bd",
          className: "fa fa-address-book",
        };
        break;

      // Node
      case 41:
        targetedLC = {
          bgColor: "#582bfc",
          className: "fa fa-laptop",
        };
        break;
      case 42:
        targetedLC = {
          bgColor: "orange",
          className: "fa fa-laptop",
        };
        break;
      case 43:
        targetedLC = {
          bgColor: "black",
          className: "fa fa-laptop",
        };
        break;
      case 44:
        targetedLC = {
          bgColor: "red",
          className: "fa fa-laptop",
        };
        break;
      case 45:
        targetedLC = {
          bgColor: "#bbb9bd",
          className: "fa fa-laptop",
        };
        break;
      case 46:
        targetedLC = {
          bgColor: "#00807b",
          className: "fa fa-laptop",
        };
        break;
      case 47:
        targetedLC = {
          bgColor: "#bbb9bd",
          className: "fa fa-laptop",
        };
        break;
      case 48:
        targetedLC = {
          bgColor: "#c7323e",
          className: "fa fa-laptop",
        };
        break;

      // Network
      case 61:
        targetedLC = {
          bgColor: "#8c29c2",
          className: "fas fa-network-wired",
        };
        break;
      case 62:
        targetedLC = {
          bgColor: "#2d31a6",
          className: "fa fa-sitemap",
        };
        break;
      case 63:
        targetedLC = {
          bgColor: "black",
          className: "fas fa-network-wired",
        };
        break;
      case 64:
        targetedLC = {
          bgColor: "red",
          className: "fas fa-network-wired",
        };
        break;
      case 65:
        targetedLC = {
          bgColor: "#bbb9bd",
          className: "fas fa-network-wired",
        };
        break;
      case 66:
        targetedLC = {
          bgColor: "#00807b",
          className: "fa fa-paper-plane",
        };
        break;
      case 67:
        targetedLC = {
          bgColor: "#c7323e",
          className: "fa fa-sitemap",
        };
        break;
      case 68:
        targetedLC = {
          bgColor: "#bbb9bd",
          className: "fa fa-sitemap",
        };
        break;
      case 69:
        targetedLC = {
          bgColor: "#9b42ed",
          className: "fa fa-sitemap",
        };
        break;
      case 70:
        targetedLC = {
          bgColor: "orange",
          className: "fa fa-sitemap",
        };
        break;
      case 71:
        targetedLC = {
          bgColor: "#420cad",
          className: "fa fa-paper-plane",
        };
        break;
      case 48:
        targetedLC = {
          bgColor: "#c7323e",
          className: "fa fa-trash-can",
        };
        break;

      // App
      case 81:
        targetedLC = {
          bgColor: "blue",
          className: "fa fa-map",
        };
        break;
      case 82:
        targetedLC = {
          bgColor: "orange",
          className: "fa fa-map",
        };
        break;
      case 83:
        targetedLC = {
          bgColor: "black",
          className: "fa fa-map",
        };
        break;
      case 84:
        targetedLC = {
          bgColor: "red",
          className: "fa fa-map",
        };
        break;
      case 85:
        targetedLC = {
          bgColor: "#bbb9bd",
          className: "fa fa-map",
        };
        break;

      // Enduser
      case 81:
        targetedLC = {
          bgColor: "blue",
          className: "fa fa-circle-user",
        };
        break;
      case 82:
        targetedLC = {
          bgColor: "orange",
          className: "fa fa-circle-user",
        };
        break;
      case 83:
        targetedLC = {
          bgColor: "black",
          className: "fa fa-circle-user",
        };
        break;
      case 84:
        targetedLC = {
          bgColor: "red",
          className: "fa fa-circle-user",
        };
        break;
      case 85:
        targetedLC = {
          bgColor: "#bbb9bd",
          className: "fa fa-circle-user",
        };
        break;

      // Auth
      case 81:
        targetedLC = {
          bgColor: "blue",
          className: "fa fa-key",
        };
        break;
      case 82:
        targetedLC = {
          bgColor: "orange",
          className: "fa fa-key",
        };
        break;
      case 83:
        targetedLC = {
          bgColor: "black",
          className: "fa fa-key",
        };
        break;
      case 84:
        targetedLC = {
          bgColor: "red",
          className: "fa fa-key",
        };
        break;
      case 85:
        targetedLC = {
          bgColor: "#bbb9bd",
          className: "fa fa-key",
        };
        break;
      default:
        targetedLC = {
          bgColor: "#582bfc",
          className: "fa fa-file",
        };
        break;
    }
    return targetedLC;
  };

  triggerOperationTypeFilter = (otKey) => {
    this.setState({ currentOTFvalue: otKey });
  };

  getTitleFromOT = () => {
    let targetedOTTitle;
    switch (parseInt(this.state.selectedOperationType)) {
      case -1:
        targetedOTTitle = "All Operations";
        break;
      // File operations
      case 1:
        targetedOTTitle = "File Read";
        break;
      case 2:
        targetedOTTitle = "File Version";
        break;
      case 3:
        targetedOTTitle = "File Status";
        break;
      case 4:
        targetedOTTitle = "Moibit World";
        break;
      case 5:
        targetedOTTitle = "List File";
        break;
      case 6:
        targetedOTTitle = "List Files per Directory";
        break;
      case 7:
        targetedOTTitle = "List Deleted Files";
        break;
      case 8:
        targetedOTTitle = "List Deleted Files per Directory";
        break;
      case 9:
        targetedOTTitle = "Create Directory";
        break;
      case 10:
        targetedOTTitle = "Storage Used";
        break;
      case 11:
        targetedOTTitle = "Delete File";
        break;
      case 12:
        targetedOTTitle = "Restore File";
        break;
      case 18:
        targetedOTTitle = "Write File";
        break;
      case 19:
        targetedOTTitle = "Write Multiple Files";
        break;
      case 20:
        targetedOTTitle = "Write Text File";
        break;

      // Developer operations
      case 21:
        targetedOTTitle = "Create Developer";
        break;
      case 22:
        targetedOTTitle = "Update Developer";
        break;
      case 23:
        targetedOTTitle = "Get Developer Details";
        break;
      case 24:
        targetedOTTitle = "Delete Developer";
        break;
      case 25:
        targetedOTTitle = "List Developer";
        break;

      // Node operations
      case 41:
        targetedOTTitle = "Create Node";
        break;
      case 42:
        targetedOTTitle = "Update Node";
        break;
      case 43:
        targetedOTTitle = "Get Node Details";
        break;
      case 44:
        targetedOTTitle = "Delete Node ";
        break;
      case 45:
        targetedOTTitle = "List Nodes";
        break;
      case 46:
        targetedOTTitle = "List Owned Nodes";
        break;
      case 47:
        targetedOTTitle = "Validate Nodes";
        break;
      case 48:
        targetedOTTitle = "List Premium Nodes";
        break;

      // Network opertions
      case 61:
        targetedOTTitle = "Create Network";
        break;
      case 62:
        targetedOTTitle = "Update Network";
        break;
      case 63:
        targetedOTTitle = "Get Network Details";
        break;
      case 64:
        targetedOTTitle = "Delete Network";
        break;
      case 65:
        targetedOTTitle = "List Networks";
        break;
      case 66:
        targetedOTTitle = "Accept Network Invitation";
        break;
      case 67:
        targetedOTTitle = "List Owned Networks";
        break;
      case 68:
        targetedOTTitle = "List Partoff Networks";
        break;
      case 69:
        targetedOTTitle = "List Network Token";
        break;
      case 70:
        targetedOTTitle = "List Nodes in Networks";
        break;
      case 71:
        targetedOTTitle = "Send Network Invitation";
        break;
      case 72:
        targetedOTTitle = "Network GC";
        break;

      // App operations
      case 81:
        targetedOTTitle = "Create App";
        break;
      case 82:
        targetedOTTitle = "Update App";
        break;
      case 83:
        targetedOTTitle = "Get App Details";
        break;
      case 84:
        targetedOTTitle = "Delete App";
        break;
      case 85:
        targetedOTTitle = "List App";
        break;

      // Enduser operations
      case 101:
        targetedOTTitle = "Create Enduser";
        break;
      case 102:
        targetedOTTitle = "Update Enduser";
        break;
      case 103:
        targetedOTTitle = "Get Enduser Details";
        break;
      case 104:
        targetedOTTitle = "Delete Enduser";
        break;
      case 105:
        targetedOTTitle = "List Enduser";
        break;

      // Auth operations
      case 121:
        targetedOTTitle = "Create AuthKey";
        break;
      case 122:
        targetedOTTitle = "Update AuthKey";
        break;
      case 123:
        targetedOTTitle = "Get AuthKey Details";
        break;
      case 124:
        targetedOTTitle = "Delete AuthKey";
        break;
      case 125:
        targetedOTTitle = "List AuthKey";
        break;

      // Payment operations
      case 141:
        targetedOTTitle = "Create Payment Intent";
        break;
      case 142:
        targetedOTTitle = "Get Publisher Key";
        break;
      case 143:
        targetedOTTitle = "Create Subscription";
        break;
      case 144:
        targetedOTTitle = "Update Subscription";
        break;
      case 145:
        targetedOTTitle = "Get Subscription";
        break;
      case 146:
        targetedOTTitle = "Delete Subscription";
        break;
      case 147:
        targetedOTTitle = "Get Payment Info";
        break;
      case 148:
        targetedOTTitle = "Create Setup Intent";
        break;
      case 149:
        targetedOTTitle = "Get Invoice";
        break;
      case 150:
        targetedOTTitle = "Webhook";
        break;
      case 151:
        targetedOTTitle = "AutoPay";
        break;
    }
    return targetedOTTitle;
  };

  render() {
    return (
      <main style={{ width: "100%" }}>
        <div style={style.toolbar} />
        <div style={{ margin: "30px", marginTop: "0px" }}>
          <Modal
            show={this.state.loader}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Body>
              <div style={{ margin: "20px", textAlign: "center" }}>
                <Spinner animation="border" />
              </div>
            </Modal.Body>
          </Modal>
          <div className="row">
            <div className="col-md-6">
              <div
                style={{ fontSize: "18px", fontWeight: "600" }}
                className="heading-css"
              >
                Bandwidth
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div style={{ width: "15%", marginLeft: "5px" }}>
                  <label for="from_date" className="fontweight600">
                    From
                  </label>
                  <input
                    style={{
                      height: "32px",
                      fontSize: "12px",
                      fontFamily: "sans-serif",
                    }}
                    id="from_date"
                    type="date"
                    defaultValue={this.state.fromTs}
                    max={this.state.toTs}
                    onChange={(e) => {
                      this.setState({ fromTs: e.target.value });
                      setTimeout(() => {
                        this.fetchBandwidthFromNetwork();
                      }, 500);
                    }}
                    value={this.state.fromTs}
                  />
                </div>
                <div style={{ width: "15%", marginLeft: "5px" }}>
                  <label for="to_date" className="fontweight600">
                    To
                  </label>
                  <input
                    style={{
                      height: "32px",
                      fontSize: "12px",
                      fontFamily: "sans-serif",
                    }}
                    id="to_date"
                    type="date"
                    defaultValue={this.state.toTs}
                    min={this.state.fromTs}
                    onChange={(e) => {
                      this.setState({ toTs: e.target.value });
                      setTimeout(() => {
                        this.fetchBandwidthFromNetwork();
                      }, 500);
                    }}
                    value={this.state.toTs}
                  />
                </div>
                <div style={{ marginLeft: "5px" }}>
                  <label for="to_date" className="fontweight600">
                    Operation Type
                  </label>
                  <OperationTypeFilter
                    triggerOTFilter={(otKey) => {
                      this.setState({ selectedOperationType: otKey });
                      setTimeout(() => {
                        this.fetchBandwidthFromNetwork();
                      }, 500);
                    }}
                    otTitle={this.getTitleFromOT()}
                    activeOTvalue={this.state.selectedOperationType}
                  />
                </div>
              </div>
            </div>
          </div>

          <Card style={{ backgroundColor: "#faf9f8", border: "none" }}>
            <Card.Body style={{ overflowY: "scroll" }}>
              <Table
                className="no-wrap  v-middle"
                responsive
                style={{ opacity: this.state.effectiveLoading }}
              >
                <tbody>
                  {this.state.bandwidthlogs.length > 0 ? (
                    <tr
                      style={{
                        color: "#000",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      <td
                        style={{
                          borderBottom: "2px solid #5f97ce",
                          borderTop: "none",
                        }}
                      >
                        {" "}
                        {this.state.bandwidthlogs.length} logs
                      </td>

                      <td
                        style={{
                          borderBottom: "2px solid #5f97ce",
                          borderTop: "none",
                        }}
                      >
                        Type of Operation
                      </td>

                      <td
                        style={{
                          borderBottom: "2px solid #5f97ce",
                          borderTop: "none",
                        }}
                      >
                        Bandwidth
                      </td>

                      <td
                        style={{
                          borderBottom: "2px solid #5f97ce",
                          borderTop: "none",
                        }}
                      >
                        Time
                      </td>
                      <td
                        style={{
                          borderBottom: "2px solid #5f97ce",
                          borderTop: "none",
                        }}
                      />
                    </tr>
                  ) : null}

                  {this.state.bandwidthlogs.length > 0 ? (
                    this.state.bandwidthlogs
                      .slice(0)
                      .reverse()
                      .map((data, index) => {
                        const logTypeCosmetics = this.determineLogCosmetics(
                          data.operation_type
                        );
                        return (
                          <>
                            <tr key={index}>
                              <td
                                style={{
                                  borderBottom: "1px solid #dee2e6",
                                  borderTop: "none",
                                }}
                              >
                                <div className="d-flex no-block align-items-center">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: logTypeCosmetics.bgColor,
                                        width: "35px",
                                        height: "35px",
                                        borderRadius: "50px",
                                        textAlign: "center",
                                        fontSize: "22px",
                                        paddingTop: "0px",
                                        fontWeight: "normal",
                                        marginRight: "20px",
                                      }}
                                    >
                                      <i
                                        className={logTypeCosmetics.className}
                                        style={{ fontSize: "24px" }}
                                      ></i>
                                    </div>
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        marginTop: "5px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {" "}
                                      {data.namespace}
                                    </span>
                                  </div>
                                </div>
                              </td>

                              <td
                                style={{
                                  borderBottom: "1px solid #dee2e6",
                                  borderTop: "none",
                                }}
                              >
                                <span
                                  style={{ marginTop: "5px", fontSize: "13px" }}
                                >
                                  {" "}
                                  {this.determineActionType(
                                    data.operation_type
                                  )}
                                </span>
                              </td>

                              <td
                                style={{
                                  borderBottom: "1px solid #dee2e6",
                                  borderTop: "none",
                                }}
                              >
                                <span
                                  style={{ marginTop: "5px", fontSize: "13px" }}
                                >
                                  {data.bandwidth} GB
                                </span>
                              </td>

                              <td
                                style={{
                                  borderBottom: "1px solid #dee2e6",
                                  borderTop: "none",
                                }}
                              >
                                <span
                                  style={{ marginTop: "5px", fontSize: "13px" }}
                                >
                                  {" "}
                                  {"" + new Date(data.time_stamp * 1000)}
                                </span>
                              </td>
                            </tr>
                          </>
                        );
                      })
                  ) : (
                    <p style={{ fontSize: "14px", marginTop: "10px" }}>
                      {" "}
                      {this.state.effectiveLoading == 1
                        ? "No records found"
                        : "Loading..."}{" "}
                    </p>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    plan: store.price.priceState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toastifyEnable: (type, val) => dispatch(toastify(type, val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bandwidth);

function OperationTypeFilter(props) {
  return (
    <div style={{ marginTop: "3px" }}>
      <DropdownButton
        size="sm"
        title={props.otTitle}
        onSelect={(eventKey, _) => props.triggerOTFilter(eventKey)}
        className="dropdown-css"
      >
        <Dropdown.Item eventKey={-1} active={props.activeOTvalue == -1}>
          All Operations
        </Dropdown.Item>
        <Dropdown.Item eventKey={1} active={props.activeOTvalue == 1}>
          File Read
        </Dropdown.Item>
        <Dropdown.Item eventKey={2} active={props.activeOTvalue == 2}>
          File Version
        </Dropdown.Item>
        <Dropdown.Item eventKey={3} active={props.activeOTvalue == 3}>
          File Status
        </Dropdown.Item>
        <Dropdown.Item eventKey={4} active={props.activeOTvalue == 4}>
          Moibit World
        </Dropdown.Item>
        <Dropdown.Item eventKey={5} active={props.activeOTvalue == 5}>
          List File
        </Dropdown.Item>
        <Dropdown.Item eventKey={6} active={props.activeOTvalue == 6}>
          List Files per Directory
        </Dropdown.Item>
        <Dropdown.Item eventKey={7} active={props.activeOTvalue == 7}>
          List Deleted Files
        </Dropdown.Item>
        <Dropdown.Item eventKey={8} active={props.activeOTvalue == 8}>
          List Deleted Files per Directory
        </Dropdown.Item>
        <Dropdown.Item eventKey={9} active={props.activeOTvalue == 9}>
          Create Directory
        </Dropdown.Item>
        <Dropdown.Item eventKey={10} active={props.activeOTvalue == 10}>
          Storage Used
        </Dropdown.Item>
        <Dropdown.Item eventKey={11} active={props.activeOTvalue == 11}>
          Delete File
        </Dropdown.Item>
        <Dropdown.Item eventKey={12} active={props.activeOTvalue == 12}>
          Restore File
        </Dropdown.Item>
        <Dropdown.Item eventKey={18} active={props.activeOTvalue == 18}>
          Write File
        </Dropdown.Item>
        <Dropdown.Item eventKey={19} active={props.activeOTvalue == 19}>
          Write Multiple Files
        </Dropdown.Item>
        <Dropdown.Item eventKey={20} active={props.activeOTvalue == 20}>
          Write Text File
        </Dropdown.Item>
        <Dropdown.Item eventKey={21} active={props.activeOTvalue == 21}>
          Create Developer
        </Dropdown.Item>
        <Dropdown.Item eventKey={22} active={props.activeOTvalue == 22}>
          Update Developer
        </Dropdown.Item>
        <Dropdown.Item eventKey={23} active={props.activeOTvalue == 23}>
          Get Developer Details
        </Dropdown.Item>
        <Dropdown.Item eventKey={24} active={props.activeOTvalue == 24}>
          Delete Developer
        </Dropdown.Item>
        <Dropdown.Item eventKey={25} active={props.activeOTvalue == 25}>
          List Developer
        </Dropdown.Item>
        <Dropdown.Item eventKey={41} active={props.activeOTvalue == 41}>
          Create Node
        </Dropdown.Item>
        <Dropdown.Item eventKey={42} active={props.activeOTvalue == 42}>
          Update Node
        </Dropdown.Item>
        <Dropdown.Item eventKey={43} active={props.activeOTvalue == 43}>
          Get Node Details
        </Dropdown.Item>
        <Dropdown.Item eventKey={44} active={props.activeOTvalue == 44}>
          Delete Node
        </Dropdown.Item>
        <Dropdown.Item eventKey={45} active={props.activeOTvalue == 45}>
          List Node
        </Dropdown.Item>
        <Dropdown.Item eventKey={46} active={props.activeOTvalue == 46}>
          List Owned Node
        </Dropdown.Item>
        <Dropdown.Item eventKey={47} active={props.activeOTvalue == 47}>
          Validate Node
        </Dropdown.Item>
        <Dropdown.Item eventKey={48} active={props.activeOTvalue == 48}>
          List Premium Node
        </Dropdown.Item>
        <Dropdown.Item eventKey={61} active={props.activeOTvalue == 61}>
          Create Network
        </Dropdown.Item>
        <Dropdown.Item eventKey={62} active={props.activeOTvalue == 62}>
          Update Network
        </Dropdown.Item>
        <Dropdown.Item eventKey={63} active={props.activeOTvalue == 63}>
          Get Network Details
        </Dropdown.Item>
        <Dropdown.Item eventKey={64} active={props.activeOTvalue == 64}>
          Delete Network
        </Dropdown.Item>
        <Dropdown.Item eventKey={65} active={props.activeOTvalue == 65}>
          List Network
        </Dropdown.Item>
        <Dropdown.Item eventKey={66} active={props.activeOTvalue == 66}>
          Accept Network Invitation
        </Dropdown.Item>
        <Dropdown.Item eventKey={67} active={props.activeOTvalue == 67}>
          List Owned Network
        </Dropdown.Item>
        <Dropdown.Item eventKey={68} active={props.activeOTvalue == 68}>
          List Partoff Network
        </Dropdown.Item>
        <Dropdown.Item eventKey={69} active={props.activeOTvalue == 69}>
          List Network Token
        </Dropdown.Item>
        <Dropdown.Item eventKey={70} active={props.activeOTvalue == 70}>
          List Nodes in Network
        </Dropdown.Item>
        <Dropdown.Item eventKey={71} active={props.activeOTvalue == 71}>
          Send Network Invitation
        </Dropdown.Item>
        <Dropdown.Item eventKey={72} active={props.activeOTvalue == 72}>
          Network GC
        </Dropdown.Item>

        <Dropdown.Item eventKey={81} active={props.activeOTvalue == 81}>
          Create App
        </Dropdown.Item>
        <Dropdown.Item eventKey={82} active={props.activeOTvalue == 82}>
          Update App
        </Dropdown.Item>
        <Dropdown.Item eventKey={83} active={props.activeOTvalue == 83}>
          Get App Details
        </Dropdown.Item>
        <Dropdown.Item eventKey={84} active={props.activeOTvalue == 84}>
          Delete App
        </Dropdown.Item>
        <Dropdown.Item eventKey={85} active={props.activeOTvalue == 85}>
          List App
        </Dropdown.Item>
        <Dropdown.Item eventKey={101} active={props.activeOTvalue == 101}>
          Create Enduser
        </Dropdown.Item>
        <Dropdown.Item eventKey={102} active={props.activeOTvalue == 102}>
          Update Enduser
        </Dropdown.Item>
        <Dropdown.Item eventKey={103} active={props.activeOTvalue == 103}>
          Get Enduser Details
        </Dropdown.Item>
        <Dropdown.Item eventKey={104} active={props.activeOTvalue == 104}>
          Delete Enduser
        </Dropdown.Item>
        <Dropdown.Item eventKey={105} active={props.activeOTvalue == 105}>
          List Enduser
        </Dropdown.Item>
        <Dropdown.Item eventKey={121} active={props.activeOTvalue == 121}>
          Create AuthKey
        </Dropdown.Item>
        <Dropdown.Item eventKey={122} active={props.activeOTvalue == 122}>
          Update AuthKey
        </Dropdown.Item>
        <Dropdown.Item eventKey={123} active={props.activeOTvalue == 123}>
          Get AuthKey Details
        </Dropdown.Item>
        <Dropdown.Item eventKey={124} active={props.activeOTvalue == 124}>
          Delete AuthKey
        </Dropdown.Item>
        <Dropdown.Item eventKey={125} active={props.activeOTvalue == 125}>
          List AuthKey
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
}
