import { SESSION_CHECK, CLOSE_WARNING, UNLOCK_WALLET } from "./types";
import { toast } from "react-toastify";

export const sessionCheck = () => {
  return (dispatch) => {
    dispatch({ type: SESSION_CHECK, value: true });
  };
};

export const sessionClose = () => {
  return (dispatch) => {
    dispatch({ type: SESSION_CHECK, value: false });
  };
};

export const closeWarningFunc = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_WARNING, value: true });
  };
};

export const toastify = (type, val) => {
  return (dispatch) => {
    if (type == "info") {
      toast.info(val, {
        autoClose: 5000,
        position: toast.POSITION.BOTTOM_LEFT,
        className: "heading-css-bg",
      });
    } else if (type == "err") {
      toast.error(val, {
        autoClose: 10000,
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
};

export const unLockWallet = (password) => {
  return async (dispatch) => {
    try {
      let userName = JSON.parse(sessionStorage.getItem("name")).value;
      dispatch({ type: UNLOCK_WALLET, loading: true, error: false });
      let _unLockRes = await window.moi_id.login(userName, password, {
        unlockWallet: true,
      });
      if (_unLockRes) {
        let _res = await window.moi_id.getAuthToken();
        let authToken = _res.message + "_" + _res.signature;
        const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours from now

        sessionStorage.setItem(
          "defAddress",
          JSON.stringify({ value: _res.id, expiresAt: expirationTime })
        );
        sessionStorage.setItem(
          "authToken",
          JSON.stringify({ value: authToken, expiresAt: expirationTime })
        );
        sessionStorage.setItem(
          "name",
          JSON.stringify({
            value: userName,
            expiresAt: expirationTime,
          })
        );

        dispatch({ type: UNLOCK_WALLET, loading: false, error: false });
        dispatch({ type: SESSION_CHECK, value: false });
      } else {
        dispatch({ type: UNLOCK_WALLET, loading: false, error: true });
      }
    } catch (err) {
      dispatch({ type: UNLOCK_WALLET, loading: false, error: true });
    }
  };
};
