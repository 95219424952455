import { GET_ALL_APPS } from "./types";
import { getPartOfNetwork } from "../common/networkapicalls";
import { devAdmindetails } from "../common/devapicalls";

export const getDevApps = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_APPS, loading: true });
      let _val = await devAdmindetails();
      let applist = _val.data.appList;

      let list = Object.values(applist);
      let appKeys = Object.keys(applist);
      if (appKeys.length == 0) {
        dispatch({ type: GET_ALL_APPS, loading: false, value: [], count: 0 });
        return;
      } else {
        let _appRes = [];
        for (let i = 0; i < appKeys.length; i++) {
          _appRes.push({
            appID: appKeys[i],
            appName: list[i].appName,
            networkName: list[i].networkName,
            isActive: list[i].isActive,
            isRemoved: list[i].isRemoved,
            networkID: list[i].networkID,
          });

          let finalArr = [];
          finalArr = [..._appRes];

          let activeAppCount = 0;
          activeAppCount = finalArr.filter((data) => !data.isRemoved).length;
          dispatch({
            type: GET_ALL_APPS,
            loading: false,
            value: finalArr,
            count: activeAppCount,
          });
        }
      }
    } catch (err) {
      dispatch({ type: GET_ALL_APPS, loading: false, value: [], count: 0 });
    }
  };
};
