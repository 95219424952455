import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

const copyToclipBoard = async (which) => {
  const el = document.createElement("textarea");
  el.value = which;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  document.getElementById("buttoncopy").innerHTML = "Copied!!!";
  setTimeout(() => {
    if (document.getElementById("buttoncopy")) {
      document.getElementById("buttoncopy").innerHTML = "Copy";
    }
  }, 2000);
};

const AppDetails = (props) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} centered size="md">
      {props.appDetailsResult.appName ? (
        <div>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontWeight: "600", fontSize: "14px" }}>
              {props.appDetailsResult.appName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="appdetailstext" style={{ color: "#000" }}>
                <span style={{ fontWeight: "600" }}>App ID :</span>{" "}
              </p>
              <Button
                variant="info"
                size="sm"
                style={{ height: "30px", width: "100px", fontWeight: "600" }}
                className="heading-css-bg"
                onClick={() => copyToclipBoard(props.appDetailsResult.appID)}
                id="buttoncopy"
              >
                Copy
              </Button>
            </div>
            <p className="appdetailstext">{props.appDetailsResult.appID}</p>
            <p className="appdetailstext" style={{ color: "#000" }}>
              <span style={{ fontWeight: "600" }}>Description :</span>{" "}
            </p>
            <p className="appdetailstext">
              {props.appDetailsResult.appDescription}
            </p>
            <p className="appdetailstext" style={{ color: "#000" }}>
              <span style={{ fontWeight: "600" }}>Network :</span>{" "}
            </p>
            <p className="appdetailstext">
              {props.appDetailsResult.networkName == ""
                ? "Moi_Net"
                : props.appDetailsResult.networkName}
            </p>
            <p className="appdetailstext" style={{ color: "#000" }}>
              <span style={{ fontWeight: "600" }}>Replication :</span>{" "}
            </p>
            <p className="appdetailstext">
              {props.appDetailsResult.replication}
            </p>
            <p className="appdetailstext" style={{ color: "#000" }}>
              <span style={{ fontWeight: "600" }}>Encryption Type :</span>{" "}
            </p>
            <p className="appdetailstext">
              {props.appDetailsResult.encryptionType === 0
                ? "Network Level (Default) Encryption"
                : props.appDetailsResult.encryptionType === 1
                ? "Developer key Encryption"
                : props.appDetailsResult.encryptionType === 2
                ? "Encryption with the user key"
                : props.appDetailsResult.encryptionType === 3
                ? "Custom key Encryption"
                : props.appDetailsResult.encryptionType === 4
                ? "End-to-End Encryption"
                : props.appDetailsResult.encryptionType === -1
                ? "No Encryption"
                : "Unknown"}
            </p>
          </Modal.Body>
        </div>
      ) : (
        <Modal.Body style={{ textAlign: "center" }}>
          {" "}
          <Spinner animation="border" />
        </Modal.Body>
      )}
    </Modal>
  );
};

export default AppDetails;
