import React from "react";
import {
  Button,
  Modal,
  Form,
  Table,
  Spinner,
  ProgressBar,
} from "react-bootstrap";
import {
  listallOwnedNodes,
  dedicatedNodeList,
} from "../../common/nodeapicalls";
import { updateNetwork, getNetworkNodes } from "../../common/networkapicalls";
import "./style.css";
import "pretty-checkbox/src/pretty-checkbox.scss";
import { connect } from "react-redux";
import { toastify } from "../../action";
import CheckBox from "../../assets/check.png";

class AddNodesToNetwork extends React.Component {
  state = {
    nodeName: "",
    nodeIP: "",
    nodeDetails: [],
    availableNodes: [],
    checkedNodes: new Map(),
    nodeValue: [],
    loading: false,
    err: false,
    errVal: "",
    premiumNodeModal: false,
    checkedValue: "",
    modalData: "",
    selectNodeTypeErr: false,
    fetchLoader: false,
  };
  async componentDidMount() {
    this.filterNodes();
  }

  filterNodes = async () => {
    this.setState({ fetchLoader: true });
    // new code
    let _publicNodes;
    let _res;
    let _dedicated = [];
    if (this.props.plan >= 3) {
      _publicNodes = await getNetworkNodes();
      _res = await listallOwnedNodes();
      _dedicated = await dedicatedNodeList();
    } else {
      _publicNodes = await getNetworkNodes();
    }

    let _uniquePublicNodes = [];

    let _uniquePublicSet = new Set();

    if (this.props.plan >= 3) {
      if (_publicNodes.code == 200) {
        if (_publicNodes.data == null) {
          _publicNodes.data = [];
        } else {
          _publicNodes.data.map((data, index) => {
            if (!_uniquePublicSet.has(data.nodeID)) {
              _uniquePublicNodes.push(data);
              _uniquePublicSet.add(data.nodeID);
            }
          });
        }
      } else {
        _publicNodes.data = [];
      }

      if (_res.code == 200) {
        if (_res.data == null) {
          _res.data = [];
        } else {
          _res.data.map((data, index) => {
            if (!_uniquePublicSet.has(data.nodeID)) {
              _uniquePublicNodes.push(data);
              _uniquePublicSet.add(data.nodeID);
            }
          });
        }
      } else {
        _res.data = [];
      }

      if (_dedicated.code == 200) {
        if (_dedicated.data == null) {
          _dedicated.data = [];
        } else {
          _dedicated.data.map((data, index) => {
            if (!_uniquePublicSet.has(data.nodeID)) {
              _uniquePublicNodes.push(data);
              _uniquePublicSet.add(data.nodeID);
            }
          });
        }
      } else {
        _dedicated.data = [];
      }

      let _takenNodes = this.props.nodes;
      let _availableNodes = _uniquePublicNodes.filter(
        (x) => !_takenNodes.filter((y) => y.nodeID === x.nodeID).length
      );
      this.setState({ availableNodes: _availableNodes, fetchLoader: false });
    } else {
      _publicNodes.map((data, index) => {
        if (!_uniquePublicSet.has(data.nodeID)) {
          _uniquePublicNodes.push(data);
          _uniquePublicSet.add(data.nodeID);
        }
      });

      let _takenNodes = this.props.nodes;
      let _availableNodes = _uniquePublicNodes.filter(
        (x) => !_takenNodes.filter((y) => y.nodeID === x.nodeID).length
      );
      this.setState({ availableNodes: _availableNodes, fetchLoader: false });
    }

    //end  new code
  };

  handleCheckBox = (e, data) => {
    this.setState({
      modalData: "",
      premiumNodeModal: false,
      checkedValue: "",
      selectNodeTypeErr: false,
    });
    const item = e.target.name;
    const isChecked = e.target.checked;
    data.userDefinedNodeType = data.nodeType;
    if (data.nodeType == 2) {
      if (isChecked) {
        let modalData = {
          name: item,
          data: data,
        };
        this.setState({ premiumNodeModal: true, modalData: modalData });
        return;
      } else {
        let obj = this.state.checkedNodes;
        obj.delete(item);
        this.setState({ checkedNodes: obj }, () => {
          this.nodeCheckBoxFunc(this.state.checkedNodes);
        });
      }
    } else {
      this.setState(
        (prevState) => ({
          checkedNodes: prevState.checkedNodes.set(item, {
            value: isChecked,
            data: data,
          }),
        }),
        () => {
          this.nodeCheckBoxFunc(this.state.checkedNodes);
        }
      );
    }
  };

  handlePremiumCheckBox = (args) => {
    const item = args.name;
    args.data.userDefinedNodeType =
      this.state.checkedValue == ""
        ? args.data.nodeType
        : this.state.checkedValue === "private"
        ? 0
        : 1;
    const isChecked = true;
    this.setState(
      (prevState) => ({
        checkedNodes: prevState.checkedNodes.set(item, {
          value: isChecked,
          data: args.data,
        }),
      }),
      () => {
        this.setState({
          modalData: "",
          premiumNodeModal: false,
          checkedValue: "",
          selectNodeTypeErr: false,
        });
        this.nodeCheckBoxFunc(this.state.checkedNodes);
      }
    );
  };

  premiumModalClose = () => {
    this.setState({ premiumNodeModal: false });
  };

  setPremiumNodeType = (e) => {
    this.setState({ checkedValue: e.target.value });
  };

  handlePremiumCheckedSubmit = () => {
    if (this.state.checkedValue !== "") {
      this.handlePremiumCheckBox(this.state.modalData);
    } else {
      this.setState({ selectNodeTypeErr: true });
    }
  };

  nodeCheckBoxFunc = (value) => {
    let newObj = [];
    for (let obj of value) {
      newObj.push({ name: obj[0], value: obj[1].value, data: obj[1].data });
    }
    this.setState({ nodeValue: newObj });
  };

  handleSubmit = async () => {
    try {
      this.setState({ loading: true });
      let selectedNodeArr = await this.state.nodeValue.filter(
        (selectedNodeMetaInfo) => {
          if (selectedNodeMetaInfo.value) {
            return {
              nodeName: selectedNodeMetaInfo.data.nodeName,
              nodeIP: selectedNodeMetaInfo.data.nodeIP,
            };
          }
        }
      );

      let finalNodeArr = selectedNodeArr.map((data1) => {
        return {
          nodeID: data1.data.nodeID,
          nodeType: data1.data.userDefinedNodeType,
          isPremiumNode: data1.data.nodeType == 2 ? true : false,
        };
      });

      let data = {
        networkName: this.props.networkName,
        nodeList: finalNodeArr,
        networkID: this.props.networkID,
      };

      let _response = await updateNetwork(data, this.props.networkID);

      if (_response.code == "200") {
        this.props.successModal();
        this.setState({ loading: false });
      } else {
        this.setState({
          loading: false,
          errVal: _response.data[0].nodeError,
          err: true,
        });
        this.props.toastifyEnable(
          "err",
          _response.data[0].nodeError
            ? _response.data[0].nodeError
            : _response.data[0].nodeError == ""
            ? _response.data
            : _response.message
        );
        setTimeout(() => {
          this.setState({ errVal: "", err: true });
        }, 3000);
      }
    } catch (err) {
      this.setState({ loading: false });
    }
  };
  render() {
    return (
      <div>
        {this.state.premiumNodeModal ? (
          <Modal
            show={this.state.premiumNodeModal}
            onHide={this.premiumModalClose}
            centered
            backdrop="true"
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontWeight: "600", fontSize: "14px" }}>
                {this.state.modalData.name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Select the node type
              <div style={{ marginTop: "10px", display: "grid" }}>
                <label>
                  <input
                    type="radio"
                    value="private"
                    checked={this.state.checkedValue === "private"}
                    onChange={this.setPremiumNodeType}
                  />
                  &nbsp; Private
                </label>
                <label>
                  <input
                    type="radio"
                    value="public"
                    checked={this.state.checkedValue === "public"}
                    onChange={this.setPremiumNodeType}
                  />
                  &nbsp; Public
                </label>

                {this.state.selectNodeTypeErr ? (
                  <p
                    style={{
                      marginBottom: "5px",
                      color: "crimson",
                      fontSize: "12px",
                    }}
                  >
                    Node type should not be empty
                  </p>
                ) : null}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="info"
                onClick={this.handlePremiumCheckedSubmit}
                size="sm"
                style={{ fontWeight: "600" }}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}

        <Modal
          show={this.props.show}
          centered
          size="xl"
          onHide={this.props.closeNodeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "14px", fontWeight: "600" }}>
              Add Node
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!this.state.fetchLoader ? (
              <div>
                <h5
                  style={{
                    fontWeight: "600",
                    marginBottom: "20px",
                    fontSize: "13px",
                  }}
                >
                  Select desired nodes that you want to add to{" "}
                  {this.props.networkName}
                </h5>
                {this.state.availableNodes.length == 0 ? (
                  <p style={{ fontWeight: "600", fontSize: "13px" }}>
                    No nodes available
                  </p>
                ) : (
                  <Table bordered hover style={{ fontSize: "12px" }} responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Node</th>
                        <th>Cloud Provider</th>
                        <th>Ram</th>
                        <th>Storage</th>
                        <th>Region</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.availableNodes.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ cursor: "pointer" }}>
                              <Form.Group style={{ marginBottom: "0px" }}>
                                <div className="pretty p-image p-plain">
                                  <input
                                    type="checkbox"
                                    className="styled"
                                    id={"id_" + data.nodeID}
                                    name={data.region + "_" + data.nodeID}
                                    checked={
                                      this.state.checkedNodes.get(
                                        data.region + "_" + data.nodeID
                                      )
                                        ? this.state.checkedNodes.get(
                                            data.region + "_" + data.nodeID
                                          ).value
                                        : false || false
                                    }
                                    onChange={(e) =>
                                      this.handleCheckBox(e, data)
                                    }
                                    disabled={
                                      data.devNetworks == undefined
                                        ? false
                                        : data.devNetworks.length >= 3
                                        ? true
                                        : false
                                    }
                                  />
                                  <div className="state">
                                    <img
                                      class="image"
                                      src={CheckBox}
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                        objectFit: "contain",
                                      }}
                                    />
                                    <label
                                      className="chklabelclr"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {(
                                        data.nodeName +
                                        " - " +
                                        data.region +
                                        " - " +
                                        (data.nodeType == 0
                                          ? "Private"
                                          : data.nodeType == 1
                                          ? "Public"
                                          : `Premium${
                                              data.userDefinedNodeType == 0
                                                ? "(Private)"
                                                : data.userDefinedNodeType == 1
                                                ? "(Public)"
                                                : ""
                                            }`)
                                      ).toUpperCase()}
                                    </label>
                                  </div>
                                </div>
                              </Form.Group>
                              <div style={{ marginTop: "10px" }}>
                                <ProgressBar
                                  variant="info"
                                  now={
                                    data.devNetworks == undefined
                                      ? 0
                                      : data.devNetworks.length >= 3
                                      ? 100
                                      : (data.devNetworks.length / 3) * 100
                                  }
                                  label={`${
                                    data.devNetworks == undefined
                                      ? 0
                                      : data.devNetworks.length >= 3
                                      ? 100
                                      : Math.round(
                                          (data.devNetworks.length / 3) * 100
                                        )
                                  }% of node used`}
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "11px",
                                  }}
                                  className={`progress-bar-color5`}
                                />
                              </div>
                            </td>
                            <td>{data.nodeName}</td>
                            <td>{data.cloudProvider}</td>
                            <td>{data.ram}</td>
                            <td>{data.storage}</td>
                            <td>{data.region}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                {this.state.err ? (
                  <p style={{ fontSize: "12px", color: "crimson" }}>
                    {this.state.errVal}
                  </p>
                ) : null}
              </div>
            ) : (
              <div style={{ margin: "20px", textAlign: "center" }}>
                <Spinner animation="border" />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.props.closeNodeModal}
              className="fontweight600 button-css"
              size="sm"
            >
              Close
            </Button>

            {!this.state.loading ? (
              this.state.availableNodes.length > 0 ? (
                <Button
                  variant="info"
                  className="fontweight600 heading-css-bg"
                  onClick={this.handleSubmit}
                  size="sm"
                  disabled={this.state.checkedNodes.size === 0 ? true : false}
                >
                  Add
                </Button>
              ) : null
            ) : (
              <Button variant="info" disabled>
                <Spinner
                  animation="border"
                  style={{ height: "20px", width: "20px" }}
                />
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    plan: store.price.priceState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toastifyEnable: (type, val) => dispatch(toastify(type, val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNodesToNetwork);
