import React from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { updateNetwork } from "../../common/networkapicalls";

class UpdateNetwork extends React.Component {
  state = {
    networkName: "",
    minRF: 0,
    maxRF: 0,
    nodeList: [],
    zeroLessErr: false,
    minRFError: false,
    maxRFError: false,
    loader: false,
  };
  componentDidMount() {
    this.setState({
      networkName: this.props.network.networkName,
      minRF: this.props.network.minReplicationFactor,
      maxRF: this.props.network.maxReplicationFactor,
      nodeList: this.props.network.nodelist,
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (this.state.minRF < 2 || this.state.maxRF < 2) {
        this.setState({ zeroLessErr: true });
        return;
      }
      if (this.state.minRFError) {
        return;
      }
      if (this.state.maxRFError) {
        return;
      }
      this.setState({ loader: true });
      let data = {
        networkName: this.state.networkName,
        MinReplicationFactor: parseInt(this.state.minRF),
        MaxReplicationFactor: parseInt(this.state.maxRF),
        // networkID: this.props.network.networkID,
      };

      let _res = await updateNetwork(data, this.props.network.networkID);
      if (_res.code == 200) {
        this.setState({ loader: false });
        this.props.successModal();
      } else {
        this.setState({ loader: false });
        this.props.updateModalFailure(_res.data);
      }
    } catch (err) {
      // do nothing
    }
  };
  render() {
    return (
      <Modal
        show={this.props.show}
        centered
        onHide={this.props.closeUpdateNetwork}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "16px", fontWeight: "600" }}>
            Update Network
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Network Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={this.state.networkName}
                onChange={(e) => this.setState({ networkName: e.target.value })}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Minimum Replication Factor</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                value={this.state.minRF}
                onChange={(e) => {
                  let x = e.target.value;
                  this.setState(
                    { minRF: x, zeroLessErr: false, minRFError: false },
                    () => {
                      if (x > this.state.maxRF) {
                        this.setState({ minRFError: true });
                      }
                    }
                  );
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Maximum Replication Factor</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                value={this.state.maxRF}
                onChange={(e) => {
                  let x = e.target.value;
                  this.setState(
                    {
                      maxRF: x,
                      maxRFError: false,
                      maxRFError: false,
                      zeroLessErr: false,
                    },
                    () => {
                      if (this.state.nodeList.length < x) {
                        this.setState({ maxRFError: true });
                      }
                    }
                  );
                }}
              />
            </Form.Group>
            {this.state.zeroLessErr ? (
              <p
                className="text-muted replicationclr"
                style={{ fontSize: "12px" }}
              >
                Minimum or Maximum Replication factor cannot be less than 2
              </p>
            ) : null}
            {this.state.minRFError ? (
              <Form.Text className="text-muted replicationclr">
                Replication factor cant be greater than maximum replication
                factor
              </Form.Text>
            ) : null}
            {this.state.maxRFError ? (
              <Form.Text className="text-muted replicationclr">
                Replication factor should be less than or equal to node count
              </Form.Text>
            ) : null}
          </Modal.Body>

          <Modal.Footer>
            {!this.state.loader ? (
              <Button
                type="submit"
                size="sm"
                variant="primary"
                style={{ fontWeight: "600" }}
                className="heading-css-bg"
              >
                Update
              </Button>
            ) : (
              <Button variant="primary" disabled>
                <Spinner
                  animation="border"
                  style={{ height: "20px", width: "20px" }}
                />
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default UpdateNetwork;
