import React from "react";
import { Button, Modal, Form, Table, Spinner } from "react-bootstrap";
import { getNodeDetails, createNode } from "../../common/nodeapicalls";

class AddNodeModal extends React.Component {
  state = {
    nodeName: "",
    nodeIP: "",
    nodeDetails: [],
    showTable: false,
    addloader: false,
    confirmLoader: false,
    fieldsRequired: false,
    errVal: "",
    err: false,
    selectedNodeType: "",
  };

  fetchNodeDetails = async () => {
    try {
      if (
        this.state.nodeIP !== "" &&
        this.state.nodeName !== "" &&
        this.state.selectedNodeType !== ""
      ) {
        this.setState({ addloader: true });
        let data = [
          {
            nodeName: this.state.nodeName,
            nodeIP: this.state.nodeIP,
            nodeType: parseInt(this.state.selectedNodeType),
          },
        ];
        let _res = await getNodeDetails(data);
        if (typeof _res.data == "string") {
          this.setState({ addloader: false });
          this.props.errToastify(_res.data);
          return;
        }
        if (typeof _res == "object") {
          this.setState({
            nodeDetails: _res,
            showTable: true,
            addloader: false,
          });
        } else {
          this.setState({ errVal: _res.data, err: true });
          setTimeout(() => {
            this.setState({ errVal: "", err: false });
          }, 3000);
        }
      } else {
        this.setState({ fieldsRequired: true });
        setTimeout(() => {
          this.setState({ fieldsRequired: false });
        }, 3000);
      }
    } catch (err) {
      //do nothing
    }
  };

  handleConfigure = async () => {
    try {
      this.setState({ confirmLoader: true });
      let data = [
        {
          nodeName: this.state.nodeName,
          nodeIP: this.state.nodeIP,
          nodeType: parseInt(this.state.selectedNodeType),
        },
      ];

      let _res = await createNode(data);
      if (
        typeof _res?.code === "number" &&
        (_res?.code === 200 || _res?.code === "200")
      ) {
        this.setState({
          confirmLoader: false,
          nodeDetails: [],
          showTable: false,
        });
        this.props.successModal();
      } else {
        this.setState({
          confirmLoader: false,
          nodeDetails: [],
          showTable: false,
        });
        this.props.errToastify(
          _res.data ? _res.data[0].nodeError : _res.message
        );
      }
    } catch (err) {
      this.setState({
        confirmLoader: false,
        nodeDetails: [],
        showTable: false,
      });
    }
  };

  closeNodeModal = () => {
    this.setState({
      nodeName: "",
      nodeIP: "",
      nodeDetails: [],
      showTable: false,
      addloader: false,
      fieldsRequired: false,
    });
    this.props.closeNodeModal();
  };

  render() {
    return (
      <div>
        <Modal
          show={this.props.addNodeModal}
          centered
          size="lg"
          onHide={this.closeNodeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontWeight: "600", fontSize: "14px" }}>
              Add Node
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label
                  className="fontweight600"
                  style={{ fontSize: "14px" }}
                >
                  Node name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Desired Node name"
                  onChange={(e) => this.setState({ nodeName: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label
                  className="fontweight600"
                  style={{ fontSize: "14px" }}
                >
                  Node IP
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Node IP"
                  onChange={(e) => this.setState({ nodeIP: e.target.value })}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label
                  className="fontweight600"
                  style={{ fontSize: "14px" }}
                >
                  Node Type
                </Form.Label>

                <select
                  className="form-control"
                  required
                  onChange={(e) => {
                    this.setState({ selectedNodeType: e.target.value });
                  }}
                >
                  <option value="">--Select Node Type--</option>
                  <option value={0}>Private</option>
                  <option value={1}>Public</option>
                  <option value={2}>Premium</option>
                </select>
              </Form.Group>
            </Form>
            {this.state.fieldsRequired ? (
              <p
                style={{
                  margin: "10px",
                  marginLeft: "0px",
                  color: "crimson",
                  fontSize: "12px",
                }}
              >
                All Fields are Mandatory
              </p>
            ) : null}
            {this.state.err ? (
              <p
                style={{
                  margin: "10px",
                  marginLeft: "0px",
                  color: "crimson",
                  fontSize: "12px",
                }}
              >
                {this.state.errVal}
              </p>
            ) : null}

            {this.state.nodeDetails.length !== 0 && this.state.showTable
              ? this.state.nodeDetails.map((data, index) => (
                  <Table
                    striped
                    bordered
                    hover
                    className="tablecss"
                    key={index}
                  >
                    <tbody>
                      <tr>
                        <th>Node IP</th>
                        <td>{data.nodeIP ? data.nodeIP : "-"}</td>
                      </tr>

                      <tr>
                        <th>Cloud Provider</th>
                        <td>{data.cloudProvider ? data.cloudProvider : "-"}</td>
                      </tr>
                      <tr>
                        <th>CPU</th>
                        <td>{data.cpu ? data.cpu : "-"}</td>
                      </tr>
                      <tr>
                        <th>RAM</th>
                        <td>{data.ram ? data.ram : "-"}</td>
                      </tr>
                      <tr>
                        <th>Storage</th>
                        <td>{data.storage ? data.storage : "-"}</td>
                      </tr>

                      <tr>
                        <th>Latitude</th>
                        <td>{data.lat ? data.lat : "-"}</td>
                      </tr>

                      <tr>
                        <th>Longitude</th>
                        <td>{data.lon ? data.lon : "-"}</td>
                      </tr>
                      <tr>
                        <th>Region</th>
                        <td>{data.region ? data.region : "-"}</td>
                      </tr>

                      <tr>
                        <th>City</th>
                        <td>{data.city ? data.city : "-"}</td>
                      </tr>
                      <tr>
                        <th>Country</th>
                        <td>{data.country ? data.country : "-"}</td>
                      </tr>
                      <tr>
                        <th>Timezone</th>
                        <td>{data.timeZone ? data.timeZone : "-"}</td>
                      </tr>

                      <tr>
                        <th>Zipcode</th>
                        <td>{data.zip ? data.zip : "-"}</td>
                      </tr>
                    </tbody>
                  </Table>
                ))
              : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.closeNodeModal}
              className="fontweight600 button-css"
              size="sm"
            >
              Close
            </Button>
            {!this.state.showTable ? (
              <div>
                {!this.state.confirmLoader ? (
                  <Button
                    variant="info"
                    onClick={this.handleConfigure}
                    size="sm"
                    className="fontweight600 heading-css-bg"
                  >
                    Add
                  </Button>
                ) : (
                  <Button
                    variant="info"
                    disabled
                    size="sm"
                    className="fontweight600 heading-css-bg"
                  >
                    <Spinner
                      animation="border"
                      style={{ height: "20px", width: "20px" }}
                    />
                  </Button>
                )}
              </div>
            ) : null}

            {/* {this.state.showTable ? (
              <div>
                {!this.state.confirmLoader ? (
                  <Button
                    variant="info"
                    onClick={this.handleConfigure}
                    className="fontweight600 heading-css-bg"
                    size="sm"
                  >
                    Add
                  </Button>
                ) : (
                  <Button
                    variant="info"
                    disabled
                    size="sm"
                    className="fontweight600 heading-css-bg"
                  >
                    <Spinner
                      animation="border"
                      style={{ height: "20px", width: "20px" }}
                    />
                  </Button>
                )}
              </div>
            ) : null} */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AddNodeModal;
