import React, { useState, useCallback } from "react";
import { Table, Spinner, Button } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import Lock from "../../../assets/lock.gif";
import "./style.css";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import Geocode from "react-geocode";
import { getIntermediateShards } from "../../../common/fileapi";
import { getParticularNetworkDetails } from "../../../common/networkapicalls";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);

// set response language. Defaults to english.
Geocode.setLanguage("en");

class Maps extends React.Component {
  static defaultProps = {
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 0,
  };
  state = {
    markers: [],
    replicas: [],
    loader: false,
    fileName: "",
    version: 1,
    networkID: "",
    appID: "",
  };
  async componentDidMount() {
    try {
      this.setState({ loader: true });
      let _result = await getParticularNetworkDetails(this.props.networkID);
      let peerDetail = _result.peerIDs.map((data) => {
        return {
          nodeID: data.nodeID,
          nodePeerID: data.nodePeerID,
          peername: data.nodePeerName,
        };
      });
      let _getNodeId = this.mergeByName(peerDetail, this.props.world.replicas);
      let _nodeDetails = this.mergeByID(_result.nodelist, _getNodeId);

      let arr = _nodeDetails;
      let markers = [];
      for (let i = 0; i < arr.length; i++) {
        let _res = await Geocode.fromAddress(
          arr[i].region !== "" ? arr[i].region : arr[i].city
        );
        const { lat, lng } = _res.results[0].geometry.location;
        let newLat = lat + (Math.random() - 0.5) / 1500;
        let newLng = lng + (Math.random() - 0.5) / 1500;
        markers.push({
          lat: newLat,
          lng: newLng,
          region: arr[i].region !== "" ? arr[i].region : arr[i].city,
          name: arr[i].nodeName,
          type: arr[i].nodeType,
        });
      }
      let fileName =
        this.props.world.directory == "/"
          ? this.props.world.path
          : this.props.world.directory + this.props.world.path;
      let version = this.props.world.version;
      this.setState({
        markers: markers,
        replicas: _nodeDetails,
        loader: false,
        networkID: this.props.networkID,
        appID: this.props.appID,
        fileName: fileName,
        version: version,
      });
    } catch (err) {
      console.log(err);
    }
  }

  mergeByName = (a1, a2) => {
    let result = a1.filter((n) => a2.some((n2) => n.nodeID == n2.nodeID));
    return result;
  };

  mergeByID = (a1, a2) => {
    let result1 = a1.filter((n) => a2.some((n2) => n.nodeID == n2.nodeID));
    let result = [
      ...[result1, a2]
        .reduce(
          (m, a) => (
            a.forEach(
              (o) =>
                (m.has(o.nodeID) && Object.assign(m.get(o.nodeID), o)) ||
                m.set(o.nodeID, o)
            ),
            m
          ),
          new Map()
        )
        .values(),
    ];
    return result;
  };

  renderMarkers = (map, maps) => {
    const _marker = this.state.markers;
    var icon = {
      url: Lock,
      scaledSize: new maps.Size(50, 50), // scaled size
    };

    var _allMarkers = [];

    for (let i = 0; i < _marker.length; i++) {
      const infowindow = new maps.InfoWindow({
        content:
          '<div id="content">' +
          '<div id="siteNotice">' +
          "</div>" +
          '<div id="bodyContent" style="margin-top:5px;">' +
          `<p style="font-weight:600;margin-bottom:5px;">Region : ${_marker[i].region}</p>` +
          `<p style="font-weight:600;margin-bottom:5px;">Name : ${_marker[i].name}</p>` +
          `<p style="font-weight:600;margin-bottom:5px;">Type : ${
            _marker[i].type == 0 ? "Private" : "Public"
          }</p>` +
          "</div>" +
          "</div>",
      });
      let marker = new maps.Marker({
        position: { lat: _marker[i].lat, lng: _marker[i].lng },
        map,
        icon: icon,
      });
      marker.addListener("click", () => {
        infowindow.open(map, marker);
      });
      _allMarkers.push(marker);
    }
    const markerCluster = new MarkerClusterer(map, _allMarkers, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });
  };

  render() {
    return (
      <div>
        {!this.state.loader ? (
          <div style={{ height: "45vh", width: "100%" }}>
            {this.state.markers.length > 0 ? (
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
                defaultCenter={this.props.center}
                defaultZoom={this.props.zoom}
                onGoogleApiLoaded={({ map, maps }) =>
                  this.renderMarkers(map, maps)
                }
                options={(map) => ({ mapTypeId: map.MapTypeId.SATELLITE })}
              ></GoogleMapReact>
            ) : null}
          </div>
        ) : (
          <div style={{ textAlign: "center", marginBottom: "30px" }}>
            <p style={{ marginBottom: "5px", fontSize: "14px" }}>Fetching...</p>
            <Spinner
              animation="border"
              style={{ height: "25px", width: "25px" }}
            />
          </div>
        )}

        {this.state.replicas.length !== 0 ? (
          <div>
            <Table
              hover
              responsive
              style={{
                animationDuration: "0.8s",
                animationTimingFunction: "cubic-bezier(.86, .03, .53, 1.01)",
              }}
            >
              <thead>
                <tr>
                  <th className="moibitworldtable">Peer Name</th>
                  <th className="moibitworldtable">Peer ID</th>
                  <th className="moibitworldtable">Region</th>
                  <th className="moibitworldtable">Cloud Providers</th>
                </tr>
              </thead>
              <tbody>
                {this.state.replicas.map((data, index) => (
                  <tr style={{ borderBottom: "1px solid #c0c0c0" }} key={index}>
                    <td className="tabletd">
                      {data.peername ? data.peername : "-"}
                    </td>
                    <td className="tabletd">
                      {data.nodePeerID ? data.nodePeerID : "-"}
                    </td>
                    <td className="tabletd heading-css">
                      {data.region !== "" ? data.region : data.city}
                    </td>
                    <td className="tabletd">
                      {data.cloudProvider ? data.cloudProvider : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : null}

        {this.props.shards.length !== 0 ? (
          <h4 style={{ fontSize: "12px", fontWeight: "600", color: "#000" }}>
            Shards
          </h4>
        ) : null}
        {this.props.shards.length !== 0 ? (
          <div
            style={{
              height: this.props.shards.length >= 2 ? "200px" : "auto",
              overflowY: "scroll",
            }}
          >
            {this.props.shards.map((data, index) => (
              <TreeNode
                items={this.props.shards}
                id={index}
                hash={data.hash}
                size={data.size}
                networkID={this.props.networkID}
                appID={this.props.appID}
                fileName={
                  this.props.world.directory == "/"
                    ? this.props.world.path
                    : this.props.world.directory + this.props.world.path
                }
                version={this.props.world.version}
              />
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}

function fetchChildShards(props) {
  return new Promise(async (resolve, reject) => {
    let data = {
      networkID: props.networkID,
      appID: props.appID,
      fileName: props.fileName,
      version: props.version,
      hash: props.hash,
    };
    let _res = await getIntermediateShards(data);

    if (_res.meta.code == 200) {
      resolve(_res.data.shards.map((childId) => childId));
    } else {
      reject(new Error("Error fetching child shards"));
    }
  });
}

function fetchChildNodes(props) {
  return fetchChildShards(props);
}

function TreeNode(props) {
  // The nodes, or `null` if we don't have them yet
  const [childNodes, setChildNodes] = useState(null);
  // Flag for whether this node is expanded
  const [expanded, setExpanded] = useState(false);
  // Flag for whether we're fetching child nodes
  const [fetching, setFetching] = useState(false);
  // Flag for whether child node fetch failed
  const [failed, setFailed] = useState(false);

  // Toggle our display of child nodes
  const toggleExpanded = useCallback(() => {
    setExpanded(!expanded);
    if (!expanded && !childNodes && !fetching) {
      setFailed(false);
      setFetching(true);
      fetchChildNodes(props)
        .then((nodes) =>
          setChildNodes(
            nodes.map((node) => (
              <TreeNode {...props} hash={node.hash} size={node.size} />
            ))
          )
        )
        .catch((error) => setFailed(true))
        .finally(() => setFetching(false));
    }
  }, [expanded, childNodes, fetching]);

  return (
    <div class="treenode">
      {props.size > 262158 ? (
        <Button
          onClick={toggleExpanded}
          size="sm"
          className="btn btn-info"
          style={{ marginBottom: "10px", fontWeight: "600" }}
        >
          {expanded ? (
            <i className="fa fa-minus" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-plus" aria-hidden="true"></i>
          )}
        </Button>
      ) : null}
      <span
        style={{
          color: props.size > 262158 ? "blue" : "#000",
          paddingLeft: "10px",
          fontSize: props.size > 262158 ? "14px" : "12px",
          fontWeight: props.size > 262158 ? "600" : "normal",
        }}
      >
        {props.hash}
      </span>
      {failed && expanded && (
        <div className="failed">Error fetching child shards</div>
      )}
      {fetching && (
        <div className="loading" style={{ color: "#000", fontSize: "12px" }}>
          Loading...
        </div>
      )}
      {!failed &&
        !fetching &&
        expanded &&
        childNodes &&
        (childNodes.length > 0 ? (
          childNodes
        ) : (
          <div class="none">No shards found.</div>
        ))}
    </div>
  );
}

export default Maps;
