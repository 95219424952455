import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { networkReducers, apps, sessionCheckFunc, priceReducers, Home, storageReducers } from "./export";




const composeEnhancers =
    typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const Init = () => {
    const reducer = combineReducers({
        network: networkReducers,
        apps: apps,
        sessionCheck: sessionCheckFunc,
        price: priceReducers,
        home: Home,
        storage: storageReducers
    });
    const store = createStore(reducer, enhancer);
    return store;
}

export { Init };