import React from "react";
import { Modal } from "react-bootstrap";
import "../pricing/style.css";
import "../creditcard/style.css";
import { createPaymentIntent } from "../../common/paymentapi";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./chekout";
const stripePromise = loadStripe(
  "pk_test_51Io2fdSDAahQCaC54dFLuQ44MzsPh7g8LO1pJHaT6HTy0W949WHLbAFrWklnyNQe6sK6u3z9ZGD6llWbnHys6Qmi00mPqOqPeC"
); //pankaj key

class Payment extends React.Component {
  state = {
    clientSecret: "",
  };
  handleClose = () => {
    this.props.closeModal();
  };
  componentDidMount() {
    if (this.props.price !== 0 && this.props.id !== "") {
      this.getClientSecret();
    }
  }
  getClientSecret = async () => {
    try {
      let data = {
        amount: parseFloat(this.props.price).toFixed(2) * 100,
        currency: "USD",
        description: "monthlyPayment_" + this.props.id,
        operationType: 0,
        operationValue: this.props.id + "",
        line1: "",
        postalCode: "12345",
        city: "Bangalore",
        state: "karnataka",
        country: "india",
        // paymentMethodID: paymentMethodId,
      };

      let _res = await createPaymentIntent(data);
      this.setState({ clientSecret: _res.data });
    } catch (err) {
      //do nothing
    }
  };

  paymentSuccess = () => {
    this.props.paymentSuccess();
  };
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="cardheader">Payment details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <div className="product">
              <p className="popup-plan-price">$ {this.props.price} USD</p>

              <div>
                <Elements stripe={stripePromise}>
                  {this.state.clientSecret !== "" ? (
                    <CheckoutForm
                      clientSecret={this.state.clientSecret}
                      stripe={stripePromise}
                      closeModal={this.handleClose}
                      paymentSuccess={this.paymentSuccess}
                    />
                  ) : null}
                </Elements>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
export default Payment;
