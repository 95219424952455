import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Spinner } from "react-bootstrap";
import { toastify } from "../../action";
import { useDispatch } from "react-redux";
import { timeDiffCalc } from "../../common/func";
import { restoreAppMoiBit } from "../../common/appapicalls";
import { devAdmindetails } from "../../common/devapicalls";

function Apps() {
  const [restoreSpinner, setRestoreSpinner] = useState(false);
  const dispatch = useDispatch();
  // const [getAllApps, setAllApps] = useSelector((store) => store.apps.allApps);
  const [allApps, setAllApps] = useState([]);
  useEffect(() => {
    getDeletedApps();
  }, []);

  async function restoreApps(data) {
    try {
      await setRestoreSpinner(true);
      let res = await restoreAppMoiBit(data.appID, data.networkID);
      if (res.code == 200) {
        getDeletedApps();
        dispatch(toastify("info", "App restored successfully"));
        await setRestoreSpinner(false);
      } else {
        dispatch(toastify("err", res.message));
        await setRestoreSpinner(false);
      }
    } catch (err) {
      dispatch(toastify("err", err.message));
      await setRestoreSpinner(false);
    }
  }

  async function getDeletedApps() {
    try {
      let _val = await devAdmindetails();
      let _appsVal = _val.data.appList;
      let list = Object.values(_appsVal);
      let appKeys = Object.keys(_appsVal);
      let _appRes = [];
      if (appKeys.length != 0) {
        for (let i = 0; i < appKeys.length; i++) {
          _appRes.push({
            appID: appKeys[i],
            appName: list[i].appName,
            networkName: list[i].networkName,
            networkID: list[i].networkID,
            isActive: list[i].isActive,
            isRemoved: list[i].isRemoved,
            recoveryTime: list[i].recoveryTime,
          });

          let finalArr = [];
          finalArr = [..._appRes];
          if (_appsVal !== "error") {
            let _activeapps = finalArr.filter((data) => data.isRemoved == true);
            setAllApps(finalArr);
          }
        }
      }
    } catch (error) {}
  }

  return (
    <div style={{ marginTop: "20px" }}>
      <Modal show={restoreSpinner} centered>
        <Modal.Body style={{ textAlign: "center" }}>
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </Modal>
      {allApps.filter((data) => data.isRemoved).length ? (
        <Table
          striped
          style={{ fontWeight: "600", fontSize: "14px" }}
          className="table-condensed1"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>App ID</th>
              <th>Recovery Time</th>
              <th>Network</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {allApps && allApps.length !== 0 ? (
              allApps.map((data, index) =>
                data.isRemoved ? (
                  <tr key={index}>
                    <td className="tdalign">{data.appName}</td>
                    <td className="tdalign" style={{ color: "#8021BF" }}>
                      {data.appID}
                    </td>
                    <td className="tdalign">
                      {timeDiffCalc(data.recoveryTime)}
                    </td>
                    <td className="tdalign">{data.networkName}</td>
                    <td>
                      <Button
                        variant="dark button-css"
                        size="sm"
                        style={{ fontWeight: "600", width: "100%" }}
                        onClick={() => restoreApps(data)}
                      >
                        Restore
                      </Button>
                    </td>
                  </tr>
                ) : null
              )
            ) : allApps.filter((data) => data.isRemoved).length == 0 ? (
              <p style={{ fontWeight: "500", marginTop: "20px" }}>
                No apps found
              </p>
            ) : null}
          </tbody>
        </Table>
      ) : (
        <p style={{ fontWeight: "500", marginTop: "20px" }}>No apps found</p>
      )}
    </div>
  );
}

export default Apps;
