import React, { Component } from "react";
import { Button, Table, Spinner } from "react-bootstrap";
import {
  getParticularNetworkDetails,
  getPartOfNetwork,
  getOwnedNetwork,
} from "../../common/networkapicalls";
import { Redirect } from "react-router-dom";
import "./style.css";
import NodeModal from "./addnodemodal";
import { connect } from "react-redux";
import { priceModalOpen } from "../../action";

class Networks extends Component {
  state = {
    networks: [],
    redirect: false,
    selectedNetwork: "",
    addNodeModal: false,
    successMsg: false,
    networkSelect: "",
    loader: false,
  };

  async componentDidMount() {
    if (this.props.plan == -1) {
      this.props.priceModalOpen();
      return;
    }
    this.getAllNetworks();
  }

  getAllNetworks = async () => {
    try {
      this.setState({ loader: true });
      let networkListVal = await getPartOfNetwork();
      let _newNetworkVal = [];
      for (let i = 0; i < networkListVal.data.length; i++) {
        let _res = await getParticularNetworkDetails(
          networkListVal.data[i].network_id
        );
        _newNetworkVal.push(_res);
      }
      this.setState({ networks: _newNetworkVal, loader: false });
    } catch (err) {
      this.setState({ loader: false, networks: [] });
    }
  };

  getPartofNetwork = async () => {
    try {
      let _res = await getPartOfNetwork();
      let networkListVal = _res.data;
      let _newNetworkVal = [];
      for (let i = 0; i < networkListVal.length; i++) {
        let _res = await getParticularNetworkDetails(
          networkListVal.data[i].network_id
        );
        _newNetworkVal.push(_res);
      }
      this.setState({ networks: _newNetworkVal });
    } catch (err) {
      //do nothing
    }
  };

  getOwnedNetwork = async () => {
    try {
      let _res = await getOwnedNetwork();
      let networkListVal = _res.data;
      let _newNetworkVal = [];
      for (let i = 0; i < networkListVal.length; i++) {
        let _res = await getParticularNetworkDetails(
          networkListVal.data[i].network_id
        );
        _newNetworkVal.push(_res);
      }
      this.setState({ networks: _newNetworkVal });
    } catch (err) {
      //do nothing
    }
  };

  selectedNetworkFunc = (e) => {
    let val = e.target.value;
    if (val == "") {
      this.getAllNetworks();
    } else if (val == "owned") {
      this.getOwnedNetwork();
    } else if (val == "partof") {
      this.getPartofNetwork();
    }
  };

  redirectCreateNetwork = () => {
    if (this.props.plan <= 1) {
      this.props.priceModalOpen();
    } else {
      this.setState({ redirect: true });
    }
  };

  closeNodeModal = () => {
    this.setState({ addNodeModal: false });
  };

  successModal = () => {
    this.setState({ addNodeModal: false, successMsg: true });
    setTimeout(() => {
      this.setState({ successMsg: false });
    }, 3000);
  };

  render() {
    return this.state.redirect ? (
      <Redirect to={"/network/create"} />
    ) : this.state.selectedNetwork != "" ? (
      <Redirect to={"/network/view/" + this.state.selectedNetwork} />
    ) : !this.state.loader ? (
      <div>
        <NodeModal
          addNodeModal={this.state.addNodeModal}
          closeNodeModal={this.closeNodeModal}
          successModal={this.successModal}
        />
        {this.state.successMsg ? (
          <p
            style={{
              fontWeight: "600",
              marginBottom: "0px",
              color: "green",
              fontSize: "13px",
            }}
          >
            Node added successfully
          </p>
        ) : null}
        <div className="aligntextleft">
          <div className="displayflex">
            <div>
              <h5
                className="heading-css"
                style={{ fontWeight: "600", fontSize: "18px" }}
              >
                Your Networks
              </h5>
            </div>

            <div className="displayflex">
              <div>
                <Button
                  size="sm"
                  className="button-css"
                  style={{ fontWeight: "600", fontSize: "15px" }}
                  onClick={this.redirectCreateNetwork}
                >
                  Create New Network
                </Button>
              </div>
            </div>
          </div>

          <div className="fixedheight" style={{ marginTop: "20px" }}>
            {this.state.networks.length !== 0 ? (
              <Table responsive hover>
                <thead>
                  <tr>
                    <th className="network_headerColumns">#</th>
                    <th className="network_headerColumns">Name</th>
                    <th className="network_headerColumns">ID</th>
                    <th className="network_headerColumns">Nodes</th>
                    <th className="network_headerColumns">Network Type</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.networks.map((data, index) => (
                    <tr
                      onClick={() =>
                        this.setState({ selectedNetwork: data.networkID })
                      }
                      style={{ cursor: "pointer" }}
                      key={index}
                    >
                      <td className="network__detail_col">{index + 1}</td>
                      <td className="network__detail_col">
                        {data.networkName ? data.networkName : "-"}
                      </td>
                      <td className="network__detail_col">
                        {data.networkID ? data.networkID : "-"}
                      </td>
                      <td className="network__detail_col">
                        {data.nodelist ? data.nodelist.length : "-"}
                      </td>
                      <td
                        className="network__detail_col"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div>
                          {data.networkType == 0
                            ? "Open network"
                            : data.networkType == 1
                            ? "Permissioned network"
                            : "-"}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No Network Available</p>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div style={{ textAlign: "center" }}>
        <p>Fetching...</p>
        <Spinner animation="border" />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    plan: store.price.priceState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    priceModalOpen: () => dispatch(priceModalOpen()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Networks);
