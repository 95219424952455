import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { Button, Spinner } from "react-bootstrap";

import CardSection from "./cardsection";

class CheckoutForm extends React.Component {
  state = {
    responseMessage: "",
    msgType: "",
    loader: false,
  };

  handleSubmit = async (event) => {
    try {
      this.setState({ loader: true });
      event.preventDefault();

      const { stripe, elements } = this.props;
      if (!stripe || !elements) {
        return;
      }

      const card = elements.getElement(CardElement);
      let result = await this.props.stripe.confirmCardPayment(
        this.props.clientSecret,
        {
          payment_method: {
            card: card,
          },
        }
      );
      if (result.error) {
        this.showMsg("err", result.error.message);
      } else {
        this.showMsg("success", "Payment succeeded");
        setTimeout(() => {
          this.props.paymentSuccess();
        }, 2000);
      }
    } catch (err) {
      this.setState({ loader: true });
      this.showMsg("err", err.message);
    }
  };
  showMsg = (type, message) => {
    this.setState({ responseMessage: message, msgType: type, loader: false });
    let time = 0;
    if (type == "err") {
      time = 6000;
    } else {
      time = 3000;
    }
    setTimeout(() => {
      this.setState({
        responseMessage: "",
        msgType: "",
      });
    }, time);
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <CardSection />
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            <Button
              size="sm"
              variant="danger"
              className="paymentbtn"
              onClick={this.props.closeModal}
            >
              Close
            </Button>
            &nbsp;
            {!this.state.loader ? (
              <Button
                type="submit"
                size="sm"
                disabled={!this.props.stripe}
                variant="info"
                className="paymentbtn paynow"
              >
                Subscribe!
              </Button>
            ) : (
              <Button
                variant="info"
                className="paymentbtn paynow"
                size="sm"
                disabled
              >
                <Spinner
                  animation="border"
                  style={{ height: "20px", width: "20px" }}
                />
              </Button>
            )}
          </div>
        </form>

        <p
          style={{
            color: this.state.msgType == "err" ? "crimson" : "green",
            fontSize: "12px",
            marginTop: "20px",
          }}
        >
          {this.state.responseMessage}
        </p>
      </div>
    );
  }
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          clientSecret={props.clientSecret}
          closeModal={props.closeModal}
          paymentSuccess={props.paymentSuccess}
        />
      )}
    </ElementsConsumer>
  );
}
