import React from 'react';
import Loader from '../assets/loading.gif';

const Loading = WrappedComponent => props => {
    if (props.loading ||props.loader) {
        return <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: props.page === "fileexplorer" ? "20%" : null
        }}>
            <img src={Loader} style={{height:'50px'}}/>
        </div>
    }
    else {
        return <WrappedComponent {...props} />;
    }
}

export default Loading;