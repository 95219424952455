import React, { Component } from "react";
import { Card, Navbar, Nav, Button } from "react-bootstrap";
import { bounceInRight, rollIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { Link, Redirect } from "react-router-dom";
import "./style.css";
import DigitalMe from "../../assets/digital<Me>.png";
import Moi_ID from "../../assets/logo_moiid.png";

const styles = {
  bounce: {
    animation: "x 2.5s",
    animationName: Radium.keyframes(bounceInRight, "bounceInRight"),
  },
  rollIn: {
    animation: "x 2.5s",
    animationName: Radium.keyframes(rollIn, "rollIn"),
  },
};

class Landing extends Component {
  state = { isSessionOn: false };

  componentDidMount() {
    const tokenString = sessionStorage.getItem("authToken");
    if (!tokenString) {
      return false;
    }
    const token = JSON.parse(tokenString).value;
    if (token && token.length > 10) {
      this.setState({ isSessionOn: true });
    }
  }

  render() {
    return this.state.isSessionOn ? (
      <Redirect to="/home" />
    ) : (
      <div style={{ overflow: "hidden" }} className="landingbackground">
        <div className="galaxy">
          <div className="stars"></div>
        </div>
        <>
          <Navbar
            variant="dark"
            style={{ backgroundColor: "transparent", margin: "0px 4vw" }}
          >
            <Navbar.Brand href="#home">
              <img
                src={require("../../assets/moibit_logo.png")}
                style={{ width: "120px" }}
              />
            </Navbar.Brand>
            <Nav className="mr-auto"></Nav>
            <p style={{ marginTop: "1rem", color: "#fff", fontWeight: "600" }}>
              Already have Moi_ID?{" "}
              <Link to="/login">
                <span style={{ color: "#06fd3f", marginLeft: "2px" }}>
                  {" "}
                  Signin
                </span>
              </Link>
            </p>
          </Navbar>
        </>

        <div className="container">
          <div className="row margintopval">
            <div className="col-md-6">
              <div style={{ marginTop: "100px" }}>
                <h3 style={{ color: "orange", fontWeight: "600" }}>
                  The Evolution and Future of Storage is here
                </h3>
                <div>
                  <div style={{ color: "#fff", marginTop: "30px" }}>
                    <p style={{ fontSize: "25px" }}>A FILE IN MOIBIT IS</p>
                    <p className="points4">Immutable</p>
                    <p className="points4">Privacy Enabled</p>
                    <p className="points4">Secure</p>
                    <p className="points4">Decentralised</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <StyleRoot>
                  <div style={[styles.bounce, { textAlign: "-webkit-right" }]}>
                    <Card className="glasseffect widthcard">
                      <Card.Body>
                        <div style={{ textAlign: "center", marginTop: "10px" }}>
                          <img
                            src={Moi_ID}
                            style={{
                              height: "100px",
                              objectFit: "contain",
                              width: "100px",
                            }}
                          />
                        </div>

                        <Card.Title className="text-center">
                          <div
                            style={{
                              color: "#fff",
                              textAlign: "center",
                              marginBottom: "20px",
                            }}
                            className="css-typing"
                          >
                            <p
                              style={{
                                marginBottom: "10px",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                            >
                              MòiBit now leverage MOI_ID for
                            </p>
                            <p
                              style={{
                                marginBottom: "15px",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                            >
                              IDENTITY MANAGEMENT
                            </p>
                          </div>
                        </Card.Title>

                        <div
                          style={{
                            textAlign: "center",
                            marginBottom: "5px",
                          }}
                        >
                          <img
                            src={DigitalMe}
                            style={{ height: "200px", objectFit: "contain" }}
                          />
                        </div>

                        <div
                          style={{
                            textAlign: "center",
                            margin: "50px",
                            marginTop: "5px",
                          }}
                        >
                          <a
                            href={
                              process.env.REACT_APP_MOI_ID_URL +
                              "?redirect=" +
                              process.env.REACT_APP_MOIBIT_URL
                            }
                            target="_blank"
                          >
                            <Button
                              variant="outline-dark"
                              style={{
                                color: "#ff6e6e",
                                border: "2px solid #ff6e6e",
                              }}
                            >
                              Get{" "}
                              <span style={{ fontWeight: "600" }}>Moi_ID</span>{" "}
                              now
                            </Button>
                          </a>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </StyleRoot>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;
