const { mEncode } = require('./general');
const MoiRequestWrapper = require('./reqWrapper');

exports.getUserDefAddr = async (_userName) => {
    const encodedUsername = mEncode(_userName);
    try {
        return await MoiRequestWrapper({
            reqRouteType: 'identity',
            reqRouteName: 'getdefaultaddress',
            reqQueryParams: [{
                queryKey: 'username',
                queryValue: encodedUsername
            }],
            reqMethod: 'GET',
            reqPayload: undefined
        })
    } catch (e) {
        throw new Error(e.message);
    }
}

exports.getNamespaceDetails = async (namespace, authBoolean, authToken = {}) => {
    const encodedAppName = mEncode('moiApp' + namespace);
    try {
        return await MoiRequestWrapper({
            reqRouteType: 'app',
            reqRouteName: 'get',
            reqQueryParams: [{
                queryKey: 'namespace',
                queryValue: encodedAppName
            }, {
                queryKey: 'auth',
                queryValue: authBoolean || false
            }],
            reqMethod: 'GET',
            reqPayload: undefined,
            reqHeaders: authToken
        })
    } catch (e) {
        return { igcPath: [] }
    }
}

exports.getMoiDetails = async (userMoiIDAddr) => {
    try {
        return await MoiRequestWrapper({
            reqRouteType: 'identity',
            reqRouteName: 'getaccounts',
            reqQueryParams: [{
                queryKey: 'defAddr',
                queryValue: userMoiIDAddr
            }],
            reqMethod: 'GET',
            reqPayload: undefined
        })
    } catch (e) {
        throw new Error(e.message);
    }
}

exports.getUsersList = async () => {
    try {
        return await MoiRequestWrapper({
            reqRouteType: 'list',
            reqRouteName: 'users',
            reqQueryParams: [],
            reqMethod: 'GET',
            reqPayload: undefined
        })
    } catch (e) {
        throw new Error(e.message);
    }
}

exports.userInteractions = async (userMoiIDAddr) => {
    try {
        return await MoiRequestWrapper({
            reqRouteType: 'list',
            reqRouteName: 'interactions',
            reqQueryParams: [{
                queryKey: 'defAddr',
                queryValue: userMoiIDAddr
            }],
            reqMethod: 'GET',
            reqPayload: undefined
        })
    } catch (e) {
        throw new Error(e.message);
    }
}

exports.checkForDuplicate = async (attr) => {
    const createKeccakHash = require('keccak')
    const targetedHash = createKeccakHash('keccak256').update(attr).digest('hex');
    try {
        return await MoiRequestWrapper({
            reqRouteType: 'misc',
            reqRouteName: 'isduplicateentry',
            reqQueryParams: [{
                queryKey: 'givenHash',
                queryValue: targetedHash
            }],
            reqMethod: 'GET',
            reqPayload: undefined
        })
    } catch (e) {
        throw new Error(e.message);
    }
}
