export const SELECT_CHECKBOX_CREATE_NETWORK = "SELECT_CHECKBOX_CREATE_NETWORK";

export const GET_ALL_APPS = "GET_ALL_APPS";


export const SESSION_CHECK = "SESSION_CHECK";
export const CLOSE_WARNING = "CLOSE_WARNING";

export const PRICE_STATE = "PRICE_STATE";
export const PRICE_MODAL_OPEN = "PRICE_MODAL_OPEN";
export const PRICE_MODAL_CLOSE = "PRICE_MODAL_CLOSE";

export const HIT_UPDATE_DEV = "HIT_UPDATE_DEV";
export const GET_PENDING_INVITATION_LIST = "GET_PENDING_INVITATION_LIST";

export const UNLOCK_WALLET = "UNLOCK_WALLET";

export const TOTAL_STORAGE_CONSUMED = "TOTAL_STORAGE_CONSUMED";

export const FIRST_LOGIN_CHECK_PRICING = "FIRST_LOGIN_CHECK_PRICING";

export const SET_DEV_STAT = "SET_DEV_STAT";
export const GET_SUBSCRIPTION_OBJECT = "GET_SUBSCRIPTION_OBJECT";

