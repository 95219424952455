var abi = require("ethereumjs-abi");
var ethers = require("ethers");
const bs58 = require("bs58");
const Web3 = require("web3");
const web3 = new Web3();

exports.mEncode = (_stringToBeEncoded) => {
  if (_stringToBeEncoded && _stringToBeEncoded != "") {
    const hexOfUsername = web3.extend.utils.toHex(_stringToBeEncoded);
    const _bytes = Buffer.from(hexOfUsername.slice(2), "hex");
    const base58OfString = bs58.encode(_bytes);
    return base58OfString;
  } else {
    throw new Error("invalid string to encode");
  }
};

exports.mDecode = (_bsStringToBeDecoded, isMoiApp) => {
  const _bytes1 = bs58.decode(_bsStringToBeDecoded);
  const hexString = _bytes1.toString("hex");
  const decodedString = web3.extend.utils.hexToAscii("0x" + hexString);
  if (isMoiApp) {
    return decodedString.substring(6);
  } else {
    return decodedString;
  }
};

const getMsgDigest = (message) => {
  const targetedPkt = Array.isArray(message) ? message : [message];
  const digestHash =
    "0x" + abi.soliditySHA3(["string"], targetedPkt).toString("hex");
  return digestHash;
};

exports.complicateIt = (message) => {
  let digest = getMsgDigest(message);
  let messageHashBytes = ethers.utils.arrayify(digest);
  return ethers.utils.hashMessage(messageHashBytes).substring(2);
};

exports.signMessage = async (...args) => {
  try {
    const wallet = new ethers.Wallet(args[0]);
    const pkts = args.slice(1);
    let digest = getMsgDigest(pkts);
    let messageHashBytes = ethers.utils.arrayify(digest);
    let flatSig = await wallet.signMessage(messageHashBytes);
    return flatSig;
  } catch (e) {
    throw new Error(e.message);
  }
};
