import React, { Component } from "react";
import { Nav, Badge, Tooltip, OverlayTrigger } from "react-bootstrap";
import Created from "./creatednetwork";
import PartOf from "./partof";
import Pending from "./pending";
import {
  getParticularNetworkDetails,
  getPartOfNetwork,
  getOwnedNetwork,
  getPublicNetworkTokens,
} from "../../common/networkapicalls";
import { connect } from "react-redux";
import { sessionCheck, toastify, getTokenInitiate } from "../../action";
import Logo from "../../assets/logoicon.png";
import "./style.css";

class NetworkTabs extends Component {
  state = {
    activeKey: 1,
    networkList: [],
    pendingList: [],
    partOfList: [],
    pendingNwCount: 0,
    pendingErr: false,
    pendingErrVal: "",
    cookiePending: false,
    loading: false,
  };

  componentDidMount() {
    this.setState({ loading: true });
    let val = JSON.parse(sessionStorage.getItem("pending")).value;
    this.getOwnedNetwork();
    this.getPartOf();

    // this.getNetworksInvitation();
    this.setState({ cookiePending: val });
    if (val == true || val == "true") {
      // this.setState({ activeKey: 3, pendingList: [] });
      this.setState({ activeKey: 3 });
    } else {
      if (this.props.plan == 2) {
        this.setState({ activeKey: 1 });
      } else {
        this.setState({ activeKey: 2 });
      }
    }
  }

  setPendingInvitePopup = async () => {
    sessionStorage.setItem("pending", false);
    this.setState({ cookiePending: false });
  };

  renderTooltip = (props) => (
    <Tooltip className="in" id="tooltip-top" {...props}>
      <div>
        <div
          style={{ textAlign: "right", cursor: "pointer" }}
          onClick={this.setPendingInvitePopup}
        >
          {" "}
          <i className="fa fa-close" aria-hidden="true"></i>
        </div>
        <div>
          <img
            src={Logo}
            alt="..."
            style={{ height: "40px", marginBottom: "10px" }}
          />
          <p style={{ marginBottom: "0px", fontSize: "12px" }}>
            {" "}
            Hurray! You got invitation from MoiNet
          </p>
        </div>

        <p style={{ fontSize: "12px" }}>
          MoiNet is a open network in moibit eco-system
        </p>
      </div>
    </Tooltip>
  );

  handleSelect = async (selectedKey, event) => {
    if (selectedKey == 1) {
      this.getOwnedNetwork();
    } else if (selectedKey == 2) {
      this.getPartOf();
    } else if (selectedKey == 3) {
      this.props.getTokenHit();
      //this.getNetworksInvitation();
    }
    this.setState({ activeKey: selectedKey });
  };

  // getPartOf = async () => {
  //   let _res = await getPartOfNetwork();
  //   if (!(typeof _res.data == "object")) {
  //     this.setState({ pendingErr: true, pendingErrVal: _res.data });
  //     setTimeout(() => {
  //       this.setState({ pendingErr: false });
  //     }, 5000);

  //     return;
  //   }
  //   this.setState({ partOfList: _res.data });
  // };

  getPartOf = async () => {
    this.setState({ loading: true });
    let networkListVal = await getPartOfNetwork();
    let _newPartofNetworkVal = [];

    if (typeof networkListVal == "string") {
      this.props.toastifyEnable("err", networkListVal);
      return;
    }
    if (!networkListVal) {
      this.props.toastifyEnable("err", networkListVal);
      return;
    }

    for (let i = 0; i < networkListVal.data.length; i++) {
      // let _res = await getParticularNetworkDetails(
      //   networkListVal.data[i].network_id
      // );
      // _newPartofNetworkVal.push(_res);
      _newPartofNetworkVal.push({
        networkID: networkListVal.data[i].network_id,
        networkName: networkListVal.data[i].networkName,
        networkToken: networkListVal.data[i].network_token,
      });
    }

    this.setState({ partOfList: _newPartofNetworkVal });
    this.props.networkListCheck(_newPartofNetworkVal);
  };

  getNetworksInvitation = async () => {
    let networkListVal = await getPublicNetworkTokens();
    let _newPendingVal = [];

    if (typeof networkListVal == "string") {
      this.props.toastifyEnable("err", networkListVal);
      return;
    }
    if (!networkListVal) {
      this.props.toastifyEnable("err", networkListVal);
      return;
    }

    for (let i = 0; i < networkListVal.data.length; i++) {
      let invitation = networkListVal.data[i].accepted;
      if (invitation == false || invitation == "false") {
        // let _res = await getParticularNetworkDetails(
        //   networkListVal.data[i].devToken.network_id
        // );

        // _newPendingVal.push(_res);
        _newPendingVal.push({
          networkID: networkListVal.data[i].network_id,
          networkName: networkListVal.data[i].networkName,
          networkToken: networkListVal.data[i].network_token,
        });
      }

      this.setState({ pendingList: _newPendingVal });
      this.props.networkListCheck(_newPendingVal);
    }
  };

  getOwnedNetwork = async () => {
    this.setState({ loading: true });
    let networkListVal = await getOwnedNetwork();
    let _newNetworkVal = [];

    if (typeof networkListVal == "string") {
      this.props.toastifyEnable("err", networkListVal);
      return;
    }
    if (!networkListVal) {
      this.props.toastifyEnable("err", networkListVal);
      return;
    }
    for (let i = 0; i < networkListVal.data.length; i++) {
      // let _res = await getParticularNetworkDetails(
      //   networkListVal.data[i].network_id
      // );
      // _newNetworkVal.push(_res);
      _newNetworkVal.push({
        networkID: networkListVal.data[i].network_id,
        networkName: networkListVal.data[i].networkName,
        networkToken: networkListVal.data[i].network_token,
      });
    }
    this.setState({ networkList: _newNetworkVal });
    this.props.networkListCheck(_newNetworkVal);
  };

  refreshPending = () => {
    this.props.getTokenHit();
    this.setPendingInvitePopup();
    this.setState({ activeKey: 2 });
    this.getPartOf();
  };

  render() {
    return (
      <div>
        <Nav
          variant="tabs"
          activeKey={this.state.activeKey}
          onSelect={this.handleSelect}
        >
          {this.props.plan >= 2 ? (
            <Nav.Item>
              <Nav.Link eventKey={1} style={{ fontWeight: "600" }}>
                Created
              </Nav.Link>
            </Nav.Item>
          ) : null}
          <Nav.Item>
            <Nav.Link eventKey={2} style={{ fontWeight: "600" }}>
              Part of
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={this.renderTooltip}
              show={false}
            >
              <Nav.Link eventKey={3} style={{ fontWeight: "600" }}>
                Pending&nbsp;
                {this.props.pendingInvitationList.length !== 0 ? (
                  <Badge
                    variant="light"
                    style={{ color: "#fff", background: "#8021BF" }}
                  >
                    {this.props.pendingInvitationList.length}
                  </Badge>
                ) : null}
              </Nav.Link>
            </OverlayTrigger>
          </Nav.Item>
        </Nav>
        <div>
          {this.state.pendingErr ? (
            <p
              style={{
                fontSize: "12px",
                margin: "10px",
                color: "crimson",
                fontWeight: "600",
              }}
            >
              {this.state.pendingErrVal !== "developer validation failed"
                ? this.state.pendingErrVal
                : null}
            </p>
          ) : null}
          {this.state.activeKey == 1 ? (
            <div style={{ marginTop: "10px" }}>
              {" "}
              {this.props.plan >= 2 ? (
                <Created networkList={this.state.networkList} />
              ) : null}
            </div>
          ) : null}
          {this.state.activeKey == 2 ? (
            <div style={{ marginTop: "10px" }}>
              <PartOf partOfList={this.state.partOfList} />
            </div>
          ) : null}
          {this.state.activeKey == 3 ? (
            <div style={{ marginTop: "10px" }}>
              {this.props.pendingInvitationList.length !== 0 ? (
                <Pending refreshPending={this.refreshPending} />
              ) : (
                <p>No data found</p>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    plan: store.price.priceState,
    pendingInvitationList: store.home.pendingInvitationList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSession: () => dispatch(sessionCheck()),
    toastifyEnable: (type, val) => dispatch(toastify(type, val)),
    getTokenHit: () => dispatch(getTokenInitiate()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkTabs);
