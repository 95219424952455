import React, { Component } from "react";
import { memoryStore } from "../../index";

import { Redirect } from "react-router-dom";
import { Spinner, Modal, Button } from "react-bootstrap";
import "./style.css";
import { connect } from "react-redux";
import {
  priceModalClose,
  getPrice,
  toastify,
  firstLoginCheckPricing,
} from "../../action";
import { cancelSubscription, getPriceDetails } from "../../common/paymentapi";

import { updatePlan } from "../../common/devapicalls";

import Payment from "./payment";
import { ToastContainer } from "react-toastify";
import ContactUs from "./contactus";
import Cookies from "js-cookie";
import CancelSubscription from "./cancelsubscription";
import "./price.css";

class Pricing extends Component {
  state = {
    price: [],
    planType: 0,
    amount: 0,
    openPaymentModal: false,
    contactUsPopUp: false,
    loaderForPricingStarter: false,
    homeRedirect: false,
    paymentPlan: 0,
    cancelSubscriptionModal: false,
    cancelLoader: false,
    duration: "Monthly",
    billing: "Monthly",
  };
  async componentDidMount() {
    try {
      this.props.priceModalClose();
      // let _res = await getPriceDetails();
      // this.setState({
      //   price:
      //     _res.data.length > 0
      //       ? _res.data.sort((a, b) => a.planName - b.planName)
      //       : [],
      // });
      let _res = await getPriceDetails();
      let filteredData = _res.data.filter(
        (data) =>
          data.planName === 0 ||
          data.planName === 2 ||
          data.planName === 3 ||
          data.planName === 4
      );
      this.setState({
        price: filteredData.sort((a, b) => a.planName - b.planName),
      });
    } catch (err) {
      this.props.getPrice();
      this.props.toastifyEnable("err", "Failed to fetch the pricing details");
    }
  }

  upgradeStarterPlan = async () => {
    try {
      let _res = await updatePlan();
      this.props.getPrice();
      this.props.toastifyEnable("info", "Plan Upgraded succcessfully");
    } catch (err) {}
  };

  setPlan = (plan, price, paymentPlan) => {
    this.setState({
      planType: plan,
      openPaymentModal: true,
      amount: price,
      paymentPlan: paymentPlan,
    });
  };

  closeModal = () => {
    this.setState({
      planType: this.props.plan,
      openPaymentModal: false,
      amount: 0,
    });
  };

  errMsg = (data) => {
    this.setState({
      planType: this.props.plan,
      openPaymentModal: false,
      amount: 0,
    });
    this.props.toastifyEnable("err", data);
  };
  paymentSuccess = () => {
    setTimeout(() => {
      this.setState({ openPaymentModal: false, amount: 0 });
      this.props.getPrice();
      sessionStorage.setItem("pricing", true);
      // Cookies.set("pricing", true);
      this.setState({ loaderForPricingStarter: false, homeRedirect: false });
    }, 3000);
  };

  contactUsOpen = () => {
    this.setState({ contactUsPopUp: true });
  };
  contactUsClose = () => {
    this.setState({ contactUsPopUp: false });
  };
  setStarterPlan = () => {
    this.setState({ loaderForPricingStarter: true });
    setTimeout(() => {
      sessionStorage.setItem("pricing", true);
      // Cookies.set("pricing", true);
      this.setState({ loaderForPricingStarter: false, homeRedirect: true });
    }, 3000);
  };

  enableCancelSubscriptionFunc = () => {
    this.setState({ cancelSubscriptionModal: true });
  };

  closeCancelSubscription = () => {
    this.setState({ cancelSubscriptionModal: false });
  };

  cancelSubScriptionFunc = async () => {
    try {
      this.setState({ cancelLoader: true });
      let _result = await cancelSubscription();
      if (
        typeof _result?.code == "number" &&
        (_result?.code == 200 || _result?.code == "200")
      ) {
        this.setState({ cancelLoader: false });
        this.paymentSuccess();
        this.props.toastifyEnable("info", "Your subscription cancelled");
      } else {
        this.setState({ cancelLoader: false });
        this.props.toastifyEnable("err", _result.data);
      }
      this.closeCancelSubscription();
    } catch (err) {
      this.setState({ cancelLoader: false });
      this.props.toastifyEnable("err", err.message);
      this.closeCancelSubscription();
    }
  };

  changePlanType = (e) => {
    console.log("e", e.currentTarget.value);
    this.setState({ billing: e.currentTarget.value });
  };

  render() {
    if (this.state.homeRedirect) {
      return <Redirect to="/home" />;
    } else {
      return (
        <div className="container-fluid">
          {this.state.cancelSubscriptionModal ? (
            <CancelSubscription
              show={this.state.cancelSubscriptionModal}
              closeCancelSubscription={this.closeCancelSubscription}
              cancelSubscription={this.cancelSubScriptionFunc}
              loading={this.state.cancelLoader}
            />
          ) : null}
          <Modal
            show={this.state.loaderForPricingStarter}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Body>
              <div style={{ margin: "20px", textAlign: "center" }}>
                <Spinner animation="border" />
              </div>
            </Modal.Body>
          </Modal>
          {this.state.openPaymentModal ? (
            <Payment
              show={this.state.openPaymentModal}
              plan={this.state.planType}
              billingPeriod={this.state.billing}
              closeModal={this.closeModal}
              price={this.state.amount}
              paymentSuccess={this.paymentSuccess}
              errMsg={this.errMsg}
              paymentPlan={this.state.paymentPlan}
              userPlan={this.props.plan}
              freetrail={this.props.checkFreeorBuyStatus}
            />
          ) : null}
          {this.state.contactUsPopUp ? (
            <ContactUs
              show={this.state.contactUsPopUp}
              handleClose={this.contactUsClose}
            />
          ) : null}
          <ToastContainer draggable={false} closeOnClick={false} />

          <div className="row">
            <div className="col-sm-12">
              <div className="pricing-switcher">
                <p className="fieldset">
                  <input
                    type="radio"
                    name="duration-1"
                    value="Monthly"
                    id="monthly-1"
                    checked={this.state.duration === "Monthly"}
                    // onChange={() => this.setState({ duration: "Monthly" })}
                    onChange={(e) => {
                      this.setState({ duration: "Monthly" });
                      this.changePlanType(e);
                    }}
                  />
                  <label for="monthly-1">Monthly Billing</label>
                  <input
                    type="radio"
                    name="duration-1"
                    value="Yearly"
                    id="yearly-1"
                    checked={this.state.duration === "Yearly"}
                    // onChange={() => this.setState({ duration: "Yearly" })}

                    onChange={(e) => {
                      this.setState({ duration: "Yearly" });
                      this.changePlanType(e);
                    }}
                  />
                  <label for="yearly-1">Yearly Billing</label>
                  <span className="switch"></span>
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="pricing-plans d-flex flex-column flex-lg-row my-auto">
                <div className="plan options">
                  <div className="plan-info">
                    <nav>
                      <ul
                        className="list-group first text-left"
                        style={{ width: "199px" }}
                      >
                        <li className="list-group-item">
                          <span className="item"></span>
                        </li>
                        <li className="list-group-item heading-label">Plan</li>
                        <li className="list-group-item">
                          <span className="item"></span>
                        </li>
                        <li className="list-group-item heading-label">
                          Base Fee/month
                        </li>
                        <li className="list-group-item">
                          <span className="item"></span>
                        </li>
                        <li className="list-group-item heading-label">
                          Ideal For
                        </li>
                        <li className="list-group-item">
                          <span className="item"></span>
                        </li>
                        <li className="list-group-item">
                          <span className="item"></span>
                        </li>
                        <li className="list-group-item heading-label">
                          Data Availability <br /> Regions
                        </li>
                        <li className="list-group-item">
                          <span className="item"></span>
                        </li>
                        <li className="list-group-item heading-label">
                          Storage on Public Network
                        </li>
                        <li className="list-group-item">
                          <span className="item"></span>
                        </li>
                        <li className="list-group-item heading-label">
                          Bandwidth
                        </li>
                        <li className="list-group-item">
                          <span className="item"></span>
                        </li>
                        <li className="list-group-item heading-label">
                          Application Buckets
                        </li>
                        <li className="list-group-item">
                          <span className="item"></span>
                        </li>
                        <li className="list-group-item heading-label">
                          Private & Premium <br /> Nodes
                        </li>
                        <li className="list-group-item">
                          <span className="item"></span>
                        </li>
                        <li className="list-group-item heading-label">
                          API Features
                        </li>
                        <li className="list-group-item">
                          <span className="item"></span>
                        </li>
                        <li className="list-group-item">
                          <span className="item"></span>
                        </li>
                        <li className="list-group-item heading-label">
                          Support
                        </li>
                        <li className="list-group-item">
                          <span className="item"></span>
                        </li>
                        <li className="list-group-item heading-label">
                          User Control of Data
                        </li>
                        <li className="list-group-item">
                          <span className="item"></span>
                        </li>
                        <li className="list-group-item heading-label">
                          Delete Protection
                        </li>
                        <li className="list-group-item">
                          <span className="item"></span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>

                {this.state.price.length == 0
                  ? null
                  : this.state.price.map((data, index) => {
                      let planName;
                      if (data.planName == 0) {
                        planName = "DEVELOPER";
                      } else if (data.planName == 2) {
                        planName = "STARTUP";
                      } else if (data.planName == 3) {
                        planName = "BUSINESS";
                      } else if (data.planName == 4) {
                        planName = "ENTERPRISE";
                      }

                      return (
                        <div
                          className={
                            this.props.plan == data.planName
                              ? "plan popular"
                              : "plan"
                          }
                          key={index}
                        >
                          <div className="plan-info">
                            <nav
                              className={
                                "marginrgt-margnlft gradient" + data.planName
                              }
                            >
                              <ul className="list-group">
                                <li className="list-group-item">
                                  {" "}
                                  <span className="item"></span>
                                </li>
                                <li
                                  className="list-group-item"
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "24px",
                                  }}
                                >
                                  {planName}
                                </li>

                                {data.planName == 0 ? (
                                  <>
                                    <li className="list-group-item">
                                      <h2 className="price">
                                        $
                                        {this.state.duration === "Monthly"
                                          ? data.price[1].price
                                          : data.price[12].price}
                                      </h2>
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      Individual developers, hobbyists and
                                      personal data activists.
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      Pick between Asia-Pacific or a similar
                                      region (subject to availability and MOI
                                      Bit's discretion)
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      5 GB free storage. <br />
                                      Additional usage at $0.20/GB
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      15 GB free bandwidth, <br />
                                      Additional usage at $0.025/GB
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      5 App buckets free, <br />
                                      Additional usage at $10/app
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      Paid premium & private nodes <br />
                                      available on request​
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      All features free up to 10K calls per
                                      month. <br />
                                      Additional usage chargeable at <br />
                                      $50/1M calls​​
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      Community support
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      Default Encryption
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">3 Days</li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                  </>
                                ) : null}

                                {data.planName == 2 ? (
                                  <>
                                    <li className="list-group-item">
                                      <h2 className="price">
                                        {this.state.duration === "Monthly" ? (
                                          "$" + data.price[1].price / 100
                                        ) : (
                                          <span>
                                            <span
                                              className="strikeprice"
                                              style={{
                                                fontWeight: "400",
                                                color: "black",
                                                fontSize: "20px",
                                                textDecoration: "line-through",
                                              }}
                                            >
                                              {"$1188"}
                                            </span>
                                            {" $" + data.price[12].price / 100}
                                          </span>
                                        )}
                                      </h2>
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      Small teams in the growth phase looking to
                                      ship fast using secure shared
                                      infrastructure
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      Pick between Europe, North America,
                                      Asia-Pacific or Middle East as per your
                                      requirement
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      100 GB free storage.
                                      <br /> Additional usage at $0.18/GB
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      300 GB free bandwidth, <br />
                                      Additional usage at $0.02/GB
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      10 App buckets free, <br />
                                      Additional usage at $7/app
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      Readily available paid premium <br /> &
                                      private nodes​
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      All features free up to 200K calls per
                                      month.
                                      <br />
                                      Additional usage chargeable at
                                      <br />
                                      $25/1M calls
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      Email support
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      Developer and Custom Encryption
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">30 Days</li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                  </>
                                ) : null}

                                {data.planName == 3 ? (
                                  <>
                                    <li className="list-group-item">
                                      <h2 className="price">
                                        {this.state.duration === "Monthly" ? (
                                          "$" + data.price[1].price / 100
                                        ) : (
                                          <span>
                                            <span
                                              className="strikeprice"
                                              style={{
                                                fontWeight: "400",
                                                color: "black",
                                                fontSize: "20px",
                                                textDecoration: "line-through",
                                              }}
                                            >
                                              {"$9588"}
                                            </span>
                                            {" $" + data.price[12].price / 100}
                                          </span>
                                        )}
                                      </h2>
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      SMBs looking for peace of mind data
                                      security and compliance
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      Pick between Europe, North or South
                                      America, Middle East, China, Africa,
                                      Asia-Pacific or Oceania.
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      2 TB free storage. <br />
                                      Additional usage at $0.16/GB
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      6TB free bandwidth,
                                      <br /> Additional usage at $0.015/GB
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      25 App buckets free, <br />
                                      Additional usage at $5/app
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      Readily available paid premium <br /> &
                                      private nodes​
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      All features free up to 2M calls per
                                      month. <br />
                                      Additional usage chargeable at <br />
                                      $10/1M calls​​
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      On-demand​ support​
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">
                                      EndUser Encryption
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li className="list-group-item">60 Days</li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                  </>
                                ) : null}

                                {data.planName == 4 ? (
                                  <>
                                    <li className="list-group-item">
                                      <h2 className="price">$custom</h2>
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li
                                      className="list-group-item"
                                      style={{ filter: "blur(5px)" }}
                                    >
                                      Large companies looking to de-risk their
                                      PDI Infrastructure
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>

                                    <li
                                      className="list-group-item"
                                      style={{ filter: "blur(5px)" }}
                                    >
                                      Pick between Europe, North or South
                                      America, Middle East, China, Africa,
                                      Asia-Pacific or Oceania.
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>

                                    <li
                                      className="list-group-item"
                                      style={{ filter: "blur(5px)" }}
                                    >
                                      5 TB free storage. <br />
                                      Additional usage at $0.009/GB
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li
                                      className="list-group-item"
                                      style={{ filter: "blur(5px)" }}
                                    >
                                      10 TB free bandwidth,
                                      <br /> Additional usage at 0.005/GB
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li
                                      className="list-group-item"
                                      style={{ filter: "blur(5px)" }}
                                    >
                                      50 App buckets free, <br />
                                      Additional usage at $5/app
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li
                                      className="list-group-item"
                                      style={{ filter: "blur(5px)" }}
                                    >
                                      Readily available paid premium & <br />
                                      private nodes​
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li
                                      className="list-group-item"
                                      style={{ filter: "blur(5px)" }}
                                    >
                                      All features free up to 10M calls.
                                      <br />
                                      Additional usage chargeable at <br />
                                      $10/1M calls​​
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li
                                      className="list-group-item"
                                      style={{ filter: "blur(5px)" }}
                                    >
                                      On-demand support​​​
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li
                                      className="list-group-item"
                                      style={{ filter: "blur(5px)" }}
                                    >
                                      End-to-End Encryption
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                    <li
                                      className="list-group-item"
                                      style={{ filter: "blur(5px)" }}
                                    >
                                      365 Days
                                    </li>
                                    <li className="list-group-item">
                                      <span className="item"></span>
                                    </li>
                                  </>
                                ) : null}
                              </ul>
                            </nav>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>

          {/* Required */}
          <div className="row">
            <div className="col-sm-12">
              <div className="pricing-plans d-flex flex-column flex-lg-row my-auto">
                <div className="plan options">
                  <div className="plan-info">
                    <nav>
                      <ul className="list-group first text-left"></ul>
                    </nav>
                  </div>
                </div>
                {this.state.price.length == 0
                  ? null
                  : this.state.price.map((data, index) => {
                      return data.planName == 4 ? (
                        <div className="plan padding0" key={index}>
                          <div
                            className="plan-info"
                            onClick={this.contactUsOpen}
                          >
                            <nav>
                              <ul
                                className={`list-group button-css`}
                                style={{ color: "#fff", cursor: "pointer" }}
                              >
                                <li className="list-group-item padding20 fontweight600">
                                  <i className="fa fa-phone fa-lg"></i>
                                  {" CONTACT US"}
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      ) : (
                        <div className="plan padding0" key={index}>
                          <div className="plan-info">
                            <nav
                              className={
                                "marginrgt-margnlft gradient" + data.planName
                              }
                            >
                              {this.props.plan == -1 &&
                              data.planName == 0 &&
                              this.props.checkFreeorBuyStatus == "" ? (
                                <ul
                                  className={`list-group button-css`}
                                  style={{ color: "#fff", cursor: "pointer" }}
                                  onClick={() => this.upgradeStarterPlan()}
                                >
                                  <li className="list-group-item padding20 fontweight600">
                                    {"JOIN NOW"}
                                  </li>
                                </ul>
                              ) : this.props.plan < data.planName &&
                                data.planName !== 0 &&
                                data.planName !== 4 &&
                                (this.props.checkFreeorBuyStatus == "" ||
                                  this.props.checkFreeorBuyStatus ==
                                    "active") ? (
                                <div>
                                  <ul
                                    className={`list-group button-css`}
                                    style={{ color: "#fff" }}
                                  >
                                    <li
                                      className="list-group-item padding20 fontweight600"
                                      onClick={() =>
                                        this.setPlan(
                                          data.planName,
                                          data.price,
                                          1,
                                          this.state.planTypesss
                                        )
                                      }
                                      style={{
                                        color: "#fff",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {this.props.checkFreeorBuyStatus == "" &&
                                      this.props.checkFreeorBuyStatus ==
                                        "active"
                                        ? "Upgrade"
                                        : "Get Plan"}
                                    </li>
                                  </ul>
                                </div>
                              ) : null}
                            </nav>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>

          {this.props.plan <= 0 ? null : (
            <div
              style={{
                textAlign: "right",
                marginBottom: "20px",
                marginTop: "40px",
              }}
            >
              <Button
                variant="danger"
                size="sm"
                onClick={() => this.enableCancelSubscriptionFunc()}
                className="button-css"
                style={{ fontWeight: "600" }}
              >
                Cancel your subscription
              </Button>
            </div>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (store) => {
  return {
    plan: store.price.priceState,
    pricingLinkForStarter: store.price.firstTimeLoginCheck,
    subcribeButtonCheck: store.price.devStatVal,
    checkFreeorBuyStatus: store.price.freeorbuy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    firstLoginCheckPricing: () => dispatch(firstLoginCheckPricing()),
    toastifyEnable: (type, val) => dispatch(toastify(type, val)),
    priceModalClose: () => dispatch(priceModalClose()),
    getPrice: () => dispatch(getPrice()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
