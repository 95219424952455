import React from "react";
import { Card } from "react-bootstrap";
import { getParticularNetworkDetails } from "../../common/networkapicalls";
import { listallOwnedNodes } from "../../common/nodeapicalls";

import { Link } from "react-router-dom";

class NetworkPartOf extends React.Component {
  state = {
    network: [],
    opened: [],
    tableData: [],
    selectedIndex: -1,
    openCloseToggle: false,
  };

  toggleShowHide = async (id, network_id) => {
    try {
      this.setState({
        tableData: [],
        selectedIndex: id,
        openCloseToggle: !this.state.openCloseToggle,
        nodeErrVal: "",
        nodeErr: false,
      });
      let _getNetworkDetails = await getParticularNetworkDetails(network_id);
      let _listallOwnedNodes = _getNetworkDetails.nodes;
      let _allNodes = [];
      let public_nodes = [];
      let private_nodes = [];
      let _nodeRes = await listallOwnedNodes();
      if (_nodeRes.data.private_nodes !== null) {
        private_nodes = _nodeRes.data.private_nodes;
      }
      if (_nodeRes.data.public_nodes !== null) {
        public_nodes = _nodeRes.data.public_nodes;
      }
      _allNodes = [...public_nodes, ...private_nodes];
      let newArr = [];
      for (let i = 0; i < _listallOwnedNodes.length; i++) {
        for (let j = 0; j < _allNodes.length; j++) {
          if (
            _allNodes[j].node_name == _listallOwnedNodes[i].nodeName &&
            _allNodes[j].Node_IP == _listallOwnedNodes[i].nodeIP
          ) {
            newArr.push(_allNodes[j]);
            break;
          }
        }
      }
      this.setState({ opened: [...this.state.opened, id], tableData: newArr });
    } catch (Err) {
      //do nothing
    }
  };

  render() {
    return (
      <div className="scrollhomecard">
        {this.props.partOfList &&
        this.props.partOfList.length > 0 &&
        this.props.partOfList !== "error" ? (
          this.props.partOfList.map((data, index) =>
            data.networkName !== "" ? (
              <Link
                to={"/network/view/" + data.networkID}
                style={{ textDecoration: "none" }}
              >
                <Card
                  key={index}
                  className="appscard"
                  style={{ cursor: "pointer" }}
                >
                  <Card.Body style={{ padding: "10px 20px 20px 20px" }}>
                    <div className="cardcss">
                      <div style={{ display: "flex" }}>
                        <div className="appfirstletter heading-css-bg">
                          <p style={{ paddingTop: "7px" }}>
                            {" "}
                            {data.networkName.charAt(0)}
                          </p>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                          <Card.Title
                            style={{
                              fontSize: "18px",
                              fontWeight: "600",
                              marginBottom: "2px",
                              color: "#000",
                            }}
                          >
                            {data.networkName}
                          </Card.Title>
                          <Card.Text className="carddesccss">
                            {data.networkID}
                          </Card.Text>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
            ) : null
          )
        ) : (
          <p>No data found</p>
        )}
      </div>
    );
  }
}

export default NetworkPartOf;
