import React, { Component } from "react";
import { Spinner, Modal, Button, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { getDevApps, toastify } from "../../action";
import { updateEndUserDetails } from "../../common/endusercalls";
import { getAppDetails, removeAppMoiBit } from "../../common/appapicalls";

import AppDetails from "./appdetails";
import EditApp from "./editapp";
import "./style.css";

class YourApps extends Component {
  state = {
    appDetailsShow: false,
    appDetailsResult: [],
    editAppDisplay: false,
    editAppData: [],
    removeappModal: false,
    appName: "",
    appID: "",
    networkID: "",
    appSuccess: false,
    appErr: false,
    appRemoveLoading: false,
    appUpdateSuccess: false,
    updateEndUserSpinner: false,
  };
  componentDidMount() {
    this.fetchApps();
  }
  fetchApps = async () => {
    await this.props.getallAppsFunc();
  };
  appDetails = async (id, network) => {
    try {
      this.setState({ appDetailsShow: true });
      let _res = await getAppDetails(id, network);
      if (typeof _res.data == "string") {
        this.setState({ appDetailsShow: false });
        this.props.toastifyEnable("err", _res.data);
        return;
      }
      this.setState({ appDetailsResult: _res });
    } catch (err) {
      console.log(err);
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps.check !== this.props.check) {
      this.fetchApps();
    }
  }

  editApp = async (id, networkid) => {
    this.setState({ editAppDisplay: true });
    let _res = await getAppDetails(id, networkid);

    if (typeof _res.data == "string") {
      this.setState({ editAppDisplay: false });
      this.props.toastifyEnable("err", _res.data);
      return;
    }
    this.setState({ editAppData: _res });
  };

  handleClose = () => {
    this.setState({ appDetailsShow: false, appDetailsResult: [] });
  };

  handleEditClose = () => {
    this.setState({ editAppDisplay: false, editAppData: [] });
  };

  editSuccess = () => {
    this.setState({
      editAppDisplay: false,
      editAppData: [],
      appUpdateSuccess: true,
    });
    this.fetchApps();
    setTimeout(() => {
      this.setState({ appUpdateSuccess: false });
    }, 3000);
  };

  handleCloseModal = () => {
    this.setState({ removeappModal: false });
  };
  removeAppModalState = (id, name, networkID) => {
    this.setState({
      appName: name,
      removeappModal: true,
      appID: id,
      networkID: networkID,
      editAppDisplay: false,
    });
  };

  removeApp = async () => {
    this.setState({ appRemoveLoading: true });
    let res = await removeAppMoiBit(this.state.appID, this.state.networkID);
    if (res.code == 200) {
      this.setState({ appSuccess: true });
      setTimeout(() => {
        this.setState({ appSuccess: false });
      }, 4000);
    } else {
      this.props.toastifyEnable("err", res.message);
    }
    this.setState({
      appName: "",
      removeappModal: false,
      appID: "",
      appRemoveLoading: false,
      networkID: "",
    });
    this.fetchApps();
  };

  removeEndUser = async (data) => {
    let appDetail = this.state.appDetailsResult;
    let networkID = appDetail.networkID;
    let appID = appDetail.appID;
    this.setState(
      { appDetailsShow: false, updateEndUserSpinner: true },
      async () => {
        try {
          let _res = await updateEndUserDetails(
            appID,
            networkID,
            data.enduserID
          );
          if (typeof _res !== "string" && _res) {
            this.setState({
              updateEndUserSpinner: false,
              appDetailsResult: [],
            });
            this.props.toastifyEnable("err", "App updated successfully");
          } else {
            this.setState({
              updateEndUserSpinner: false,
              appDetailsResult: [],
            });
            this.props.toastifyEnable("err", _res);
          }
        } catch (err) {
          this.props.toastifyEnable("err", err.message);
        }
      }
    );
  };
  render() {
    return (
      <div className="scrollhomecard">
        {this.state.updateEndUserSpinner ? (
          <Modal show={this.state.updateEndUserSpinner} centered size="md">
            <Modal.Body style={{ textAlign: "center" }}>
              {" "}
              <Spinner animation="border" />
            </Modal.Body>
          </Modal>
        ) : null}
        {this.state.appDetailsShow ? (
          <AppDetails
            show={this.state.appDetailsShow}
            appDetailsResult={this.state.appDetailsResult}
            handleClose={this.handleClose}
            removeEndUser={this.removeEndUser}
          />
        ) : null}
        {this.state.editAppDisplay ? (
          <EditApp
            show={this.state.editAppDisplay}
            editAppData={this.state.editAppData}
            handleEditClose={this.handleEditClose}
            editSuccess={this.editSuccess}
            removeAppModalState={this.removeAppModalState}
            plan={this.props.plan}
          />
        ) : null}

        {this.state.removeappModal ? (
          <Modal
            show={this.state.removeappModal}
            onHide={this.handleCloseModal}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>{this.state.appName}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p style={{ color: "#000" }}>
                {" "}
                Are you sure you want to remove this app?
              </p>
            </Modal.Body>

            <Modal.Footer>
              {!this.state.appRemoveLoading ? (
                <Button
                  variant="danger"
                  size="sm"
                  className="fontweight600 heading-css-bg"
                  onClick={this.removeApp}
                >
                  Remove
                </Button>
              ) : (
                <Button
                  variant="danger"
                  size="sm"
                  disabled
                  style={{ width: "80px" }}
                  className="fontweight600 heading-css-bg"
                >
                  <Spinner
                    animation="border"
                    style={{ height: "15px", width: "15px" }}
                  />
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        ) : null}

        {this.state.appSuccess ? (
          <p style={{ fontSize: "12px", fontWeight: "600", color: "green" }}>
            App removed successfully
          </p>
        ) : null}
        {this.state.appErr ? (
          <p style={{ fontSize: "12px", fontWeight: "600", color: "crimson" }}>
            Something went wrong. Try again later
          </p>
        ) : null}
        {this.state.appUpdateSuccess ? (
          <p style={{ fontSize: "12px", fontWeight: "600", color: "green" }}>
            App updated successfully
          </p>
        ) : null}

        {this.props.getAllApps !== "error" &&
        this.props.getAllApps !== undefined &&
        this.props.getAllApps.length > 0 ? (
          <div>
            <Table style={{ fontSize: "12px" }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>App ID</th>
                  <th>Network</th>
                  <th>Status</th>
                  <th>View</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {this.props.getAllApps !== "error" &&
                this.props.getAllApps !== undefined &&
                this.props.getAllApps.length > 0
                  ? this.props.getAllApps.map((data, index) =>
                      !data.isRemoved ? (
                        <tr key={index}>
                          <td>{data.appName}</td>
                          <td>{data.appID}</td>
                          <td>{data.networkName}</td>
                          <td>{data.isActive ? "Active" : "Inactive"}</td>
                          <td>
                            {" "}
                            <div
                              className="appicon"
                              style={{
                                color: "green",
                                marginTop: "0px",
                                marginRight: "0px",
                              }}
                              onClick={() =>
                                this.appDetails(data.appID, data.networkID)
                              }
                            >
                              <i className="fa fa-eye" aria-hidden="true"></i>
                            </div>
                          </td>
                          <td>
                            <div
                              className="appicon heading-css"
                              style={{ marginTop: "0px", marginRight: "0px" }}
                              onClick={() =>
                                this.editApp(data.appID, data.networkID)
                              }
                            >
                              <i
                                className="fa fa-pencil-square"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </td>
                        </tr>
                      ) : null
                    )
                  : null}
              </tbody>
            </Table>
          </div>
        ) : this.props.getAllApps !== undefined &&
          this.props.getAllApps.length == 0 ? (
          <p>No data found</p>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    getAllApps: store.apps.allApps,
    loading: store.apps.appsLoading,
    plan: store.price.priceState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getallAppsFunc: () => dispatch(getDevApps()),
    toastifyEnable: (type, val) => dispatch(toastify(type, val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(YourApps);
