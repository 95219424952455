import React, { Component } from "react";
import { Spinner, Table, Button } from "react-bootstrap";
import {
  listallOwnedNodes,
  dedicatedNodeList,
} from "../../common/nodeapicalls";
import { getNetworkNodes } from "../../common/networkapicalls";
import NodeModal from "./addnode";
import { connect } from "react-redux";
import NodeDetails from "../network1/displaynodedetails";
import { toastify, priceModalOpen } from "../../action";

class Nodes extends Component {
  state = {
    allNodes: [],
    selectedIndex: -1,
    openCloseToggle: false,
    addNodeModal: false,
    displayNodeDetails: false,
    data: "",
    loader: false,
  };
  componentDidMount() {
    if (this.props.plan == -1) {
      this.props.priceModalOpen();
      return;
    }

    this.listAllNodes();
  }

  listAllNodes = async () => {
    try {
      this.setState({ loader: true });
      let _dedicatedNodes = [];

      let _listallOwnedNodesRes = [];
      if (this.props.plan >= 3) {
        _listallOwnedNodesRes = await listallOwnedNodes();
        if (_listallOwnedNodesRes.data == null) {
          _listallOwnedNodesRes = [];
        } else if (_listallOwnedNodesRes.status == "fail") {
          _listallOwnedNodesRes = [];
        } else {
          _listallOwnedNodesRes = _listallOwnedNodesRes.data;
        }
        _dedicatedNodes = await dedicatedNodeList();
        if (_dedicatedNodes.data == null) {
          _dedicatedNodes = [];
        } else if (_dedicatedNodes.status == "fail") {
          _dedicatedNodes = [];
        } else {
          _dedicatedNodes = _dedicatedNodes.data;
        }
      }

      let _getNetworkNodesRes = await getNetworkNodes();
      if (_getNetworkNodesRes.data == null) {
        _getNetworkNodesRes = [];
      } else if (_getNetworkNodesRes.status == "fail") {
        _getNetworkNodesRes = [];
      } else {
        _getNetworkNodesRes = _getNetworkNodesRes.data;
      }
      let _arr = [
        ..._listallOwnedNodesRes,
        ..._getNetworkNodesRes,
        ..._dedicatedNodes,
      ];
      let _devkey = await JSON.parse(sessionStorage.getItem("defAddress"))
        .value;
      _arr.filter((data) =>
        data.nodeOwnerID == _devkey
          ? (data.owned = "owned")
          : (data.owned = "partof")
      );
      const unique = await [
        ...new Map(_arr.map((item) => [item["nodeID"], item])).values(),
      ];
      this.setState({ allNodes: unique, loader: false });
    } catch (err) {
      this.setState({ allNodes: [], loader: false });
    }
  };

  toggleShowHide = (index) => {
    this.setState({
      selectedIndex: index,
      openCloseToggle: !this.state.openCloseToggle,
    });
  };

  closeNodeModal = () => {
    this.setState({ addNodeModal: false });
  };

  closeNodeDetails = () => {
    this.setState({ displayNodeDetails: false, data: "" });
  };
  openNodeDetails = (data) => {
    this.setState({ displayNodeDetails: true, data: data });
  };

  errToastify = (val) => {
    this.setState({ addNodeModal: false });
    this.props.toastifyEnable("err", val);
  };
  successModal = () => {
    this.props.toastifyEnable("info", "Node added Successfully");
    this.listAllNodes();
    this.setState({ addNodeModal: false });
  };

  render() {
    return (
      <div className="container">
        <NodeModal
          addNodeModal={this.state.addNodeModal}
          closeNodeModal={this.closeNodeModal}
          successModal={this.successModal}
          errToastify={this.errToastify}
        />

        <NodeDetails
          displayNodeDetails={this.state.displayNodeDetails}
          closeNodeDetails={this.closeNodeDetails}
          data={this.state.data}
        />
        {!this.state.loader ? (
          <>
            <div className="displayflex" style={{ marginTop: "30px" }}>
              <div>
                <h5
                  className="heading-css"
                  style={{ fontWeight: "600", fontSize: "18px" }}
                >
                  Nodes
                </h5>
              </div>

              <div className="displayflex">
                {this.props.plan > 3 ? (
                  <div>
                    <Button
                      size="sm"
                      className="button-css"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                      onClick={() => this.setState({ addNodeModal: true })}
                    >
                      + Add Node
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>

            <div
              className="fixedheight"
              style={{ height: "auto", marginTop: "20px" }}
            >
              {this.state.allNodes.length !== 0 ? (
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th className="network_headerColumns">#</th>
                      <th className="network_headerColumns">Node Name</th>
                      {this.props.plan >= 3 ? (
                        <th
                          className="network_headerColumns"
                          style={{ width: "19%" }}
                        >
                          Node IP
                        </th>
                      ) : null}
                      <th className="network_headerColumns">Region</th>
                      <th className="network_headerColumns">Accessibility</th>
                      <th className="network_headerColumns">Node Type</th>
                      <th className="network_headerColumns"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.allNodes.map((data, index) => (
                      <tr
                        onClick={() => this.openNodeDetails(data)}
                        style={{ cursor: "pointer" }}
                        key={index}
                      >
                        <td className="network__detail_col">{index + 1}</td>
                        <td className="network__detail_col">{data.nodeName}</td>
                        {this.props.plan >= 3 ? (
                          data.owned == "owned" ? (
                            <td className="network__detail_col">
                              {data.nodeIP}
                            </td>
                          ) : (
                            <td
                              className="network__detail_col"
                              style={{ textAlign: "left" }}
                            >
                              {"-"}
                            </td>
                          )
                        ) : null}
                        <td className="network__detail_col">
                          {data.region !== ""
                            ? data.region
                            : data.city !== ""
                            ? data.city
                            : data.country}
                        </td>
                        <td className="network__detail_col">
                          {" "}
                          {data.owned == "owned" ? "Owned" : "Have access to"}
                        </td>
                        <td className="network__detail_col">
                          {" "}
                          {data.nodeType == 0
                            ? "Private"
                            : data.nodeType == 1
                            ? "Public"
                            : "Premium"}
                        </td>
                        <td
                          className="network__detail_col"
                          style={{ color: data.isActive ? "green" : "#122c50" }}
                        >
                          {" "}
                          {data.isActive ? (
                            <span>
                              <i
                                className="fa fa-circle"
                                aria-hidden="true"
                              ></i>{" "}
                              Live{" "}
                            </span>
                          ) : (
                            <span>
                              <i
                                className="fa fa-circle"
                                aria-hidden="true"
                              ></i>{" "}
                              Inactive
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>No Nodes Available</p>
              )}
            </div>
          </>
        ) : (
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <p>Fetching...</p>
            <Spinner animation="border" />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    plan: store.price.priceState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toastifyEnable: (type, val) => dispatch(toastify(type, val)),
    priceModalOpen: () => dispatch(priceModalOpen()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Nodes);
