const ethers = require('ethers');
const { noop } = require('../utils');
const { moiValidationError } = require('../lib/errors');
const Constants = require('../lib/constants');

function HdWallet() {
    this.hdWallet = {}
}

HdWallet.prototype.createInstance = function () {
    const random_bytes = ethers.utils.randomBytes(12);
    this.hdWallet = ethers.Wallet.createRandom(random_bytes);
}

HdWallet.prototype.createFromSeedphrase = function (seed_phrase) {
    moiValidationError(seed_phrase.split(' ').length !== 12, Constants.INVALID_KEYSTORE);
    this.hdWallet = ethers.Wallet.fromMnemonic(seed_phrase);
}

HdWallet.prototype.createfromWrapper = async function (secureJson, decryptingKey) {
    moiValidationError(secureJson === undefined, Constants.KEYSTORE_UNDEFINED);
    moiValidationError(decryptingKey === undefined, Constants.PASSWORD_UNDEFINED);
    try {
        this.hdWallet = await ethers.Wallet.fromEncryptedJson(JSON.stringify(secureJson), decryptingKey);
    } catch (e) {
        throw new Error(e.message);
    }
}

HdWallet.prototype.getSeedPhrase = function () {
    moiValidationError(this.hdWallet.mnemonic === undefined, Constants.NO_WALLET_INSTANCE);
    return this.hdWallet.mnemonic;
}

HdWallet.prototype.getKey = function () {
    moiValidationError(this.hdWallet.privateKey === undefined, Constants.NO_WALLET_INSTANCE);
    return this.hdWallet.privateKey;
}

HdWallet.prototype.getID = function () {
    moiValidationError(this.hdWallet.address === undefined, Constants.NO_WALLET_INSTANCE);
    return this.hdWallet.address;
}

HdWallet.prototype.getAccountWrapper = function (_passphrase) {
    moiValidationError(_passphrase === undefined, Constants.PASSWORD_UNDEFINED);
    moiValidationError(this.hdWallet.privateKey === undefined, Constants.NO_WALLET_INSTANCE);
    let _HdWallet = new ethers.Wallet(this.hdWallet.privateKey);

    let options = {
        scrypt: {
            N: (1 << 12)
        },
        mnemonic: this.hdWallet.mnemonic
    };
    return _HdWallet.encrypt(_passphrase, options, noop)
        .then(jsonWrapper => {
            return Promise.resolve(jsonWrapper);
        })
        .catch(e => {
            return Promise.reject(e);
        })
}

HdWallet.prototype.deriveAccountFromPath = function (pathArray, deriveAndLoad, moiIDVersion) {
    moiValidationError(pathArray === undefined, 'Path cannot be undefined');

    let pathToBeDerived;
    if(moiIDVersion && moiIDVersion == 1) {
        pathToBeDerived = "m/44'/" + pathArray[0] + "'/" + pathArray[1] + "'/" + pathArray[2] + "/" + pathArray[3]; // not hardened at scope index in hd wallet path
    }else {
        pathToBeDerived = "m/44'/" + pathArray[0] + "'/" + pathArray[1] + "'/" + pathArray[2] + "'/" + pathArray[3];
    }

    const tempHdw = new ethers.Wallet.fromMnemonic(this.hdWallet.mnemonic, pathToBeDerived);

    if (deriveAndLoad) {
        this.hdWallet = tempHdw;
        return
    }

    return tempHdw;
}

HdWallet.prototype.createFromSeedphrase = function (seed_phrase) {
    moiValidationError(seed_phrase.split(' ').length !== 12, Constants.INVALID_KEYSTORE);
    this.hdWallet = ethers.Wallet.fromMnemonic(seed_phrase);
}


module.exports = HdWallet;
