import React from "react";
import { Table, Card, Modal, Spinner } from "react-bootstrap";
import { getParticularNetworkDetails } from "../../common/networkapicalls";
import { Link } from "react-router-dom";

class NetworkCreated extends React.Component {
  state = {
    network: [],
    opened: [],
    openInviteModal: false,
    getAllUsers: [],
    networkDetails: [],
    selectedUser: [],
    selectUserErr: false,
    inviteUserLoading: false,
    invitationSentSuccess: false,
    tableData: [],
    selectedIndex: -1,
    openCloseToggle: false,
    nodeErr: false,
    nodeErrVal: "",
  };
  async componentDidMount() {
    // let _res = await window.moi_id.getAllUsers();
    // this.setState({ getAllUsers: _res });
  }
  toggleShowHide = async (id, network_id) => {
    try {
      this.setState({
        tableData: [],
        selectedIndex: id,
        openCloseToggle: !this.state.openCloseToggle,
        nodeErrVal: "",
        nodeErr: false,
      });
      let _getNetworkDetails = await getParticularNetworkDetails(network_id);
      let _allNodes = [];
      _allNodes = _getNetworkDetails.nodelist;
      this.setState({
        opened: [...this.state.opened, id],
        tableData: _allNodes,
      });
    } catch (Err) {
      //do nothing
    }
  };
  openInviteModal = (data) => {
    this.setState({ openInviteModal: true, networkDetails: data });
  };
  closeInviteModal = () => {
    this.setState({ openInviteModal: false, networkDetails: [] });
  };
  onSelect = (selectedList, selectedItem) => {
    this.setState({ selectedUser: selectedList, selectUserErr: false });
  };
  onRemove = (selectedList, removedItem) => {
    this.setState({ selectedUser: selectedList, selectUserErr: false });
  };

  inviteUsers = async () => {
    try {
      if (this.state.selectedUser.length == 0) {
        this.setState({ selectUserErr: true });
        return;
      }
      this.setState({ inviteUserLoading: true });
      let val = this.state.networkDetails;
      const token = {
        network_id: val.network_id,
        network_name: val.network_name,
        network_token: val.networkToken,
        isActive: true,
      };
      let _adminCreds = {
        id: JSON.parse(sessionStorage.getItem("defAddress")).value,
        nonce: JSON.parse(sessionStorage.getItem("authToken")).value.split(
          "_"
        )[0],
        auth_signature: JSON.parse(
          sessionStorage.getItem("authToken")
        ).value.split("_")[1],
      };
      let res = [];
      for (let i = 0; i < this.state.selectedUser.length; i++) {
        res.push(
          await window.moi_id.inviteUser(
            this.state.selectedUser[i],
            token,
            _adminCreds
          )
        );
      }
      this.setState({
        inviteUserLoading: false,
        openInviteModal: false,
        invitationSentSuccess: true,
      });
      setTimeout(() => {
        this.setState({ invitationSentSuccess: false });
      }, 3000);
    } catch (err) {
      this.setState({ inviteUserLoading: false });
    }
  };
  render() {
    return (
      <div className="scrollhomecard">
        <Modal
          show={this.state.openInviteModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.closeInviteModal}
        >
          <Modal.Body closeButton>
            <div style={{ padding: "30px" }}>
              <h1 style={{ textAlign: "center" }}>Coming Soon!!!</h1>
            </div>
          </Modal.Body>
        </Modal>

        {this.state.invitationSentSuccess ? (
          <p style={{ fontSize: "12px", fontWeight: "600", color: "green" }}>
            Invitation sent successfully
          </p>
        ) : null}
        {this.props.networkList.length > 0 &&
        this.props.networkList !== "error" ? (
          this.props.networkList.map((data, index) => {
            return (
              <Link
                to={"/network/view/" + data.networkID}
                style={{ textDecoration: "none" }}
              >
                <Card
                  key={index}
                  className="appscard"
                  style={{ cursor: "pointer" }}
                >
                  <Card.Body style={{ padding: "10px 20px 20px 20px" }}>
                    <div className="cardcss">
                      <div style={{ display: "flex" }}>
                        <div className="appfirstletter heading-css-bg">
                          <p style={{ paddingTop: "7px" }}>
                            {" "}
                            {data.networkName.charAt(0)}
                          </p>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                          <Card.Title
                            style={{
                              fontSize: "18px",
                              fontWeight: "600",
                              marginBottom: "2px",
                              color: "#000",
                            }}
                          >
                            {data.networkName}
                          </Card.Title>
                          <Card.Text className="carddesccss">
                            {data.networkID}
                          </Card.Text>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop:
                          this.state.selectedIndex == index &&
                          this.state.openCloseToggle
                            ? "20px"
                            : "0px",
                      }}
                    >
                      <Table
                        hover
                        responsive
                        borderless
                        style={{
                          display:
                            this.state.selectedIndex == index &&
                            this.state.openCloseToggle
                              ? "table"
                              : "none",
                          animationDuration:
                            this.state.opened.indexOf(index) !== -1
                              ? "0.8s"
                              : "none",
                          animationTimingFunction:
                            "cubic-bezier(.86, .03, .53, 1.01)",
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Region</th>
                            <th>Storage</th>
                            <th>C.P.U</th>
                            <th>Ram</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.tableData == -1 ? (
                            <div
                              style={{ textAlign: "center", margin: "10px" }}
                            >
                              {" "}
                              <Spinner
                                animation="border"
                                style={{ height: "20px", width: "20px" }}
                              />{" "}
                            </div>
                          ) : null}

                          {this.state.tableData.map((data, index) => (
                            <tr
                              style={{ borderBottom: "1px solid #c0c0c0" }}
                              key={index}
                            >
                              <td style={{ color: "blue" }}>{data.region}</td>
                              <td>{data.storage ? data.storage : "-"}</td>
                              <td>{data.cpu ? data.cpu : "-"}</td>
                              <td>{data.ram ? data.ram : "-"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
            );
          })
        ) : (
          <p>No data found</p>
        )}
      </div>
    );
  }
}

export default NetworkCreated;
