import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { sessionCheck, priceModalOpen } from "../../action";
import {
  devAdmindetails,
  registerBearerToken,
  getBearerToken,
  updateBearerToken,
  deleteBearerToken,
} from "../../common/devapicalls";
import { Button, Modal, Table } from "react-bootstrap";
import { toastify } from "../../action";
import base64url from "base64url";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      emailid: "",
      company: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      enable: true,
      err: false,
      successMessage: false,

      defAddress: "",
      nonce: "",
      signature: "",
      showAlert: false,
      copiedKey: "",
      plan: null,

      smthngWentWrong: false,

      date: "",
      showInput: false,
      dateSelected: false,

      encodedToken: "",
      tokenName: "",
      tokenDetails: [],
      refreshing: false,
    };
  }
  async componentDidMount() {
    try {
      if (this.props.plan == -1) {
        this.props.priceModalOpen();
        return;
      }

      let userDefaultAddress = await window.moi_id.getUserID(
        JSON.parse(sessionStorage.getItem("name")).value
      );
      let _res = await window.moi_id.getProfiledetails(userDefaultAddress, "");

      let tokens = await getBearerToken();
      if (typeof tokens.data !== "string") {
        let filteredData = tokens.data.filter((data) => {
          return !data.name.includes("NameGeneratedFromBrowserDuringLogin");
        });
        this.setState({
          tokenDetails: filteredData,
        });
      }

      let _devstatRes = (await devAdmindetails()).data;
      this.setState({
        emailid: _res.dimensions.basic.email.value,
        plan: _devstatRes.plan,
      });
    } catch (Err) {
      this.setState({ smthngWentWrong: true });
      setTimeout(() => {
        this.setState({ smthngWentWrong: false });
      }, 3000);
    }
  }

  enableTextBox = (e) => {
    e.preventDefault();
    this.setState({ enable: !this.state.enable });
  };
  handleProfileUpdate = async (e) => {
    e.preventDefault();
    let data = {
      firstname: this.state.firstName,
      lastname: this.state.lastName,
      email: this.state.emailid,
      address1: this.state.address1,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      pincode: this.state.pincode,
    };
  };

  copyToclipBoard = async (which) => {
    let keyVal;
    const el = document.createElement("textarea");
    el.value =
      which === 1
        ? this.state.defAddress
        : which === 2
        ? this.state.nonce
        : which === 3
        ? this.state.signature
        : this.state.encodedToken;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    if (which == 1) {
      keyVal = "ID";
    } else if (which == 2) {
      keyVal = "Nonce";
    } else if (which == 3) {
      keyVal = "Signature";
    } else if (which == 4) {
      keyVal = "Token";
    }
    this.setState({ showAlert: true, copiedKey: keyVal });
    setTimeout(() => {
      this.setState({ showAlert: false, copiedKey: "" });
    }, 2000);
  };

  generateNewAuth = async (date) => {
    this.setState({ dateSelected: true });
    // let _res = await window.moi_id.isWalletUnlocked();
    // if (!_res) {
    //   this.props.setSession();
    // } else {
    let expiryDate = new Date(date);
    const timeNow = new Date();
    expiryDate.setHours(timeNow.getHours());
    expiryDate.setMinutes(timeNow.getMinutes());
    expiryDate.setSeconds(timeNow.getSeconds());
    let _res = await window.moi_id.getAuthToken(
      "" + expiryDate.getTime() / 1000
    );
    if (_res !== undefined) {
      let authToken = _res.message + "_" + _res.signature;

      const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours from now
      sessionStorage.setItem(
        "authToken",
        JSON.stringify({ value: authToken, expiresAt: expirationTime })
      );
      sessionStorage.setItem(
        "defAddress",
        JSON.stringify({ value: _res.id, expiresAt: expirationTime })
      );

      this.setState({
        nonce: _res.message,
        signature: _res.signature,
        defAddress: _res.id,
      });
      // }

      // base64URL encoding
      const defAddress = this.state.defAddress;
      const nonce = this.state.nonce;
      const signature = this.state.signature;
      const name = this.state.tokenName;
      const obj = {
        defAddress,
        nonce,
        signature,
        name,
      };

      const KEY = JSON.stringify(obj);
      let encodedKey = base64url(KEY);

      let res = await registerBearerToken(encodedKey);
      this.setState({
        encodedToken: encodedKey,
      });
      let tokens = await getBearerToken();
      if (tokens.status === "success") {
        if (Array.isArray(tokens.data)) {
          let filteredData = tokens.data.filter((data) => {
            return !data.name.includes("NameGeneratedFromBrowserDuringLogin");
          });
          this.setState({
            tokenDetails: filteredData,
          });
        } else {
          this.props.toastifyEnable("err", tokens.message);
        }
      } else {
        this.props.toastifyEnable("err", tokens.message);
      }
    }
  };

  handleCreateAccessToken = () => {
    let _res = window.moi_id.isWalletUnlocked();
    if (!_res) {
      this.props.setSession();
    } else {
      this.setState({ showInput: true });
    }
  };

  handleClose = () => {
    this.props.closeModal();
    this.setState({ showInput: false, dateSelected: false, date: "" });
  };

  async activateToken(data) {
    try {
      this.setState({ refreshing: true }, async () => {
        const res = await updateBearerToken(data.bearer_token, true);
        if (res.code === 200) {
          this.props.toastifyEnable("info", "Token activated successfully");
          this.setState({ refreshing: false });
          setTimeout(async () => {
            let tokens = await getBearerToken();
            if (typeof tokens.data !== "string") {
              let filteredData = tokens.data.filter((data) => {
                return !data.name.includes(
                  "NameGeneratedFromBrowserDuringLogin"
                );
              });
              this.setState({
                tokenDetails: filteredData,
              });
            }
          }, 5000);
        } else {
          this.props.toastifyEnable("err", res.message);
          this.setState({ refreshing: false });
        }
      });
    } catch (err) {
      this.props.toastifyEnable("err", err.message);
      this.setState({ refreshing: false });
    }
  }

  async deactivateToken(data) {
    try {
      this.setState({ refreshing: true });
      const res = await updateBearerToken(data.bearer_token, false);
      if (res.code === 200) {
        this.props.toastifyEnable("info", "Token Deactivated successfully");
        this.setState({ refreshing: false });
        setTimeout(async () => {
          let tokens = await getBearerToken();
          if (typeof tokens.data !== "string") {
            let filteredData = tokens.data.filter((data) => {
              return !data.name.includes("NameGeneratedFromBrowserDuringLogin");
            });
            this.setState({
              tokenDetails: filteredData,
            });
          }
        }, 5000);
      } else {
        this.props.toastifyEnable("err", res.message);
        this.setState({ refreshing: false });
      }
    } catch (err) {
      this.props.toastifyEnable("err", err.message);
      this.setState({ refreshing: false });
    }
  }

  async deleteToken(data) {
    try {
      this.setState({ refreshing: true });
      const res = await deleteBearerToken(data.bearer_token);
      if (res.code === 200) {
        this.props.toastifyEnable("info", "Token Deleted successfully");
        this.setState({ refreshing: false });
        setTimeout(async () => {
          let tokens = await getBearerToken();
          if (tokens.status === "success") {
            if (Array.isArray(tokens.data)) {
              let filteredData = tokens.data.filter((data) => {
                return !data.name.includes(
                  "NameGeneratedFromBrowserDuringLogin"
                );
              });
              this.setState({
                tokenDetails: filteredData,
              });
            } else {
              this.props.toastifyEnable("err", tokens.message);
            }
          } else {
            this.props.toastifyEnable("err", tokens.message);
          }

          if (typeof tokens.data !== "string") {
            let filteredData = tokens.data.filter((data) => {
              return !data.name.includes("NameGeneratedFromBrowserDuringLogin");
            });
            this.setState({
              tokenDetails: filteredData,
            });
          }
        }, 5000);
      } else {
        this.props.toastifyEnable("err", res.message);
        this.setState({ refreshing: false });
      }
    } catch (err) {
      this.props.toastifyEnable("err", err.message);
      this.setState({ refreshing: false });
    }
  }

  render() {
    return (
      <div className="container">
        <div
          className="row"
          style={{ marginTop: "40px", marginLeft: "10px", marginRight: "10px" }}
        >
          <div className="col-md-12">
            <div className="row">
              <p
                style={{ fontSize: "18px", fontWeight: "600" }}
                className="heading-css"
              >
                Profile
              </p>

              <div
                className="row"
                style={{ display: "flex", position: "absolute", right: "10px" }}
              >
                <Button
                  variant="info"
                  size="sm"
                  className="heading-css-bg fontweight600"
                  onClick={() => this.handleCreateAccessToken()}
                >
                  Create Access Token
                </Button>
              </div>

              <div
                className="col-md-12"
                style={{ margin: "30px", marginTop: "0px" }}
              >
                <form
                  onSubmit={this.handleProfileUpdate}
                  // className="form align-items-center formbackground"
                >
                  <div className="col-auto marginBtm20">
                    <div className="col-md-5">
                      <div style={{ left: "10px" }}>
                        <label>Email</label>
                        <div className="input-group mb-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            defaultValue={this.state.emailid}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div
                className="col-md-12"
                style={{ margin: "30px", marginTop: "0px" }}
              >
                <div className="row">
                  <div
                    className="col-auto"
                    style={{ position: "relative", left: "30px" }}
                  >
                    <p className="field-heading">Price tier : </p>
                  </div>
                  <div
                    className="col-md-7"
                    style={{
                      color: "cadetblue",
                      position: "relative",
                      left: "30px",
                    }}
                  >
                    <p
                      className="plan-heading-css"
                      style={{
                        // marginTop: "-10px",
                        fontSize: "16px",
                        fontWeight: "700",
                        letterSpacing: "1px",
                        paddingRight: "5px",
                      }}
                    >
                      {this.state.plan !== null &&
                        (this.state.plan == 0
                          ? "DEVELOPER"
                          : this.state.plan == 1
                          ? "INDIVIDUAL"
                          : this.state.plan == 2
                          ? "START UP"
                          : this.state.plan == 3
                          ? "SMB"
                          : this.state.plan == 4
                          ? "ENTERPRISE"
                          : null)}
                    </p>
                  </div>

                  <div>
                    {this.state.plan !== null && this.state.plan !== 4 ? (
                      <Button
                        variant="info"
                        size="sm"
                        className="heading-css-bg fontweight600"
                        onClick={() => (window.location.href = "/pricing")}
                      >
                        Upgrade
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>

              <div
                className="col-md-12"
                style={{ margin: "30px", marginTop: "0px" }}
              >
                <div className="row">
                  <div
                    className="col-auto"
                    style={{
                      position: "relative",
                      left: "30px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <p className="field-heading">Your Token: </p>
                    </div>
                    <div style={{ flexDirection: "row" }}>
                      {this.state.tokenDetails.length == 0 ? (
                        <p style={{ marginLeft: "5px" }}>No data found</p>
                      ) : this.state.tokenDetails.length > 0 ? (
                        <Table
                          striped
                          style={{
                            fontWeight: "600",
                            fontSize: "14px",
                            padding: "12px",
                            margin: "10px",
                          }}
                          className="table-condensed1"
                        >
                          <thead>
                            <tr>
                              <th>Token Name</th>
                              {/* <th>Update Token</th> */}
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.tokenDetails.map((data, index) =>
                              !data.is_active ? (
                                <tr key={index}>
                                  <td
                                    className="tdalign"
                                    style={{ color: "blue" }}
                                  >
                                    {data.name}
                                  </td>
                                  {/* <td>
                                    <Button
                                      variant="dark button-css"
                                      size="sm"
                                      style={{
                                        fontWeight: "600",
                                        width: "100%",
                                      }}
                                      onClick={() => this.activateToken(data)}
                                    >
                                      Activate
                                    </Button>
                                  </td> */}
                                  <td>
                                    <div
                                      className="trashdelete"
                                      style={{
                                        fontWeight: "600",
                                        width: "100%",
                                        marginRight: "10px",
                                        marginLeft: "10px",
                                        fontSize: "16px",
                                        alignItems: "center",
                                        padding: "4px 8px",
                                      }}
                                      onClick={() => this.deleteToken(data)}
                                    >
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <tr key={index}>
                                  <td
                                    className="tdalign"
                                    style={{ color: "blue" }}
                                  >
                                    {data.name}
                                  </td>
                                  {/* <td>
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      style={{
                                        fontWeight: "600",
                                        width: "100%",
                                      }}
                                      onClick={() => this.deactivateToken(data)}
                                    >
                                      Deactivate
                                    </Button>
                                  </td> */}
                                  <td>
                                    <div
                                      className="trashdelete"
                                      style={{
                                        fontWeight: "600",
                                        width: "100%",
                                        marginRight: "10px",
                                        marginLeft: "10px",
                                        fontSize: "16px",
                                        alignItems: "center",
                                        padding: "4px 8px",
                                      }}
                                      onClick={() => this.deleteToken(data)}
                                    >
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              {this.state.showInput && (
                <Modal
                  show={this.state.showInput}
                  onHide={this.handleClose}
                  centered
                  backdrop="static"
                  keyboard={false}
                  size="lg"
                >
                  <Modal.Header>
                    <p>Create Access Token</p>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1 }}>
                          <label style={{ marginRight: "10px" }}>
                            Choose Expiry Date:
                          </label>
                          <input
                            type="date"
                            placeholder="dd-mm-yyyy"
                            value={this.state.date}
                            onChange={(e) =>
                              this.setState({ date: e.target.value })
                            }
                            min={new Date().toISOString().split("T")[0]}
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <label style={{ marginRight: "10px" }}>
                            Token name:
                          </label>
                          <input
                            type="text"
                            placeholder=""
                            value={this.state.tokenName}
                            onChange={(e) =>
                              this.setState({ tokenName: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // height: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          type="submit"
                          size="sm"
                          className="heading-css-bg fontweight600"
                          style={{
                            position: "relative",
                            left: "30px",
                            marginTop: "20px",
                          }}
                          onClick={() => this.generateNewAuth(this.state.date)}
                          disabled={!this.state.date}
                        >
                          Generate Auth key
                        </Button>
                      </div>

                      <div
                        className="row"
                        style={{
                          marginTop: "10px",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="col-md-10"
                          style={{
                            marginTop: "20px",
                            flex: 1,
                          }}
                        >
                          <p className="createappnote">
                            Generated Token can be used MoiBit auth token. Make
                            sure you save it - you won't be able to access it
                            again.
                          </p>
                        </div>
                      </div>
                    </div>

                    {this.state.dateSelected ? (
                      <div style={{ padding: "10px" }}>
                        {/* <div className="row">
                          <div className="col-md-3">
                            <p className="field-heading">Your Id :</p>
                          </div>
                          <div className="col-md-7 fieldvalue">
                            {this.state.defAddress}
                          </div>
                          <div className="col-md-2">
                            <button
                              className="btn btn-info button-css"
                              style={{
                                marginTop: "-10px",
                                fontSize: "12px",
                                fontWeight: "600",
                              }}
                              onClick={() => this.copyToclipBoard(1)}
                            >
                              Copy
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <p className="field-heading">Nonce :</p>
                          </div>
                          <div className="col-md-7 fieldvalue">
                            {this.state.nonce}
                          </div>
                          <div className="col-md-2">
                            <button
                              className="btn btn-info button-css"
                              style={{
                                marginTop: "-5px",
                                fontSize: "12px",
                                fontWeight: "600",
                              }}
                              onClick={() => this.copyToclipBoard(2)}
                            >
                              Copy
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <p className="field-heading">Signature :</p>
                          </div>
                          <div
                            className="col-md-7 fieldvalue"
                            style={{ width: "10px", wordWrap: "break-word" }}
                          >
                            {this.state.signature}
                          </div>
                          <div className="col-md-2">
                            <button
                              className="btn btn-info button-css"
                              style={{
                                marginTop: "10px",
                                fontSize: "12px",
                                fontWeight: "600",
                              }}
                              onClick={() => this.copyToclipBoard(3)}
                            >
                              Copy
                            </button>
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="col-md-3">
                            <p className="field-heading">Token :</p>
                          </div>
                          <div
                            className="col-md-7 fieldvalue"
                            style={{
                              width: "10px",
                              wordWrap: "break-word",
                              fontSize: "12px",
                            }}
                          >
                            {this.state.encodedToken}
                          </div>
                          <div className="col-md-2">
                            <button
                              className="btn btn-info button-css"
                              style={{
                                marginTop: "10px",
                                fontSize: "12px",
                                fontWeight: "600",
                              }}
                              onClick={() => this.copyToclipBoard(4)}
                            >
                              Copy
                            </button>
                          </div>
                        </div>
                        <div>
                          {this.state.showAlert ? (
                            <p
                              style={{
                                color: "crimson",
                                fontWeight: 600,
                                fontSize: "16px",
                                animationDirection: "alternate",
                                transition: "ease",
                              }}
                            >
                              {this.state.copiedKey} Copied!
                            </p>
                          ) : null}
                        </div>

                        <div style={{ marginTop: "10px" }}>
                          {this.state.smthngWentWrong ? (
                            <p
                              style={{
                                color: "crimson",
                                fontWeight: 600,
                                fontSize: "16px",
                                animationDirection: "alternate",
                                transition: "ease",
                              }}
                            >
                              Something went wrong. Please try again after
                              sometime.
                            </p>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      style={{ fontWeight: "600" }}
                      className="button-css"
                      onClick={() =>
                        this.setState({
                          showInput: false,
                          showInput: false,
                          dateSelected: false,
                          date: "",
                          nonce: null,
                          signature: null,
                          defAddress: null,
                        })
                      }
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    plan: store.price.priceState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSession: () => dispatch(sessionCheck()),
    priceModalOpen: () => dispatch(priceModalOpen()),
    toastifyEnable: (type, val) => dispatch(toastify(type, val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
