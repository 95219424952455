import React from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { Modal, Spinner, Button } from "react-bootstrap";
import { inviteUser } from "../home/utils";

class InviteUser extends React.Component {
  state = {
    networkDetails: [],
    openInviteModal: false,
    getAllUsers: [],
    selectedUser: [],
    selectUserErr: false,
    inviteUserLoading: false,
    invitationSentSuccess: false,
  };
  async componentDidMount() {
    let _res = await window.moi_id.getAllUsers();
    let _filterArr = _res.filter(
      (data) => data !== JSON.parse(sessionStorage.getItem("name")).value
    );
    this.setState({
      getAllUsers: _filterArr,
      networkDetails: this.props.networkDetails,
    });
  }
  onSelect = (selectedList, selectedItem) => {
    this.setState({ selectedUser: selectedList, selectUserErr: false });
  };
  onRemove = (selectedList, removedItem) => {
    this.setState({ selectedUser: selectedList, selectUserErr: false });
  };

  inviteUsers = async () => {
    try {
      if (this.state.selectedUser.length == 0) {
        this.setState({ selectUserErr: true });
        return;
      }
      this.setState({ inviteUserLoading: true });
      let val = this.state.networkDetails;
      const token = {
        network_id: val.networkID,
        network_name: val.networkName,
        network_token: val.networkToken,
        isActive: true,
      };
      let _adminCreds = {
        id: JSON.parse(sessionStorage.getItem("defAddress")).value,
        nonce: JSON.parse(sessionStorage.getItem("authToken")).value.split(
          "_"
        )[0],
        auth_signature: JSON.parse(
          sessionStorage.getItem("authToken")
        ).value.split("_")[1],
      };
      let res = [];
      for (let i = 0; i < this.state.selectedUser.length; i++) {
        res.push(
          await inviteUser(this.state.selectedUser[i], token, _adminCreds)
        );
      }
      this.setState({
        inviteUserLoading: false,
        openInviteModal: false,
        invitationSentSuccess: true,
      });
      this.props.inviteSuccess();
      this.props.closeInviteModal();
      setTimeout(() => {
        this.setState({ invitationSentSuccess: false });
      }, 3000);
    } catch (err) {
      this.setState({ inviteUserLoading: false });
    }
  };
  render() {
    return (
      <div>
        <Modal
          show={this.props.show}
          size="md"
          centered
          onHide={this.props.closeInviteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "16px" }} className="fontweight600">
              Invite
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Multiselect
              options={this.state.getAllUsers}
              onSelect={this.onSelect}
              onRemove={this.onRemove}
              isObject={false}
              placeholder="--Select Developers--"
              style={{
                chips: {
                  background: "#000",
                  fontWeight: "600",
                  fontSize: "16px",
                },
                searchBox: { "border-radius": "10px", fontSize: "16px" },
                inputField: { color: "#8021BF" },
                multiselectContainer: { color: "#000", fontSize: "14px" },
              }}
            />
            {this.state.selectUserErr ? (
              <p
                style={{
                  fontWeight: "600",
                  color: "crimson",
                  fontSize: "12px",
                  margin: "10px",
                  marginBottom: "0px",
                }}
              >
                Please select atleast one user
              </p>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            {!this.state.inviteUserLoading ? (
              <Button
                variant="success"
                size="sm"
                className="fontweight600 heading-css-bg"
                onClick={this.inviteUsers}
              >
                Invite developers
              </Button>
            ) : (
              <Spinner
                animation="border"
                style={{ height: "20px", width: "20px" }}
              />
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default InviteUser;
