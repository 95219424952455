
import React from "react";
import { Table, Spinner } from "react-bootstrap";
import Geocode from "react-geocode";
import GoogleMapReact from 'google-map-react';
import Lock from "../../assets/lock.gif";
import MarkerClusterer from '@googlemaps/markerclustererplus';


// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);

// set response language. Defaults to english.
Geocode.setLanguage("en");



class Maps extends React.Component {
    static defaultProps = {
        center: {
            lat: 0,
            lng: 0
        },
        zoom: 0
    };
    state = {
        markers: [],
        nodelist: [],
        loader: false
    }
    async componentDidMount() {
        try {
            this.setState({ loader: true });
            let arr = this.props.nodeValue;
            let markers = [];
            if (this.props.preview !== "preview") {
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].value) {
                        let _res = await Geocode.fromAddress(arr[i].name.split("_")[0] !== "" ? arr[i].name.split("_")[0] : arr[i].data.city);
                        const { lat, lng } = _res.results[0].geometry.location

                        let newLat = lat + (Math.random() - .5) / 1500;// * (Math.random() * (max - min) + min);
                        let newLng = lng + (Math.random() - .5) / 1500;// * (Math.random() * (max - min) + min);
                        markers.push({ lat: newLat, lng: newLng, name: arr[i].data.nodeName, region: arr[i].data.region !== "" ? arr[i].data.region : arr[i].data.city, type: arr[i].data.userDefinedNodeType })
                    }
                }
            } else {
                for (let i = 0; i < arr.length; i++) {

                    let _res = await Geocode.fromAddress(arr[i].region);
                    const { lat, lng } = _res.results[0].geometry.location
                    markers.push({ lat: lat, lng: lng, name: arr[i].region })
                }
            }
            let mapArr = [];
            for (let i = 0; i < this.props.nodeValue.length; i++) {
                if (this.props.nodeValue[i].value) {
                    mapArr.push(this.props.nodeValue[i])
                }
            }
            this.setState({ markers: markers, nodelist: mapArr, loader: false });
        }
        catch (err) {
            this.setState({ markers: [], nodelist: [], loader: false });
        }

    }




    renderMarkers = (map, maps) => {

        const _marker = this.state.markers;
        var icon = {
            url: Lock,
            scaledSize: new maps.Size(50, 50), // scaled size
        };

        var _allMarkers = [];

        for (let i = 0; i < _marker.length; i++) {
            const infowindow = new maps.InfoWindow({
                content: '<div id="content">' +
                    '<div id="siteNotice">' +
                    "</div>" +
                    '<div id="bodyContent" style="margin-top:5px;">' +
                    `<p style="font-weight:600;margin-bottom:5px;">Region : ${_marker[i].region !== "" ? _marker[i].region : _marker[i].city}</p>` +
                    `<p style="font-weight:600;margin-bottom:5px;">Name : ${_marker[i].name}</p>` +
                    `<p style="font-weight:600;margin-bottom:5px;">Type : ${_marker[i].type == 0 ? "Private" : "Public"}</p>` +
                    "</div>" +
                    "</div>",
            });
            let marker = new maps.Marker({
                position: { lat: _marker[i].lat, lng: _marker[i].lng },
                map,
                icon: icon
            });
            marker.addListener("click", () => {
                infowindow.open(map, marker);
            });
            _allMarkers.push(marker);
        }
        const markerCluster = new MarkerClusterer(map, _allMarkers, {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        });
    }


    render() {
        return (
            <div>
                {this.state.loader ?
                    <div style={{ textAlign: "center", fontSize: "14px" }}>
                        <p>Please wait...</p>
                        <Spinner animation="border" />
                    </div> :
                    <div>
                        <div style={{ height: this.state.markers.length > 0 ? '65vh' : "auto", width: '100%' }}>
                            {this.state.markers.length > 0 ? <GoogleMapReact
                                bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
                                defaultCenter={this.props.center}
                                defaultZoom={this.props.zoom}
                                onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                                options={map => ({ mapTypeId: map.MapTypeId.SATELLITE })}

                            >
                            </GoogleMapReact> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}> No preview available</div>}
                        </div>

                        {this.props.message ? <div>
                            <div className="previewnet">
                                <p>Network Name : {this.props.network}</p>
                                <p>Minimum Replication Factor : {this.props.min}</p>
                                <p>Maximum Replication Factor : {this.props.max}</p>
                            </div>

                        </div> : null}

                        {this.state.nodelist.length !== 0 ?
                            <div style={{ marginTop: "10px" }}>
                                <Table hover responsive borderless
                                    style={{ animationDuration: "0.8s", animationTimingFunction: "cubic-bezier(.86, .03, .53, 1.01)" }}
                                >
                                    <thead>
                                        <tr>
                                            <th>Region</th>
                                            <th>Node IP</th>
                                            <th>Cloud Providers</th>
                                            <th>Storage</th>
                                            <th>C.P.U</th>
                                            <th>Ram</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.state.nodelist.map((data, index) => (
                                            <tr style={{ borderBottom: "1px solid #c0c0c0" }} key={index}>
                                                <td className="tabletd heading-css">{data.data.region !== "" ? data.data.region : data.data.city}</td>
                                                <td className="tabletd">{data.data.nodeIP ? data.data.nodeIP : "-"}</td>
                                                <td className="tabletd">{data.data.cloudProvider ? data.data.cloudProvider : "-"}</td>
                                                <td className="tabletd">{data.data.storage ? data.data.storage : "-"}</td>
                                                <td className="tabletd">{data.data.cpu ? data.data.cpu : "-"}</td>
                                                <td className="tabletd">{data.data.ram ? data.data.ram : "-"}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>
                            </div>
                            : null}
                    </div>}
            </div>
        )
    }
}

export default Maps;


