import axios from "axios";

export const endUserDetails = async (appID, networkID, endUserID) => {
  try {
    return (
      await axios({
        url:
          process.env.REACT_APP_MOIBIT_API_URL +
          "/net/" +
          networkID +
          "/app/" +
          appID +
          "/enduser",
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const updateEndUserDetails = async (appID, networkID, endUserID) => {
  try {
    let data = JSON.stringify({ isActive: false });
    return (
      await axios({
        url:
          process.env.REACT_APP_MOIBIT_API_URL +
          "/net/" +
          networkID +
          "/app/" +
          appID +
          "/enduser/" +
          endUserID,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },

        data: data,
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const removeEnduser = async (endUserID, appID, networkID) => {
  try {
    return (
      await axios({
        url:
          process.env.REACT_APP_MOIBIT_API_URL +
          "/net/" +
          networkID +
          "/app/" +
          appID +
          "/enduser/" +
          endUserID,
        method: "DELETE",
        headers: {
          Authorization: "***",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          remove: true,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const restoreEnduser = async (endUserID, appID, networkID) => {
  try {
    return (
      await axios({
        url:
          process.env.REACT_APP_MOIBIT_API_URL +
          "/net/" +
          networkID +
          "/app/" +
          appID +
          "/enduser/" +
          endUserID,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          remove: false,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};
