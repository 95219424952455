import React, { Component } from "react";
import {
  Card,
  Table,
  Dropdown,
  DropdownButton,
  Modal,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { getInvoice } from "../../common/paymentapi";
import { toastify, priceModalOpen } from "../../action";
import "./style.css";

const style = {
  paper: {
    padding: "10px",
    margin: "5px",
    marginBottom: "0px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "10px",
  },
};

const userLogs = [];

class ProvenanceLog extends Component {
  state = {
    userLogs: userLogs,
    effectiveLoading: 0.2,
    inDetailsRows: [],
    fromTs: "",
    toTs: "",
    selectedOperationType: -1,
    loader: false,
  };

  async componentDidMount() {
    if (this.props.plan == -1) {
      this.props.priceModalOpen();
      return;
    }
    let _today = new Date();
    let currentMonth = _today.getMonth() + 1;
    currentMonth = currentMonth < 10 ? "0" + currentMonth : currentMonth;
    const currentToDate =
      _today.getFullYear() +
      "-" +
      currentMonth +
      "-" +
      (_today.getDate() < 10 ? "0" + _today.getDate() : _today.getDate());

    let before2Days = _today.getTime() - 172800000;

    before2Days = new Date(before2Days);
    let before2DaysMonth = before2Days.getMonth() + 1;
    before2DaysMonth =
      before2DaysMonth < 10 ? "0" + before2DaysMonth : before2DaysMonth;

    const currentFromDate =
      before2Days.getFullYear() +
      "-" +
      before2DaysMonth +
      "-" +
      (before2Days.getDate() < 10
        ? "0" + before2Days.getDate()
        : before2Days.getDate());
    this.setState({ fromTs: currentFromDate, toTs: currentToDate });
    setTimeout(() => {
      this.getUserLogs();
    }, 500);
  }

  determineActionType = (actionType) => {
    let targetActionType;
    switch (actionType) {
      case 0:
        targetActionType = "Add Node";
        break;
      case 1:
        targetActionType = "Create Network";
        break;
      case 2:
        targetActionType = "Update Network";
        break;
      case 3:
        targetActionType = "Write/Update File";
        break;
      case 4:
        targetActionType = "Delete File";
        break;
      case 5:
        targetActionType = "Disable File";
        break;
      case 6:
        targetActionType = "Enable File";
        break;
      case 7:
        targetActionType = "Restore File";
        break;
      case 8:
        targetActionType = "Create App";
        break;
      case 9:
        targetActionType = "Update App";
        break;
      case 10:
        targetActionType = "Delete App";
        break;
      case 11:
        targetActionType = "Restore App";
        break;
      case 12:
        targetActionType = "Add End User";
        break;
      case 13:
        targetActionType = "Update End User";
        break;
      case 14:
        targetActionType = "Remove End User";
        break;
      case 15:
        targetActionType = "Restore End User";
        break;
      case 16:
        targetActionType = "Payment Related";
        break;
      case 17:
        targetActionType = "Update Pricing Plan";
        break;

      case 18:
        targetActionType = "Subscription Created";
        break;
      case 19:
        targetActionType = "Subscription Updated";
        break;
      case 20:
        targetActionType = "Subscription Cancelled";
        break;
      case 21:
        targetActionType = "Invoice Paid";
        break;
      case 22:
        targetActionType = "Free Trial";
        break;
      case 23:
        targetActionType = "Create Folder";
        break;
      case 24:
        targetActionType = "Delete Folder";
        break;
      case 25:
        targetActionType = "Restore Folder";
        break;
      case 26:
        targetActionType = "Upload Folder";
        break;
    }
    return targetActionType;
  };

  getUserLogs = async () => {
    this.setState({ effectiveLoading: 0.2 });
    const getLogsURL = process.env.REACT_APP_MOIBIT_API_URL + "getprovenance";

    let requestedFromDate = new Date(this.state.fromTs);
    let requestedToDate = new Date(this.state.toTs);

    requestedFromDate.setHours(0, 0, 0, 0);
    requestedToDate.setHours(23, 59, 59, 999);

    try {
      let currentUserLogs = await axios({
        url: getLogsURL,
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: {
          developerKey: JSON.parse(sessionStorage.getItem("defAddress")).value,
          operationType:
            this.state.selectedOperationType == "-1"
              ? undefined
              : parseInt(this.state.selectedOperationType),
          from: parseInt(requestedFromDate.getTime() / 1000),
          to: parseInt(requestedToDate.getTime() / 1000),
        },
      });
      let falseArr = new Array(currentUserLogs.data.data.logs.length).fill(
        false
      );
      this.setState({
        userLogs: currentUserLogs.data.data.logs,
        inDetailsRows: falseArr,
        effectiveLoading: 1,
      });
    } catch (e) {
      this.setState({ userLogs: [], inDetailsRows: [], effectiveLoading: 1 });
    }
  };

  formatTime = (ts) => {
    var d = new Date(); // Gets the current time
    var nowTs = Math.floor(d.getTime() / 1000); // getTime() returns milliseconds, and we need seconds, hence the Math.floor and division by 1000
    var seconds = nowTs - ts;

    // more that two days
    if (seconds > 2 * 24 * 3600) {
      return "a few days ago";
    } else if (seconds > 24 * 3600) {
      return "yesterday";
    } else if (seconds > 3600) {
      return "a few hours ago";
    } else if (seconds > 1800) {
      return "Half an hour ago";
    } else if (seconds > 60) {
      return Math.floor(seconds / 60) + " minutes ago";
    } else {
      return "a while ago";
    }
  };

  determineLogCosmetics = (interactionAction) => {
    let targetedLC;
    switch (interactionAction) {
      case 0:
        targetedLC = {
          bgColor: "#294b6b",
          className: "fas fa-laptop",
        };
        break;
      case 1:
        targetedLC = {
          bgColor: "#8c29c2",
          className: "fas fa-network-wired",
        };
        break;
      case 2:
        targetedLC = {
          bgColor: "#2d31a6",
          className: "fa fa-sitemap",
        };
        break;
      case 3:
        targetedLC = {
          bgColor: "#3034da",
          className: "fa fa-file",
        };
        break;
      case 4:
        targetedLC = {
          bgColor: "red",
          className: "fa fa-cut",
        };
        break;
      case 5:
        targetedLC = {
          bgColor: "#bbb9bd",
          className: "fas fa-pause-circle",
        };
        break;
      case 6:
        targetedLC = {
          bgColor: "#c7323e",
          className: "fas fa-play-circle",
        };
        break;
      case 7:
        targetedLC = {
          bgColor: "#00807b",
          className: "fas fa-trash-restore",
        };
        break;
      case 8:
        targetedLC = {
          bgColor: "blue",
          className: "fa fa-map",
        };
        break;
      case 9:
        targetedLC = {
          bgColor: "orange",
          className: "fa fa-map",
        };
        break;
      case 10:
        targetedLC = {
          bgColor: "black",
          className: "fa fa-trash",
        };
        break;
      case 11:
        targetedLC = {
          bgColor: "orange",
          className: "fas fa-trash-restore",
        };
        break;
      case 12:
        targetedLC = {
          bgColor: "#582bfc",
          className: "fas fa-user-plus",
        };
        break;
      case 13:
        targetedLC = {
          bgColor: "#5ca0ed",
          className: "fas fa-user-edit",
        };
        break;
      case 14:
        targetedLC = {
          bgColor: "#b86663",
          className: "fas fa-user-minus",
        };
        break;

      case 15:
        targetedLC = {
          bgColor: "#b86663",
          className: "fas fa-user-check",
        };
        break;
      case 16:
        targetedLC = {
          bgColor: "#008744",
          className: "fa fa-money",
        };
        break;
      case 17:
        targetedLC = {
          bgColor: "#008744",
          className: "fas fa-dollar-sign",
        };
        break;

      case 18:
        targetedLC = {
          bgColor: "#8021BF",
          className: "fas fa-dollar-sign",
        };
        break;
      case 19:
        targetedLC = {
          bgColor: "#8021BF",
          className: "fas fa-dollar-sign",
        };
        break;
      case 20:
        targetedLC = {
          bgColor: "#8021BF",
          className: "fas fa-dollar-sign",
        };
        break;
      case 21:
        targetedLC = {
          bgColor: "#8021BF",
          className: "fas fa-dollar-sign",
        };
        break;
      case 22:
        targetedLC = {
          bgColor: "#8021BF",
          className: "fas fa-dollar-sign",
        };
        break;
      case 23:
        targetedLC = {
          bgColor: "black",
          className: "fa fa-folder",
        };
        break;
      case 24:
        targetedLC = {
          bgColor: "red",
          className: "fa fa-folder",
        };
        break;
      case 25:
        targetedLC = {
          bgColor: "#00807b",
          className: "fa fa-folder",
        };
        break;
      case 26:
        targetedLC = {
          bgColor: "#00807b",
          className: "fa fa-folder",
        };
        break;
      default:
        targetedLC = {
          bgColor: "#3034da",
          className: "fa fa-file",
        };
        break;
    }
    return targetedLC;
  };

  showRHideInDetails = (inIndex) => {
    let toBeUpdatedInDetailsRows = this.state.inDetailsRows;
    toBeUpdatedInDetailsRows[inIndex] = !toBeUpdatedInDetailsRows[inIndex];
    this.setState({ inDetailsRows: toBeUpdatedInDetailsRows });
  };

  triggerOperationTypeFilter = (otKey) => {
    this.setState({ currentOTFvalue: otKey });
  };

  getTitleFromOT = () => {
    let targetedOTTitle;
    switch (parseInt(this.state.selectedOperationType)) {
      case -1:
        targetedOTTitle = "All Operations";
        break;
      case 0:
        targetedOTTitle = "Add Node";
        break;
      case 1:
        targetedOTTitle = "Create Network";
        break;
      case 2:
        targetedOTTitle = "Update Network";
        break;
      case 3:
        targetedOTTitle = "Write/Update File";
        break;
      case 4:
        targetedOTTitle = "Delete File";
        break;
      case 5:
        targetedOTTitle = "Disable File";
        break;
      case 6:
        targetedOTTitle = "Enable File";
        break;
      case 7:
        targetedOTTitle = "Restore File";
        break;
      case 8:
        targetedOTTitle = "Create App";
        break;
      case 9:
        targetedOTTitle = "Update App";
        break;
      case 10:
        targetedOTTitle = "Delete App";
        break;
      case 11:
        targetedOTTitle = "Restore App";
        break;
      case 12:
        targetedOTTitle = "Add End User";
        break;
      case 13:
        targetedOTTitle = "Update End User";
        break;
      case 14:
        targetedOTTitle = "Remove End User";
        break;
      case 15:
        targetedOTTitle = "Restore End User";
        break;
      case 16:
        targetedOTTitle = "Payment Related";
        break;

      case 18:
        targetedOTTitle = "Subscription Created";
        break;
      case 19:
        targetedOTTitle = "Subscription Updated";
        break;
      case 20:
        targetedOTTitle = "Subscription Cancelled";
        break;
      case 21:
        targetedOTTitle = "Invoice Paid";
        break;
      case 22:
        targetedOTTitle = "Free Trial";
        break;
      case 23:
        targetedOTTitle = "Create Folder";
        break;
      case 24:
        targetedOTTitle = "Delete Folder";
        break;
      case 25:
        targetedOTTitle = "Recover Folder";
        break;
      case 26:
        targetedOTTitle = "Upload Folder";
        break;
    }
    return targetedOTTitle;
  };

  getPaymentIndentDetails = async (entity) => {
    try {
      this.setState({ loader: true });
      let _splitEntity = entity.split("_");
      let _mergeEntity = _splitEntity[0] + "_" + _splitEntity[1];
      let _res = await getInvoice(_mergeEntity);
      if (_res.code == 200) {
        let result = _res.data;
        let _receiptURL = result.hosted_invoice_url;
        window.open(_receiptURL, "_blank");
      } else {
        this.props.toastifyEnable("err", _res.data);
      }
      this.setState({ loader: false });
    } catch (err) {
      this.props.toastifyEnable("err", err.message);
      this.setState({ loader: false });
    }
  };

  render() {
    return (
      <main style={{ width: "100%" }}>
        <div style={style.toolbar} />
        <div style={{ margin: "30px", marginTop: "0px" }}>
          <Modal
            show={this.state.loader}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Body>
              <div style={{ margin: "20px", textAlign: "center" }}>
                <Spinner animation="border" />
              </div>
            </Modal.Body>
          </Modal>
          <div className="row">
            <div className="col-md-6">
              <div
                style={{ fontSize: "18px", fontWeight: "600" }}
                className="heading-css"
              >
                Proof of Storage Explorer
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div style={{ width: "15%", marginLeft: "5px" }}>
                  <label for="from_date" className="fontweight600">
                    From
                  </label>
                  <input
                    style={{
                      height: "32px",
                      fontSize: "12px",
                      fontFamily: "sans-serif",
                    }}
                    id="from_date"
                    type="date"
                    defaultValue={this.state.fromTs}
                    max={this.state.toTs}
                    onChange={(e) => {
                      this.setState({ fromTs: e.target.value });
                      setTimeout(() => {
                        this.getUserLogs();
                      }, 500);
                    }}
                    value={this.state.fromTs}
                  />
                </div>
                <div style={{ width: "15%", marginLeft: "5px" }}>
                  <label for="to_date" className="fontweight600">
                    To
                  </label>
                  <input
                    style={{
                      height: "32px",
                      fontSize: "12px",
                      fontFamily: "sans-serif",
                    }}
                    id="to_date"
                    type="date"
                    defaultValue={this.state.toTs}
                    min={this.state.fromTs}
                    onChange={(e) => {
                      this.setState({ toTs: e.target.value });
                      setTimeout(() => {
                        this.getUserLogs();
                      }, 500);
                    }}
                    value={this.state.toTs}
                  />
                </div>
                <div style={{ marginLeft: "5px" }}>
                  <label for="to_date" className="fontweight600">
                    Operation Type
                  </label>
                  <OperationTypeFilter
                    triggerOTFilter={(otKey) => {
                      this.setState({ selectedOperationType: otKey });
                      setTimeout(() => {
                        this.getUserLogs();
                      }, 500);
                    }}
                    otTitle={this.getTitleFromOT()}
                    activeOTvalue={this.state.selectedOperationType}
                  />
                </div>
              </div>
            </div>
          </div>

          <Card style={{ backgroundColor: "#faf9f8", border: "none" }}>
            <Card.Body style={{ overflowY: "scroll" }}>
              <Table
                className="no-wrap  v-middle"
                responsive
                style={{ opacity: this.state.effectiveLoading }}
              >
                <tbody>
                  {this.state.userLogs.length > 0 ? (
                    <tr
                      style={{
                        color: "#000",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      <td
                        style={{
                          borderBottom: "2px solid #5f97ce",
                          borderTop: "none",
                        }}
                      >
                        {" "}
                        {this.state.userLogs.length} logs
                      </td>
                      <td
                        style={{
                          borderBottom: "2px solid #5f97ce",
                          borderTop: "none",
                          width: "200px",
                        }}
                      >
                        Entity
                      </td>

                      <td
                        style={{
                          borderBottom: "2px solid #5f97ce",
                          borderTop: "none",
                        }}
                      >
                        Type of Operation
                      </td>

                      <td
                        style={{
                          borderBottom: "2px solid #5f97ce",
                          borderTop: "none",
                        }}
                      >
                        Time
                      </td>
                      <td
                        style={{
                          borderBottom: "2px solid #5f97ce",
                          borderTop: "none",
                        }}
                      />
                    </tr>
                  ) : null}

                  {this.state.userLogs.length > 0 ? (
                    this.state.userLogs
                      .slice(0)
                      .reverse()
                      .map((data, index) => {
                        const logTypeCosmetics = this.determineLogCosmetics(
                          data.operationType
                        );
                        return (
                          <>
                            <tr key={index}>
                              <td
                                style={{
                                  borderBottom: "1px solid #dee2e6",
                                  borderTop: "none",
                                }}
                              >
                                <div className="d-flex no-block align-items-center">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: logTypeCosmetics.bgColor,
                                        width: "35px",
                                        height: "35px",
                                        borderRadius: "50px",
                                        textAlign: "center",
                                        fontSize: "22px",
                                        paddingTop: "0px",
                                        fontWeight: "normal",
                                        marginRight: "20px",
                                      }}
                                    >
                                      <i
                                        className={logTypeCosmetics.className}
                                        style={{ fontSize: "24px" }}
                                      ></i>
                                    </div>
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        marginTop: "5px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {" "}
                                      {data.namespace}
                                    </span>
                                  </div>
                                </div>
                              </td>

                              <td
                                style={{
                                  borderBottom: "1px solid #dee2e6",
                                  width: "40%",
                                  borderTop: "none",
                                }}
                                onClick={
                                  data.operationType == 16 ||
                                  data.operationType == 21
                                    ? () =>
                                        this.getPaymentIndentDetails(
                                          data.entity
                                        )
                                    : () => {}
                                }
                              >
                                <span
                                  style={{
                                    marginTop: "5px",
                                    fontSize: "13px",
                                    color:
                                      data.operationType == 16
                                        ? "#8021BF"
                                        : "#000",
                                    textDecoration:
                                      data.operationType == 16 ||
                                      data.operationType == 21
                                        ? "underline"
                                        : "none",
                                    cursor:
                                      data.operationType == 16 ||
                                      data.operationType == 21
                                        ? "pointer"
                                        : "normal",
                                  }}
                                >
                                  {" "}
                                  {data.operationType == 16 ||
                                  data.operationType == 21
                                    ? "Stripe: " + data.entity
                                    : data.entity}
                                </span>
                              </td>

                              <td
                                style={{
                                  borderBottom: "1px solid #dee2e6",
                                  borderTop: "none",
                                }}
                              >
                                <span
                                  style={{ marginTop: "5px", fontSize: "13px" }}
                                >
                                  {" "}
                                  {this.determineActionType(data.operationType)}
                                </span>
                              </td>

                              <td
                                style={{
                                  borderBottom: "1px solid #dee2e6",
                                  borderTop: "none",
                                }}
                              >
                                <span
                                  style={{ marginTop: "5px", fontSize: "13px" }}
                                >
                                  {" "}
                                  {/* {this.formatTime(data.atTime)} */}
                                  {"" + new Date(data.atTime * 1000)}
                                </span>
                              </td>
                              <td
                                style={{
                                  borderBottom: "1px solid #dee2e6",
                                  borderTop: "none",
                                }}
                              >
                                <span
                                  className="heading-css"
                                  style={{
                                    marginTop: "5px",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    fontSize: "13px",
                                    fontWeight: "600",
                                  }}
                                  onClick={() => this.showRHideInDetails(index)}
                                >
                                  {!this.state.inDetailsRows[index]
                                    ? "View details"
                                    : "Close"}
                                </span>
                              </td>
                            </tr>
                            {this.state.inDetailsRows[index] ? (
                              <tr>
                                <td
                                  colSpan={5}
                                  style={{
                                    fontSize: "12px",
                                    backgroundColor: "#f5f5f5",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        margin: "5px 0px",
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%" }}
                                        className="greenclr"
                                      >
                                        <b>Interaction ID</b>
                                      </div>
                                      <div>{data.TxnID}</div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        margin: "5px 0px",
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%" }}
                                        className="greenclr"
                                      >
                                        <b>Tesseract Number</b>
                                      </div>
                                      <div>{data.blockNumber}</div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        margin: "5px 0px",
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%" }}
                                        className="greenclr"
                                      >
                                        <b>Tesseract Hash</b>
                                      </div>
                                      <div>{data.blockHash}</div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        margin: "5px 0px",
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%" }}
                                        className="greenclr"
                                      >
                                        <b>Verified By</b>
                                      </div>
                                      <div>{data.verifiedBy}</div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        margin: "5px 0px",
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%" }}
                                        className="greenclr"
                                      >
                                        <b>Date</b>
                                      </div>
                                      <div>
                                        {"" + new Date(data.atTime * 1000)}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ) : null}
                          </>
                        );
                      })
                  ) : (
                    <p style={{ fontSize: "14px", marginTop: "10px" }}>
                      {" "}
                      {this.state.effectiveLoading == 1
                        ? "No records found"
                        : "Loading..."}{" "}
                    </p>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    plan: store.price.priceState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    priceModalOpen: () => dispatch(priceModalOpen()),
    toastifyEnable: (type, val) => dispatch(toastify(type, val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProvenanceLog);

function OperationTypeFilter(props) {
  return (
    <div style={{ marginTop: "3px" }}>
      <DropdownButton
        size="sm"
        title={props.otTitle}
        onSelect={(eventKey, _) => props.triggerOTFilter(eventKey)}
        className="dropdown-css"
      >
        <Dropdown.Item eventKey={-1} active={props.activeOTvalue == -1}>
          All Operations
        </Dropdown.Item>
        <Dropdown.Item eventKey={0} active={props.activeOTvalue == 0}>
          Add Node
        </Dropdown.Item>
        <Dropdown.Item eventKey={1} active={props.activeOTvalue == 1}>
          Create Network
        </Dropdown.Item>
        <Dropdown.Item eventKey={2} active={props.activeOTvalue == 2}>
          Update Network
        </Dropdown.Item>
        <Dropdown.Item eventKey={3} active={props.activeOTvalue == 3}>
          Write/Update File
        </Dropdown.Item>
        <Dropdown.Item eventKey={4} active={props.activeOTvalue == 4}>
          Delete File
        </Dropdown.Item>
        <Dropdown.Item eventKey={5} active={props.activeOTvalue == 5}>
          Disable File
        </Dropdown.Item>
        <Dropdown.Item eventKey={6} active={props.activeOTvalue == 6}>
          Enable File
        </Dropdown.Item>
        <Dropdown.Item eventKey={7} active={props.activeOTvalue == 7}>
          Restore File
        </Dropdown.Item>
        <Dropdown.Item eventKey={8} active={props.activeOTvalue == 8}>
          Create App
        </Dropdown.Item>
        <Dropdown.Item eventKey={9} active={props.activeOTvalue == 9}>
          Update App
        </Dropdown.Item>
        <Dropdown.Item eventKey={10} active={props.activeOTvalue == 10}>
          Delete App
        </Dropdown.Item>
        <Dropdown.Item eventKey={11} active={props.activeOTvalue == 11}>
          Restore App
        </Dropdown.Item>
        <Dropdown.Item eventKey={12} active={props.activeOTvalue == 12}>
          Add End User
        </Dropdown.Item>
        <Dropdown.Item eventKey={13} active={props.activeOTvalue == 13}>
          Update End User
        </Dropdown.Item>
        <Dropdown.Item eventKey={14} active={props.activeOTvalue == 14}>
          Remove End User
        </Dropdown.Item>
        <Dropdown.Item eventKey={15} active={props.activeOTvalue == 15}>
          Restore End User
        </Dropdown.Item>
        <Dropdown.Item eventKey={16} active={props.activeOTvalue == 16}>
          Payment Related
        </Dropdown.Item>
        {/* new changes */}

        <Dropdown.Item eventKey={18} active={props.activeOTvalue == 18}>
          Subscription Created
        </Dropdown.Item>
        <Dropdown.Item eventKey={19} active={props.activeOTvalue == 19}>
          Subscription Updated
        </Dropdown.Item>
        <Dropdown.Item eventKey={20} active={props.activeOTvalue == 20}>
          Subscription Cancelled
        </Dropdown.Item>
        <Dropdown.Item eventKey={21} active={props.activeOTvalue == 21}>
          Invoice Paid
        </Dropdown.Item>
        <Dropdown.Item eventKey={22} active={props.activeOTvalue == 22}>
          Free Trial
        </Dropdown.Item>
        <Dropdown.Item eventKey={23} active={props.activeOTvalue == 23}>
          Create Folder
        </Dropdown.Item>
        <Dropdown.Item eventKey={24} active={props.activeOTvalue == 24}>
          Delete Folder
        </Dropdown.Item>
        <Dropdown.Item eventKey={25} active={props.activeOTvalue == 25}>
          Restore Folder
        </Dropdown.Item>
        <Dropdown.Item eventKey={26} active={props.activeOTvalue == 26}>
          Upload Folder
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
}
