import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class createFileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.createFileNames !== this.props.createFileNames) {
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <Modal
        show={this.props.createFileModal}
        size={this.props.createFileModalType == 2 ? "" : "lg"}
        centered
        onHide={this.props.handleModalClose}
        backdrop="static"
      >
        <Modal.Header closeButton={this.state.loading ? false : true}>
          <Modal.Title style={{ fontSize: "14px", fontWeight: "600" }}>
            {this.props.createFileModalType == 1
              ? "Create new file"
              : this.props.createFileModalType == 2
              ? "New folder"
              : null}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.createFileModalType == 1 ? (
            <div>
              <div className="input-group" style={{ marginBottom: "20px" }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="File name"
                  onChange={(e) => this.props.createFileName(e)}
                  style={{
                    border:
                      this.props.createFileNames == ""
                        ? "1px solid red"
                        : "1px solid #ccc",
                  }}
                />
                <div
                  className="input-group-btn"
                  style={{ background: "#8021BF" }}
                >
                  <i
                    className="fa fa-file-text btn btn-default"
                    style={{ color: "#fff" }}
                  ></i>
                </div>
              </div>
              <div style={{ padding: "30px" }}>
                <Editor
                  // toolbarHidden
                  placeholder="Write a note..."
                  editorState={this.props.editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={this.props.onEditorStateChange}
                  toolbar={{
                    options: ["inline", "fontSize", "fontFamily", "textAlign"],
                  }}
                />
              </div>
              {this.props.fileExistsMsg1 ? (
                <div>
                  <p
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      padding: "25px",
                      color: "#8021BF",
                    }}
                  >
                    File already exists. Do you want to replace the file or keep
                    both versions?
                  </p>
                </div>
              ) : this.props.fileExistsMsgInRecovery ? (
                <div>
                  <p
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      padding: "10px",
                      color: "#8021BF",
                    }}
                  >
                    File already exists in Recovery State. Do you want to update
                    the file with newer version?
                  </p>
                </div>
              ) : null}
            </div>
          ) : this.props.createFileModalType == 2 ? (
            <div className="input-group" style={{ marginBottom: "20px" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Folder name"
                onChange={(e) => this.props.createFileName(e)}
                style={{
                  border:
                    this.props.createFileNames == ""
                      ? "1px solid red"
                      : "1px solid #ccc",
                }}
              />
              <div
                className="input-group-btn"
                style={{ background: "#8021BF" }}
              >
                <i
                  className="fa fa-folder btn btn-default"
                  style={{ color: "#fff" }}
                ></i>
              </div>
            </div>
          ) : null}
        </Modal.Body>

        <Modal.Footer>
          {this.props.createFileModalType == 1 ? (
            <div style={{ display: "flex" }}>
              <Button
                variant="secondary"
                className="button-css"
                size="sm"
                style={{ fontWeight: "600" }}
                onClick={this.props.handleModalClose}
              >
                Close
              </Button>{" "}
              &nbsp;&nbsp;
              {this.props.fileExistsMsg1 ? (
                <div>
                  <Button
                    variant="info"
                    className="heading-css-bg"
                    size="sm"
                    style={{ fontWeight: "600" }}
                    onClick={() => this.props.submitEditor(3)}
                    disabled={this.props.uploadFileSpinner}
                  >
                    Replace
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="primary"
                    className="heading-css-bg"
                    size="sm"
                    style={{ fontWeight: "600" }}
                    onClick={() => this.props.submitEditor(4)}
                    disabled={this.props.uploadFileSpinner}
                  >
                    Keep both
                  </Button>
                </div>
              ) : this.props.fileExistsMsgInRecovery ? (
                <div>
                  <Button
                    variant="info"
                    className="heading-css-bg"
                    size="sm"
                    style={{ fontWeight: "600" }}
                    onClick={() => this.props.submitEditor(3)}
                    disabled={this.props.uploadFileSpinner}
                  >
                    Update
                  </Button>
                </div>
              ) : (
                <Button
                  variant="primary"
                  className="heading-css-bg"
                  size="sm"
                  style={{ fontWeight: "600" }}
                  disabled={this.state.loading}
                  onClick={() => {
                    if (this.props.createFileNames !== "") {
                      this.setState({ loading: true });
                    }

                    this.props.submitEditor(1);
                  }}
                >
                  {this.state.loading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : null}{" "}
                  Submit
                </Button>
              )}
            </div>
          ) : (
            <div>
              <Button
                variant="secondary button-css"
                size="sm"
                style={{ fontWeight: "600" }}
                disabled={this.state.loading}
                onClick={this.props.handleModalClose}
              >
                Close
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="primary heading-css-bg"
                size="sm"
                style={{ fontWeight: "600" }}
                disabled={this.state.loading}
                createFileNames
                onClick={async () => {
                  if (this.props.createFileNames !== "") {
                    this.setState({ loading: true });
                    this.props.submitEditor(2);
                  }
                }}
              >
                {this.state.loading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : null}
                Submit
              </Button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default createFileModal;
