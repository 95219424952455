import React from "react";
import { Modal, Table } from "react-bootstrap";


class DisplayNodeDetails extends React.Component {
    render() {
        return (
            <div>
                <Modal show={this.props.displayNodeDetails}
                    centered
                    size="lg"
                    onHide={this.props.closeNodeDetails}
                >
                    <Modal.Header closeButton>
                        <Modal.Title style={{ fontWeight: "600" }}>{this.props.data.nodeName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Table striped bordered hover className="tablecss" >


                            <tbody>
                                <tr>
                                    <th>Cloud Provider</th>
                                    <td>{this.props.data.cloudProvider ? this.props.data.cloudProvider : "-"}</td>
                                </tr>
                                <tr>
                                    <th>CPU</th>
                                    <td>{this.props.data.cpu ? this.props.data.cpu : "-"}</td>
                                </tr>
                                <tr>
                                    <th>RAM</th>
                                    <td>{this.props.data.ram ? this.props.data.ram : "-"}</td>
                                </tr>
                                <tr>
                                    <th>Storage</th>
                                    <td>{this.props.data.storage ? this.props.data.storage : "-"}</td>
                                </tr>

                                <tr>
                                    <th>Region</th>
                                    <td>{this.props.data.region ? this.props.data.region : "-"}</td>
                                </tr>

                                <tr>
                                    <th>City</th>
                                    <td>{this.props.data.city ? this.props.data.city : "-"}</td>
                                </tr>
                                <tr>
                                    <th>Country</th>
                                    <td>{this.props.data.country ? this.props.data.country : "-"}</td>
                                </tr>
                                <tr>
                                    <th>Timezone</th>
                                    <td>{this.props.data.timeZone ? this.props.data.timeZone : "-"}</td>
                                </tr>


                            </tbody>
                        </Table>
                    </Modal.Body>

                </Modal>
            </div >)
    }
}

export default DisplayNodeDetails;