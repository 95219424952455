import React from "react";
import { Modal, Button, Table } from "react-bootstrap";



class Invoice extends React.Component {
    render() {
        return (
            <div>
                <Modal show={this.props.show} onHide={this.props.invoiceModalClose} centered size="lg">
                    <Modal.Header closeButton>
                        <div>
                            <Modal.Title className="cardheader">Invoice ID- <span style={{ color: "blue" }}>{this.props.invoiceData.invoiceID}</span></Modal.Title><br />
                            <Modal.Title className="cardheader">Invoice Number - <span style={{ color: "blue" }}>{this.props.invoiceData.invoiceNumber}</span></Modal.Title>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {this.props.invoiceData.itemList.length > 0 ? <Table style={{ fontSize: "14px", marginBottom: "0px" }} responsive>
                                <thead>
                                    <tr>
                                        <th style={{ border: "none" }}>Description</th>
                                        <th style={{ border: "none" }}>Qty</th>
                                        <th style={{ border: "none" }}>Unit price</th>
                                        <th style={{ border: "none" }}>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.invoiceData.itemList.map((data, index) => (
                                        <tr key={index}>
                                            <td>{data.description}</td>
                                            <td>{data.quantity}</td>
                                            <td>${data.amount}</td>
                                            <td>${data.amount}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <th>Total</th>
                                        <th>${(this.props.invoiceData.amountDue / 100)}</th>
                                    </tr>
                                </tbody>
                            </Table> : null}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <a href={this.props.invoiceData.invoicePDF} target="_blank"><Button variant="info" className="paymentbtn paynow" size="sm" onClick={this.props.invoiceModalClose}>
                            Download Invoice
                       </Button></a>
                    </Modal.Footer>
                </Modal >
            </div >
        );
    }
}

export default Invoice;