export const customDateFormat = (dateVal) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var newDate = new Date(dateVal);
  var currentMonth = monthNames[newDate.getMonth()];
  var currentDate = newDate.getDate();
  var currentYear = newDate.getFullYear();
  var returnDateFormat = "";

  //   let todayDate = new Date();
  // if (todayDate.getDate() == currentDate && monthNames[todayDate.getMonth()] == currentMonth && todayDate.getFullYear() == currentYear) {
  //     returnDateFormat = "Today";
  // }
  // else if ((todayDate.getDate() - 1) == currentDate && monthNames[todayDate.getMonth()] == currentMonth && todayDate.getFullYear() == currentYear) {
  //     returnDateFormat = "Yesterday";
  // }
  // else {
  //     returnDateFormat = currentDate + "-" + currentMonth + "-" + currentYear;
  // }

  var strTime = new Date(dateVal).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  var timeZone = new Date(dateVal).toTimeString().match(/\(([^)]+)\)/)[1];
  returnDateFormat =
    currentDate +
    "-" +
    currentMonth +
    "-" +
    currentYear +
    " at " +
    strTime +
    " (" +
    timeZone +
    ")";
  return returnDateFormat;
};

export const bytesToSize = (num) => {
  var neg = num < 0;
  var units = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  if (neg) {
    num = -num;
  }
  if (num < 1) {
    return (neg ? "-" : "") + num + " Bytes";
  }
  var exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1000)),
    units.length - 1
  );
  num = Number((num / Math.pow(1000, exponent)).toFixed(2));
  var unit = units[exponent];
  return (neg ? "-" : "") + num + " " + unit;
};

export const timeDiffCalc = (dateFuture) => {
  let dateNow = Date.now();
  let diffInMilliSeconds = Math.abs(dateFuture * 1000 - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  if (days > 0) {
    difference += days === 1 ? `${days} day, ` : `${days} days, `;
  }
  difference +=
    hours === 0 || hours === 1 ? `${hours} hour, ` : `${hours} hours, `;
  difference +=
    minutes === 0 || hours === 1 ? `${minutes} minutes` : `${minutes} minutes`;
  return difference;
};

export const kFormatter = (num) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
};
