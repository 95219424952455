import React from "react";

let data = [
  {
    name: "API Documentation",
    icon: "files-o",
    link: "https://apidocs.moinet.io/docs/intro",
  },
  {
    name: "Tutorial and Sample App",
    icon: "caret-square-o-right",
    link: "https://github.com/moibit/Moibit-Sample-DApp/tree/matic-dapp",
  },
  {
    name: "SDKs",
    icon: "wrench",
    link: "https://www.npmjs.com/search?q=moibitjs",
  },
  // {
  //     name: 'Slack Community',
  //     icon: 'slack',
  //     link: 'https://join.slack.com/t/moibit/shared_invite/enQtODA1MTA0MTE1NjIwLWZjZGJiNzA0NzZjYmIzMDgwNzRlYzY2YWFiNzE5YWVjZGI5Y2ZmZmE4NTg0NWY4YTdhZjQyMTgyNzA3NjVlODI',
  // },
  {
    name: "Terms Of Services",
    icon: "gavel",
    link: process.env.REACT_APP_TERMS_OF_SERVICES,
  },
  {
    name: "Privacy Policy",
    icon: "shield",
    link: process.env.REACT_APP_PRIVACY_POLICY,
  },
];

const Resources = () => (
  <div className="container" style={{ marginTop: "50px" }}>
    <div className="row" style={{ marginLeft: "10px", marginRight: "10px" }}>
      {data.map((value) => (
        <div
          className="col-md-4"
          style={{
            marginBottom: "50px",
          }}
          key={value.name}
        >
          <a href={value.link} target="_blank">
            {" "}
            <div
              className="card-header text-center text-white"
              style={{
                background: "#fff",
                borderRadius: "7px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                cursor: "pointer",
              }}
            >
              <div className="row align-items-center">
                <div className="col-md-12">
                  <h5
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "700",
                      margin: "10px",
                    }}
                  >
                    {value.name}
                  </h5>
                </div>
                <div
                  className="col-md-12 heading-css"
                  style={{ fontSize: "60px" }}
                >
                  {value.icon == "matic" ? (
                    <img src={require("../../assets/matic.png")} height="80" />
                  ) : (
                    <i className={`fa fa-${value.icon}`}></i>
                  )}
                </div>
              </div>
            </div>
          </a>
        </div>
      ))}
    </div>
  </div>
);

export default Resources;
