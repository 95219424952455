import React from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";


class DisplayConfig extends React.Component {

    state = {
        newArrValue: []
    }
   
    render() {
        return (
            this.props.nodeValue.length > 0 ?
                this.props.nodeValue.map((data, index) => (
                    data.value ? <Table striped bordered hover key={index} style={{ fontSize: "12px" }}>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Configuration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Node</td>
                                <td>{data.name.split("_")[0]}</td>

                            </tr>
                            <tr>
                                <td>Hardware</td>
                                <td>{data.data.ram + " RAM, " + data.data.cpu + " CPU, " + data.data.storage + " SSD"}</td>
                            </tr>
                        </tbody>
                    </Table> : null
                )) : <div></div>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        networkNodes: state.network.nodeCheckBox
    }
}

export default connect(mapStateToProps)(DisplayConfig);