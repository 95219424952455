import React, { useEffect, useState } from "react";
import NetworkAppDropdown from "./networkdropdown";
import { Table, Button } from "react-bootstrap";
import { getAppDetails } from "../../common/appapicalls";
import { removeEnduser, restoreEnduser } from "../../common/endusercalls";
import { toastify } from "../../action";
import { useDispatch } from "react-redux";
import { timeDiffCalc } from "../../common/func";
import "./style.css";

function Endusers() {
  const [restoreSpinner, setRestoreSpinner] = useState(false);
  const dispatch = useDispatch();
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [selectedApp, setSelectedApp] = useState("");
  const [selectedNetworkName, setSelectedNetworkName] = useState("");
  const [selectedAppName, setSelectedAppName] = useState("");
  const [enduser, setEndUser] = useState([]);

  async function networkDetail(data) {
    await setSelectedApp("");
    await setSelectedAppName("");
    await setSelectedNetwork(data.id);
    await setSelectedNetworkName(data.name);
  }

  async function appDetail(data) {
    await setSelectedApp(data.id);
    await setSelectedAppName(data.name);
  }

  useEffect(() => {
    fetchEnduserDetails();
  }, [selectedApp, selectedNetwork]);

  async function fetchEnduserDetails() {
    try {
      let _res = await getAppDetails(selectedApp, selectedNetwork);
      if (typeof _res.data !== "string" && _res) {
        setEndUser(_res.endUsers);
      } else {
        setEndUser([]);
      }
    } catch (err) {
      setEndUser([]);
    }
  }

  async function restoreendUser(data) {
    try {
      await setRestoreSpinner(true);
      let res = await restoreEnduser(
        data.enduserID,
        selectedApp,
        selectedNetwork
      );
      if (res.code == 200) {
        dispatch(toastify("info", "EndUser restored successfully"));
        await setRestoreSpinner(false);
        setTimeout(async () => {
          let _res = await getAppDetails(selectedApp, selectedNetwork);
          if (typeof _res.data !== "string" && _res) {
            setEndUser(_res.endUsers);
          } else {
            setEndUser([]);
          }
        }, 3000);
      } else {
        dispatch(toastify("err", res.message));
        await setRestoreSpinner(false);
      }
    } catch (err) {
      dispatch(toastify("err", err.message));
      await setRestoreSpinner(false);
    }
  }

  async function removeendUser(data) {
    try {
      await setRestoreSpinner(true);
      let res = await removeEnduser(
        data.enduserID,
        selectedApp,
        selectedNetwork
      );
      if (res.code == 200) {
        dispatch(toastify("info", "EndUser removed successfully"));
        await setRestoreSpinner(false);
        setTimeout(async () => {
          let _res = await getAppDetails(selectedApp, selectedNetwork);
          if (typeof _res.data !== "string" && _res) {
            setEndUser(_res.endUsers);
          } else {
            setEndUser([]);
          }
        }, 3000);
      } else {
        dispatch(toastify("err", res.message));
        await setRestoreSpinner(false);
      }
    } catch (err) {
      dispatch(toastify("err", err.message));
      await setRestoreSpinner(false);
    }
  }

  return (
    <div>
      <NetworkAppDropdown appDetail={appDetail} networkDetail={networkDetail} />

      <div style={{ marginTop: "20px" }}>
        {!enduser || enduser.length == 0 || enduser == undefined ? (
          <p>No data found</p>
        ) : enduser.length > 0 ? (
          <Table
            striped
            style={{ fontWeight: "600", fontSize: "14px" }}
            className="table-condensed1"
          >
            <thead>
              <tr>
                <th></th>
                <th>Enduser Name</th>
                <th>Enduser ID</th>
                <th>Recovery Time</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {enduser.map((data, index) =>
                data.isRemoved ? (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="tdalign">{`User ${index + 1}`}</td>
                    <td className="tdalign" style={{ color: "blue" }}>
                      {data.enduserID}
                    </td>
                    <td className="tdalign">
                      {timeDiffCalc(data.recoveryTime)}
                    </td>
                    <td>
                      <Button
                        variant="dark button-css"
                        size="sm"
                        style={{ fontWeight: "600", width: "100%" }}
                        onClick={() => restoreendUser(data)}
                      >
                        Restore
                      </Button>
                    </td>
                  </tr>
                ) : (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="tdalign">{`User ${index + 1}`}</td>
                    <td className="tdalign" style={{ color: "blue" }}>
                      {data.enduserID}
                    </td>
                    {/* <td></td> */}
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        style={{ fontWeight: "600", width: "100%" }}
                        onClick={() => removeendUser(data)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        ) : null}
      </div>
    </div>
  );
}

export default Endusers;
