import { TOTAL_STORAGE_CONSUMED } from "../action/types";


const initialState = {
    storage: 0
}

export const storageReducers = (state = initialState, action) => {
    switch (action.type) {
        case TOTAL_STORAGE_CONSUMED:
            return {
                ...state,
                storage: action.value
            }
        default:
            return state;
    }
}
