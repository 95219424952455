import axios from "axios";

export const createNetwork = async (payload) => {
  try {
    return (
      await axios({
        url: process.env.REACT_APP_MOIBIT_API_URL + "network",
        method: "POST",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: payload,
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const updateNetwork = async (payload, networkID) => {
  try {
    return (
      await axios({
        url: process.env.REACT_APP_MOIBIT_API_URL + "network" + "/" + networkID,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        data: payload,
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const getParticularNetworkDetails = async (networkID) => {
  try {
    const tempUrl =
      process.env.REACT_APP_MOIBIT_API_URL + "network" + "/" + networkID;
    return (
      await axios({
        url: tempUrl,
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          operationType: 1,
        },
      })
    ).data.data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const getOwnedNetwork = async () => {
  try {
    const getOwnedNetwork = (
      await axios({
        url: process.env.REACT_APP_MOIBIT_API_URL + "network",
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          operationType: 2,
        },
      })
    ).data;
    return getOwnedNetwork;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const getPartOfNetwork = async () => {
  try {
    const getNetworkDetails = (
      await axios({
        url: process.env.REACT_APP_MOIBIT_API_URL + "network",
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          operationType: 3,
        },
      })
    ).data;
    return getNetworkDetails;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const getPublicNetworkTokens = async () => {
  try {
    const gettokens = (
      await axios({
        url: process.env.REACT_APP_MOIBIT_API_URL + "network",
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          operationType: 4,
        },
      })
    ).data;
    return gettokens;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

export const acceptNetworkinvitation = async (networkID) => {
  try {
    return (
      await axios({
        url: process.env.REACT_APP_MOIBIT_API_URL + "network" + "/" + networkID,
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          operationType: 5,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};

//get all nodes in the network
export const getNetworkNodes = async (networkID) => {
  try {
    return (
      await axios({
        url: process.env.REACT_APP_MOIBIT_API_URL + "network",
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("bearerToken")).value
          }`,
        },
        params: {
          operationType: 6,
        },
      })
    ).data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else {
      return err.message;
    }
  }
};
