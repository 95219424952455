import { GET_ALL_APPS } from "../action/types";


const initialState = {
    allApps: [],
    appsLoading: false,
    appCount: 0
}

export const apps = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_APPS:
            return {
                ...state,
                allApps: action.value,
                appCount: action.count,
                appsLoading: action.loading
            }
        default:
            return state;
    }
}
