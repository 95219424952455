import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class uploadFile extends Component {
  render() {
    return (
      <Modal
        show={this.props.fileUploadModal}
        onHide={this.props.handleModalCloseFile}
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title style={{ fontWeight: "600", fontSize: "14px" }}>
            Upload file
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <img
              src={this.props.uploadFile}
              style={{
                objectFit: "contain",
                height: "100px",
                width: "100px",
              }}
              onError={(e) =>
                (e.target.src = require(`../../../assets/file.png`))
              }
            />
            <div className="input-group" style={{ marginTop: "20px" }}>
              <div className="col-md-4">
                <label style={{ marginTop: "5px" }}>File Name :</label>
              </div>
              <div className="col-md-8 row">
                <div className="form-group">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control col-md-6"
                      placeholder="File name"
                      onChange={(e) => {}}
                      value={this.props.fileNameDisplay}
                      onChange={(e) => this.props.uploadFileNameChange(e)}
                      style={{
                        border: this.props.borderColorForFileInput
                          ? "1px solid red"
                          : "",
                      }}
                    />
                    <input
                      type="text"
                      className="form-control col-md-4"
                      value={this.props.uploadFileExtension}
                      disabled={true}
                      placeholder="Extension"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* replication factor */}

            <div className="input-group">
              <div className="col-md-4">
                <label style={{ marginTop: "5px" }}>Replicas :</label>
              </div>
              <div className="col-md-8 row">
                <div className="form-group">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control col-md-4"
                      placeholder="Replicas"
                      value={this.props.replicationFactor}
                      onChange={(e) => this.props.uploadFileReplica(e)}
                      style={{
                        border: this.props.borderColorForFileInput
                          ? "1px solid red"
                          : "",
                      }}
                      disabled={this.props.plan <= 1}
                    />
                    <input
                      type="text"
                      className="form-control col-md-6"
                      value={"(Default 2)"}
                      disabled={true}
                      placeholder="Default"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* end replication factor */}

            {/* encryption check box */}
            <div className="input-group" style={{ marginBottom: "20px" }}>
              <div
                className="col-md-4 row"
                style={{ justifyContent: "flex-end" }}
              >
                <div className="form-check">
                  <input
                    type="checkbox"
                    checked={this.props.uploadChecked}
                    className="form-check-input"
                    onChange={(e) => this.props.uploadFileCheckbox(e)}
                  />
                  <label className="form-check-label">Encryprt</label>
                </div>
              </div>
            </div>

            {/* end encryption check box */}
          </div>

          {this.props.fileExistsMsg ? (
            <div>
              <p
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  padding: "10px",
                  color: "#8021BF",
                }}
              >
                File already exists. Do you want to replace the file or keep the
                versions?
              </p>
            </div>
          ) : this.props.fileExistsMsgInRecovery ? (
            <div>
              <p
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  padding: "10px",
                  color: "#8021BF",
                }}
              >
                File already exists in Recovery State. Do you want to update the
                file with newer version?
              </p>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary button-css"
            size="sm"
            onClick={this.props.handleModalCloseFile}
            style={{ fontWeight: "600" }}
          >
            Close
          </Button>

          {this.props.fileExistsMsg ? (
            <div>
              <Button
                variant="info heading-css-bg"
                size="sm"
                onClick={this.props.replaceFileUpload}
                disabled={this.props.uploadFileSpinner}
                style={{ fontWeight: "600" }}
              >
                Replace
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="primary heading-css-bg"
                size="sm"
                onClick={() =>
                  this.props.keepBothFileUpload(this.props.fileNameDisplay)
                }
                disabled={this.props.uploadFileSpinner}
                style={{ fontWeight: "600" }}
              >
                Keep both
              </Button>
            </div>
          ) : this.props.fileExistsMsgInRecovery ? (
            <div>
              <Button
                variant="info heading-css-bg"
                size="sm"
                onClick={this.props.replaceFileUpload}
                disabled={this.props.uploadFileSpinner}
                style={{ fontWeight: "600" }}
              >
                Update
              </Button>
            </div>
          ) : (
            <Button
              variant="primary heading-css-bg"
              size="sm"
              onClick={() => this.props.checkFileExists(false)}
              style={{ fontWeight: "600" }}
            >
              {this.props.uploadFileSpinner ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : null}
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default uploadFile;
