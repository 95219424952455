import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

class CancelSubscription extends React.Component {
    render() {
        return (
            <div>
                <Modal show={this.props.show}
                    onHide={this.props.closeCancelSubscription}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title style={{ fontSize: "14px", fontWeight: "600" }}>Cancel Subscription</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ color: "#000", fontSize: "14px" }}>Do you really want to cancel the subscription?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary button-css" size="sm" style={{ fontWeight: "600" }} onClick={this.props.closeCancelSubscription}>
                            Close
                         </Button>
                        {!this.props.loading ? <Button variant="primary heading-css-bg" size="sm" style={{ fontWeight: "600" }}
                            onClick={this.props.cancelSubscription}>
                            Okay
                        </Button> :
                            <Button variant="success" className="footerbtn heading-css-bg" disabled size="sm">  <Spinner animation="border" style={{ height: "20px", width: "20px" }} /></Button>
                        }

                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default CancelSubscription;