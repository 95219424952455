import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { contactUs } from "../../common/apicalls";
import { toastify } from "../../action";
import { connect } from "react-redux";

class ContactUs extends React.Component {
  state = {
    name: "",
    email: "",
    description: "",
    loader: false,
  };

  handleSubmitContactUsForm = async (e) => {
    try {
      e.preventDefault();
      this.setState({ loader: true });
      let _res = await contactUs(this.state);
      if (_res == "success") {
        this.setState({ loader: false });
        this.props.toastifyEnable("info", "Thank you for contacting us!!!");
        this.props.handleClose();
      } else {
        this.setState({ loader: false });
        this.props.toastifyEnable(
          "err",
          "Something went wrong. Please try again later"
        );
        this.props.handleClose();
      }
    } catch (err) {
      this.setState({ loader: false });
      this.props.toastifyEnable(
        "err",
        "Something went wrong. Please try again later"
      );
      this.props.handleClose();
    }
  };
  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "14px", fontWeight: "600" }}>
              Contact us
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-12">
              <form onSubmit={this.handleSubmitContactUsForm}>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    style={{ fontSize: "14px" }}
                    onChange={(e) => this.setState({ name: e.target.value })}
                    required
                  />
                </div>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    style={{ fontSize: "14px" }}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    required
                  />
                </div>
                <div className="input-group mb-3">
                  <textarea
                    className="form-control"
                    rows="7"
                    placeholder="Description"
                    style={{ fontSize: "14px" }}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    required
                  ></textarea>
                </div>

                <div
                  className="product"
                  style={{ textAlign: "right", marginTop: "20px" }}
                >
                  <Button
                    variant="secondary"
                    size="sm"
                    className="paymentbtn"
                    onClick={this.props.handleClose}
                  >
                    Close
                  </Button>
                  &nbsp;
                  {!this.state.loader ? (
                    <Button
                      type="submit"
                      size="sm"
                      variant="info"
                      className="paymentbtn paynow"
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      variant="info"
                      className="paymentbtn paynow"
                      disabled
                    >
                      <Spinner
                        animation="border"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toastifyEnable: (type, val) => dispatch(toastify(type, val)),
  };
};

export default connect(null, mapDispatchToProps)(ContactUs);
