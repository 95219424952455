import React, { Component } from "react";
import Overview from "./overview";
import NetworkNodes from "./networknodes";
import { getParticularNetworkDetails } from "../../common/networkapicalls";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import NodeAdd from "./addnodestonetwork";
import UpdateNetwork from "./updatenetwork";
import { connect } from "react-redux";
import { toastify } from "../../action";
import Map from "./displaymap";
import InviteModal from "./invite";

class DisplayNetwork extends Component {
  state = {
    networkDetails: {
      nodelist: [],
    },
    addNodeModal: false,
    allNodes: [],
    selectedIndex: -1,
    openCloseToggle: false,
    addNodeModal: false,
    owner: false,
    successMsg: false,
    showUpdateNetwork: false,
    inviteModal: false,
  };

  async componentDidMount() {
    this.getLandedNetworkDetails();
  }

  mergeById = (a1, a2) =>
    a1.map((itm) => ({
      ...a2.find((item) => item.nodeID === itm.nodeID && item),
      ...itm,
    }));

  getLandedNetworkDetails = async () => {
    try {
      if (this.props.match.params.id) {
        let _devKey = await JSON.parse(sessionStorage.getItem("defAddress"))
          .value;
        const _currentNetworkDetails = await getParticularNetworkDetails(
          this.props.match.params.id
        );
        let arr1 = _currentNetworkDetails.nodelist;
        let arr2 = _currentNetworkDetails.peerIDs;

        if (arr1) {
          arr1 = arr1;
        } else {
          arr1 = [];
        }
        if (arr2) {
          arr2 = arr2;
        } else {
          arr2 = [];
        }

        let _updatedNodeList = await this.mergeById(arr1, arr2);

        _currentNetworkDetails.nodelist = _updatedNodeList;

        this.setState({
          networkDetails: _currentNetworkDetails,
          owner:
            _currentNetworkDetails.networkOwnerID == _devKey ? true : false,
        });
      }
    } catch (err) {
      //do nothing
    }
  };

  closeNodeModal = () => {
    this.setState({ addNodeModal: false });
  };
  successModal = () => {
    this.setState({ addNodeModal: false, successMsg: true });
    this.props.toastifyEnable("info", "Node added successfully");
    this.getLandedNetworkDetails();
    setTimeout(() => {
      this.setState({ successMsg: false });
    }, 3000);
  };

  closeUpdateNetwork = () => {
    this.setState({ showUpdateNetwork: false });
  };

  closeInviteModal = () => {
    this.setState({ inviteModal: false });
  };

  inviteSuccess = () => {
    this.props.toastifyEnable("info", "Invitation sent Successfully");
  };

  updateSuccessModal = () => {
    this.setState({ showUpdateNetwork: false });
    this.props.toastifyEnable("info", "Network updated successfully");
    this.getLandedNetworkDetails();
  };

  updateModalFailure = (data) => {
    this.setState({ showUpdateNetwork: false });
    this.props.toastifyEnable("err", data);
  };

  render() {
    return (
      <div className="container" style={{ marginTop: "30px" }}>
        {this.state.addNodeModal ? (
          <NodeAdd
            nodes={this.state.networkDetails.nodelist}
            show={this.state.addNodeModal}
            closeNodeModal={this.closeNodeModal}
            successModal={this.successModal}
            networkName={this.state.networkDetails.networkName}
            networkID={this.state.networkDetails.networkID}
          />
        ) : null}
        {this.state.showUpdateNetwork ? (
          <UpdateNetwork
            show={this.state.showUpdateNetwork}
            closeUpdateNetwork={this.closeUpdateNetwork}
            network={this.state.networkDetails}
            successModal={this.updateSuccessModal}
            updateModalFailure={this.updateModalFailure}
          />
        ) : null}

        {this.state.inviteModal ? (
          <InviteModal
            show={this.state.inviteModal}
            closeInviteModal={this.closeInviteModal}
            networkDetails={this.state.networkDetails}
            inviteSuccess={this.inviteSuccess}
          />
        ) : null}

        <section id="tabs">
          <div className="col-md-12" style={{ marginBottom: "20px" }}>
            <Link to="/network" className="heading-css">
              {" "}
              <h6
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                <span>
                  <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                </span>{" "}
                Go Back
              </h6>
            </Link>
            {this.state.networkDetails !== "" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <div style={{ fontWeight: "600", fontSize: "20px" }}>
                  <span style={{ color: "#1B1C1D", fontWeight: "600" }}>
                    Network :{" "}
                  </span>
                  <span className="heading-css fontweight600">
                    {this.state.networkDetails.networkName}
                  </span>
                </div>
                <div style={{ display: "flex" }}>
                  {this.state.owner &&
                  (this.state.networkDetails.networkType == 1 ||
                  this.state.networkDetails.networkType == 3
                    ? true
                    : false) ? (
                    <div>
                      <Button
                        size="sm"
                        className="addnodecss heading-css-bg"
                        onClick={() => this.setState({ inviteModal: true })}
                      >
                        Invite
                      </Button>
                    </div>
                  ) : null}{" "}
                  &nbsp;
                  {this.state.owner ? (
                    <div>
                      <Button
                        size="sm"
                        className="addnodecss button-css"
                        onClick={() =>
                          this.setState({ showUpdateNetwork: true })
                        }
                      >
                        Update Network Details
                      </Button>
                    </div>
                  ) : null}{" "}
                  &nbsp;
                  {this.state.owner ? (
                    <div>
                      <Button
                        size="sm"
                        className="addnodecss button-css"
                        onClick={() => this.setState({ addNodeModal: true })}
                      >
                        + Add Nodes to Network
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>

          <div className="col-md-12 text-center" style={{ marginTop: "30px" }}>
            {this.state.networkDetails.nodelist.length !== 0 ? (
              <Map nodeValue={this.state.networkDetails} />
            ) : null}
          </div>

          <div className="col-md-12 text-center ">
            <div>
              <Overview data={this.state.networkDetails} />
            </div>
            <div>
              <h5 className="network__heading">Peers : </h5>
              <NetworkNodes
                networkName={this.state.networkDetails.networkName}
                nodes={this.state.networkDetails.nodelist}
                peerID={
                  this.state.networkDetails.peerIDs
                    ? this.state.networkDetails.peerIDs
                    : []
                }
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toastifyEnable: (type, val) => dispatch(toastify(type, val)),
  };
};

export default connect(null, mapDispatchToProps)(DisplayNetwork);
