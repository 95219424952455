const axios = require('axios');
const Constants = require('../lib/constants');

function constructUrl(routeType, routeName, queryParamsArray) {
    if (queryParamsArray.length != 0) {
        let queryString = '?';
        for (let queryIndex = 0; queryIndex < queryParamsArray.length; queryIndex++) {
            queryString += '&' + queryParamsArray[queryIndex].queryKey + '=' + queryParamsArray[queryIndex].queryValue
        }
        return Constants.MOI_ID_BASE_URL + '/' + routeType + '/' + routeName + '' + queryString;
    } else {
        return Constants.MOI_ID_BASE_URL + '/' + routeType + '/' + routeName;
    }
}

module.exports = async (requestParams) => {
    try {
        let targetedURL = constructUrl(requestParams.reqRouteType, requestParams.reqRouteName, requestParams.reqQueryParams);
        const _response = await axios({
            url: targetedURL,
            method: requestParams.reqMethod,
            data: requestParams.reqPayload,
            headers: requestParams.reqHeaders
        });
        return _response.data;
    } catch (e) {
        if (e.response) {
            throw new Error(e.response.data.data ? e.response.data.data : e.response.data);
        }
        else {
            throw new Error(e.message);
        }
    }
}
