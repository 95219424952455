import React, { Component } from "react";
import { Table } from "react-bootstrap";
import "./style.css";

class OverView extends Component {
    render() {
        return (
            <div style={{ marginTop: "10px" }}>
                <Table striped bordered responsive hover className="tablecss">

                    <tbody>
                        {this.props.data.networkName ? <tr>
                            <th className="network__overviewTH">Network Name</th>
                            <td className="network__overviewTColVal">{this.props.data.networkName ? this.props.data.networkName : "-"}</td>
                        </tr> : null}

                        {this.props.data.networkID ? <tr>
                            <th className="network__overviewTH">Network ID</th>
                            <td className="network__overviewTColVal">{this.props.data.networkID ? this.props.data.networkID : "-"}</td>
                        </tr> : null}

                        {this.props.data.swarmKey ? <tr>
                            <th className="network__overviewTH">Swarm Key</th>
                            <td className="network__overviewTColVal">{this.props.data.swarmKey ? this.props.data.swarmKey : "-"}</td>
                        </tr> : null}

                        {this.props.data.clustersecret ? <tr>
                            <th className="network__overviewTH">Cluster Secret</th>
                            <td className="network__overviewTColVal">{this.props.data.clustersecret ? this.props.data.clustersecret : "-"}</td>
                        </tr> : null}

                        {this.props.data.ipfsport ? <tr>
                            <th className="network__overviewTH">IPFS Port</th>
                            <td className="network__overviewTColVal">{this.props.data.ipfsport ? this.props.data.ipfsport : "-"}</td>
                        </tr> : null}

                        {this.props.data.clusterport ? <tr>
                            <th className="network__overviewTH">Cluster Port</th>
                            <td className="network__overviewTColVal">{this.props.data.clusterport ? this.props.data.clusterport : "-"}</td>
                        </tr> : null}

                        {this.props.data.minReplicationFactor ? <tr>
                            <th className="network__overviewTH">Minimum Replication Factor</th>
                            <td className="network__overviewTColVal">{this.props.data.minReplicationFactor ? this.props.data.minReplicationFactor : "-"}</td>
                        </tr> : null}

                        {this.props.data.maxReplicationFactor ? <tr>
                            <th className="network__overviewTH">Maximum Replication Factor</th>
                            <td className="network__overviewTColVal">{this.props.data.maxReplicationFactor ? this.props.data.maxReplicationFactor : "-"}</td>
                        </tr> : null}
                    </tbody>
                </Table>
            </div>
        )

    }
}

export default OverView;